var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import BaseApiClient from '@/plugins/apis/public/base-api-client';
var qs = require('qs');
var PublicContentApiClient = /** @class */ (function (_super) {
    __extends(PublicContentApiClient, _super);
    function PublicContentApiClient() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(PublicContentApiClient.prototype, "basePath", {
        get: function () {
            return '/contents';
        },
        enumerable: false,
        configurable: true
    });
    PublicContentApiClient.prototype.fetchContents = function (_a) {
        var uuid = _a.uuid, path = _a.path, offset = _a.offset, sort = _a.sort;
        var basePath = this.basePath;
        var url = basePath + "/" + uuid;
        var params = {
            path: path || '',
            offset: offset,
            sort: {
                target: sort.prop,
                order: sort.order
            }
        };
        return this.requestAction({
            method: 'get',
            resourceName: 'public_content',
            actionName: 'GET',
            url: url,
            params: params,
            notice: false,
            paramsSerializer: function (params) {
                return qs.stringify(params, {
                    arrayFormat: 'brackets',
                    encode: false
                });
            }
        });
    };
    PublicContentApiClient.prototype.download = function (_a) {
        var uuid = _a.uuid, path = _a.path;
        var basePath = this.basePath;
        var url = basePath + "/" + uuid + "/download";
        var params = {
            path: decodeURIComponent(path || '')
        };
        var config = {
            params: __assign({}, params)
        };
        return this.client.get(url, config);
    };
    return PublicContentApiClient;
}(BaseApiClient));
export default new PublicContentApiClient();
