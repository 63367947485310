var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Mixins, Component, Prop } from 'vue-property-decorator';
import { NotificationType } from '@/types/notification';
import NotificationIconUnread from '@/components/notification/atom/icon-unread.vue';
import CommonDateMixin from '@/mixins/common/date-mixin.ts';
import BaseMixin from '@/mixins/base-mixin.ts';
import NotificationBaseMixin from '@/mixins/notification/base-mixin.ts';
var NotificationListContent = /** @class */ (function (_super) {
    __extends(NotificationListContent, _super);
    function NotificationListContent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.canDisplayLogo = true;
        return _this;
    }
    // methods
    NotificationListContent.prototype.notificationClickHandler = function () {
        var notification = this.notification;
        this.readNotification(notification.id);
        this.$gtag('event', this.$gaEvent.clickNotificationItemUser);
        // FIXME $router を使う. switchに書き直す
        if (notification.type === NotificationType.CREATE_BOARD_POST_COMMENT) {
            window.location.href = notification.url + "?target_id=" + notification.board_post_comment_id;
        }
        else if (notification.type === NotificationType.CREATE_CONTENT_COMMENT) {
            window.location.href = notification.url + "?target_id=" + notification.content_comment_id;
        }
        else {
            window.location.href = notification.url;
        }
    };
    NotificationListContent.prototype.showDefaultLogo = function () {
        this.canDisplayLogo = false;
    };
    __decorate([
        Prop({ type: Object, required: true })
    ], NotificationListContent.prototype, "notification", void 0);
    NotificationListContent = __decorate([
        Component({
            components: {
                NotificationIconUnread: NotificationIconUnread
            }
        })
    ], NotificationListContent);
    return NotificationListContent;
}(Mixins(CommonDateMixin, BaseMixin, NotificationBaseMixin)));
export default NotificationListContent;
