var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
/* eslint camelcase: 0 */
import axios from 'axios';
import * as dayjs from 'dayjs';
import BaseApiClient from '@/plugins/apis/base-api-client';
import compressor from '@/plugins/compressor';
import getFileInfo from '@/plugins/get-file-info';
var ProjectApiClient = /** @class */ (function (_super) {
    __extends(ProjectApiClient, _super);
    function ProjectApiClient() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(ProjectApiClient.prototype, "basePath", {
        get: function () {
            var companyId = this.companyId;
            return "/companies/" + companyId + "/projects";
        },
        enumerable: false,
        configurable: true
    });
    ProjectApiClient.prototype.fetchProjects = function (params) {
        var _a = this, basePath = _a.basePath, client = _a.client;
        var sortParam = {
            sort: { target: 'status', order: 'asc' }
        };
        return client.get(basePath, {
            params: __assign(__assign({}, params), sortParam)
        });
    };
    ProjectApiClient.prototype.searchProjects = function (params) {
        var _a = this, basePath = _a.basePath, client = _a.client;
        var sortParam = {
            sort: { target: 'status', order: 'asc' }
        };
        return client.get(basePath, {
            params: __assign(__assign({}, params), sortParam)
        });
    };
    ProjectApiClient.prototype.fetchProject = function (id) {
        var _a = this, basePath = _a.basePath, client = _a.client;
        var url = basePath + "/" + id;
        return client.get(url);
    };
    ProjectApiClient.prototype.createProject = function (data) {
        var _a = this, basePath = _a.basePath, client = _a.client;
        var formData = new FormData();
        this.appendProjectData(data, formData);
        return client.post(basePath, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    };
    ProjectApiClient.prototype.updateProject = function (id, data) {
        var _a = this, basePath = _a.basePath, client = _a.client;
        var url = basePath + "/" + id;
        var formData = new FormData();
        this.appendProjectData(data, formData);
        return client.put(url, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    };
    ProjectApiClient.prototype.updateProjectPeriod = function (id, data) {
        var _a = this, basePath = _a.basePath, requestAction = _a.requestAction;
        var url = basePath + "/" + id;
        var startDate = dayjs(data.start_date).format('YYYY年MM月DD日');
        var endDate = dayjs(data.end_date).format('YYYY年MM月DD日');
        var message = startDate + " \u301C " + endDate;
        return requestAction('put', url, data, 'project', 'UPDATE_PERIOD', true, message);
    };
    ProjectApiClient.prototype.updateStatus = function (id, status) {
        var basePath = this.basePath;
        var url = basePath + "/" + id;
        return this.requestActionWithNamedProperty({
            method: 'put',
            url: url,
            data: {
                project: {
                    status: status
                }
            },
            resourceName: 'project',
            actionName: 'UPDATE',
            hasData: true
        });
    };
    ProjectApiClient.prototype.uploadFileS3Request = function (meta, file, config) {
        if (config === void 0) { config = {}; }
        return __awaiter(this, void 0, void 0, function () {
            var fileType, compressedFile;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, getFileInfo(file)];
                    case 1:
                        fileType = (_a.sent()).fileType;
                        return [4 /*yield*/, compressor(file)];
                    case 2:
                        compressedFile = _a.sent();
                        config.headers = { 'Content-Type': fileType.mime };
                        return [4 /*yield*/, axios.put(meta.s3_direct_upload_url, compressedFile, config)];
                    case 3:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ProjectApiClient.prototype.deleteProject = function (id) {
        var _a = this, basePath = _a.basePath, client = _a.client;
        var url = basePath + "/" + id;
        return client.delete(url);
    };
    ProjectApiClient.prototype.appendProjectData = function (data, formData) {
        Object.keys(data).forEach(function (key) {
            if (data[key] === null || data[key] === undefined) {
                data[key] = '';
            }
            if (!Array.isArray(data[key])) {
                formData.append("project[" + key + "]", data[key]);
            }
            else {
                // 条件式より、data[key]は配列であることが証明済
                // なので、強制的にstring[]に変換
                var arrayData = data[key];
                arrayData.forEach(function (value) {
                    formData.append("project[" + key + "][]", value);
                });
            }
        });
    };
    ProjectApiClient.prototype.fetchPlaces = function (id) {
        var _a = this, basePath = _a.basePath, client = _a.client;
        var url = basePath + "/" + id + "/places";
        return client.get(url);
    };
    ProjectApiClient.prototype.suggest = function (text) {
        var _a = this, basePath = _a.basePath, client = _a.client;
        return client.get(basePath + "/suggest", { params: { text: text } });
    };
    ProjectApiClient.prototype.fetchSearchHistories = function () {
        var _a = this, companyId = _a.companyId, client = _a.client;
        return client.get("/companies/" + companyId + "/project_search_histories");
    };
    ProjectApiClient.prototype.deleteSearchHistory = function (id) {
        var _a = this, companyId = _a.companyId, client = _a.client;
        return client.delete("/companies/" + companyId + "/project_search_histories/" + id);
    };
    ProjectApiClient.prototype.createBookmark = function (data) {
        var _a = this, companyId = _a.companyId, client = _a.client;
        return client.post("/companies/" + companyId + "/project_bookmarks", data);
    };
    ProjectApiClient.prototype.fetchBookmarks = function () {
        var _a = this, companyId = _a.companyId, client = _a.client;
        return client.get("/companies/" + companyId + "/project_bookmarks");
    };
    ProjectApiClient.prototype.updateBookmark = function (_a) {
        var id = _a.id, sort_num = _a.sort_num;
        var _b = this, companyId = _b.companyId, client = _b.client;
        return client.patch("/companies/" + companyId + "/project_bookmarks/" + id, { sort_num: sort_num });
    };
    ProjectApiClient.prototype.deleteBookmark = function (id) {
        var _a = this, companyId = _a.companyId, client = _a.client;
        return client.delete("/companies/" + companyId + "/project_bookmarks/" + id);
    };
    return ProjectApiClient;
}(BaseApiClient));
export default new ProjectApiClient();
