// カスタムマッピング
var MyMime = require('mime/Mime');
var typeMap = {
    'image/icns': ['icns'],
    'application/keynote': ['key']
};
var myMime = new MyMime(typeMap);
export default {
    getType: function (ext) {
        return myMime.getType(ext);
    },
    getExtension: function (contentType) {
        return myMime.getExtension(contentType);
    }
};
