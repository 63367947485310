var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Loading } from 'element-ui';
// テキストカラーはelement-uiのprimary colorが適応されます
var DefaultOption = {
    lock: true,
    text: 'Loading',
    spinner: 'el-icon-loading',
    background: 'rgba(0, 0, 0, 0.7)'
};
/*
// start
const loading = showLoading()

// stop
loading.close()
*/
var showLoading = function (option) {
    if (option === void 0) { option = {}; }
    return Loading.service(__assign(__assign({}, DefaultOption), option));
};
export default showLoading;
