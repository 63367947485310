import Compressor from 'compressorjs';
// 圧縮対象のファイルタイプ一覧
var CompressTargetFile = ['image/png', 'image/jpeg', 'image/jpg'];
export var isImage = function (type) {
    return CompressTargetFile.includes(type);
};
export default (function (file) {
    // 画像以外は圧縮しません
    if (!isImage(file.type)) {
        return Promise.resolve(file);
    }
    return new Promise(function (resolve) {
        /* eslint no-new: 0 */
        new Compressor(file, {
            checkOrientation: true,
            quality: 0.8,
            success: function (result) {
                resolve(result);
            },
            error: function () {
                resolve(file);
            }
        });
    });
});
