var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Vue, Component, Prop } from 'vue-property-decorator';
import MaterialIcons from '@/components/material-icons.vue';
var ProgressTypeIcon = /** @class */ (function (_super) {
    __extends(ProgressTypeIcon, _super);
    function ProgressTypeIcon() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(ProgressTypeIcon.prototype, "iconName", {
        get: function () {
            var progressStatus = this.progressStatus;
            switch (progressStatus) {
                case 'success':
                    return 'check_circle';
                case 'warning':
                    return 'error';
                case 'exception':
                    return 'highlight_off';
            }
        },
        enumerable: false,
        configurable: true
    });
    __decorate([
        Prop({ type: String })
    ], ProgressTypeIcon.prototype, "progressStatus", void 0);
    ProgressTypeIcon = __decorate([
        Component({
            components: {
                MaterialIcons: MaterialIcons
            }
        })
    ], ProgressTypeIcon);
    return ProgressTypeIcon;
}(Vue));
export default ProgressTypeIcon;
