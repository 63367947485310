var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _a;
import { SearchContentMutationTypes } from '@/types/search/content';
import contentApiClient from '@/plugins/apis/content-api-client';
export var state = function () { return ({
    defaultSort: {
        prop: 'name',
        order: 'desc'
    },
    contents: [],
    paginate: {
        current_limit: 0,
        current_offset: 0,
        total_count: 0
    },
    searchForm: {
        name: '',
        type: '',
        date: [],
        project_id: ''
    }
}); };
export var getters = {
    contents: function (state) {
        return state.contents;
    },
    defaultSort: function (state) {
        return state.defaultSort;
    },
    paginate: function (state) {
        return state.paginate;
    },
    searchForm: function (state) {
        return state.searchForm;
    }
};
export var mutations = (_a = {},
    _a[SearchContentMutationTypes.INIT_STATE] = function (state, payload) {
        Object.assign(state, payload);
    },
    _a[SearchContentMutationTypes.SET_SORT_TYPE] = function (state, payload) {
        state.defaultSort = payload;
    },
    _a[SearchContentMutationTypes.SET_SEARCH_FORM] = function (state, payload) {
        state.searchForm = payload;
    },
    _a[SearchContentMutationTypes.RESET_LIST] = function (state) {
        state.contents = [];
    },
    _a[SearchContentMutationTypes.SEARCH_CONTENTS] = function (state, _a) {
        var contents = _a.contents, paginate = _a.paginate;
        var ids = state.contents.map(function (c) { return c.id; });
        var filterdList = contents.filter(function (c) {
            return !ids.includes(c.id);
        });
        state.contents = state.contents.concat(filterdList);
        state.paginate = paginate;
    },
    _a);
export var actions = {
    initState: function (_a) {
        var commit = _a.commit;
        commit(SearchContentMutationTypes.INIT_STATE, state());
    },
    changeSort: function (_a, payload) {
        var commit = _a.commit, dispatch = _a.dispatch;
        commit(SearchContentMutationTypes.SET_SORT_TYPE, payload);
        return dispatch('resetList');
    },
    setSearchForm: function (_a, payload) {
        var commit = _a.commit;
        commit(SearchContentMutationTypes.SET_SEARCH_FORM, payload);
    },
    resetList: function (_a) {
        var commit = _a.commit;
        commit(SearchContentMutationTypes.RESET_LIST);
    },
    initContents: function (_a, payload) {
        var dispatch = _a.dispatch;
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, dispatch('resetList')];
                    case 1:
                        _b.sent();
                        return [2 /*return*/, dispatch('fetchContents', payload)];
                }
            });
        });
    },
    // 初期一覧をルートの一覧にする・フォルダを開いていけるようにする
    fetchContents: function (_a, payload) {
        var commit = _a.commit, getters = _a.getters, rootGetters = _a.rootGetters;
        return __awaiter(this, void 0, void 0, function () {
            var defaultSort, currentContents, params, res, _b, contents, paginate;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        defaultSort = getters.defaultSort, currentContents = getters.contents;
                        params = {
                            plan_code: rootGetters['initial-setting/plan'].code,
                            offset: currentContents.length,
                            sort: {
                                target: defaultSort.prop,
                                order: defaultSort.order
                            }
                        };
                        return [4 /*yield*/, contentApiClient.fetchContents(payload.id, params)];
                    case 1:
                        res = _c.sent();
                        _b = res.data, contents = _b.contents, paginate = _b.meta.paginate;
                        commit(SearchContentMutationTypes.SEARCH_CONTENTS, { contents: contents, paginate: paginate });
                        return [2 /*return*/];
                }
            });
        });
    },
    initSearchContents: function (_a, payload) {
        var dispatch = _a.dispatch;
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, dispatch('resetList')];
                    case 1:
                        _b.sent();
                        return [2 /*return*/, dispatch('searchContents', payload)];
                }
            });
        });
    },
    searchContents: function (_a, payload) {
        var commit = _a.commit, getters = _a.getters, rootGetters = _a.rootGetters;
        return __awaiter(this, void 0, void 0, function () {
            var defaultSort, currentContents, params, res, _b, contents, paginate;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        defaultSort = getters.defaultSort, currentContents = getters.contents;
                        params = {
                            name: payload.name,
                            type: payload.type,
                            plan_code: rootGetters['initial-setting/plan'].code,
                            offset: payload.reset ? 0 : currentContents.length,
                            sort: {
                                target: defaultSort.prop,
                                order: defaultSort.order
                            }
                        };
                        if (Array.isArray(payload.date)) {
                            params.start_date = payload.date[0];
                            params.end_date = payload.date[1];
                        }
                        if (payload.project_id !== '') {
                            params.project_id = payload.project_id;
                        }
                        return [4 /*yield*/, contentApiClient.searchContents(params)];
                    case 1:
                        res = _c.sent();
                        _b = res.data, contents = _b.contents, paginate = _b.meta.paginate;
                        if (payload.reset) {
                            commit(SearchContentMutationTypes.RESET_LIST);
                        }
                        commit(SearchContentMutationTypes.SEARCH_CONTENTS, { contents: contents, paginate: paginate });
                        return [2 /*return*/];
                }
            });
        });
    }
};
