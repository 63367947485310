var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import BaseApiClient from '@/plugins/apis/base-api-client';
var SharingContentApi = /** @class */ (function (_super) {
    __extends(SharingContentApi, _super);
    function SharingContentApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(SharingContentApi.prototype, "basePath", {
        get: function () {
            var companyId = this.companyId;
            return "/companies/" + companyId + "/sharing_contents";
        },
        enumerable: false,
        configurable: true
    });
    SharingContentApi.prototype.fetchContents = function (_a) {
        var sort = _a.sort, offset = _a.offset;
        var basePath = this.basePath;
        return this.requestActionWithNamedProperty({
            method: 'get',
            url: basePath,
            params: {
                sort: {
                    target: sort.prop,
                    order: sort.order
                },
                offset: offset
            },
            resourceName: 'public_content',
            actionName: 'GET',
            notice: false
        });
    };
    SharingContentApi.prototype.createLink = function (contentId) {
        var basePath = this.basePath;
        var url = "" + basePath;
        var data = {
            sharing_content: {
                content_id: contentId
            }
        };
        return this.requestActionWithNamedProperty({
            method: 'post',
            url: url,
            data: data,
            resourceName: 'sharing_content',
            actionName: 'CREATE'
        });
    };
    SharingContentApi.prototype.updateLink = function (sharingContentId, expiresIn) {
        var basePath = this.basePath;
        var url = basePath + "/" + sharingContentId;
        var data = {
            sharing_content: {
                expires_in: expiresIn
            }
        };
        return this.requestActionWithNamedProperty({
            method: 'put',
            url: url,
            data: data,
            hasData: true,
            resourceName: 'sharing_content',
            actionName: 'UPDATE'
        });
    };
    SharingContentApi.prototype.removeLink = function (sharingContentId) {
        var basePath = this.basePath;
        var url = basePath + "/" + sharingContentId;
        return this.requestActionWithNamedProperty({
            method: 'delete',
            url: url,
            hasData: true,
            resourceName: 'sharing_content',
            actionName: 'DELETE'
        });
    };
    return SharingContentApi;
}(BaseApiClient));
export default new SharingContentApi();
