var ByteUnit;
(function (ByteUnit) {
    ByteUnit[ByteUnit["KB"] = 1024] = "KB";
    ByteUnit[ByteUnit["MB"] = 1048576] = "MB";
    ByteUnit[ByteUnit["GB"] = 1073741824] = "GB";
})(ByteUnit || (ByteUnit = {}));
var byteUnit = function (value) {
    if (!Number.isInteger(value)) {
        return '---';
    }
    if (value > ByteUnit.GB) {
        return (value / ByteUnit.GB).toFixed(2) + "GB";
    }
    if (value > ByteUnit.MB) {
        return (value / ByteUnit.MB).toFixed(2) + "MB";
    }
    if (value > ByteUnit.KB) {
        return (value / ByteUnit.KB).toFixed(2) + "KB";
    }
    return value + "B";
};
export default byteUnit;
