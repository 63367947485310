var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Mixins, Component, Prop, Emit } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import ProgressTypeIcon from '@/components/progress-type-icon.vue';
import BlockUnloadMixin from '@/mixins/block-unload-mixin';
var ContentModule = namespace('content');
var FileUploader = /** @class */ (function (_super) {
    __extends(FileUploader, _super);
    function FileUploader() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.success = 'success';
        _this.warning = 'warning';
        _this.exception = 'exception';
        _this.blockUnloadMessage = 'ファイルのアップロードが中断されますがページを移動しますか？';
        // data
        // progress詳細ダイアログ表示フラグ
        _this.visible = true;
        return _this;
    }
    Object.defineProperty(FileUploader.prototype, "totalProgress", {
        // computed
        get: function () {
            if (this.progresses.length === 0) {
                return 0;
            }
            var sum = this.progresses.reduce(function (sum, obj) {
                if (obj.status) {
                    sum += 1;
                }
                return sum;
            }, 0);
            return Math.floor((sum / this.progresses.length) * 100);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(FileUploader.prototype, "totalStatus", {
        // エラーになったファイルが存在するときなどは前提のprogressのステータスも変更します
        get: function () {
            var _a = this, totalProgress = _a.totalProgress, progresses = _a.progresses;
            var hasError = progresses.some(function (prog) {
                return prog.status === 'exception' || prog.status === 'warning';
            });
            if (hasError) {
                return 'warning';
            }
            return totalProgress >= 100 ? 'success' : null;
        },
        enumerable: false,
        configurable: true
    });
    FileUploader.prototype.completeEmitter = function () {
        this.isBlockUnload = false;
    };
    FileUploader.prototype.cancelEmitter = function () {
        this.isBlockUnload = false;
    };
    FileUploader.prototype.showDialogHandler = function () {
        this.visible = true;
    };
    FileUploader.prototype.cancelHandler = function () {
        this.cancelEmitter();
    };
    FileUploader.prototype.closeHandler = function () {
        this.visible = false;
    };
    FileUploader.prototype.downloadingLength = function (progresses) {
        var downloadingLength = 1;
        progresses.forEach(function (progress) {
            if (progress.status === 'success' && downloadingLength < progresses.length) {
                downloadingLength += 1;
            }
        });
        return downloadingLength;
    };
    __decorate([
        Prop({ type: Array })
    ], FileUploader.prototype, "progresses", void 0);
    __decorate([
        ContentModule.Action('uploadContent')
    ], FileUploader.prototype, "uploadContent", void 0);
    __decorate([
        Emit('complete')
    ], FileUploader.prototype, "completeEmitter", null);
    __decorate([
        Emit('cancel')
    ], FileUploader.prototype, "cancelEmitter", null);
    FileUploader = __decorate([
        Component({
            components: {
                ProgressTypeIcon: ProgressTypeIcon
            }
        })
    ], FileUploader);
    return FileUploader;
}(Mixins(BlockUnloadMixin)));
export default FileUploader;
