var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import debounce from '@/plugins/debounce';
import showErrorMessage from '@/plugins/show-error-message';
var ProjectModule = namespace('project');
var ProjectBookmarkMixin = /** @class */ (function (_super) {
    __extends(ProjectBookmarkMixin, _super);
    function ProjectBookmarkMixin() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.isDebouncing = false;
        return _this;
    }
    Object.defineProperty(ProjectBookmarkMixin.prototype, "isBookmarked", {
        get: function () {
            var _a = this, bookmarks = _a.bookmarks, projectId = _a.projectId;
            return !!bookmarks.find(function (_a) {
                var project = _a.project;
                return project.id === projectId;
            });
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ProjectBookmarkMixin.prototype, "currentBookmark", {
        get: function () {
            var _a = this, projectId = _a.projectId, bookmarks = _a.bookmarks;
            return bookmarks.find(function (_a) {
                var project = _a.project;
                return project.id === projectId;
            });
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ProjectBookmarkMixin.prototype, "bookmarkTooltipMessage", {
        get: function () {
            var isBookmarked = this.isBookmarked;
            return isBookmarked ? 'ブックマークを解除' : 'ブックマークに追加';
        },
        enumerable: false,
        configurable: true
    });
    ProjectBookmarkMixin.prototype.request = function (requestMethod, payload) {
        var _this = this;
        this.isDebouncing = true;
        debounce(function () {
            requestMethod(payload)
                .catch(function () {
                showErrorMessage('エラーが発生しました', {
                    message: 'しばらくしてから画面を読み込み直してください'
                });
            })
                .finally(function () {
                _this.isDebouncing = false;
            });
        }, 0.8 * 1000);
    };
    ProjectBookmarkMixin.prototype.changeBookmarkHandler = function () {
        var _a = this, currentBookmark = _a.currentBookmark, projectId = _a.projectId;
        if (currentBookmark) {
            this.request(this.deleteBookmark, currentBookmark.id);
            this.$gtag('event', this.$gaEvent.actionDeleteBookmark);
        }
        else {
            this.request(this.createBookmark, projectId);
            this.$gtag('event', this.$gaEvent.actionCreateBookmark);
        }
    };
    __decorate([
        Prop({ type: String, required: false })
    ], ProjectBookmarkMixin.prototype, "projectId", void 0);
    __decorate([
        ProjectModule.Getter('bookmarks')
    ], ProjectBookmarkMixin.prototype, "bookmarks", void 0);
    __decorate([
        ProjectModule.Action('createBookmark')
    ], ProjectBookmarkMixin.prototype, "createBookmark", void 0);
    __decorate([
        ProjectModule.Action('deleteBookmark')
    ], ProjectBookmarkMixin.prototype, "deleteBookmark", void 0);
    __decorate([
        ProjectModule.Action('sortBookmarks')
    ], ProjectBookmarkMixin.prototype, "sortBookmarks", void 0);
    ProjectBookmarkMixin = __decorate([
        Component
    ], ProjectBookmarkMixin);
    return ProjectBookmarkMixin;
}(Vue));
export default ProjectBookmarkMixin;
