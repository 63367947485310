import axios from 'axios';
var qs = require('qs');
var paramsSerializer = function (params) {
    return qs.stringify(params, {
        arrayFormat: 'brackets'
    });
};
export default axios.create({
    baseURL: process.env.workApiBaseUrl + "/api/v1/",
    headers: {
        ContentType: 'application/json',
        'X-Device-UUID': null
    },
    paramsSerializer: paramsSerializer
});
