export var ScheduleHeight = {
    navigation: 61,
    openedPlanRow: 128,
    closedRow: 31,
    scrollBar: 10
};
export var ScheduleTypes;
(function (ScheduleTypes) {
    ScheduleTypes["Basic"] = "basic";
    ScheduleTypes["Changed"] = "changed";
    ScheduleTypes["View"] = "view";
    ScheduleTypes["Print"] = "print";
    ScheduleTypes["Preview"] = "preview";
})(ScheduleTypes || (ScheduleTypes = {}));
export var ScheduleMutationTypes;
(function (ScheduleMutationTypes) {
    ScheduleMutationTypes["FETCH_SCHEDULE"] = "FETCH_SCHEDULE";
    ScheduleMutationTypes["FETCH_SCHEDULES"] = "FETCH_SCHEDULES";
    ScheduleMutationTypes["FETCH_PUBLISHED_SCHEDULE"] = "FETCH_PUBLISHED_SCHEDULE";
    ScheduleMutationTypes["FETCH_PRINT_SETTINGS"] = "FETCH_PRINT_SETTINGS";
    ScheduleMutationTypes["FETCH_PUBLISHED_SCHEDULES"] = "FETCH_PUBLISHED_SCHEDULES";
    ScheduleMutationTypes["FETCH_LARGE_PROCESSES"] = "FETCH_LARGE_PROCESSES";
    ScheduleMutationTypes["SORT_LARGE_PROCESSES"] = "SORT_LARGE_PROCESSES";
    ScheduleMutationTypes["FETCH_SMALL_PROCESSES"] = "FETCH_SMALL_PROCESSES";
    ScheduleMutationTypes["FETCH_PUBLISHED_LARGE_PROCESSES"] = "FETCH_PUBLISHED_LARGE_PROCESSES";
    ScheduleMutationTypes["FETCH_PUBLISHED_SMALL_PROCESSES"] = "FETCH_PUBLISHED_SMALL_PROCESSES";
    ScheduleMutationTypes["CREATE_SCHEDULE_PLAN"] = "CREATE_SCHEDULE_PLAN";
    ScheduleMutationTypes["FETCH_SCHEDULE_PLANS"] = "FETCH_SCHEDULE_PLANS";
    ScheduleMutationTypes["UPDATE_SCHEDULE_PLAN"] = "UPDATE_SCHEDULE_PLAN";
    ScheduleMutationTypes["DELETE_SCHEDULE_PLAN"] = "DELETE_SCHEDULE_PLAN";
    ScheduleMutationTypes["FETCH_PUBLISHED_SCHEDULE_PLANS"] = "FETCH_PUBLISHED_SCHEDULE_PLANS";
    ScheduleMutationTypes["EXIST_SCHEDULE"] = "EXIST_SCHEDULE";
    ScheduleMutationTypes["EXIST_PUBLISHED_SCHEDULE"] = "EXIST_PUBLISHED_SCHEDULE";
    ScheduleMutationTypes["DELETE_SCHEDULE"] = "DELETE_SCHEDULE";
    ScheduleMutationTypes["CREATE_LARGE_PROCESS"] = "CREATE_LARGE_PROCESS";
    ScheduleMutationTypes["UPDATE_LARGE_PROCESS"] = "UPDATE_LARGE_PROCESS";
    ScheduleMutationTypes["DELETE_LARGE_PROCESS"] = "DELETE_LARGE_PROCESS";
    ScheduleMutationTypes["CREATE_SMALL_PROCESS"] = "CREATE_SMALL_PROCESS";
    ScheduleMutationTypes["UPDATE_SMALL_PROCESS"] = "UPDATE_SMALL_PROCESS";
    ScheduleMutationTypes["DELETE_SMALL_PROCESS"] = "DELETE_SMALL_PROCESS";
    ScheduleMutationTypes["CREATE_PUBLISHED_SCHEDULE"] = "CREATE_PUBLISHED_SCHEDULE";
    ScheduleMutationTypes["UPDATE_PUBLISHED_SCHEDULE"] = "UPDATE_PUBLISHED_SCHEDULE";
    ScheduleMutationTypes["CHANGE_GANTT_WIDTH"] = "CHANGE_GANTT_WIDTH";
    ScheduleMutationTypes["CHANGE_GANTT_CHART_POSITION"] = "CHANGE_GANTT_CHART_POSITION";
    ScheduleMutationTypes["UPDATE_LARGE_PROCESS_FILETER_STATUSES"] = "UPDATE_LARGE_PROCESS_FILETER_STATUSES";
    ScheduleMutationTypes["CHANGE_SIDEMENU_HEIGHT"] = "CHANGE_SIDEMENU_HEIGHT";
    ScheduleMutationTypes["CHANGE_IS_SCHEDULE_STATUS"] = "CHANGE_IS_SCHEDULE_STATUS";
    ScheduleMutationTypes["CHANGE_IS_OPENED_STATUS_DIALOG"] = "CHANGE_IS_OPENED_STATUS_DIALOG";
    ScheduleMutationTypes["UPDATE_SCHEDULE_PLANS_OPENED"] = "UPDATE_SCHEDULE_PLANS_OPENED";
    ScheduleMutationTypes["UPDATE_LARGE_PROCESS_OPENED"] = "UPDATE_LARGE_PROCESS_OPENED";
    ScheduleMutationTypes["UPDATE_PUBLISHED_LARGE_PROCESS_OPENED"] = "UPDATE_PUBLISHED_LARGE_PROCESS_OPENED";
    ScheduleMutationTypes["UPDATE_IS_OPENED_ANY_LIST_ITEM"] = "UPDATE_IS_OPENED_ANY_LIST_ITEM";
})(ScheduleMutationTypes || (ScheduleMutationTypes = {}));
