var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _a;
import axios from 'axios';
import Vue from 'vue';
import projectApiClient from '@/plugins/apis/project-api-client';
import projectUserApiClient from '@/plugins/apis/project-user-api-client';
import { ProjectMutationTypes, ProjectStatus } from '@/types/project';
var CancelToken = axios.CancelToken;
export var state = function () { return ({
    projects: [],
    project: {
        id: '',
        title: '',
        customer_id: '',
        content_id: '',
        construction_kinds: [],
        status: ProjectStatus.waiting,
        cover_image: '',
        cover_image_name: '',
        saturday_off: false,
        sunday_off: false,
        holiday_off: false,
        invitation_link_expires_in: '',
        invitation_link: '',
        invitation_link_expired: true,
        users: [],
        customer: {
            id: '',
            name: '',
            content_id: '',
            policies: {
                destroy: false,
                show: false,
                update: false
            }
        },
        policies: {
            update: false,
            destroy: false,
            show: false,
            invite_members: false
        }
    },
    paginate: {
        current_limit: 0,
        current_offset: 0,
        total_count: 0
    },
    places: {
        convenience_store: [],
        parking: [],
        home_center: []
    },
    bookmarks: []
}); };
export var getters = {
    projects: function (state) {
        if (!state.projects.length) {
            return state.projects;
        }
        return JSON.parse(JSON.stringify(state.projects));
    },
    project: function (state) {
        return state.project;
    },
    paginate: function (state) {
        return state.paginate;
    },
    mapAddress: function (state) {
        return state.project.prefecture_name + state.project.city;
    },
    places: function (state) {
        var dumpPlaces = JSON.parse(JSON.stringify(state.places));
        for (var key in dumpPlaces) {
            dumpPlaces[key].sort(function (a, b) {
                return a.distance - b.distance;
            });
        }
        return dumpPlaces;
    },
    bookmarks: function (state) {
        return state.bookmarks;
    }
};
export var mutations = (_a = {},
    _a[ProjectMutationTypes.INIT_STATE] = function (state, payload) {
        Object.assign(state, payload);
    },
    _a[ProjectMutationTypes.INIT_PROJECTS] = function (state, _a) {
        var projects = _a.projects, paginate = _a.paginate;
        state.projects = projects;
        state.paginate = paginate;
    },
    _a[ProjectMutationTypes.FETCH_PROJECTS] = function (state, _a) {
        var projects = _a.projects, paginate = _a.paginate;
        var ids = state.projects.map(function (c) { return c.id; });
        var filteredList = projects.filter(function (c) {
            return !ids.includes(c.id);
        });
        state.projects = state.projects.concat(filteredList);
        state.paginate = paginate;
    },
    _a[ProjectMutationTypes.FETCH_PROJECT] = function (state, payload) {
        state.project = payload;
    },
    _a[ProjectMutationTypes.DELETE_PROJECT] = function (state, payload) {
        var index = state.projects.findIndex(function (p) { return p.id === payload; });
        if (index !== -1) {
            Vue.delete(state.projects, index);
        }
    },
    _a[ProjectMutationTypes.FETCH_PLACES] = function (state, payload) {
        state.places = payload;
    },
    _a[ProjectMutationTypes.CLEAR_PLACES] = function (state) {
        state.places.convenience_store = [];
        state.places.parking = [];
        state.places.home_center = [];
    },
    _a[ProjectMutationTypes.CREATE_INVITATION_LINK] = function (state, payload) {
        state.project.invitation_link = payload.invitation_link;
        state.project.invitation_link_expires_in = payload.invitation_link_expires_in;
        state.project.invitation_link_expired = false;
    },
    _a[ProjectMutationTypes.DELETE_INVITATION_LINK] = function (state) {
        state.project.invitation_link = '';
        state.project.invitation_link_expires_in = '';
        state.project.invitation_link_expired = true;
    },
    _a[ProjectMutationTypes.CHANGE_STATUS] = function (state, payload) {
        var index = state.projects.findIndex(function (p) { return p.id === payload.id; });
        if (index !== -1) {
            state.projects[index].status = payload.status;
        }
    },
    _a[ProjectMutationTypes.FETCH_BOOKMARKS] = function (state, payload) {
        state.bookmarks = payload;
    },
    _a[ProjectMutationTypes.SORT_BOOKMARKS] = function (state, payload) {
        var target_index = state.bookmarks.findIndex(function (bookmark) { return payload.id === bookmark.id; });
        if (target_index === -1)
            return;
        state.bookmarks.splice(target_index, 1);
        state.bookmarks.splice(payload.sort_num - 1, 0, payload);
        state.bookmarks.forEach(function (bookmark, index) {
            bookmark.sort_num = index + 1;
        });
    },
    _a);
export var actions = {
    initState: function (_a) {
        var commit = _a.commit;
        commit(ProjectMutationTypes.INIT_STATE, state());
    },
    initProjects: function (_a) {
        var commit = _a.commit;
        return __awaiter(this, void 0, void 0, function () {
            var params, res, _b, projects, paginate;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        params = { offset: 0 };
                        return [4 /*yield*/, projectApiClient.fetchProjects(params)];
                    case 1:
                        res = _c.sent();
                        _b = res.data, projects = _b.projects, paginate = _b.meta.paginate;
                        commit(ProjectMutationTypes.INIT_PROJECTS, { projects: projects, paginate: paginate });
                        return [2 /*return*/];
                }
            });
        });
    },
    fetchProjects: function (_a) {
        var getters = _a.getters, commit = _a.commit;
        return __awaiter(this, void 0, void 0, function () {
            var currentProjects, params, res, _b, projects, paginate;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        currentProjects = getters.projects;
                        params = { offset: currentProjects.length };
                        return [4 /*yield*/, projectApiClient.fetchProjects(params)];
                    case 1:
                        res = _c.sent();
                        _b = res.data, projects = _b.projects, paginate = _b.meta.paginate;
                        commit(ProjectMutationTypes.FETCH_PROJECTS, { projects: projects, paginate: paginate });
                        return [2 /*return*/];
                }
            });
        });
    },
    fetchProject: function (_a, payload) {
        var commit = _a.commit, dispatch = _a.dispatch;
        return __awaiter(this, void 0, void 0, function () {
            var res;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, projectApiClient.fetchProject(payload)];
                    case 1:
                        res = _b.sent();
                        commit(ProjectMutationTypes.FETCH_PROJECT, res.data.project);
                        dispatch('policy/fetchPolicies', res.data.meta.policies, {
                            root: true
                        });
                        return [2 /*return*/];
                }
            });
        });
    },
    createProject: function (_, payload) {
        return __awaiter(this, void 0, void 0, function () {
            var file, coverImageName, res, canceller;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        file = payload.cover_image;
                        coverImageName = '';
                        payload.cover_image = '';
                        if (file) {
                            coverImageName = file.name;
                        }
                        return [4 /*yield*/, projectApiClient.createProject(Object.assign(payload, { cover_image_name: coverImageName }))];
                    case 1:
                        res = _a.sent();
                        canceller = CancelToken.source();
                        if (!(res.status === 200 && res.data.meta && file)) return [3 /*break*/, 3];
                        return [4 /*yield*/, projectApiClient.uploadFileS3Request(res.data.meta, file, {
                                cancelToken: canceller.token
                            })];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3: return [2 /*return*/, res];
                }
            });
        });
    },
    updateProject: function (_a, payload) {
        var commit = _a.commit;
        return __awaiter(this, void 0, void 0, function () {
            var id, params, file, coverImageName, res, canceller;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        id = payload.id, params = payload.params;
                        file = params.cover_image;
                        coverImageName = '';
                        params.cover_image = '';
                        if (file) {
                            coverImageName = file.name;
                        }
                        return [4 /*yield*/, projectApiClient.updateProject(id, Object.assign(params, { cover_image_name: coverImageName }))];
                    case 1:
                        res = _b.sent();
                        canceller = CancelToken.source();
                        if (!(res.status === 200 && res.data.meta && file)) return [3 /*break*/, 3];
                        return [4 /*yield*/, projectApiClient.uploadFileS3Request(res.data.meta, file, {
                                cancelToken: canceller.token
                            })];
                    case 2:
                        _b.sent();
                        _b.label = 3;
                    case 3:
                        commit(ProjectMutationTypes.FETCH_PROJECT, res.data.project);
                        return [2 /*return*/];
                }
            });
        });
    },
    updateProjectPeriod: function (_a, payload) {
        var commit = _a.commit;
        return __awaiter(this, void 0, void 0, function () {
            var id, params, res;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        id = payload.id, params = payload.params;
                        return [4 /*yield*/, projectApiClient.updateProjectPeriod(id, params)];
                    case 1:
                        res = _b.sent();
                        commit(ProjectMutationTypes.FETCH_PROJECT, res.data.project);
                        return [2 /*return*/];
                }
            });
        });
    },
    // 作成・更新時はフロントソート処理を行い現在の一覧を保持します
    deleteProject: function (_a, payload) {
        var commit = _a.commit, dispatch = _a.dispatch;
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, projectApiClient.deleteProject(payload)];
                    case 1:
                        _b.sent();
                        commit(ProjectMutationTypes.DELETE_PROJECT, payload);
                        dispatch('fetchBookmarks');
                        return [2 /*return*/];
                }
            });
        });
    },
    fetchPlaces: function (_a, payload) {
        var commit = _a.commit;
        return __awaiter(this, void 0, void 0, function () {
            var res;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, projectApiClient.fetchPlaces(payload)];
                    case 1:
                        res = _b.sent();
                        commit(ProjectMutationTypes.FETCH_PLACES, res.data);
                        return [2 /*return*/];
                }
            });
        });
    },
    clearPlaces: function (_a) {
        var commit = _a.commit;
        commit(ProjectMutationTypes.CLEAR_PLACES);
    },
    createInvitationLink: function (_a, payload) {
        var commit = _a.commit;
        return __awaiter(this, void 0, void 0, function () {
            var res;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, projectUserApiClient.createInvitationLink(payload)];
                    case 1:
                        res = _b.sent();
                        return [4 /*yield*/, navigator.clipboard.writeText(res.data.invitation_link)];
                    case 2:
                        _b.sent();
                        commit(ProjectMutationTypes.CREATE_INVITATION_LINK, res.data);
                        return [2 /*return*/];
                }
            });
        });
    },
    deleteInvitationLink: function (_a, payload) {
        var commit = _a.commit;
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, projectUserApiClient.deleteIvitationLink(payload)];
                    case 1:
                        _b.sent();
                        commit(ProjectMutationTypes.DELETE_INVITATION_LINK);
                        return [2 /*return*/];
                }
            });
        });
    },
    changeStatus: function (_a, payload) {
        var commit = _a.commit;
        return __awaiter(this, void 0, void 0, function () {
            var project, status;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        project = payload.project, status = payload.status;
                        return [4 /*yield*/, projectApiClient.updateStatus(project.id, status)];
                    case 1:
                        _b.sent();
                        commit(ProjectMutationTypes.CHANGE_STATUS, { id: project.id, status: status });
                        return [2 /*return*/];
                }
            });
        });
    },
    createBookmark: function (_a, payload) {
        var dispatch = _a.dispatch;
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, projectApiClient.createBookmark({ project_id: payload })];
                    case 1:
                        _b.sent();
                        dispatch('fetchBookmarks');
                        return [2 /*return*/];
                }
            });
        });
    },
    fetchBookmarks: function (_a) {
        var commit = _a.commit;
        return __awaiter(this, void 0, void 0, function () {
            var project_bookmarks;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, projectApiClient.fetchBookmarks()];
                    case 1:
                        project_bookmarks = (_b.sent()).data.project_bookmarks;
                        commit(ProjectMutationTypes.FETCH_BOOKMARKS, project_bookmarks);
                        return [2 /*return*/];
                }
            });
        });
    },
    deleteBookmark: function (_a, payload) {
        var dispatch = _a.dispatch;
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, projectApiClient.deleteBookmark(payload)];
                    case 1:
                        _b.sent();
                        dispatch('fetchBookmarks');
                        return [2 /*return*/];
                }
            });
        });
    },
    sortBookmarks: function (_a, _b) {
        var commit = _a.commit, state = _a.state;
        var newIndex = _b.newIndex, oldIndex = _b.oldIndex;
        return __awaiter(this, void 0, void 0, function () {
            var id, project_bookmark;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        id = state.bookmarks[oldIndex].id;
                        return [4 /*yield*/, projectApiClient.updateBookmark({ id: id, sort_num: newIndex + 1 })];
                    case 1:
                        project_bookmark = (_c.sent()).data.project_bookmark;
                        commit(ProjectMutationTypes.SORT_BOOKMARKS, project_bookmark);
                        return [2 /*return*/];
                }
            });
        });
    }
};
