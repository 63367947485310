var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
/* eslint camelcase: 0 */
import Axios from 'axios';
import BaseApiClient from '@/plugins/apis/base-api-client';
var NotificationApiClient = /** @class */ (function (_super) {
    __extends(NotificationApiClient, _super);
    function NotificationApiClient() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    NotificationApiClient.prototype.fetchNotifications = function (limit, offset) {
        var client = this.client;
        var basePath = '/notifications';
        var params = { limit: limit, offset: offset };
        return client.get(basePath, { params: params });
    };
    // Zendesk API から運営からのお知らせを取得
    NotificationApiClient.prototype.fetchProviderNotifications = function () {
        var url = 'https://support.work.careecon.jp/api/v2/help_center/ja/categories/360003435433/articles';
        // API の仕様で section が param に含まれていなければいけないため付与した
        var params = {
            per_page: 10,
            page: 1,
            sort_by: 'updated_at',
            sort_order: 'desc'
        };
        return Axios.get(url, { params: params });
    };
    NotificationApiClient.prototype.readNotification = function (id) {
        var client = this.client;
        var basePath = '/notifications/' + id + '/read';
        return client.patch(basePath);
    };
    NotificationApiClient.prototype.readAllNotification = function () {
        var client = this.client;
        var basePath = '/notifications/read_all';
        return client.patch(basePath);
    };
    return NotificationApiClient;
}(BaseApiClient));
export default new NotificationApiClient();
