import Notification from '@/plugins/notification';
var showErrorMessage = function (title, data, message) {
    if (data && typeof data.message === 'string') {
        Notification.error({
            title: title,
            message: data.message
        });
        return;
    }
    if (data && Array.isArray(data.message)) {
        var message_1 = data.message.reduce(function (str, msg) {
            return str + "\n" + msg;
        });
        Notification.error({ title: title, message: message_1 });
        return;
    }
    if (message) {
        Notification.error({ title: title, message: message });
        return;
    }
    Notification.error({ title: title, message: '' });
};
export default showErrorMessage;
