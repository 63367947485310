var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Vue, Component } from 'vue-property-decorator';
import { Getter, namespace } from 'vuex-class';
import { Routes } from '@/types/routes';
import { LocalStorageKeys } from '@/types';
import TheHeader from '@/components/the-header.vue';
import TheSideMenu from '@/components/the-side-menu.vue';
import MaterialIcons from '@/components/material-icons.vue';
import FileUploader from '@/components/file-uploader.vue';
import FileDownloader from '@/components/common/organisms/file-downloader.vue';
import ImageLinkButton from '@/components/atoms/image-link-button.vue';
/*
  ファイルアップロード時、他の画面へ遷移してもアップロード処理を継続するため
  ここにアップロード用コンポーネントを配置しています
*/
var ContentModule = namespace('content');
var InitialSettingModule = namespace('initial-setting');
var MemberModule = namespace('member');
var LayoutDefault = /** @class */ (function (_super) {
    __extends(LayoutDefault, _super);
    function LayoutDefault() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        // data
        _this.windowWidth = 0;
        _this.showMenu = true;
        _this.isActive = false;
        return _this;
    }
    Object.defineProperty(LayoutDefault.prototype, "isFilePage", {
        get: function () {
            var $route = this.$route;
            if (!$route.name) {
                return false;
            }
            return $route.name.startsWith(Routes.CompanyFilesRootPage.name);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(LayoutDefault.prototype, "isCustomerPage", {
        get: function () {
            var $route = this.$route;
            if (!$route.name) {
                return false;
            }
            return $route.name.startsWith(Routes.CustomerListPage.name);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(LayoutDefault.prototype, "isProjectPage", {
        get: function () {
            var $route = this.$route;
            if (!$route.name) {
                return false;
            }
            return $route.name.startsWith(Routes.ProjectListPage.name);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(LayoutDefault.prototype, "isProjectFilesPage", {
        get: function () {
            var $route = this.$route;
            if (!$route.name) {
                return false;
            }
            return $route.name.startsWith('companies-company_id-projects-id-files');
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(LayoutDefault.prototype, "isFilesDetailPage", {
        get: function () {
            var $route = this.$route;
            if (!$route.name) {
                return false;
            }
            return $route.name.startsWith(Routes.ProjectDetailPage.name);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(LayoutDefault.prototype, "isReportsPage", {
        get: function () {
            var $route = this.$route;
            if (!$route.name) {
                return false;
            }
            return $route.name === Routes.ReportListPage.name;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(LayoutDefault.prototype, "isProjectReportsPage", {
        get: function () {
            var $route = this.$route;
            if (!$route.name) {
                return false;
            }
            return $route.name === Routes.ProjectReportsListPage.name;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(LayoutDefault.prototype, "isSchedulePage", {
        get: function () {
            var $route = this.$route;
            if (!$route.name) {
                return false;
            }
            return $route.name === Routes.ProjectSchedulePage.name;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(LayoutDefault.prototype, "mainClassNames", {
        get: function () {
            var _a = this, isFilePage = _a.isFilePage, isProjectFilesPage = _a.isProjectFilesPage, isFilesDetailPage = _a.isFilesDetailPage, isReportsPage = _a.isReportsPage, isProjectReportsPage = _a.isProjectReportsPage, isSchedulePage = _a.isSchedulePage;
            return {
                // TODO: infoのあるクラスをまとめる
                'file-page': isFilePage || isProjectFilesPage || isFilesDetailPage,
                'reports-page': isReportsPage || isProjectReportsPage,
                'schedule-page': isSchedulePage,
                'tablet-main-size': this.isTabletSize && this.showMenu
            };
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(LayoutDefault.prototype, "isTabletSize", {
        get: function () {
            if (this.windowWidth <= 800) {
                this.showMenu = false;
                return true;
            }
            else {
                this.isActive = false;
                return false;
            }
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(LayoutDefault.prototype, "menuWidth", {
        get: function () {
            if (!this.isTabletSize || !this.showMenu) {
                return '198px';
            }
            if (this.isActive) {
                return '100%';
            }
        },
        enumerable: false,
        configurable: true
    });
    // lyfe_cyce_hooks
    LayoutDefault.prototype.created = function () {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
        // FIXME: WEBでpush通知必要なタイミングでコメント外す
        // try {
        //   const isGranted = await Permission.requestNotification()
        //   if (isGranted) {
        //     const token: string = await this.$fire.messaging.getToken()
        //     await meApiClient.registerFcmToken(token)
        //   }
        // } catch (e) {
        //   console.log(e)
        // }
    };
    LayoutDefault.prototype.destroyed = function () {
        window.removeEventListener('resize', this.handleResize);
    };
    // 対象ファイル一覧のアップロードをキャンセルします
    LayoutDefault.prototype.cancelHandler = function (key) {
        this.cancelQueues(key);
    };
    LayoutDefault.prototype.handleResize = function () {
        this.windowWidth = window.innerWidth;
    };
    LayoutDefault.prototype.menuOpenHandler = function () {
        if (!this.showMenu && !this.isActive) {
            this.$gtag('event', this.$gaEvent.actionSidebarOpen);
            this.showMenu = true;
            this.isActive = true;
        }
    };
    LayoutDefault.prototype.menuCloseHandler = function () {
        if (this.showMenu && this.isActive) {
            this.$gtag('event', this.$gaEvent.actionSidebarClose);
            this.showMenu = false;
            this.isActive = false;
        }
    };
    LayoutDefault.prototype.shouldShowImageLink = function () {
        var _this = this;
        var _a = this, user = _a.user, members = _a.members;
        var owner = members.find(function (member) { return member.is_owner; });
        // Owner のみ表示する
        var isOwner = user.id === (owner === null || owner === void 0 ? void 0 : owner.id);
        // 下記のいずれかのページでない場合は表示しない
        var isTargetPage = function () {
            var isProjectListRoot = _this.$route.name === Routes.ProjectListPage.name;
            var isFileListRoot = _this.$route.name === Routes.CompanyFilesRootPage.name;
            var isCompanyFilesList = _this.$route.name === Routes.CompanyFilesListPage.name;
            var isSharingContentRoot = _this.$route.name === Routes.SharingContentListPage.name;
            var isReportListRoot = _this.$route.name === Routes.ReportListPage.name;
            var isCustomerListRoot = _this.$route.name === Routes.CustomerListPage.name;
            var isCustomerDetail = _this.$route.name === Routes.CustomerDetailPage.name;
            var isMemberListRoot = _this.$route.name === Routes.MemberListPage.name;
            return (isProjectListRoot ||
                isFileListRoot ||
                isCompanyFilesList ||
                isSharingContentRoot ||
                isReportListRoot ||
                isCustomerListRoot ||
                isCustomerDetail ||
                isMemberListRoot);
        };
        if (!isOwner)
            return false;
        if (!isTargetPage())
            return false;
        return localStorage.getItem(LocalStorageKeys.ImageLinkSettingShowed) !== 'on';
    };
    __decorate([
        ContentModule.Getter('progressMap')
    ], LayoutDefault.prototype, "progressMap", void 0);
    __decorate([
        ContentModule.Getter('existsFile')
    ], LayoutDefault.prototype, "existsFile", void 0);
    __decorate([
        ContentModule.Getter('ancestors')
    ], LayoutDefault.prototype, "ancestors", void 0);
    __decorate([
        InitialSettingModule.Getter('company')
    ], LayoutDefault.prototype, "company", void 0);
    __decorate([
        Getter('downloadQueues')
    ], LayoutDefault.prototype, "downloadQueues", void 0);
    __decorate([
        MemberModule.Getter('members')
    ], LayoutDefault.prototype, "members", void 0);
    __decorate([
        InitialSettingModule.Getter('user')
    ], LayoutDefault.prototype, "user", void 0);
    __decorate([
        ContentModule.Action('cancelQueues')
    ], LayoutDefault.prototype, "cancelQueues", void 0);
    __decorate([
        ContentModule.Action('clearQueues')
    ], LayoutDefault.prototype, "clearQueues", void 0);
    LayoutDefault = __decorate([
        Component({
            components: {
                ImageLinkButton: ImageLinkButton,
                FileDownloader: FileDownloader,
                TheHeader: TheHeader,
                TheSideMenu: TheSideMenu,
                FileUploader: FileUploader,
                MaterialIcons: MaterialIcons
            },
            head: function () {
                var company = this.company;
                return {
                    titleTemplate: "%s - " + (company ? company.name : '')
                };
            }
        })
    ], LayoutDefault);
    return LayoutDefault;
}(Vue));
export default LayoutDefault;
