export var ContentUiMutationTypes;
(function (ContentUiMutationTypes) {
    ContentUiMutationTypes["INIT_STATE"] = "INIT_STATE";
    ContentUiMutationTypes["CHANGE_PIN_MODE"] = "CHANGE_PIN_MODE";
    ContentUiMutationTypes["SET_NEW_PIN"] = "SET_NEW_PIN";
    ContentUiMutationTypes["MOVE_PIN"] = "MOVE_PIN";
    ContentUiMutationTypes["SET_TARGET_COMMENT"] = "SET_TARGET_COMMENT";
    ContentUiMutationTypes["CHANGE_DRAGGING"] = "CHANGE_DRAGGING";
    ContentUiMutationTypes["SET_IS_PUBLIC"] = "SET_IS_PUBLIC";
})(ContentUiMutationTypes || (ContentUiMutationTypes = {}));
