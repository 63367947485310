import { Notification } from 'element-ui';
// Notification
// https://element.eleme.io/2.10/#/en-US/component/notification
// element-uiのデフォルトは4500
var DefaultDuration = 2000;
var defaultOption = function (option, type, customClass) {
    if (!option.duration && option.duration !== 0) {
        option.duration = DefaultDuration;
    }
    option.dangerouslyUseHTMLString = true;
    option.message = "\n    <div class=\"el-notification__message " + (option.onClick ? 'pointer' : '') + "\">\n      <h2 class=\"el-notification__title--custom\">\n        <i class=\"material-icons\">\n          " + type + "\n        </i>\n        " + option.title + "\n      </h2>\n\n      " + (option.message ? "<p class=\"el-notification__message--text\">" + option.message + "</p>" : '') + "\n    </div>\n  ";
    option.showClose = true;
    option.customClass = customClass;
    return option;
};
export default {
    success: function (option) {
        Notification.success(defaultOption(option, 'check_circle', 'el-notification__success'));
    },
    info: function (option) {
        Notification.info(defaultOption(option, 'info', 'el-notification__info'));
    },
    warning: function (option) {
        Notification.warning(defaultOption(option, 'error', 'el-notification__warning'));
    },
    error: function (option) {
        Notification.error(defaultOption(option, 'error', 'el-notification__error'));
    }
};
