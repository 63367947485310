var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Mixins, Watch } from 'vue-property-decorator';
import ScrollEndLoading from '@/components/common/atoms/scroll-end-loading.vue';
import NotificationListContent from '@/components/notification/atom/list-content.vue';
import NotificationBaseMixin from '@/mixins/notification/base-mixin.ts';
var UserNotificationBoard = /** @class */ (function (_super) {
    __extends(UserNotificationBoard, _super);
    function UserNotificationBoard() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        // data
        _this.isObserved = false;
        return _this;
    }
    Object.defineProperty(UserNotificationBoard.prototype, "isFetchSuccess", {
        get: function () {
            var isFetchError = this.isFetchError;
            return !isFetchError;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(UserNotificationBoard.prototype, "isNotificationEmpty", {
        get: function () {
            var notifications = this.notifications;
            return notifications.length === 0;
        },
        enumerable: false,
        configurable: true
    });
    // methods
    UserNotificationBoard.prototype.onChangeLoading = function () {
        var _this = this;
        if (this.isObserved)
            return;
        var _a = this, notifications = _a.notifications, isShowLoading = _a.isShowLoading;
        if (isShowLoading)
            return;
        if (notifications.length === 0)
            return;
        this.$nextTick(function () {
            _this.setObserver();
        });
    };
    UserNotificationBoard.prototype.mounted = function () {
        this.fetchNotification();
    };
    UserNotificationBoard.prototype.setObserver = function () {
        var _this = this;
        var observer = new IntersectionObserver(function (entries) {
            entries.forEach(function (entry) {
                _this.$nextTick(function () {
                    if (entry.isIntersecting && !_this.isShowLoading) {
                        _this.fetchNotification();
                    }
                });
            });
        });
        observer.observe(this.$refs.loadTrigger);
        this.$once('hook:beforeDestroy', function () {
            observer.disconnect();
        });
        this.isObserved = true;
    };
    UserNotificationBoard.prototype.fetchNotification = function () {
        this.initUserNotifications();
    };
    __decorate([
        Watch('isShowLoading')
    ], UserNotificationBoard.prototype, "onChangeLoading", null);
    UserNotificationBoard = __decorate([
        Component({
            components: {
                NotificationListContent: NotificationListContent,
                ScrollEndLoading: ScrollEndLoading
            }
        })
    ], UserNotificationBoard);
    return UserNotificationBoard;
}(Mixins(NotificationBaseMixin)));
export default UserNotificationBoard;
