var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
// 拡張子を元にmime typeを取得します
import * as Mime from 'mime';
import fileType from 'file-type';
import * as PDFJS from 'pdfjs-dist';
import { isImage } from '@/plugins/compressor';
import myMime from '@/plugins/my-mime';
PDFJS.GlobalWorkerOptions.workerSrc = '/pdf.worker.js';
var Exif = require('exif-js');
var getMovieSize = function (element, file) {
    return new Promise(function (resolve) {
        element.onloadedmetadata = function () {
            var width = element.videoWidth;
            var height = element.videoHeight;
            resolve({ width: width, height: height });
            URL.revokeObjectURL(element.src);
        };
        element.onerror = function () {
            resolve({});
        };
        element.src = URL.createObjectURL(file);
        element.load();
    });
};
// 再取得対象type
// excelがmsi判定になったので再取得します
// aiがpdf判定になったので再取得します
// svgがxml判定になったので再取得します
// keynoteがzip判定になったので再取得
// wmvがx-ms-wma判定になったので再取得します
var GetAgainFileTypes = [
    'application/x-msi',
    'application/pdf',
    'application/xml',
    'application/zip',
    'audio/x-ms-wma'
];
export default (function (file) {
    return new Promise(function (resolve) {
        var reader = new FileReader();
        reader.addEventListener('load', function () { return __awaiter(void 0, void 0, void 0, function () {
            var result, info_1, splittedName, ext, mime, pdfLoadingTask, pdf, pdfPagesNum, element, movieSize, exif, img_1, url_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        result = reader.result;
                        if (!(result instanceof ArrayBuffer)) return [3 /*break*/, 6];
                        info_1 = { fileType: fileType(result) };
                        splittedName = file.name.split('.');
                        ext = '';
                        if (splittedName.length > 1 && splittedName[0]) {
                            ext = splittedName.reverse()[0];
                        }
                        if (!(!info_1.fileType ||
                            !info_1.fileType.mime ||
                            GetAgainFileTypes.includes(info_1.fileType.mime))) return [3 /*break*/, 3];
                        mime = Mime.getType(ext) || myMime.getType(ext);
                        if (!(mime === 'application/pdf')) return [3 /*break*/, 2];
                        pdfLoadingTask = PDFJS.getDocument(result);
                        return [4 /*yield*/, pdfLoadingTask.promise];
                    case 1:
                        pdf = (_a.sent());
                        pdfPagesNum = pdf.numPages;
                        pdf.destroy();
                        return [2 /*return*/, resolve({
                                fileType: { mime: mime, ext: ext },
                                pdfPagesNum: pdfPagesNum
                            })];
                    case 2: return [2 /*return*/, resolve({
                            fileType: {
                                mime: mime || 'application/octet-stream',
                                ext: ext
                            }
                        })];
                    case 3:
                        if (!!isImage(info_1.fileType.mime)) return [3 /*break*/, 5];
                        element = document.createElement('video');
                        // 動画でも無い時・maybe/probablyを通します
                        if (element.canPlayType(info_1.fileType.mime) === '') {
                            return [2 /*return*/, resolve(info_1)];
                        }
                        return [4 /*yield*/, getMovieSize(element, file)];
                    case 4:
                        movieSize = _a.sent();
                        info_1.width = movieSize.width;
                        info_1.height = movieSize.height;
                        return [2 /*return*/, resolve(info_1)];
                    case 5:
                        exif = Exif.readFromBinaryFile(result);
                        if (exif) {
                            info_1.exif = exif;
                        }
                        img_1 = new Image();
                        url_1 = URL.createObjectURL(file);
                        img_1.addEventListener('load', function () {
                            info_1.width = img_1.naturalWidth;
                            info_1.height = img_1.naturalHeight;
                            resolve(info_1);
                            URL.revokeObjectURL(url_1);
                        });
                        img_1.src = url_1;
                        _a.label = 6;
                    case 6: return [2 /*return*/];
                }
            });
        }); });
        reader.readAsArrayBuffer(file);
    });
});
