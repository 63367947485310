var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
var _a;
import Vue from 'vue';
import { ConstructionKindMutationTypes } from '@/types/construction-kind';
import constructionKindApiClient from '@/plugins/apis/construction-kind-api-client';
import sortResources from '@/plugins/sort-resources';
var sortBy = function (kinds, sort) {
    var prop = sort.prop, order = sort.order;
    return sortResources(kinds, prop, order);
};
export var state = function () { return ({
    defaultSort: {
        prop: 'name',
        order: 'asc'
    },
    constructionKinds: [],
    paginate: {
        current_limit: 0,
        // サーバーが設定値を持っているのでこちらからは指定しません
        current_offset: 0,
        total_count: 0
    }
}); };
export var getters = {
    defaultSort: function (state) {
        return state.defaultSort;
    },
    paginate: function (state) {
        return state.paginate;
    },
    constructionKinds: function (state) {
        return state.constructionKinds;
    }
};
export var mutations = (_a = {},
    _a[ConstructionKindMutationTypes.INIT_STATE] = function (state, payload) {
        Object.assign(state, payload);
    },
    _a[ConstructionKindMutationTypes.SET_SORT_TYPE] = function (state, payload) {
        state.defaultSort = payload;
    },
    _a[ConstructionKindMutationTypes.INIT_CONSTRUCTION_KINDS] = function (state, _a) {
        var constructionKinds = _a.constructionKinds, paginate = _a.paginate;
        state.constructionKinds = constructionKinds;
        state.paginate = paginate;
    },
    _a[ConstructionKindMutationTypes.FETCH_CONSTRUCTION_KINDS] = function (state, _a) {
        var constructionKinds = _a.constructionKinds, paginate = _a.paginate;
        var ids = state.constructionKinds.map(function (c) { return c.id; });
        var filterdList = constructionKinds.filter(function (c) {
            return !ids.includes(c.id);
        });
        state.constructionKinds = state.constructionKinds.concat(filterdList);
        state.paginate = paginate;
    },
    _a[ConstructionKindMutationTypes.FETCH_ALL_CONSTRUCTION_KINDS] = function (state, payload) {
        state.constructionKinds = payload;
    },
    _a[ConstructionKindMutationTypes.DELETE_CONSTRUCTION_KIND] = function (state, payload) {
        var index = state.constructionKinds.findIndex(function (ck) { return ck.id === payload; });
        if (index !== -1) {
            Vue.delete(state.constructionKinds, index);
        }
    },
    _a[ConstructionKindMutationTypes.SET_SORTED_CONSTRUCTION_KINDS] = function (state, payload) {
        state.constructionKinds = payload;
    },
    _a);
export var actions = {
    initState: function (_a) {
        var commit = _a.commit;
        commit(ConstructionKindMutationTypes.INIT_STATE, state());
    },
    changeSort: function (_a, payload) {
        var commit = _a.commit;
        commit(ConstructionKindMutationTypes.SET_SORT_TYPE, payload);
    },
    initConstructionKinds: function (_a) {
        var commit = _a.commit, getters = _a.getters;
        return __awaiter(this, void 0, void 0, function () {
            var defaultSort, params, res, _b, constructionKinds, paginate;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        defaultSort = getters.defaultSort;
                        params = {
                            offset: 0,
                            sort: {
                                target: defaultSort.prop,
                                order: defaultSort.order
                            }
                        };
                        return [4 /*yield*/, constructionKindApiClient.fetchConstructionKinds(params)];
                    case 1:
                        res = _c.sent();
                        _b = res.data, constructionKinds = _b.construction_kinds, paginate = _b.meta.paginate;
                        commit(ConstructionKindMutationTypes.INIT_CONSTRUCTION_KINDS, {
                            constructionKinds: constructionKinds,
                            paginate: paginate
                        });
                        return [2 /*return*/];
                }
            });
        });
    },
    fetchConstructionKinds: function (_a) {
        var commit = _a.commit, getters = _a.getters;
        return __awaiter(this, void 0, void 0, function () {
            var currentConstructionKinds, defaultSort, params, res, _b, constructionKinds, paginate;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        currentConstructionKinds = getters.constructionKinds, defaultSort = getters.defaultSort;
                        params = {
                            offset: currentConstructionKinds.length,
                            sort: {
                                target: defaultSort.prop,
                                order: defaultSort.order
                            }
                        };
                        return [4 /*yield*/, constructionKindApiClient.fetchConstructionKinds(params)];
                    case 1:
                        res = _c.sent();
                        _b = res.data, constructionKinds = _b.construction_kinds, paginate = _b.meta.paginate;
                        commit(ConstructionKindMutationTypes.FETCH_CONSTRUCTION_KINDS, {
                            constructionKinds: constructionKinds,
                            paginate: paginate
                        });
                        return [2 /*return*/];
                }
            });
        });
    },
    fetchAllConstructionKinds: function (_a) {
        var commit = _a.commit;
        return __awaiter(this, void 0, void 0, function () {
            var res, constructionKinds;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, constructionKindApiClient.fetchAllConstructionKinds()];
                    case 1:
                        res = _b.sent();
                        constructionKinds = res.data.construction_kinds;
                        commit(ConstructionKindMutationTypes.FETCH_ALL_CONSTRUCTION_KINDS, constructionKinds);
                        return [2 /*return*/];
                }
            });
        });
    },
    createConstructionKind: function (_a, payload) {
        var dispatch = _a.dispatch;
        return __awaiter(this, void 0, void 0, function () {
            var res;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, constructionKindApiClient.createConstructionKind(payload)];
                    case 1:
                        res = _b.sent();
                        return [4 /*yield*/, dispatch('setSortedConstructionKinds', res.data.construction_kind)];
                    case 2:
                        _b.sent();
                        return [2 /*return*/, res];
                }
            });
        });
    },
    updateConstructionKind: function (_a, payload) {
        var dispatch = _a.dispatch;
        return __awaiter(this, void 0, void 0, function () {
            var id, params, res;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        id = payload.id, params = payload.params;
                        return [4 /*yield*/, constructionKindApiClient.updateConstructionKind(id, params)];
                    case 1:
                        res = _b.sent();
                        return [4 /*yield*/, dispatch('setSortedConstructionKinds', res.data.construction_kind)];
                    case 2:
                        _b.sent();
                        return [2 /*return*/, res];
                }
            });
        });
    },
    deleteConstructionKind: function (_a, id) {
        var commit = _a.commit;
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, constructionKindApiClient.deleteConstructionKind(id)];
                    case 1:
                        _b.sent();
                        commit(ConstructionKindMutationTypes.DELETE_CONSTRUCTION_KIND, id);
                        return [2 /*return*/];
                }
            });
        });
    },
    // 作成・更新時はフロントソート処理を行い現在の一覧を保持します
    setSortedConstructionKinds: function (_a, payload) {
        var dispatch = _a.dispatch, commit = _a.commit, getters = _a.getters;
        return __awaiter(this, void 0, void 0, function () {
            var currentConstructionKinds, defaultSort, dupKinds, index, sortedKinds, lastKind, isLastKind;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        currentConstructionKinds = getters.constructionKinds, defaultSort = getters.defaultSort;
                        dupKinds = __spreadArrays(currentConstructionKinds);
                        index = dupKinds.findIndex(function (ck) { return ck.id === payload.id; });
                        if (index !== -1) {
                            dupKinds.splice(index, 1, payload);
                        }
                        else {
                            dupKinds.push(payload);
                        }
                        sortedKinds = sortBy(dupKinds, defaultSort);
                        lastKind = sortedKinds[sortedKinds.length - 1];
                        isLastKind = lastKind.id === payload.id;
                        if (isLastKind) {
                            sortedKinds.pop();
                        }
                        commit(ConstructionKindMutationTypes.SET_SORTED_CONSTRUCTION_KINDS, sortedKinds);
                        if (!isLastKind) return [3 /*break*/, 2];
                        return [4 /*yield*/, dispatch('fetchConstructionKinds')];
                    case 1:
                        _b.sent();
                        _b.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    }
};
