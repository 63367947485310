var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Getter, namespace } from 'vuex-class';
import { Component, Vue } from 'vue-property-decorator';
import FileUploader from '@/components/file-uploader.vue';
import FileDownloader from '@/components/common/organisms/file-downloader.vue';
var InitialSettingModule = namespace('initial-setting');
var ContentModule = namespace('content');
var LayoutBlank = /** @class */ (function (_super) {
    __extends(LayoutBlank, _super);
    function LayoutBlank() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    LayoutBlank.prototype.cancelHandler = function (key) {
        this.cancelQueues(key);
    };
    __decorate([
        ContentModule.Getter('progressMap')
    ], LayoutBlank.prototype, "progressMap", void 0);
    __decorate([
        ContentModule.Getter('existsFile')
    ], LayoutBlank.prototype, "existsFile", void 0);
    __decorate([
        InitialSettingModule.Getter('company')
    ], LayoutBlank.prototype, "company", void 0);
    __decorate([
        Getter('downloadQueues')
    ], LayoutBlank.prototype, "downloadQueues", void 0);
    __decorate([
        ContentModule.Action('cancelQueues')
    ], LayoutBlank.prototype, "cancelQueues", void 0);
    __decorate([
        ContentModule.Action('clearQueues')
    ], LayoutBlank.prototype, "clearQueues", void 0);
    LayoutBlank = __decorate([
        Component({
            components: { FileDownloader: FileDownloader, FileUploader: FileUploader },
            head: function () {
                var company = this.company;
                return {
                    titleTemplate: "%s - " + (company ? company.name : '')
                };
            }
        })
    ], LayoutBlank);
    return LayoutBlank;
}(Vue));
export default LayoutBlank;
