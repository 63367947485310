var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import BaseApiClient from '@/plugins/apis/base-api-client';
var UserApiClient = /** @class */ (function (_super) {
    __extends(UserApiClient, _super);
    function UserApiClient() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(UserApiClient.prototype, "basePath", {
        get: function () {
            var companyId = this.companyId;
            return "/companies/" + companyId + "/users";
        },
        enumerable: false,
        configurable: true
    });
    UserApiClient.prototype.fetchUsers = function () {
        var config = {
            params: { limit: 500 }
        };
        var _a = this, basePath = _a.basePath, client = _a.client;
        return client.get(basePath, config);
    };
    // TODO: fetchと統合
    UserApiClient.prototype.searchMembers = function (data) {
        var _a = this, basePath = _a.basePath, client = _a.client;
        var params = {
            user: data,
            limit: 500
        };
        return client.get(basePath, { params: params });
    };
    // FIXME: 不要？
    UserApiClient.prototype.updateUser = function (id, data) {
        var _a = this, basePath = _a.basePath, client = _a.client;
        var url = basePath + "/" + id;
        return client.put(url, data);
    };
    UserApiClient.prototype.updateCompaniesUser = function (id, data) {
        var _a = this, basePath = _a.basePath, client = _a.client;
        var url = basePath + "/" + id + "/update_company_user";
        return client.put(url, data);
    };
    UserApiClient.prototype.removeUser = function (id) {
        var basePath = this.basePath;
        var url = basePath + "/" + id + "/exclude_company_user";
        return this.requestActionWithNamedProperty({
            method: 'delete',
            url: url,
            resourceName: 'delete_member',
            actionName: 'OTHER'
        });
    };
    return UserApiClient;
}(BaseApiClient));
export default new UserApiClient();
