import policies from '@/policies';
var can = function (company, resource, action, options) {
    if (!company) {
        return false;
    }
    // 未設定の場合
    if (!policies[resource]) {
        if (process.env.NODE_ENV === 'development') {
            console.error('権限が未設定です >>> policies[resource] is undefined', resource, action);
        }
        return true;
    }
    if (!policies[resource][action]) {
        if (process.env.NODE_ENV === 'development') {
            console.error('権限が未設定です >>> policies[resource][action] is undefined', resource, action);
        }
        return true;
    }
    return policies[resource][action](company, options);
};
export default can;
