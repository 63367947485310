import createApp from './app.js'

import authService from './service.auth.js'
import firestoreService from './service.firestore.js'
import messagingService from './service.messaging.js'

const appConfig = {"apiKey":"AIzaSyCogJv4AWqevgLEKreubaTblZ-4gxG-atI","authDomain":"careecon-for-work-sekou-stg.firebaseapp.com","databaseURL":"https:\u002F\u002Fcareecon-for-work-sekou-stg.firebaseio.com","projectId":"careecon-for-work-sekou-stg","storageBucket":"careecon-for-work-sekou-stg.appspot.com","messagingSenderId":"536531162621","appId":"1:536531162621:web:767ac5c7b0e14136e2f594","measurementId":"G-N91NBJTLPQ"}

export default async (ctx, inject) => {
  /****************************************
  **************** LAZY MODE **************
  ****************************************/

  const { firebase, session } = await createApp(appConfig, ctx)

  let servicePromises = []

  if (process.server) {
    servicePromises = [
      authService(session, firebase, ctx, inject),
    firestoreService(session, firebase, ctx, inject),

    ]
  }

  if (process.client) {
    servicePromises = [
      authService(session, firebase, ctx, inject),
      firestoreService(session, firebase, ctx, inject),
      messagingService(session, firebase, ctx, inject),

    ]
  }

  const [
    auth,
    firestore,
    messaging
  ] = await Promise.all(servicePromises)

  const fire = {
    auth: auth,
    firestore: firestore,
    messaging: messaging
  }

    inject('fireModule', firebase)
    ctx.$fireModule = firebase

  inject('fire', fire)
  ctx.$fire = fire
}
