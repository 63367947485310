var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
var _a;
import Vue from 'vue';
import { CustomerMutationTypes } from '@/types/customer';
import customerApiClient from '@/plugins/apis/customer-api-client';
var sortBy = function (customers, sort) {
    var target = __spreadArrays(customers);
    var prop = sort.prop, order = sort.order;
    var vector = order === 'asc' ? -1 : 1;
    if (prop === 'name') {
        return target.sort(function (a, b) {
            if (a[prop] < b[prop]) {
                return vector;
            }
            return -1 * vector;
        });
    }
    return target;
};
export var state = function () { return ({
    defaultSort: {
        prop: 'name',
        order: 'asc'
    },
    customers: [],
    customer: {
        id: '',
        name: '',
        content_id: '',
        policies: {
            destroy: false,
            show: false,
            update: false
        }
    },
    paginate: {
        current_limit: 1,
        // サーバーが設定値を持っているのでこちらからは指定しません
        current_offset: 0,
        total_count: 0
    }
}); };
export var getters = {
    customers: function (state) {
        return state.customers;
    },
    customer: function (state) {
        return state.customer;
    },
    defaultSort: function (state) {
        return state.defaultSort;
    },
    paginate: function (state) {
        return state.paginate;
    }
};
export var mutations = (_a = {},
    _a[CustomerMutationTypes.INIT_STATE] = function (state, payload) {
        Object.assign(state, payload);
    },
    _a[CustomerMutationTypes.SET_SORT_TYPE] = function (state, payload) {
        state.defaultSort = payload;
    },
    _a[CustomerMutationTypes.INIT_CUSTOMERS] = function (state, _a) {
        var customers = _a.customers, paginate = _a.paginate;
        state.customers = customers;
        state.paginate = paginate;
    },
    _a[CustomerMutationTypes.FETCH_CUSTOMERS] = function (state, _a) {
        var customers = _a.customers, paginate = _a.paginate;
        var ids = state.customers.map(function (c) { return c.id; });
        var filteredList = customers.filter(function (c) {
            return !ids.includes(c.id);
        });
        state.customers = state.customers.concat(filteredList);
        state.paginate = paginate;
    },
    _a[CustomerMutationTypes.FETCH_CUSTOMER] = function (state, payload) {
        state.customer = payload;
    },
    _a[CustomerMutationTypes.SET_SORTED_CUSTOMERS] = function (state, payload) {
        state.customers = payload;
    },
    _a[CustomerMutationTypes.DELETE_CUSTOMER] = function (state, payload) {
        var index = state.customers.findIndex(function (cs) { return cs.id === payload; });
        if (index !== -1) {
            Vue.delete(state.customers, index);
        }
    },
    _a);
export var actions = {
    initState: function (_a) {
        var commit = _a.commit;
        commit(CustomerMutationTypes.INIT_STATE, state());
    },
    changeSort: function (_a, payload) {
        var commit = _a.commit;
        commit(CustomerMutationTypes.SET_SORT_TYPE, payload);
    },
    initCustomers: function (_a) {
        var getters = _a.getters, commit = _a.commit;
        return __awaiter(this, void 0, void 0, function () {
            var defaultSort, params, res, _b, customers, paginate;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        defaultSort = getters.defaultSort;
                        params = {
                            offset: 0,
                            sort: {
                                target: defaultSort.prop,
                                order: defaultSort.order
                            }
                        };
                        return [4 /*yield*/, customerApiClient.fetchCustomers(params)];
                    case 1:
                        res = _c.sent();
                        _b = res.data, customers = _b.customers, paginate = _b.meta.paginate;
                        commit(CustomerMutationTypes.INIT_CUSTOMERS, { customers: customers, paginate: paginate });
                        return [2 /*return*/];
                }
            });
        });
    },
    fetchCustomers: function (_a) {
        var getters = _a.getters, commit = _a.commit;
        return __awaiter(this, void 0, void 0, function () {
            var currentCustomers, defaultSort, params, res, _b, customers, paginate;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        currentCustomers = getters.customers, defaultSort = getters.defaultSort;
                        params = {
                            offset: currentCustomers.length,
                            sort: {
                                target: defaultSort.prop,
                                order: defaultSort.order
                            }
                        };
                        return [4 /*yield*/, customerApiClient.fetchCustomers(params)];
                    case 1:
                        res = _c.sent();
                        _b = res.data, customers = _b.customers, paginate = _b.meta.paginate;
                        commit(CustomerMutationTypes.FETCH_CUSTOMERS, { customers: customers, paginate: paginate });
                        return [2 /*return*/];
                }
            });
        });
    },
    fetchCustomer: function (_a, id) {
        var commit = _a.commit;
        return __awaiter(this, void 0, void 0, function () {
            var res;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, customerApiClient.fetchCustomer(id)];
                    case 1:
                        res = _b.sent();
                        commit(CustomerMutationTypes.FETCH_CUSTOMER, res.data.customer);
                        return [2 /*return*/];
                }
            });
        });
    },
    createCustomer: function (_a, payload) {
        var dispatch = _a.dispatch;
        return __awaiter(this, void 0, void 0, function () {
            var res;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, customerApiClient.createCustomer(payload)];
                    case 1:
                        res = _b.sent();
                        return [4 /*yield*/, dispatch('setSortedCustomers', res.data.customer)];
                    case 2:
                        _b.sent();
                        return [2 /*return*/, res];
                }
            });
        });
    },
    updateCustomer: function (_a, payload) {
        var dispatch = _a.dispatch, getters = _a.getters;
        return __awaiter(this, void 0, void 0, function () {
            var customer, res;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        customer = getters.customer;
                        return [4 /*yield*/, customerApiClient.updateCustomer(customer.id, payload)];
                    case 1:
                        res = _b.sent();
                        return [4 /*yield*/, dispatch('setSortedCustomers', res.data.customer)];
                    case 2:
                        _b.sent();
                        return [2 /*return*/, res];
                }
            });
        });
    },
    deleteCustomer: function (_a, payload) {
        var commit = _a.commit;
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, customerApiClient.deleteCustomer(payload)];
                    case 1:
                        _b.sent();
                        commit(CustomerMutationTypes.DELETE_CUSTOMER, payload);
                        return [2 /*return*/];
                }
            });
        });
    },
    // 作成・更新時はフロントソート処理を行い現在の一覧を保持します
    setSortedCustomers: function (_a, payload) {
        var dispatch = _a.dispatch, commit = _a.commit, getters = _a.getters;
        return __awaiter(this, void 0, void 0, function () {
            var currentCustomers, defaultSort, dupCustomers, index, sortedCustomers, lastCustomer, isLastCustomer;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        currentCustomers = getters.customers, defaultSort = getters.defaultSort;
                        dupCustomers = __spreadArrays(currentCustomers);
                        index = dupCustomers.findIndex(function (cs) { return cs.id === payload.id; });
                        if (index !== -1) {
                            dupCustomers.splice(index, 1, payload);
                        }
                        else {
                            dupCustomers.push(payload);
                        }
                        sortedCustomers = sortBy(dupCustomers, defaultSort);
                        lastCustomer = sortedCustomers[sortedCustomers.length - 1];
                        isLastCustomer = lastCustomer.id === payload.id;
                        if (isLastCustomer) {
                            sortedCustomers.pop();
                        }
                        commit(CustomerMutationTypes.SET_SORTED_CUSTOMERS, sortedCustomers);
                        if (!isLastCustomer) return [3 /*break*/, 2];
                        return [4 /*yield*/, dispatch('fetchCustomers')];
                    case 1:
                        _b.sent();
                        _b.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    }
};
