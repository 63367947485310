export var ProjectStatus;
(function (ProjectStatus) {
    ProjectStatus["waiting"] = "waiting";
    ProjectStatus["working"] = "working";
    ProjectStatus["completed"] = "completed";
    ProjectStatus["archived"] = "archived";
})(ProjectStatus || (ProjectStatus = {}));
export var ProjectFormTypes;
(function (ProjectFormTypes) {
    ProjectFormTypes["Basic"] = "basic";
    ProjectFormTypes["Overview"] = "overview";
    ProjectFormTypes["Construction"] = "construction";
    ProjectFormTypes["Note"] = "note";
    ProjectFormTypes["Complete"] = "complete";
})(ProjectFormTypes || (ProjectFormTypes = {}));
export var ProjectMutationTypes;
(function (ProjectMutationTypes) {
    ProjectMutationTypes["INIT_STATE"] = "INIT_STATE";
    ProjectMutationTypes["INIT_PROJECTS"] = "INIT_PROJECTS";
    ProjectMutationTypes["FETCH_PROJECTS"] = "FETCH_PROJECTS";
    ProjectMutationTypes["FETCH_PROJECT"] = "FETCH_PROJECT";
    ProjectMutationTypes["DELETE_PROJECT"] = "DELETE_PROJECT";
    ProjectMutationTypes["FETCH_PLACES"] = "FETCH_PLACES";
    ProjectMutationTypes["CLEAR_PLACES"] = "CLEAR_PLACES";
    ProjectMutationTypes["CREATE_INVITATION_LINK"] = "CREATE_INVITATION_LINK";
    ProjectMutationTypes["DELETE_INVITATION_LINK"] = "DELETE_INVITATION_LINK";
    ProjectMutationTypes["CHANGE_STATUS"] = "CHANGE_STATUS";
    ProjectMutationTypes["FETCH_BOOKMARKS"] = "FETCH_BOOKMARKS";
    ProjectMutationTypes["SORT_BOOKMARKS"] = "SORT_BOOKMARKS";
})(ProjectMutationTypes || (ProjectMutationTypes = {}));
