var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import BaseApiClient from '@/plugins/apis/public/base-api-client';
var qs = require('qs');
var PublicContentCommentApiClient = /** @class */ (function (_super) {
    __extends(PublicContentCommentApiClient, _super);
    function PublicContentCommentApiClient() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(PublicContentCommentApiClient.prototype, "basePath", {
        get: function () {
            return '/contents';
        },
        enumerable: false,
        configurable: true
    });
    PublicContentCommentApiClient.prototype.fetchComments = function (_a) {
        var uuid = _a.uuid, path = _a.path;
        var basePath = this.basePath;
        var url = basePath + "/" + uuid + "/content_comments";
        return this.requestAction({
            url: url,
            method: 'get',
            actionName: 'GET',
            resourceName: 'content_comment',
            notice: false,
            params: {
                path: path || '',
                limit: 500
            },
            paramsSerializer: function (params) {
                return qs.stringify(params, {
                    arrayFormat: 'brackets',
                    encode: false
                });
            }
        });
    };
    PublicContentCommentApiClient.prototype.createComments = function (_a) {
        var uuid = _a.uuid, path = _a.path, text = _a.text, mentionedUids = _a.mentionedUids, name = _a.name, pin = _a.pin;
        return __awaiter(this, void 0, void 0, function () {
            var basePath, url, token;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        basePath = this.basePath;
                        url = basePath + "/" + uuid + "/content_comments";
                        return [4 /*yield*/, window.grecaptcha.enterprise.execute(process.env.googleRecaptchaSiteKey, {
                                action: 'submit'
                            })];
                    case 1:
                        token = _b.sent();
                        return [2 /*return*/, this.requestAction({
                                url: url,
                                method: 'post',
                                actionName: 'CREATE',
                                resourceName: 'content_comment',
                                params: {
                                    path: path || ''
                                },
                                data: {
                                    content_comment: {
                                        body: text.trim(),
                                        mentioned_uids: mentionedUids,
                                        pin: pin
                                    },
                                    user: {
                                        name: name
                                    },
                                    recaptcha_token: token
                                },
                                paramsSerializer: function (params) {
                                    return qs.stringify(params, {
                                        arrayFormat: 'brackets',
                                        encode: false
                                    });
                                }
                            })];
                }
            });
        });
    };
    PublicContentCommentApiClient.prototype.updatePin = function (_a) {
        var uuid = _a.uuid, path = _a.path, commentId = _a.commentId, pin = _a.pin;
        return __awaiter(this, void 0, Promise, function () {
            var basePath, url, token;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        basePath = this.basePath;
                        url = basePath + "/" + uuid + "/content_comments/" + commentId;
                        return [4 /*yield*/, window.grecaptcha.enterprise.execute(process.env.googleRecaptchaSiteKey, {
                                action: 'submit'
                            })];
                    case 1:
                        token = _b.sent();
                        return [2 /*return*/, this.requestAction({
                                url: url,
                                method: 'put',
                                actionName: 'UPDATE',
                                resourceName: 'content_comment',
                                data: {
                                    path: path,
                                    content_comment: { pin: pin },
                                    recaptcha_token: token
                                },
                                paramsSerializer: function (params) {
                                    return qs.stringify(params, {
                                        arrayFormat: 'brackets',
                                        encode: false
                                    });
                                }
                            })];
                }
            });
        });
    };
    PublicContentCommentApiClient.prototype.updateComment = function (_a) {
        var uuid = _a.uuid, path = _a.path, commentId = _a.commentId, text = _a.text, mentionedUids = _a.mentionedUids;
        return __awaiter(this, void 0, Promise, function () {
            var basePath, url, token;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        basePath = this.basePath;
                        url = basePath + "/" + uuid + "/content_comments/" + commentId;
                        return [4 /*yield*/, window.grecaptcha.enterprise.execute(process.env.googleRecaptchaSiteKey, {
                                action: 'submit'
                            })];
                    case 1:
                        token = _b.sent();
                        return [2 /*return*/, this.requestAction({
                                url: url,
                                method: 'put',
                                actionName: 'UPDATE',
                                resourceName: 'content_comment',
                                data: {
                                    path: path,
                                    content_comment: {
                                        body: text.trim(),
                                        mentioned_uids: mentionedUids
                                    },
                                    recaptcha_token: token
                                },
                                paramsSerializer: function (params) {
                                    return qs.stringify(params, {
                                        arrayFormat: 'brackets',
                                        encode: false
                                    });
                                }
                            })];
                }
            });
        });
    };
    PublicContentCommentApiClient.prototype.deleteComment = function (_a) {
        var uuid = _a.uuid, path = _a.path, commentId = _a.commentId;
        return __awaiter(this, void 0, void 0, function () {
            var basePath, url, token;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        basePath = this.basePath;
                        url = basePath + "/" + uuid + "/content_comments/" + commentId;
                        return [4 /*yield*/, window.grecaptcha.enterprise.execute(process.env.googleRecaptchaSiteKey, {
                                action: 'submit'
                            })];
                    case 1:
                        token = _b.sent();
                        return [2 /*return*/, this.requestAction({
                                url: url,
                                method: 'delete',
                                actionName: 'DELETE',
                                resourceName: 'content_comment',
                                params: {
                                    path: path || '',
                                    recaptcha_token: token
                                },
                                paramsSerializer: function (params) {
                                    return qs.stringify(params, {
                                        arrayFormat: 'brackets',
                                        encode: false
                                    });
                                }
                            })];
                }
            });
        });
    };
    return PublicContentCommentApiClient;
}(BaseApiClient));
export default new PublicContentCommentApiClient();
