var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _a;
import { ProjectMemberMutationTypes } from '@/types/project-member';
import projectUserApiClient from '@/plugins/apis/project-user-api-client';
import invitedUsetApiClient from '@/plugins/apis/invited-user-api-alient';
export var state = function () { return ({
    members: [],
    invitedMembers: []
}); };
export var getters = {
    members: function (state) {
        return state.members;
    },
    invitedMembers: function (state) {
        return state.invitedMembers;
    }
};
export var mutations = (_a = {},
    _a[ProjectMemberMutationTypes.INIT_STATE] = function (state, payload) {
        Object.assign(state, payload);
    },
    _a[ProjectMemberMutationTypes.FETCH_MEMBERS] = function (state, payload) {
        state.members = payload;
    },
    _a[ProjectMemberMutationTypes.FETCH_INVITED_USERS] = function (state, payload) {
        state.invitedMembers = payload;
    },
    _a[ProjectMemberMutationTypes.DELETE_PROJECT_MEMBER] = function (state, payload) {
        state.members = state.members.filter(function (member) { return member.id !== payload; });
    },
    _a[ProjectMemberMutationTypes.CANCEL_INVITATION] = function (state, payload) {
        state.invitedMembers = state.invitedMembers.filter(function (member) { return member.email !== payload; });
    },
    _a[ProjectMemberMutationTypes.CHANGE_USER_TYPE] = function (state, payload) {
        state.invitedMembers.some(function (member) {
            if (member.id === payload.id) {
                member.user_type = payload.userType;
                return true;
            }
            return false;
        });
    },
    _a);
export var actions = {
    initState: function (_a) {
        var commit = _a.commit;
        commit(ProjectMemberMutationTypes.INIT_STATE, state());
    },
    fetchMembers: function (_a, id) {
        var commit = _a.commit;
        return __awaiter(this, void 0, void 0, function () {
            var res;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, projectUserApiClient.fetchProjectUsers(id)];
                    case 1:
                        res = _b.sent();
                        commit(ProjectMemberMutationTypes.FETCH_MEMBERS, res.data.users);
                        return [2 /*return*/];
                }
            });
        });
    },
    fetchInvitedMembers: function (_a, id) {
        var commit = _a.commit;
        return __awaiter(this, void 0, void 0, function () {
            var res;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, invitedUsetApiClient.fetchInvitedUsers(id)];
                    case 1:
                        res = _b.sent();
                        commit(ProjectMemberMutationTypes.FETCH_INVITED_USERS, res.data.invited_users);
                        return [2 /*return*/];
                }
            });
        });
    },
    inviteProjectMembers: function (_a, payload) {
        var dispatch = _a.dispatch;
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, projectUserApiClient.inviteMembersWithSms(payload)];
                    case 1:
                        _b.sent();
                        dispatch('fetchMembers', payload.id);
                        dispatch('fetchInvitedMembers', payload.id);
                        return [2 /*return*/];
                }
            });
        });
    },
    deleteProjectMember: function (_a, payload) {
        var commit = _a.commit;
        return __awaiter(this, void 0, void 0, function () {
            var projectId, id;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        projectId = payload.projectId, id = payload.id;
                        return [4 /*yield*/, projectUserApiClient.deleteProjectMember(projectId, id)];
                    case 1:
                        _b.sent();
                        commit(ProjectMemberMutationTypes.DELETE_PROJECT_MEMBER, id);
                        return [2 /*return*/];
                }
            });
        });
    },
    cancelInvitation: function (_a, payload) {
        var commit = _a.commit;
        return __awaiter(this, void 0, void 0, function () {
            var params;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        params = {
                            email_or_phone_number: payload.email || payload.phone_number,
                            project_id: payload.project_id
                        };
                        return [4 /*yield*/, invitedUsetApiClient.cancelProjectInvitation(params)];
                    case 1:
                        _b.sent();
                        commit(ProjectMemberMutationTypes.CANCEL_INVITATION, payload.email);
                        return [2 /*return*/];
                }
            });
        });
    },
    changeUserType: function (_a, payload) {
        var commit = _a.commit;
        commit(ProjectMemberMutationTypes.CHANGE_USER_TYPE, payload);
    }
};
