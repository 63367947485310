var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _a;
import { SearchProjectMutationTypes } from '@/types/search/project';
import projectApiClient from '@/plugins/apis/project-api-client';
export var state = function () { return ({
    paginate: {
        current_limit: 0,
        current_offset: 0,
        total_count: 0
    },
    searchForm: {
        tags: [],
        text: ''
    },
    queryChanged: false,
    suggestedTags: {
        projects: [],
        user_tags: [],
        customer_tags: [],
        projects_count: 0
    },
    searchHistories: []
}); };
export var getters = {
    suggestedProjects: function (state) {
        return state.suggestedTags.projects;
    },
    suggestedCustomers: function (state) {
        return state.suggestedTags.customer_tags;
    },
    suggestedUsers: function (state) {
        return state.suggestedTags.user_tags;
    },
    suggestedProjectTotalCount: function (state) {
        return state.suggestedTags.projects_count;
    },
    paginate: function (state) {
        return state.paginate;
    },
    searchForm: function (state) {
        return state.searchForm;
    },
    projectSearchHistories: function (state) {
        return state.searchHistories;
    }
};
export var mutations = (_a = {},
    _a[SearchProjectMutationTypes.INIT_STATE] = function (state, payload) {
        Object.assign(state, payload);
    },
    _a[SearchProjectMutationTypes.SEARCH_PROJECTS] = function (state, paginate) {
        state.paginate = paginate;
        state.queryChanged = false;
    },
    _a[SearchProjectMutationTypes.SET_SEARCH_FORM] = function (state, payload) {
        state.searchForm = payload;
        state.queryChanged = true;
    },
    _a[SearchProjectMutationTypes.ADD_SEARCH_FORM_TAG] = function (state, payload) {
        state.searchForm.tags.push(payload);
        state.queryChanged = true;
    },
    _a[SearchProjectMutationTypes.DELETE_SEARCH_FORM_TAG] = function (state, payload) {
        var targetIndex = state.searchForm.tags
            .map(function (_a) {
            var name = _a.name;
            return name;
        })
            .findIndex(function (name) { return name === payload.name; });
        state.searchForm.tags.splice(targetIndex, 1);
        state.queryChanged = true;
    },
    _a[SearchProjectMutationTypes.SUGGESTED_TAGS] = function (state, payload) {
        state.suggestedTags = payload;
    },
    _a[SearchProjectMutationTypes.SEARCH_HISTORIES] = function (state, payload) {
        var project_search_histories = payload.project_search_histories;
        state.searchHistories = project_search_histories;
    },
    _a);
export var actions = {
    initState: function (_a) {
        var commit = _a.commit;
        commit(SearchProjectMutationTypes.INIT_STATE, state());
    },
    setSearchForm: function (_a, payload) {
        var commit = _a.commit;
        commit(SearchProjectMutationTypes.SET_SEARCH_FORM, payload);
    },
    addSearchFormTag: function (_a, payload) {
        var commit = _a.commit;
        commit(SearchProjectMutationTypes.ADD_SEARCH_FORM_TAG, payload);
    },
    deleteSearchFormTag: function (_a, payload) {
        var commit = _a.commit;
        commit(SearchProjectMutationTypes.DELETE_SEARCH_FORM_TAG, payload);
    },
    searchProjects: function (_a, payload) {
        var commit = _a.commit, state = _a.state, dispatch = _a.dispatch;
        return __awaiter(this, void 0, void 0, function () {
            var _b, current_limit, current_offset, total_count, offset, res, _c, projects, paginate, mutationType;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        _b = state.paginate, current_limit = _b.current_limit, current_offset = _b.current_offset, total_count = _b.total_count;
                        offset = state.queryChanged ? 0 : current_offset + current_limit;
                        if (offset > total_count)
                            return [2 /*return*/];
                        return [4 /*yield*/, projectApiClient.searchProjects(__assign(__assign({}, payload), { offset: offset }))];
                    case 1:
                        res = _d.sent();
                        _c = res.data, projects = _c.projects, paginate = _c.meta.paginate;
                        mutationType = offset === 0 ? 'project/INIT_STATE' : 'project/FETCH_PROJECTS';
                        // @ts-ignore
                        commit(mutationType, { projects: projects, paginate: paginate }, { root: true });
                        commit(SearchProjectMutationTypes.SEARCH_PROJECTS, paginate);
                        dispatch('fetchSearchHistories');
                        return [2 /*return*/];
                }
            });
        });
    },
    suggestSearch: function (_a, payload) {
        var commit = _a.commit;
        return __awaiter(this, void 0, void 0, function () {
            var emptyData, data;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!payload.length) {
                            emptyData = {
                                projects: [],
                                customer_tags: [],
                                user_tags: [],
                                projects_count: 0
                            };
                            commit(SearchProjectMutationTypes.SUGGESTED_TAGS, emptyData);
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, projectApiClient.suggest(payload)];
                    case 1:
                        data = (_b.sent()).data;
                        commit(SearchProjectMutationTypes.SUGGESTED_TAGS, data);
                        return [2 /*return*/];
                }
            });
        });
    },
    fetchSearchHistories: function (_a) {
        var commit = _a.commit;
        return __awaiter(this, void 0, void 0, function () {
            var data;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, projectApiClient.fetchSearchHistories()];
                    case 1:
                        data = (_b.sent()).data;
                        commit(SearchProjectMutationTypes.SEARCH_HISTORIES, data);
                        return [2 /*return*/];
                }
            });
        });
    },
    deleteSearchHistory: function (_a, payload) {
        var dispatch = _a.dispatch;
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, projectApiClient.deleteSearchHistory(payload)];
                    case 1:
                        _b.sent();
                        dispatch('fetchSearchHistories');
                        return [2 /*return*/];
                }
            });
        });
    }
};
