var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _a;
import { SharingContentMutationTypes } from '@/types/sharing-content';
import sharingContentApi from '@/plugins/apis/sharing-content-api';
export var state = function () { return ({
    sharingContents: [],
    sort: {
        prop: 'created_at',
        order: 'desc'
    }
}); };
export var getters = {
    sharingContents: function (state) {
        return state.sharingContents;
    },
    sort: function (state) {
        return state.sort;
    }
};
export var mutations = (_a = {},
    _a[SharingContentMutationTypes.INIT_STATE] = function (state, payload) {
        Object.assign(state, payload);
    },
    _a[SharingContentMutationTypes.FETCH_SHARING_CONTENTS] = function (state, payload) {
        var _a;
        var existsFiles = state.sharingContents.map(function (content) { return content.id; });
        (_a = state.sharingContents).push.apply(_a, payload.filter(function (content) { return !existsFiles.includes(content.id); }));
    },
    _a[SharingContentMutationTypes.REPLACE_SHARING_CONTENT] = function (state, payload) {
        var index = state.sharingContents.findIndex(function (content) {
            if (payload.id) {
                return content.id === payload.id;
            }
            return content.content_id === payload.content_id;
        });
        if (index > -1) {
            if (payload.expires_in) {
                state.sharingContents[index].expires_in = payload.expires_in;
            }
            if (payload.name) {
                state.sharingContents[index].name = payload.name;
            }
        }
    },
    _a[SharingContentMutationTypes.CHANGE_SORT] = function (state, payload) {
        state.sort = payload;
        state.sharingContents = [];
    },
    _a[SharingContentMutationTypes.REMOVE_SHARING_CONTENT] = function (state, payload) {
        state.sharingContents = state.sharingContents.filter(function (content) { return content.id !== payload; });
    },
    _a);
export var actions = {
    initState: function (_a) {
        var commit = _a.commit;
        commit(SharingContentMutationTypes.INIT_STATE, state());
    },
    fetchSharingContents: function (_a) {
        var commit = _a.commit, getters = _a.getters;
        return __awaiter(this, void 0, void 0, function () {
            var sort, sharingContents, res;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        sort = getters.sort, sharingContents = getters.sharingContents;
                        return [4 /*yield*/, sharingContentApi.fetchContents({
                                sort: sort,
                                offset: sharingContents.length
                            })];
                    case 1:
                        res = _b.sent();
                        commit(SharingContentMutationTypes.FETCH_SHARING_CONTENTS, res.data.sharing_contents);
                        return [2 /*return*/];
                }
            });
        });
    },
    replaceSharingContent: function (_a, payload) {
        var commit = _a.commit;
        commit(SharingContentMutationTypes.REPLACE_SHARING_CONTENT, payload);
    },
    changeSort: function (_a, payload) {
        var commit = _a.commit, dispatch = _a.dispatch;
        commit(SharingContentMutationTypes.CHANGE_SORT, payload);
        commit(SharingContentMutationTypes.FETCH_SHARING_CONTENTS, []);
        return dispatch('fetchSharingContents');
    },
    removeSharingContent: function (_a, payload) {
        var commit = _a.commit;
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, sharingContentApi.removeLink(payload)];
                    case 1:
                        _b.sent();
                        commit(SharingContentMutationTypes.REMOVE_SHARING_CONTENT, payload);
                        return [2 /*return*/];
                }
            });
        });
    }
};
