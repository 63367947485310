import { isAdmin, isMember } from '@/policies/';
var projectMember = {
    fetchMembers: function () {
        return true;
    },
    fetchInvitedMembers: function (company) {
        return isAdmin(company.user_type) || isMember(company.user_type);
    },
    update: function (company) {
        return isAdmin(company.user_type) || isMember(company.user_type);
    },
    invite: function (company) {
        return isAdmin(company.user_type) || isMember(company.user_type);
    }
};
export default projectMember;
