var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Vue, Component, Prop } from 'vue-property-decorator';
import SidemenuBookmarkButton from '@/components/atoms/sidemenu-bookmark-button.vue';
import { Routes } from '@/types/routes';
var BookmarkRow = /** @class */ (function (_super) {
    __extends(BookmarkRow, _super);
    function BookmarkRow() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(BookmarkRow.prototype, "project", {
        get: function () {
            var project = this.bookmark.project;
            return project;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(BookmarkRow.prototype, "isCurrentProject", {
        get: function () {
            var _a;
            var _b = this, $route = _b.$route, project = _b.project;
            if (!((_a = $route.name) === null || _a === void 0 ? void 0 : _a.startsWith(Routes.ProjectPage.name)))
                return false;
            return $route.params.id === project.id;
        },
        enumerable: false,
        configurable: true
    });
    BookmarkRow.prototype.clickProjectBookmarkHandler = function () {
        var _a = this, id = _a.project.id, isCurrentProject = _a.isCurrentProject;
        if (isCurrentProject)
            return;
        this.$router.push({
            name: Routes.ProjectDetailPage.name,
            params: { id: id },
            // ブックマークから案件詳細画面に遷移するとき、必ず AsyncData を実行させるためのクエリパラメータ
            // クエリ自体に意味はなく、案件詳細で AsyncData を実行するためのトリガとして利用している
            query: { forceAsyncData: 'on' }
        });
    };
    __decorate([
        Prop({ type: Object, required: true })
    ], BookmarkRow.prototype, "bookmark", void 0);
    BookmarkRow = __decorate([
        Component({
            components: {
                SidemenuBookmarkButton: SidemenuBookmarkButton
            }
        })
    ], BookmarkRow);
    return BookmarkRow;
}(Vue));
export default BookmarkRow;
