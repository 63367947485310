import apiClient from '@/plugins/api-client';
import showErrorMessage from '@/plugins/show-error-message';
import Notification from '@/plugins/notification';
import MessageType from '@/plugins/message-type';
import { alertMessage, otherAlertMessage } from '@/plugins/alert-messages';
import { ResourceType } from '@/types/base';
var BaseApiClient = /** @class */ (function () {
    function BaseApiClient() {
        this.client = apiClient;
    }
    BaseApiClient.setContext = function (context) {
        BaseApiClient.context = context;
    };
    Object.defineProperty(BaseApiClient.prototype, "companyId", {
        get: function () {
            return BaseApiClient.context.store.getters['initial-setting/company'].id;
        },
        enumerable: false,
        configurable: true
    });
    BaseApiClient.prototype.requestAction = function (method, url, params, resourceName, actionName, hasData, anyMessage) {
        if (hasData === void 0) { hasData = true; }
        if (!window.navigator.onLine) {
            var error = '現在オフラインです';
            showErrorMessage(error, {
                message: 'オンラインにしてから再度試して下さい。'
            });
            throw new Error(error);
        }
        var resource = ResourceType[resourceName];
        var client = apiClient;
        return client[method](url, params)
            .then(function (res) {
            var message = '';
            if (hasData) {
                var data = res.data[resourceName];
                message = data.title || data.name;
            }
            if (anyMessage && anyMessage.length) {
                message = anyMessage;
            }
            var alertTitle = actionName === 'OTHER'
                ? otherAlertMessage[MessageType.success(resourceName)]
                : alertMessage[MessageType.success(actionName)](resource);
            Notification.success({
                title: alertTitle,
                message: message
            });
            return res;
        })
            .catch(function (e) {
            if (e.response === undefined) {
                throw new Error(e.message);
            }
            var alertTitle = actionName === 'OTHER'
                ? otherAlertMessage[MessageType.error(resourceName)]
                : alertMessage[MessageType.error(actionName)](resource);
            showErrorMessage(alertTitle, e.response.data);
            throw new Error(e.message);
        });
    };
    // HACK: deleteの時パラメーターを送れなかった為暫定対応
    BaseApiClient.prototype.requestActionWithNamedProperty = function (_a) {
        var _b = _a.method, method = _b === void 0 ? 'post' : _b, url = _a.url, params = _a.params, data = _a.data, resourceName = _a.resourceName, actionName = _a.actionName, _c = _a.hasData, hasData = _c === void 0 ? false : _c, _d = _a.notice, notice = _d === void 0 ? true : _d;
        if (!window.navigator.onLine) {
            var error = '現在オフラインです';
            showErrorMessage(error, {
                message: 'オンラインにしてから再度試して下さい。'
            });
            throw new Error(error);
        }
        var client = this.client;
        var resource = ResourceType[resourceName];
        return client
            .request({
            method: method,
            url: url,
            params: params,
            data: data
        })
            .then(function (res) {
            if (!notice)
                return res;
            var message = '';
            if (hasData) {
                var data_1 = res.data[resourceName];
                message = data_1.title || data_1.name;
            }
            var alertTitle = actionName === 'OTHER'
                ? otherAlertMessage[MessageType.success(resourceName)]
                : alertMessage[MessageType.success(actionName)](resource);
            Notification.success({
                title: alertTitle,
                message: message
            });
            return res;
        })
            .catch(function (e) {
            if (e.response === undefined) {
                throw new Error(e.message);
            }
            var alertTitle = actionName === 'OTHER'
                ? otherAlertMessage[MessageType.error(resourceName)]
                : alertMessage[MessageType.error(actionName)](resource);
            showErrorMessage(alertTitle, e.response.data);
            throw new Error(e.message);
        });
    };
    return BaseApiClient;
}());
export default BaseApiClient;
