export var NotificationType;
(function (NotificationType) {
    NotificationType["INVITE_PROJECT"] = "invite_project";
    NotificationType["PUBLISH_SCHEDULE"] = "publish_schedule";
    NotificationType["UPDATE_SCHEDULE"] = "update_schedule";
    NotificationType["UPDATE_CUSTOMER"] = "update_customer";
    NotificationType["PAYMENT_FAILURE"] = "payment_failure";
    NotificationType["NEWS"] = "news";
    NotificationType["CREATE_BOARD_POST"] = "create_board_post";
    NotificationType["CREATE_BOARD_POST_COMMENT"] = "create_board_post_comment";
    NotificationType["CREATE_CONTENT_COMMENT"] = "create_content_comment";
})(NotificationType || (NotificationType = {}));
export var NotificationMutationTypes;
(function (NotificationMutationTypes) {
    NotificationMutationTypes["IS_SHOW_LOADING"] = "IS_SHOW_LOADING";
    NotificationMutationTypes["IS_FETCH_ERROR"] = "IS_FETCH_ERROR";
    NotificationMutationTypes["INIT_NOTIFICATIONS"] = "INIT_NOTIFICATIONS";
    NotificationMutationTypes["INIT_PROVIDER_NOTIFICATIONS"] = "INIT_PROVIDER_NOTIFICATIONS";
    NotificationMutationTypes["UPDATE_NOTIFICATION_USER_BADGE"] = "UPDATE_NOTIFICATION_USER_BADGE";
    NotificationMutationTypes["FETCH_ANNOUNCEMENTS"] = "FETCH_ANNOUNCEMENTS";
})(NotificationMutationTypes || (NotificationMutationTypes = {}));
