var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import BaseApiClient from '@/plugins/apis/base-api-client';
var resourceName = 'content_comment';
var ContentCommentApi = /** @class */ (function (_super) {
    __extends(ContentCommentApi, _super);
    function ContentCommentApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(ContentCommentApi.prototype, "basePath", {
        get: function () {
            var companyId = this.companyId;
            return "/companies/" + companyId + "/contents";
        },
        enumerable: false,
        configurable: true
    });
    ContentCommentApi.prototype.fetchComments = function (contentId) {
        var basePath = this.basePath;
        var url = basePath + "/" + contentId + "/content_comments";
        return this.client.get(url, {
            params: {
                limit: 500
            }
        });
    };
    ContentCommentApi.prototype.createComment = function (_a) {
        var contentId = _a.contentId, text = _a.text, mentioned_uids = _a.mentioned_uids, pin = _a.pin;
        var basePath = this.basePath;
        var url = basePath + "/" + contentId + "/content_comments";
        return this.requestActionWithNamedProperty({
            url: url,
            method: 'post',
            data: {
                content_comment: {
                    body: text.trim(),
                    mentioned_uids: mentioned_uids,
                    pin: pin
                }
            },
            actionName: 'POST',
            resourceName: resourceName
        });
    };
    ContentCommentApi.prototype.updatePin = function (_a) {
        var contentId = _a.contentId, commentId = _a.commentId, pin = _a.pin;
        var basePath = this.basePath;
        var url = basePath + "/" + contentId + "/content_comments/" + commentId;
        return this.requestActionWithNamedProperty({
            url: url,
            method: 'put',
            data: {
                content_comment: {
                    pin: pin
                }
            },
            actionName: 'UPDATE',
            resourceName: resourceName
        });
    };
    ContentCommentApi.prototype.deleteComment = function (_a) {
        var contentId = _a.contentId, commentId = _a.commentId;
        var basePath = this.basePath;
        var url = basePath + "/" + contentId + "/content_comments/" + commentId;
        return this.requestActionWithNamedProperty({
            url: url,
            method: 'delete',
            actionName: 'DELETE',
            resourceName: resourceName
        });
    };
    ContentCommentApi.prototype.updateComment = function (_a) {
        var commentId = _a.commentId, contentId = _a.contentId, text = _a.text, mentionedUids = _a.mentionedUids;
        var basePath = this.basePath;
        var url = basePath + "/" + contentId + "/content_comments/" + commentId;
        return this.requestActionWithNamedProperty({
            url: url,
            method: 'put',
            actionName: 'UPDATE',
            resourceName: resourceName,
            data: {
                content_comment: {
                    body: text.trim(),
                    mentioned_uids: mentionedUids
                }
            }
        });
    };
    ContentCommentApi.prototype.fetchMentionableUsers = function (_a) {
        var contentId = _a.contentId;
        var _b = this, basePath = _b.basePath, client = _b.client;
        return client.get(basePath + "/" + contentId + "/mentionable_users");
    };
    return ContentCommentApi;
}(BaseApiClient));
export default new ContentCommentApi();
