import { LocalStorageKeys } from '@/types';
export default (function (_a) {
    var store = _a.store;
    var JWTKey = LocalStorageKeys.JWT;
    if (!localStorage.getItem(JWTKey)) {
        return;
    }
    var holidayList = store.getters['holiday/holidays'];
    if (!Object.keys(holidayList).length) {
        store.dispatch('holiday/fetchHolidays');
    }
});
