var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Vue, Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
var NotificationModule = namespace('notification');
var NotificationBaseMixin = /** @class */ (function (_super) {
    __extends(NotificationBaseMixin, _super);
    function NotificationBaseMixin() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        NotificationModule.Getter('isShowLoading')
    ], NotificationBaseMixin.prototype, "isShowLoading", void 0);
    __decorate([
        NotificationModule.Getter('isFetchError')
    ], NotificationBaseMixin.prototype, "isFetchError", void 0);
    __decorate([
        NotificationModule.Getter('notifications')
    ], NotificationBaseMixin.prototype, "notifications", void 0);
    __decorate([
        NotificationModule.Getter('providerNotifications')
    ], NotificationBaseMixin.prototype, "providerNotifications", void 0);
    __decorate([
        NotificationModule.Getter('notificationUserBadge')
    ], NotificationBaseMixin.prototype, "notificationUserBadge", void 0);
    __decorate([
        NotificationModule.Getter('announcements')
    ], NotificationBaseMixin.prototype, "announcements", void 0);
    __decorate([
        NotificationModule.Action('initUserNotifications')
    ], NotificationBaseMixin.prototype, "initUserNotifications", void 0);
    __decorate([
        NotificationModule.Action('initProviderNotifications')
    ], NotificationBaseMixin.prototype, "initProviderNotifications", void 0);
    __decorate([
        NotificationModule.Action('readNotification')
    ], NotificationBaseMixin.prototype, "readNotification", void 0);
    __decorate([
        NotificationModule.Action('readAllNotification')
    ], NotificationBaseMixin.prototype, "readAllNotification", void 0);
    __decorate([
        NotificationModule.Action('addListenerNotificationUserBadge')
    ], NotificationBaseMixin.prototype, "addListenerNotificationUserBadge", void 0);
    __decorate([
        NotificationModule.Action('updateNotificationUserBadge')
    ], NotificationBaseMixin.prototype, "updateNotificationUserBadge", void 0);
    __decorate([
        NotificationModule.Action('fetchAnnouncements')
    ], NotificationBaseMixin.prototype, "fetchAnnouncements", void 0);
    __decorate([
        NotificationModule.Action('readAnnouncement')
    ], NotificationBaseMixin.prototype, "readAnnouncement", void 0);
    __decorate([
        NotificationModule.Action('allReadAnnouncements')
    ], NotificationBaseMixin.prototype, "allReadAnnouncements", void 0);
    NotificationBaseMixin = __decorate([
        Component
    ], NotificationBaseMixin);
    return NotificationBaseMixin;
}(Vue));
export default NotificationBaseMixin;
