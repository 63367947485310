import Firebase from 'firebase/app';
import 'firebase/firestore';
import { captureMessage, captureException } from '@sentry/browser';
import MeApiClient from '@/plugins/apis/me-api-client';
// 検証中のため develop でのみ Sentry に通知する
var captureMessageToSentry = function (message) {
    if (process.env.appEnv === 'develop')
        captureMessage(message);
};
var captureExceptionToSentry = function (message) {
    if (process.env.appEnv === 'develop')
        captureException(message);
};
// Firebaseへのアクセス時、ログインしていない場合はログインを試みる
// また、ログインしていない場合、再ログインしつつ、その成功可否をSentryに通知する
var checkFirebaseAuthAndDoAction = function (action, companyId) {
    var timerId;
    var unsubscribe = Firebase.auth().onAuthStateChanged(function (user) {
        if (user) {
            if (timerId)
                clearTimeout(timerId);
            unsubscribe();
            action();
        }
        else {
            var waitForInitSDK = 3000;
            timerId = window.setTimeout(function () {
                // 時間経過後も timer が clear されないようなら再度ログインする
                captureMessageToSentry("Firebase \u306B\u30ED\u30B0\u30A4\u30F3\u3057\u3066\u3044\u307E\u305B\u3093 " + JSON.stringify(Firebase.auth().currentUser));
                MeApiClient.fetchMe(companyId)
                    .then(function (res) {
                    var custom_token = res.data.user.custom_token;
                    if (!custom_token) {
                        throw new Error('custom_token が取得できませんでした');
                    }
                    Firebase.auth()
                        .signInWithCustomToken(custom_token)
                        .then(function () {
                        captureMessageToSentry("Firebase \u306B\u518D\u30ED\u30B0\u30A4\u30F3\u3057\u307E\u3057\u305F\uFF1A " + JSON.stringify(Firebase.auth().currentUser));
                    });
                })
                    .catch(function (e) {
                    captureExceptionToSentry("Firebase \u306B\u30ED\u30B0\u30A4\u30F3\u3067\u304D\u307E\u305B\u3093\u3067\u3057\u305F\uFF1A" + JSON.stringify({
                        e: e,
                        user: Firebase.auth().currentUser
                    }));
                });
            }, waitForInitSDK);
        }
    });
};
export default checkFirebaseAuthAndDoAction;
