var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Mixins, Component } from 'vue-property-decorator';
import { Routes } from '@/types/routes';
import CanMember from '@/mixins/can/member';
import CanCompany from '@/mixins/can/campany';
import MaterialIcons from '@/components/material-icons.vue';
import CanSchedule from '@/mixins/can/schedule';
var InitialLeftColumn = /** @class */ (function (_super) {
    __extends(InitialLeftColumn, _super);
    function InitialLeftColumn() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(InitialLeftColumn.prototype, "isUserProfile", {
        // computed
        get: function () {
            return this.step === 1;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(InitialLeftColumn.prototype, "isCompanyProfile", {
        get: function () {
            return this.step === 2;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(InitialLeftColumn.prototype, "isPreferences", {
        get: function () {
            var $route = this.$route;
            return $route.name === Routes.InitialSettingsPreferencesPage.name;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(InitialLeftColumn.prototype, "isInviteMember", {
        get: function () {
            var _a = this, step = _a.step, canUpdatePreferences = _a.canUpdatePreferences;
            if (canUpdatePreferences) {
                return step > 4;
            }
            else {
                return step > 3;
            }
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(InitialLeftColumn.prototype, "step", {
        get: function () {
            return this.$store.state['initial-setting'].step;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(InitialLeftColumn.prototype, "doneUserProfile", {
        get: function () {
            return this.step > 1;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(InitialLeftColumn.prototype, "doneCompanyProfile", {
        get: function () {
            return this.step > 2;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(InitialLeftColumn.prototype, "donePreferences", {
        get: function () {
            return this.step > 3;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(InitialLeftColumn.prototype, "doneInviteMember", {
        get: function () {
            var _a = this, step = _a.step, canUpdatePreferences = _a.canUpdatePreferences;
            if (canUpdatePreferences) {
                return step > 3;
            }
            else {
                return step > 4;
            }
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(InitialLeftColumn.prototype, "companyUninitialized", {
        get: function () {
            return !this.$store.state['initial-setting'].company.initialized;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(InitialLeftColumn.prototype, "canUpdatePreferences", {
        get: function () {
            var canCreateSchedule = this.canCreateSchedule;
            return canCreateSchedule;
        },
        enumerable: false,
        configurable: true
    });
    InitialLeftColumn = __decorate([
        Component({
            components: {
                MaterialIcons: MaterialIcons
            }
        })
    ], InitialLeftColumn);
    return InitialLeftColumn;
}(Mixins(CanMember, CanCompany, CanSchedule)));
export default InitialLeftColumn;
