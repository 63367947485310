var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import BaseApiClient from '@/plugins/apis/base-api-client';
var MeApiClient = /** @class */ (function (_super) {
    __extends(MeApiClient, _super);
    function MeApiClient() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(MeApiClient.prototype, "basePath", {
        get: function () {
            return '/me';
        },
        enumerable: false,
        configurable: true
    });
    MeApiClient.prototype.fetchMe = function (companyId) {
        var _a = this, basePath = _a.basePath, client = _a.client;
        var params = {};
        if (companyId) {
            params.company_id = companyId;
        }
        return client.get(basePath, { params: params });
    };
    MeApiClient.prototype.updateMe = function (params) {
        var _a = this, basePath = _a.basePath, client = _a.client;
        return client.put(basePath, params);
    };
    MeApiClient.prototype.updateSettings = function (_a) {
        var settings = _a.settings;
        var _b = this, basePath = _b.basePath, client = _b.client;
        var url = basePath + "/update_settings";
        var data = {
            user_settings: settings
        };
        return client.patch(url, data);
    };
    MeApiClient.prototype.registerFcmToken = function (fcmToken) {
        var _a = this, basePath = _a.basePath, client = _a.client;
        var url = basePath + "/register_fcm_token";
        var params = {
            fcm_token: fcmToken
        };
        return client.post(url, params);
    };
    return MeApiClient;
}(BaseApiClient));
export default new MeApiClient();
