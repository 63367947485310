var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import showLoading from '@/plugins/show-loading';
import fetchHoliday from '@/middleware/fetch-holiday';
import { UserTypes } from '@/types/company';
import { Routes } from '@/types/routes';
import { LocalStorageKeys } from '@/types';
// - casからカレントワークスペースのcompanyと所属するcompaniesの取得を行う
// - カレントワークスペースのcompanyのplan.statusがavailableでない場合、専用画面にリダイレクト
export default (function (context) {
    var route = context.route, params = context.params, store = context.store, redirect = context.redirect;
    var commonExcludeRouteName = [Routes.SettingsUserProfilePage.name];
    var isRedirect;
    if (params.company_id) {
        var promises = [];
        var company = store.getters['initial-setting/company'];
        if (!company.id || !company.user_initialized) {
            promises.push(store.dispatch('initial-setting/fetchCompany', {
                id: params.company_id
            }));
        }
        var companies = store.getters['initial-setting/companies'];
        if (!companies.length) {
            promises.push(store.dispatch('initial-setting/fetchCompanies'));
        }
        return Promise.all(promises)
            .then(function () { return __awaiter(void 0, void 0, void 0, function () {
            var company_1, user, isRedirected;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!store.getters['initial-setting/isAvailable']) return [3 /*break*/, 4];
                        fetchHoliday(context);
                        company_1 = store.getters['initial-setting/company'];
                        user = store.getters['initial-setting/user'];
                        if (!!user.id) return [3 /*break*/, 3];
                        isRedirected = !!localStorage.getItem(LocalStorageKeys.RedirectLoopStopKey);
                        if (!!isRedirected) return [3 /*break*/, 2];
                        return [4 /*yield*/, store.dispatch('initial-setting/fetchMe', company_1.id)];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        localStorage.removeItem(LocalStorageKeys.RedirectLoopStopKey);
                        return [2 /*return*/];
                    case 3:
                        if (!company_1.user_initialized) {
                            redirectWith([], Routes.InitialSettingsUserProfilePage.name, true, redirect, "/initial-settings/user-profile");
                            return [2 /*return*/];
                        }
                        isRedirect = !company_1.initialized && company_1.user_type === UserTypes.Admin;
                        redirectWith([], Routes.SettingsCompanyProfilePage.name, isRedirect, redirect, "/initial-settings/company-profile");
                        return [2 /*return*/];
                    case 4:
                        // 無料トライアル-有効期限切れ
                        redirectWith(__spreadArrays(commonExcludeRouteName, [Routes.PlanExpirationPage.name]), route.name, store.getters['initial-setting/isTrialIsExpired'], redirect, "/companies/" + params.company_id + "/expiration");
                        // 有料プラン-利用停止
                        // アカウントロック
                        // 未定義ステータス
                        isRedirect =
                            store.getters['initial-setting/isUnpaidPlan'] ||
                                store.getters['initial-setting/isLockedPlan'] ||
                                store.getters['initial-setting/isUndefinedStatus'];
                        redirectWith(__spreadArrays(commonExcludeRouteName, [Routes.PlanUnavailabilityPage.name]), route.name, isRedirect, redirect, "/companies/" + params.company_id + "/unavailability");
                        return [2 /*return*/];
                }
            });
        }); })
            .catch(function (e) {
            localStorage.setItem(LocalStorageKeys.RedirectLoopStopKey, 'on');
            console.log(e);
            redirectWith([], Routes.SessionSignInPage.name, true, redirect, "/sign_in?redirect_url=" + route.fullPath);
        });
    }
});
var redirectWith = function (excludeRouteNames, routeName, isRedirect, redirect, redirectUrl) {
    if (!routeName || excludeRouteNames.includes(routeName) || !isRedirect) {
        return;
    }
    redirect(redirectUrl);
    // 別のワークスペースからの遷移時のloadingを閉じる
    var loading = showLoading();
    loading.close();
};
