var MessageType = /** @class */ (function () {
    function MessageType() {
    }
    MessageType.prototype.success = function (type) {
        return type.toUpperCase() + "_SUCCESS";
    };
    MessageType.prototype.error = function (type) {
        return type.toUpperCase() + "_ERROR";
    };
    return MessageType;
}());
export default new MessageType();
