var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import BaseApiClient from '@/plugins/apis/v2/base-api-client';
var ScheduleApiClient = /** @class */ (function (_super) {
    __extends(ScheduleApiClient, _super);
    function ScheduleApiClient() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(ScheduleApiClient.prototype, "basePath", {
        get: function () {
            var companyId = this.companyId;
            return "/companies/" + companyId + "/projects";
        },
        enumerable: false,
        configurable: true
    });
    // Schedule
    ScheduleApiClient.prototype.fetchSchedule = function (projectId) {
        var _a = this, basePath = _a.basePath, client = _a.client;
        var url = basePath + "/" + projectId + "/schedule";
        return client.get(url);
    };
    ScheduleApiClient.prototype.createSchedule = function (projectId, payload) {
        var _a = this, basePath = _a.basePath, requestAction = _a.requestAction;
        var data = {
            schedule: {
                published_schedule_id: payload.published_schedule_id
            }
        };
        var url = basePath + "/" + projectId + "/schedule";
        return requestAction({
            url: url,
            data: data,
            resourceName: 'schedule',
            actionName: 'POST',
            notice: false
        });
    };
    ScheduleApiClient.prototype.deleteSchedule = function (projectId) {
        var _a = this, basePath = _a.basePath, requestAction = _a.requestAction;
        var url = basePath + "/" + projectId + "/schedule";
        return requestAction({
            method: 'delete',
            url: url,
            resourceName: 'schedule',
            actionName: 'DELETE',
            notice: false
        });
    };
    // PublishedSchedule
    ScheduleApiClient.prototype.fetchPublishedSchedule = function (projectId) {
        var _a = this, basePath = _a.basePath, client = _a.client;
        var url = basePath + "/" + projectId + "/published_schedule";
        return client.get(url);
    };
    ScheduleApiClient.prototype.fetchPublishedSchedules = function () {
        var _a = this, companyId = _a.companyId, client = _a.client;
        var path = "/companies/" + companyId;
        var url = path + "/published_schedules";
        return client.get(url);
    };
    ScheduleApiClient.prototype.createPublishedSchedule = function (projectId) {
        var _a = this, basePath = _a.basePath, requestAction = _a.requestAction;
        var url = basePath + "/" + projectId + "/published_schedule";
        return requestAction({
            method: 'post',
            url: url,
            resourceName: 'schedule',
            actionName: 'PUBLISH'
        });
    };
    ScheduleApiClient.prototype.updatePublishedSchedule = function (projectId) {
        var _a = this, basePath = _a.basePath, requestAction = _a.requestAction;
        var url = basePath + "/" + projectId + "/published_schedule";
        return requestAction({
            method: 'put',
            url: url,
            resourceName: 'schedule',
            actionName: 'PUBLISH'
        });
    };
    ScheduleApiClient.prototype.updatePrintSettings = function (projectId, print_settings) {
        var _a = this, basePath = _a.basePath, requestAction = _a.requestAction;
        var url = basePath + "/" + projectId + "/published_schedule";
        return requestAction({
            method: 'put',
            url: url,
            resourceName: 'schedule_print_settings',
            actionName: 'UPDATE',
            params: {
                print_settings: print_settings
            }
        });
    };
    ScheduleApiClient.prototype.downloadPDF = function (projectId, params) {
        var _a = this, basePath = _a.basePath, requestAction = _a.requestAction;
        var url = basePath + "/" + projectId + "/published_schedule/download";
        return requestAction({
            method: 'get',
            url: url,
            params: params,
            resourceName: 'schedule',
            actionName: 'DOWNLOAD',
            notice: false
        });
    };
    // LargeProcess
    ScheduleApiClient.prototype.fetchLargeProcesses = function (projectId, scheduleId) {
        var _a = this, basePath = _a.basePath, client = _a.client;
        var url = basePath + "/" + projectId + "/schedules/" + scheduleId + "/large_processes";
        return client.get(url);
    };
    ScheduleApiClient.prototype.createLargeProcess = function (projectId, scheduleId, largeProcesses) {
        var _a = this, basePath = _a.basePath, client = _a.client;
        var data = {
            large_processes: largeProcesses
        };
        var url = basePath + "/" + projectId + "/schedules/" + scheduleId + "/large_processes";
        return client.post(url, data);
    };
    ScheduleApiClient.prototype.updateLargeProcess = function (projectId, scheduleId, id, name, color, prevProcessId) {
        var _a = this, basePath = _a.basePath, client = _a.client;
        var data = {
            large_process: {
                name: name,
                color: color,
                prev_process_id: prevProcessId
            }
        };
        var url = basePath + "/" + projectId + "/schedules/" + scheduleId + "/large_processes/" + id;
        return client.put(url, data);
    };
    ScheduleApiClient.prototype.deleteLargeProcess = function (projectId, scheduleId, id) {
        var _a = this, basePath = _a.basePath, requestAction = _a.requestAction;
        var url = basePath + "/" + projectId + "/schedules/" + scheduleId + "/large_processes/" + id;
        return requestAction({
            method: 'delete',
            url: url,
            resourceName: 'large_process',
            actionName: 'DELETE',
            notice: false
        });
    };
    // SmallProcess
    ScheduleApiClient.prototype.fetchSmallProcesses = function (projectId, scheduleId) {
        var _a = this, basePath = _a.basePath, client = _a.client;
        var url = basePath + "/" + projectId + "/schedules/" + scheduleId + "/small_processes";
        return client.get(url);
    };
    ScheduleApiClient.prototype.createSmallProcess = function (projectId, scheduleId, largeProcessId, smallProcesses) {
        var _a = this, basePath = _a.basePath, requestAction = _a.requestAction;
        var data = {
            small_processes: smallProcesses
        };
        var url = basePath + "/" + projectId + "/schedules/" + scheduleId + "/large_processes/" + largeProcessId + "/small_processes";
        return requestAction({
            method: 'post',
            url: url,
            data: data,
            resourceName: 'small_process',
            actionName: 'CREATE',
            notice: false
        });
    };
    ScheduleApiClient.prototype.updateSmallProcess = function (projectId, scheduleId, largeProcessId, id, smallProcess, movingBelowProcessIds, movingAboveProcessIds) {
        var _a = this, basePath = _a.basePath, requestAction = _a.requestAction;
        var data = {
            small_process: smallProcess,
            moving_below_process_ids: movingBelowProcessIds,
            moving_above_process_ids: movingAboveProcessIds
        };
        var url = basePath + "/" + projectId + "/schedules/" + scheduleId + "/large_processes/" + largeProcessId + "/small_processes/" + id;
        return requestAction({
            method: 'put',
            url: url,
            data: data,
            resourceName: 'small_process',
            actionName: 'UPDATE',
            notice: false
        });
    };
    ScheduleApiClient.prototype.deleteSmallProcess = function (projectId, scheduleId, largeProcessId, id) {
        var _a = this, basePath = _a.basePath, requestAction = _a.requestAction;
        var url = basePath + "/" + projectId + "/schedules/" + scheduleId + "/large_processes/" + largeProcessId + "/small_processes/" + id;
        return requestAction({
            method: 'delete',
            url: url,
            resourceName: 'small_process',
            actionName: 'DELETE',
            notice: false
        });
    };
    // PublishedLargeProcesses
    ScheduleApiClient.prototype.fetchPublishedLargeProcesses = function (projectId, publishedScheduleId) {
        var _a = this, basePath = _a.basePath, client = _a.client;
        var url = basePath + "/" + projectId + "/published_schedule/" + publishedScheduleId + "/published_large_processes";
        return client.get(url);
    };
    // PublishedSmallProcesses
    ScheduleApiClient.prototype.fetchPublishedSmallProcesses = function (projectId, publishedScheduleId) {
        var _a = this, basePath = _a.basePath, client = _a.client;
        var url = basePath + "/" + projectId + "/published_schedule/" + publishedScheduleId + "/published_small_processes";
        return client.get(url);
    };
    // SchedulePlans
    ScheduleApiClient.prototype.fetchSchedulePlans = function (projectId, scheduleId) {
        var _a = this, basePath = _a.basePath, client = _a.client;
        var url = basePath + "/" + projectId + "/schedules/" + scheduleId + "/plans";
        return client.get(url);
    };
    ScheduleApiClient.prototype.fetchPublishedSchedulePlans = function (projectId, publishedScheduleId) {
        var _a = this, basePath = _a.basePath, client = _a.client;
        var url = basePath + "/" + projectId + "/published_schedule/" + publishedScheduleId + "/published_schedule_plans";
        return client.get(url);
    };
    ScheduleApiClient.prototype.createSchedulePlan = function (projectId, scheduleId, payload) {
        var _a = this, basePath = _a.basePath, requestAction = _a.requestAction;
        var data = {
            schedule_plan: payload
        };
        var url = basePath + "/" + projectId + "/schedules/" + scheduleId + "/plans";
        return requestAction({
            url: url,
            data: data,
            resourceName: 'schedule_plan',
            actionName: 'CREATE',
            notice: false
        });
    };
    ScheduleApiClient.prototype.updateSchedulePlan = function (projectId, scheduleId, id, payload) {
        var _a = this, basePath = _a.basePath, requestAction = _a.requestAction;
        var data = {
            schedule_plan: payload
        };
        var url = basePath + "/" + projectId + "/schedules/" + scheduleId + "/plans/" + id;
        return requestAction({
            method: 'put',
            url: url,
            data: data,
            resourceName: 'schedule_plan',
            actionName: 'UPDATE',
            notice: false
        });
    };
    ScheduleApiClient.prototype.deleteSchedulePlan = function (projectId, scheduleId, id) {
        var _a = this, basePath = _a.basePath, requestAction = _a.requestAction;
        var url = basePath + "/" + projectId + "/schedules/" + scheduleId + "/plans/" + id;
        return requestAction({
            method: 'delete',
            url: url,
            resourceName: 'schedule_plan',
            actionName: 'DELETE',
            notice: false
        });
    };
    return ScheduleApiClient;
}(BaseApiClient));
export default new ScheduleApiClient();
