export var ConstructionKindMutationTypes;
(function (ConstructionKindMutationTypes) {
    ConstructionKindMutationTypes["INIT_STATE"] = "INIT_STATE";
    ConstructionKindMutationTypes["SET_SORT_TYPE"] = "SET_SORT_TYPE";
    ConstructionKindMutationTypes["SET_SORTED_CONSTRUCTION_KINDS"] = "SET_SORTED_CONSTRUCTION_KINDS";
    ConstructionKindMutationTypes["INIT_CONSTRUCTION_KINDS"] = "INIT_CONSTRUCTION_KINDS";
    ConstructionKindMutationTypes["FETCH_CONSTRUCTION_KINDS"] = "FETCH_CONSTRUCTION_KINDS";
    ConstructionKindMutationTypes["FETCH_ALL_CONSTRUCTION_KINDS"] = "FETCH_ALL_CONSTRUCTION_KINDS";
    ConstructionKindMutationTypes["DELETE_CONSTRUCTION_KIND"] = "DELETE_CONSTRUCTION_KIND";
})(ConstructionKindMutationTypes || (ConstructionKindMutationTypes = {}));
