export var CustomerMutationTypes;
(function (CustomerMutationTypes) {
    CustomerMutationTypes["INIT_STATE"] = "INIT_STATE";
    CustomerMutationTypes["SET_SORT_TYPE"] = "SET_SORT_TYPE";
    CustomerMutationTypes["SET_SORTED_CUSTOMERS"] = "SET_SORTED_CUSTOMERS";
    CustomerMutationTypes["INIT_CUSTOMERS"] = "INIT_CUSTOMERS";
    CustomerMutationTypes["FETCH_CUSTOMERS"] = "FETCH_CUSTOMERS";
    CustomerMutationTypes["FETCH_CUSTOMER"] = "FETCH_CUSTOMER";
    CustomerMutationTypes["DELETE_CUSTOMER"] = "DELETE_CUSTOMER";
})(CustomerMutationTypes || (CustomerMutationTypes = {}));
