var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Vue, Component, Prop } from 'vue-property-decorator';
var MaterialIcons = /** @class */ (function (_super) {
    __extends(MaterialIcons, _super);
    function MaterialIcons() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(MaterialIcons.prototype, "iconTypeClass", {
        // computed
        get: function () {
            var iconType = this.iconType;
            switch (iconType) {
                case 'outline':
                    return 'material-icons-outlined';
                case 'round':
                    return 'material-icons-round';
                case 'sharp':
                    return 'material-icons-sharp';
                case 'fill':
                    return 'material-icons';
                default:
                    return 'material-icons';
            }
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(MaterialIcons.prototype, "listeners", {
        get: function () {
            return this.$listeners;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(MaterialIcons.prototype, "classNames", {
        get: function () {
            var _a = this, iconTypeClass = _a.iconTypeClass, type = _a.type, size = _a.size, border = _a.border;
            return [iconTypeClass, "icon-" + type, size, { border: border }];
        },
        enumerable: false,
        configurable: true
    });
    __decorate([
        Prop({ type: String, default: 'fill' })
    ], MaterialIcons.prototype, "iconType", void 0);
    __decorate([
        Prop({ type: String, default: 'inline' })
    ], MaterialIcons.prototype, "type", void 0);
    __decorate([
        Prop({ type: String, default: 'normal' })
    ], MaterialIcons.prototype, "size", void 0);
    __decorate([
        Prop({ type: Boolean, default: false })
    ], MaterialIcons.prototype, "border", void 0);
    MaterialIcons = __decorate([
        Component
    ], MaterialIcons);
    return MaterialIcons;
}(Vue));
export default MaterialIcons;
