/**
 * [GA4] 推奨イベント
 * https://support.google.com/analytics/answer/9267735?hl=ja
 *
 * [Usage]
 * click{Namespace}Xxxxx: Click Event
 * action{Namespace}Xxxxx: Action Event - ex) 案件の登録が完了した / 新規会員登録が完了した
 * */
import Vue from 'vue';
var gaEvent = {
    // Sidebar --
    clickSidebarProjects: 'click-sidebar-projects',
    clickSidebarProjectsNew: 'click-sidebar-projects-new',
    clickSidebarFiles: 'click-sidebar-files',
    clickSidebarReports: 'click-sidebar-reports',
    clickSidebarCustomers: 'click-sidebar-customers',
    clickSidebarMembers: 'click-sidebar-members',
    clickSidebarCompanyProfile: 'click-sidebar-company-profile',
    clickSidebarConstructionKinds: 'click-sidebar-construction-kinds',
    actionSidebarOpen: 'action-sidebar-open',
    actionSidebarClose: 'action-sidebar-close',
    // Header --
    clickHeaderNotification: 'click-header-notification',
    clickHeaderUserProfile: 'click-header-user-profile',
    clickHeaderManageService: 'click-header-manage-service',
    clickHeaderHelpCenter: 'click-header-help-center',
    clickHeaderLogout: 'click-header-logout',
    actionChangeWorkspace: 'action-change-workspace',
    actionChangeServiceCareecon: 'action-change-service-careecon',
    actionChangeServiceCareeconSites: 'action-change-service-careecon-sites',
    // Notification --
    clickNotificationItemUser: 'click-notification-item-user',
    clickNotificationItemProvider: 'click-notification-item-provider',
    actionNotificationReadAllUser: 'action-notification-read-all-user',
    actionNotificationReadAllProvider: 'action-notification-read-all-provider',
    // Session --
    clickSignIn: 'click-sign-in',
    clickSignUp: 'click-sign-up',
    login: 'login',
    logout: 'logout',
    signup: 'signup',
    // Customers
    actionCustomerSearch: 'action-customer-search',
    actionCustomerCreate: 'action-customer-create',
    actionCustomerUpdate: 'action-customer-update',
    actionCustomerDelete: 'action-customer-delete',
    clickCustomerNew: 'click-customer-new',
    clickCustomerDetail: 'click-customer-detail',
    clickCustomerEdit: 'click-customer-edit',
    clickCustomerFiles: 'click-customer-files',
    clickCustomerDelete: 'click-customer-delete',
    // Project
    actionProjectSearch: 'action-project-search',
    clickProjectDetail: 'click-project-detail',
    clickProjectFiles: 'click-project-files',
    clickProjectDelete: 'click-project-delete',
    clickProjectNew: 'click-project-new',
    clickProjectFilesTab: 'click-project-files-tab',
    clickProjectDetailTab: 'click-project-detail-tab',
    clickProjectScheduleTab: 'click-project-schedule-tab',
    clickProjectReportsTab: 'click-project-reports-tab',
    clickProjectBoardPostsTab: 'click-project-board-posts-tab',
    clickProjectMemberTab: 'click-project-member-tab',
    clickProjectMapParking: 'click-project-map-parking',
    clickProjectMapConvinience: 'click-project-map-convinience',
    clickProjectMapHomecenter: 'click-project-map-homecenter',
    actionProjectCreate: 'action-project-create',
    actionProjectUpdateBasic: 'action-project-update-basic',
    actionProjectUpdateOverview: 'action-project-update-overview',
    actionProjectUpdateConstruction: 'action-project-update-construction',
    actionProjectUpdateNote: 'action-project-update-note',
    actionProjectDelete: 'action-project-delete',
    actionProjectAssignMember: 'action-project-assign-member',
    actionProjectInviteMember: 'action-project-invite-member',
    actionProjectRemoveMember: 'action-project-remove-member',
    // Schedule
    actionScheduleCreate: 'action-schedule-create',
    clickScheduleCopy: 'click-schedule-copy',
    actionScheduleCopy: 'action-schedule-copy',
    clickScheduleAddPlan: 'click-schedule-add-plan',
    actionScheduleCreatePlan: 'action-schedule-create-plan',
    actionScheduleUpdatePlan: 'action-schedule-update-plan',
    clickSchedulePlan: 'click-schedule-plan',
    actionScheduleDeletePlan: 'action-schedule-delete-plan',
    actionScheduleCreateLargeProcess: 'action-schedule-create-large-process',
    actionScheduleDeleteLargeProcess: 'action-schedule-delete-large-process',
    actionScheduleCreateSmallProcess: 'action-schedule-create-small-process',
    actionScheduleDeleteSmallProcess: 'action-schedule-delete-small-process',
    clickScheduleToggleLargeProcess: 'click-schedule-toggle-large-process',
    clickScheduleToggleSideMenu: 'click-schedule-toggle-side-menu',
    clickScheduleFllscreen: 'click-schedule-fullscreen',
    clickScheduleMoveToday: 'click-schedule-move-today',
    actionSchedulePublish: 'action-schedule-publish',
    clickScheduleViewPublishedSchedule: 'click-schedule-view-published-shedule',
    clickSchedulePrintPublishedSchedule: 'click-schedule-print-published-schedule',
    clickScheduleDownload: 'click-schedule-download',
    clickSchedulePrint: 'click-schedule-print',
    clickScheduleSettingWorkPeriod: 'click-scheule-setting-work-period',
    clickScheduleSettingHoliday: 'click-schedule-setting-holiday',
    clickScheduleDelete: 'click-schedule-delete',
    actionScheduleDelete: 'action-schedule-delete',
    // Board posts
    actionBoardPostsSearch: 'action-board-posts-search',
    clickBoardPostsFilterAll: 'click-board-posts-filter-all',
    clickBoardPostsFilterNotice: 'click-board-posts-filter-notice',
    clickBoardPostsFilterDiary: 'click-board-posts-filter-diary',
    clickBoardPostsFilterKy: 'click-board-posts-filter-ky',
    clickBoardPostsNew: 'click-board-posts-new',
    clickBoardPostsNotice: 'click-board-posts-notice',
    clickBoardPostsDiary: 'click-board-posts-diary',
    clickBoardPostsKy: 'click-board-posts-ky',
    actionBoardPostsCreateNotice: 'action-board-posts-create-notice',
    actionBoardPostsCreateDiary: 'action-board-posts-create-diary',
    actionBoardPostsCreateKy: 'action-board-posts-create-ky',
    clickBoardPostsEdit: 'click-board-posts-edit',
    clickBoardPostsDelete: 'click-board-posts-delete',
    actionBoardPostsUpdate: 'action-board-posts-update',
    actionBoardPostsDelete: 'action-board-posts-delete',
    clickBoardPostsConfirm: 'click-board-posts-confirm',
    clickBoardPostsReadUsers: 'click-board-posts-read-users',
    clickBoardPostsNoticeUnreadUser: 'click-board-posts-notice-unread-user',
    clickBoardPostsComment: 'click-board-posts-comment',
    clickBoardPostsAttachedFile: 'click-board-posts-attached-file',
    actionBoardPostsDownloadAttachedFile: 'click-board-posts-download-attached-file',
    actionBoardPostsComment: 'action-board-posts-comment',
    clickBoardPostsCommentReply: 'click-board-posts-comment-reply',
    clickBoardPostsEditComment: 'click-board-posts-edit-comment',
    actionBoardPostsDeleteComment: 'action-board-posts-delete-comment',
    // Reports
    actionReportsSearch: 'action-reports-search',
    clickReportNew: 'click-report-new',
    actionReportCreate: 'action-report-create',
    clickReportInformation: 'click-report-information',
    clickReportEdit: 'click-report-edit',
    clickReportDelete: 'click-report-delete',
    clickReportDownload: 'click-report-download',
    actionReportdelete: 'action-report-delete',
    clickReportShow: 'click-report-show',
    clickReportPage: 'click-report-page',
    clickReportImage: 'click-report-image',
    actionReportUndo: 'action-report-undo',
    actionReportRedo: 'action-report-redo',
    actionReportCopyPage: 'action-report-copy-page',
    actionReportDeletePage: 'action-report-delete-page',
    actionReportAddPage: 'action-report-add-page',
    // Setting
    actionUpdateProfile: 'action-update-profile',
    clickConstructionKindNew: 'click-construction-kind-new',
    actionConstructionKindCreate: 'action-construction-kind-create',
    clickConstructionKindEdit: 'click-construction-kind-edit',
    clickConstructionKindDelete: 'click-construction-kind-delete',
    actionConstructionKindUpdate: 'action-construction-kind-update',
    actionConstructionKindDelete: 'action-construction-kind-delete',
    // File(管理画面)
    actionFilesSearch: 'action-files-search',
    clickQuickAccessTabLately: 'click-quick-access-tab-lately',
    clickQuickAccessTabFrequently: 'click-quick-access-tab-frequently',
    clickFilesListDropdownMenu: 'click-files-list-dropdown-menu',
    clickFilesHeaderDropdownMenu: 'click-files-header-dropdown-menu',
    clickFilesListSwitchDisplay: 'click-files-list-switch-display',
    clickFileUpload: 'click-file-upload',
    clickFolderUpload: 'click-folder-upload',
    clickCreateFolder: 'click-create-folder',
    actionFileUpload: 'action-file-upload',
    clickFilePreviewToolbarZoomout: 'click-file-preview-toolbar-zoomout',
    clickFilePreviewToolbarToggleSize: 'click-file-preview-toolbar-toggle-size',
    clickFilePreviewToolbarZoomin: 'click-file-preview-toolbar-zoomin',
    clickFilePreviewToolbarFullscreen: 'click-file-preview-toolbar-fullscreen',
    clickFilePreviewToolbarPrint: 'click-file-preview-toolbar-print',
    clickFilePreviewToolbarPin: 'click-file-preview-toolbar-pin',
    clickFilePreviewToolbarPagedown: 'click-file-preview-toolbar-pagedown',
    clickFilePreviewToolbarPageup: 'click-file-preview-toolbar-pageup',
    clickFilePreviewDownloadButton: 'click-file-preview-download-button',
    clickFilePreviewEditButton: 'click-file-preview-edit-button',
    clickFilePreviewInfo: 'click-file-preview-info',
    clickFilePreviewComment: 'click-file-preview-comment',
    clickFileCommentPinButton: 'click-file-comment-pin-button',
    clickFileCommentMentionButton: 'click-file-comment-mention-button',
    clickFileCommentCancelButton: 'click-file-comment-cancel-button',
    clickFileCommentSubmitButton: 'click-file-comment-submit-button',
    clickFileCommentEditButton: 'click-file-comment-edit-button',
    clickFileCommentReplyButton: 'click-file-comment-reply-button',
    clickFileCommentDeleteButton: 'click-file-comment-delete-button',
    clickFilePreviewCommentPin: 'click-file-preview-comment-pin',
    clickLinkHelp: 'click-link-help',
    clickLinkPrivacyPolicy: 'click-link-privacy-policy',
    clickLinkTerms: 'click-link-terms',
    clickLinkReportProblem: 'click-link-report-problem',
    clickSharingButton: 'click-sharing-button',
    clickSharingUrlCopy: 'click-sharing-url-copy',
    clickSharingUrlEdit: 'click-sharing-url-edit',
    clickSharingUrlCreate: 'click-sharing-url-create',
    clickSharingUrlDelete: 'click-sharing-url-delete',
    // File（公開画面）
    clickPublicFileLogin: 'click-public-file-login',
    clickPublicFileSwitchDisplay: 'click-public-file-switch-display',
    clickPublicFileDownload: 'click-public-file-download',
    // Bookmark
    clickBookmark: 'click-bookmark',
    clickBookmarkButton: 'click-bookmark-button',
    actionCreateBookmark: 'action-create-bookmark',
    actionDeleteBookmark: 'action-delete-bookmark',
    actionSortBookmark: 'action-sort-bookmark'
};
export default Vue.prototype.$gaEvent = gaEvent;
