var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _a;
import { MemberMutationTypes } from '@/types/member';
import { isGuest } from '@/policies';
import userApiClient from '@/plugins/apis/user-api-client';
import invitedUserApiClient from '@/plugins/apis/invited-user-api-alient';
export var state = function () { return ({
    members: [],
    invitedUsers: []
}); };
export var getters = {
    members: function (state) {
        return state.members;
    },
    invitedUsers: function (state) {
        return state.invitedUsers;
    },
    guestUsers: function (state) {
        return state.members.filter(function (user) { return isGuest(user.user_type); });
    },
    guestInvitedUsers: function (state) {
        return state.invitedUsers.filter(function (user) { return isGuest(user.user_type); });
    }
};
export var mutations = (_a = {},
    _a[MemberMutationTypes.INIT_STATE] = function (state, payload) {
        Object.assign(state, payload);
    },
    _a[MemberMutationTypes.FETCH_MEMBERS] = function (state, payload) {
        state.members = payload;
    },
    _a[MemberMutationTypes.FETCH_INVITED_USERS] = function (state, payload) {
        state.invitedUsers = payload;
    },
    _a[MemberMutationTypes.UPDATE_MEMBER] = function (state, payload) {
        var index = state.members.findIndex(function (m) { return m.id === payload.id; });
        if (index !== -1) {
            state.members.splice(index, 1, payload);
        }
    },
    _a[MemberMutationTypes.REMOVE_MEMBER] = function (state, payload) {
        var index = state.members.findIndex(function (m) { return m.id === payload; });
        if (index !== -1) {
            state.members.splice(index, 1);
        }
    },
    _a[MemberMutationTypes.CANCEL_INVITATION] = function (state, payload) {
        var index = state.invitedUsers.findIndex(function (m) {
            return m.email === payload.email && m.user_type === payload.user_type;
        });
        if (index !== -1) {
            state.invitedUsers.splice(index, 1);
        }
    },
    _a[MemberMutationTypes.UPDATE_INVITED_USER] = function (state, payload) {
        var index = state.invitedUsers.findIndex(function (m) { return m.id === payload.id; });
        if (index !== -1) {
            var user = state.invitedUsers[index];
            state.invitedUsers.splice(index, 1, __assign(__assign({}, user), { user_type: payload.userType }));
        }
    },
    _a);
export var actions = {
    initState: function (_a) {
        var commit = _a.commit;
        commit(MemberMutationTypes.INIT_STATE, state());
    },
    fetchMembers: function (_a) {
        var commit = _a.commit;
        return __awaiter(this, void 0, void 0, function () {
            var res;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, userApiClient.fetchUsers()];
                    case 1:
                        res = _b.sent();
                        commit(MemberMutationTypes.FETCH_MEMBERS, res.data.users);
                        return [2 /*return*/];
                }
            });
        });
    },
    fetchInvitedUsers: function (_a) {
        var commit = _a.commit;
        return __awaiter(this, void 0, void 0, function () {
            var res;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, invitedUserApiClient.fetchInvitedUsers()];
                    case 1:
                        res = _b.sent();
                        commit(MemberMutationTypes.FETCH_INVITED_USERS, res.data.invited_users);
                        return [2 /*return*/];
                }
            });
        });
    },
    searchMembers: function (_, payload) {
        var params = {
            name: payload
        };
        return userApiClient.searchMembers(params);
    },
    updateMember: function (_a, payload) {
        var commit = _a.commit;
        return __awaiter(this, void 0, void 0, function () {
            var res;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, userApiClient.updateCompaniesUser(payload.id, payload.params)];
                    case 1:
                        res = _b.sent();
                        commit(MemberMutationTypes.UPDATE_MEMBER, res.data.user);
                        return [2 /*return*/];
                }
            });
        });
    },
    updateInvitedUser: function (_a, payload) {
        var commit = _a.commit;
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, invitedUserApiClient.update({
                            id: payload.id,
                            user_type: payload.userType
                        })];
                    case 1:
                        _b.sent();
                        commit(MemberMutationTypes.UPDATE_INVITED_USER, payload);
                        return [2 /*return*/];
                }
            });
        });
    },
    cancelInvitation: function (_a, payload) {
        var commit = _a.commit;
        return __awaiter(this, void 0, void 0, function () {
            var params;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        params = {
                            email_or_phone_number: payload.email || payload.phone_number,
                            user_type: payload.user_type
                        };
                        return [4 /*yield*/, invitedUserApiClient.cancelInvitation(params)];
                    case 1:
                        _b.sent();
                        commit(MemberMutationTypes.CANCEL_INVITATION, payload);
                        return [2 /*return*/];
                }
            });
        });
    },
    removeMember: function (_a, payload) {
        var commit = _a.commit;
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, userApiClient.removeUser(payload)];
                    case 1:
                        _b.sent();
                        commit(MemberMutationTypes.REMOVE_MEMBER, payload);
                        return [2 /*return*/];
                }
            });
        });
    }
};
