export var SharedRangeType;
(function (SharedRangeType) {
    SharedRangeType["allUsers"] = "all_users";
    SharedRangeType["overMembers"] = "over_members";
    SharedRangeType["limitedUsers"] = "limited_users";
})(SharedRangeType || (SharedRangeType = {}));
export var ProcessStatus;
(function (ProcessStatus) {
    ProcessStatus["uploaded"] = "uploaded";
    ProcessStatus["recordCreated"] = "record_created";
    ProcessStatus["done"] = "done";
})(ProcessStatus || (ProcessStatus = {}));
export var SideType;
(function (SideType) {
    SideType[SideType["info"] = 0] = "info";
    SideType[SideType["comment"] = 1] = "comment";
})(SideType || (SideType = {}));
export var RootModelsList;
(function (RootModelsList) {
    RootModelsList["Customer"] = "Customer";
    RootModelsList["Project"] = "Project";
    RootModelsList["Report"] = "Report";
    RootModelsList["LargeProcess"] = "LargeProcess";
})(RootModelsList || (RootModelsList = {}));
export var RootModels = Object.values(RootModelsList);
export var CanvaseBaseWidth = 976;
export var ContentMutationTypes;
(function (ContentMutationTypes) {
    ContentMutationTypes["INIT_STATE"] = "INIT_STATE";
    ContentMutationTypes["SET_SORT_TYPE"] = "SET_SORT_TYPE";
    ContentMutationTypes["SET_SORTED_CONTENTS"] = "SET_SORTED_CONTENTS";
    ContentMutationTypes["CHANGE_LIST_TYPE"] = "CHANGE_LIST_TYPE";
    ContentMutationTypes["REPLACE_CONTENT"] = "REPLACE_CONTENT";
    ContentMutationTypes["RESET_CONTENTS"] = "RESET_CONTENTS";
    ContentMutationTypes["INIT_CONTENTS"] = "INIT_CONTENTS";
    ContentMutationTypes["FETCH_CONTENTS_META"] = "FETCH_CONTENTS_META";
    ContentMutationTypes["FETCH_CONTENTS"] = "FETCH_CONTENTS";
    ContentMutationTypes["FETCH_CONTENTS_BY_IDS"] = "FETCH_CONTENTS_BY_IDS";
    ContentMutationTypes["FETCH_CONTENT"] = "FETCH_CONTENT";
    ContentMutationTypes["FETCH_CURRENT_DIRECTORY"] = "FETCH_CURRENT_DIRECTORY";
    ContentMutationTypes["FETCH_FREQUENTLY_CONTENTS"] = "FETCH_FREQUENTLY_CONTENTS";
    ContentMutationTypes["FETCH_LATELY_CONTENTS"] = "FETCH_LATELY_CONTENTS";
    ContentMutationTypes["DELETE_CONTENT"] = "DELETE_CONTENT";
    ContentMutationTypes["MOVE_CONTENTS"] = "MOVE_CONTENTS";
    ContentMutationTypes["RETRY_FETCH_CONTENTS"] = "RETRY_FETCH_CONTENTS";
    ContentMutationTypes["ADD_FILE_LIST"] = "ADD_FILE_LIST";
    ContentMutationTypes["UPDATE_PROGRESS"] = "UPDATE_PROGRESS";
    ContentMutationTypes["REMOVE_FILE_LIST"] = "REMOVE_FILE_LIST";
    ContentMutationTypes["CANCEL_FILES"] = "CANCEL_FILES";
    ContentMutationTypes["CLEAR_QUEUES"] = "CLEAR_QUEUES";
    ContentMutationTypes["DELETE_QUEUE"] = "DELETE_QUEUE";
    ContentMutationTypes["ADD_QUEUE"] = "ADD_QUEUE";
    ContentMutationTypes["UNINITIALIZED"] = "UNINITIALIZED";
    ContentMutationTypes["CHANGE_SIDE_TYPE"] = "CHANGE_SIDE_TYPE";
    ContentMutationTypes["ADD_COMMENT"] = "ADD_COMMENT";
    ContentMutationTypes["DELETE_COMMENT"] = "DELETE_COMMENT";
    ContentMutationTypes["FETCH_COMMENTS"] = "FETCH_COMMENTS";
    ContentMutationTypes["UPDATE_COMMENT"] = "UPDATE_COMMENT";
    ContentMutationTypes["FETCH_MENTIONABLE_USERS"] = "FETCH_MENTIONABLE_USERS";
    ContentMutationTypes["UPDATE_PDF_PAGE_COUNT"] = "UPDATE_PDF_PAGE_COUNT";
})(ContentMutationTypes || (ContentMutationTypes = {}));
