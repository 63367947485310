var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Vue, Component, Prop } from 'vue-property-decorator';
var NotificationIconUnread = /** @class */ (function (_super) {
    __extends(NotificationIconUnread, _super);
    function NotificationIconUnread() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(NotificationIconUnread.prototype, "iconStyleObject", {
        // computed
        get: function () {
            var style = {
                left: this.leftPosition + 'rem',
                right: this.rightPosition + 'rem',
                top: this.topPosition + 'rem',
                width: this.sizeValue + 'rem',
                height: this.sizeValue + 'rem'
            };
            return style;
        },
        enumerable: false,
        configurable: true
    });
    __decorate([
        Prop({ type: Number, required: false })
    ], NotificationIconUnread.prototype, "sizeValue", void 0);
    __decorate([
        Prop({ type: Number, required: false })
    ], NotificationIconUnread.prototype, "leftPosition", void 0);
    __decorate([
        Prop({ type: Number, required: false })
    ], NotificationIconUnread.prototype, "rightPosition", void 0);
    __decorate([
        Prop({ type: Number, required: false })
    ], NotificationIconUnread.prototype, "topPosition", void 0);
    __decorate([
        Prop({ type: Boolean, required: false })
    ], NotificationIconUnread.prototype, "isVerticalCenter", void 0);
    NotificationIconUnread = __decorate([
        Component
    ], NotificationIconUnread);
    return NotificationIconUnread;
}(Vue));
export default NotificationIconUnread;
