var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Mixins, Component } from 'vue-property-decorator';
import * as dayjs from 'dayjs';
import HolidayBaseMixin from '@/mixins/holiday/base-mixin';
import dateFormat from '@/plugins/filters/date-format';
var SundayNumber = 0;
var SaturdayNumber = 6;
var CommonDateMixin = /** @class */ (function (_super) {
    __extends(CommonDateMixin, _super);
    function CommonDateMixin() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        // data
        _this.today = {
            weekDay: '',
            month: '',
            day: ''
        };
        return _this;
    }
    // method
    CommonDateMixin.prototype.isDayOff = function (targetDay, dayOffSettings) {
        var isSaturdayOff = targetDay.day() === SaturdayNumber && dayOffSettings.saturdayOff;
        var isSundayOff = targetDay.day() === SundayNumber && dayOffSettings.sundayOff;
        var isHolidayOff = this.isHoliday(targetDay) && dayOffSettings.holidayOff;
        return isSaturdayOff || isSundayOff || isHolidayOff;
    };
    CommonDateMixin.prototype.isHoliday = function (targetDay) {
        var idDateFormat = targetDay.format('YYYY-MM-DD');
        return Object.keys(this.holidays).includes(idDateFormat);
    };
    CommonDateMixin.prototype.isToday = function (targetDay) {
        var now = dayjs();
        return targetDay.format('YYYY-MM-DD') === now.format('YYYY-MM-DD');
    };
    CommonDateMixin.prototype.setIdDateFormat = function (date) {
        var year = date.getFullYear();
        var month = ('00' + (date.getMonth() + 1)).slice(-2);
        var day = ('00' + date.getDate()).slice(-2);
        return year + '-' + month + '-' + day;
    };
    CommonDateMixin.prototype.getToday = function () {
        var weeks = ['日', '月', '火', '水', '木', '金', '土'];
        var date = new Date();
        this.today.weekDay = String(weeks[date.getDay()]);
        this.today.month = String(date.getMonth() + 1);
        this.today.day = String(date.getDate());
        return this.today;
    };
    CommonDateMixin.prototype.calculateDays = function (start, end) {
        var startDate = dayjs(start).startOf('day');
        var endDate = dayjs(end).startOf('day');
        return Math.abs(endDate.diff(startDate, 'day'));
    };
    CommonDateMixin.prototype.changeOriginalDateFormat = function (date) {
        return dayjs(date).format('YYYY-MM-DD');
    };
    CommonDateMixin.prototype.changeMonthOriginalDateFormat = function (dateString) {
        var dateStringArray = dateString.match(/(\d{4})-(\d{2})-(\d{2})/);
        return dateStringArray[1] + '年' + Number(dateStringArray[2]) + '月';
    };
    CommonDateMixin.prototype.getDiffDays = function (v1, v2) {
        var date1 = dayjs(v1);
        var date2 = dayjs(v2);
        return Math.abs(date1.diff(date2, 'day'));
    };
    CommonDateMixin.prototype.dateFormat = function (value, format, isRelative) {
        if (format === void 0) { format = 'default'; }
        if (isRelative === void 0) { isRelative = false; }
        return dateFormat(value, format, isRelative);
    };
    CommonDateMixin = __decorate([
        Component
    ], CommonDateMixin);
    return CommonDateMixin;
}(Mixins(HolidayBaseMixin)));
export default CommonDateMixin;
