var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Component, Emit, Mixins } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import CompanyAvatar from '@/components/company-avatar.vue';
import MaterialIcons from '@/components/material-icons.vue';
import SelectWorkSpace from '@/components/select-work-space.vue';
import CanCustomer from '@/mixins/can/customer';
import CanMember from '@/mixins/can/member';
import CanProject from '@/mixins/can/project';
import CanReport from '@/mixins/can/report';
import CanSharingContent from '@/mixins/can/sharing-content';
import BaseMixin from '@/mixins/project/base-mixin';
import { Routes } from '@/types/routes';
import BookmarkList from '@/components/bookmark-list.vue';
import ScrollWrapper from '@/components/common/atoms/scroll-wrapper.vue';
var InitialSettingModule = namespace('initial-setting');
var ProjectModule = namespace('project');
var TheSideMenu = /** @class */ (function (_super) {
    __extends(TheSideMenu, _super);
    function TheSideMenu() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.routeProjectListPage = Routes.ProjectListPage.name;
        _this.routeProjectNewPage = Routes.ProjectNewPage.name;
        _this.routeCompanyFilesListPage = Routes.CompanyFilesListPage.name;
        _this.routeReportListPage = Routes.ReportListPage.name;
        _this.routeCustomerListPage = Routes.CustomerListPage.name;
        _this.routeMemberListPage = Routes.MemberListPage.name;
        _this.routeSettingsCompanyProfilePage = Routes.SettingsCompanyProfilePage.name;
        _this.routeSettingsCompanySettingPage = Routes.SettingsCompanySettingPage.name;
        _this.routeSettingsConstructionKindListPage = Routes.SettingsConstructionKindListPage.name;
        // data
        _this.settingsVisible = false;
        return _this;
    }
    Object.defineProperty(TheSideMenu.prototype, "companyId", {
        get: function () {
            var params = this.$route.params;
            return params.company_id;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TheSideMenu.prototype, "resetPadding", {
        get: function () {
            return {
                paddingLeft: 0
            };
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TheSideMenu.prototype, "storageUsingRatio", {
        get: function () {
            var _a = this, maximumStorageGigabytes = _a.maximumStorageGigabytes, totalFileSize = _a.totalFileSize;
            if (totalFileSize >= maximumStorageGigabytes) {
                return 100;
            }
            return (totalFileSize / maximumStorageGigabytes) * 100;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TheSideMenu.prototype, "companyLogoURL", {
        get: function () {
            var company = this.company;
            if (company.logo) {
                return company.logo;
            }
            return require('@/assets/images/company.png');
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TheSideMenu.prototype, "isEnableMainMenu", {
        get: function () {
            var isAvailable = this.isAvailable;
            return isAvailable;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TheSideMenu.prototype, "isEnableSubMenu", {
        // NOTE: v-ifだとElPopoverが正常に動作しない関係でv-showとしている
        get: function () {
            var isAvailable = this.isAvailable;
            return isAvailable;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TheSideMenu.prototype, "hasCurrentDisplayProject", {
        get: function () {
            var _a;
            var _b = this, $route = _b.$route, bookmarks = _b.bookmarks;
            if (!bookmarks.length)
                return false;
            if (!((_a = $route.name) === null || _a === void 0 ? void 0 : _a.startsWith(Routes.ProjectPage.name)))
                return false;
            return bookmarks.some(function (_a) {
                var project = _a.project;
                return project.id === $route.params.id;
            });
        },
        enumerable: false,
        configurable: true
    });
    // lyfe_cycle_hooks
    TheSideMenu.prototype.mounted = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!!this.user.id) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.fetchMe(this.companyId)];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2:
                        this.fetchBookmarks();
                        return [2 /*return*/];
                }
            });
        });
    };
    // methods
    TheSideMenu.prototype.clickMenuEmitter = function () { };
    TheSideMenu.prototype.clickSettingMenuHandler = function () {
        this.settingsVisible = false;
    };
    __decorate([
        InitialSettingModule.Getter('maximumStorageGigabytes')
    ], TheSideMenu.prototype, "maximumStorageGigabytes", void 0);
    __decorate([
        InitialSettingModule.Getter('totalFileSize')
    ], TheSideMenu.prototype, "totalFileSize", void 0);
    __decorate([
        InitialSettingModule.Getter('displayStorage')
    ], TheSideMenu.prototype, "displayStorage", void 0);
    __decorate([
        InitialSettingModule.Getter('user')
    ], TheSideMenu.prototype, "user", void 0);
    __decorate([
        InitialSettingModule.Getter('companies')
    ], TheSideMenu.prototype, "companies", void 0);
    __decorate([
        InitialSettingModule.Getter('company')
    ], TheSideMenu.prototype, "company", void 0);
    __decorate([
        InitialSettingModule.Getter('isBusinessPlan')
    ], TheSideMenu.prototype, "isBusinessPlan", void 0);
    __decorate([
        InitialSettingModule.Getter('isAvailable')
    ], TheSideMenu.prototype, "isAvailable", void 0);
    __decorate([
        InitialSettingModule.Getter('isAdmin')
    ], TheSideMenu.prototype, "isAdmin", void 0);
    __decorate([
        InitialSettingModule.Action('fetchMe')
    ], TheSideMenu.prototype, "fetchMe", void 0);
    __decorate([
        ProjectModule.Getter('projects')
    ], TheSideMenu.prototype, "projects", void 0);
    __decorate([
        ProjectModule.Getter('bookmarks')
    ], TheSideMenu.prototype, "bookmarks", void 0);
    __decorate([
        ProjectModule.Action('fetchBookmarks')
    ], TheSideMenu.prototype, "fetchBookmarks", void 0);
    __decorate([
        Emit('clickMenu')
    ], TheSideMenu.prototype, "clickMenuEmitter", null);
    TheSideMenu = __decorate([
        Component({
            components: {
                ScrollWrapper: ScrollWrapper,
                MaterialIcons: MaterialIcons,
                SelectWorkSpace: SelectWorkSpace,
                CompanyAvatar: CompanyAvatar,
                BookmarkList: BookmarkList
            }
        })
    ], TheSideMenu);
    return TheSideMenu;
}(Mixins(CanProject, CanCustomer, CanMember, CanReport, Mixins(BaseMixin, CanSharingContent))));
export default TheSideMenu;
