// NOTE:
// enumで実装しようとしたが、動的に(ResourceType[resourceName]のように)
// 呼び出そうとするとElement implicitly has an 'any' type because index expression is not of type 'number'
// と出るのでconstで定数化したobjectで代用
var ResourceType = {
    project: '案件',
    report: '報告書',
    content: 'ファイル',
    content_comment: 'コメント',
    customer: '取引先',
    board_post: '掲示板',
    board_post_comment: '掲示板コメント',
    schedule: '工程表',
    schedule_print_settings: '印刷設定',
    large_process: '大工程',
    small_process: '小工程',
    schedule_plan: '予定',
    sharing_content: '共有リンク',
    public_content: '共有ファイル'
};
export { ResourceType };
