var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
/* eslint camelcase: 0 */
import axios from 'axios';
import Firebase from 'firebase/app';
import { FirestoreCollectionNames } from '@/types';
import formDataFromFile from '@/plugins/form-data-from-file';
import BaseClient from '@/plugins/apis/base-api-client';
import getFileInfo from '@/plugins/get-file-info';
import compressor from '@/plugins/compressor';
var qs = require('qs');
var ContentApiClient = /** @class */ (function (_super) {
    __extends(ContentApiClient, _super);
    function ContentApiClient() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(ContentApiClient.prototype, "basePath", {
        get: function () {
            var companyId = this.companyId;
            return "/companies/" + companyId + "/contents";
        },
        enumerable: false,
        configurable: true
    });
    ContentApiClient.prototype.fetchContents = function (parentId, params, projectId) {
        var _a = this, basePath = _a.basePath, client = _a.client;
        var url = basePath + "/" + (parentId || 0);
        var config = {
            params: __assign({ project_id: projectId, limit: 50 }, params)
        };
        return client.get(url, config);
    };
    ContentApiClient.prototype.searchContents = function (params) {
        var _a = this, basePath = _a.basePath, client = _a.client;
        var config = {
            params: __assign({}, params)
        };
        return params.is_root ? client.get(basePath + "/" + 0, config) : client.get(basePath, config);
    };
    ContentApiClient.prototype.fetchContent = function (id, sort) {
        if (sort === void 0) { sort = {}; }
        var _a = this, basePath = _a.basePath, client = _a.client;
        var url = basePath + "/" + id + "/preview";
        var config = {
            params: {
                sort: sort
            }
        };
        return client.get(url, config);
    };
    ContentApiClient.prototype.fetchLatelyContents = function (projectId) {
        var _a = this, basePath = _a.basePath, client = _a.client;
        var url = basePath + "/lately";
        if (!projectId)
            return client.get(url);
        var config = {
            params: {
                project_id: projectId
            }
        };
        return client.get(url, config);
    };
    ContentApiClient.prototype.fetchFrequentlyContents = function (projectId) {
        var _a = this, basePath = _a.basePath, client = _a.client;
        var url = basePath + "/frequently";
        if (!projectId)
            return client.get(url);
        var config = {
            params: {
                project_id: projectId
            }
        };
        return client.get(url, config);
    };
    // API POST ( create )
    ContentApiClient.prototype.uploadContent = function (parentId, file, config, params) {
        if (config === void 0) { config = {}; }
        return __awaiter(this, void 0, Promise, function () {
            var _a, basePath, client, formData;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this, basePath = _a.basePath, client = _a.client;
                        return [4 /*yield*/, formDataFromFile(file, parentId || '0', null, params)];
                    case 1:
                        formData = _b.sent();
                        if (params && params.board_post && params.project_id) {
                            config = {
                                params: {
                                    project_id: params.project_id,
                                    board_post: params.board_post
                                }
                            };
                        }
                        return [2 /*return*/, client.post(basePath, formData, config)];
                }
            });
        });
    };
    // S3 PUT ( create, update )
    ContentApiClient.prototype.uploadFileS3Request = function (meta, file, config) {
        if (config === void 0) { config = {}; }
        return __awaiter(this, void 0, void 0, function () {
            var fileType, compressedFile;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, getFileInfo(file)];
                    case 1:
                        fileType = (_a.sent()).fileType;
                        return [4 /*yield*/, compressor(file)];
                    case 2:
                        compressedFile = _a.sent();
                        config.headers = { 'Content-Type': fileType.mime };
                        if (!meta.s3_direct_upload_original_file_url) return [3 /*break*/, 5];
                        return [4 /*yield*/, axios.put(meta.s3_direct_upload_url, compressedFile, config)];
                    case 3:
                        _a.sent();
                        return [4 /*yield*/, axios.put(meta.s3_direct_upload_original_file_url, compressedFile, config)];
                    case 4:
                        _a.sent();
                        return [3 /*break*/, 7];
                    case 5: return [4 /*yield*/, axios.put(meta.s3_direct_upload_url, compressedFile, config)];
                    case 6:
                        _a.sent();
                        _a.label = 7;
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    // firebase content upload
    ContentApiClient.prototype.uploadFileFirebaseRequest = function (content) {
        return __awaiter(this, void 0, void 0, function () {
            var uploadContents, collection;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        uploadContents = {
                            board_post_id: '',
                            company_id: this.companyId,
                            content_id: content.id,
                            content_type: content.info_content_type,
                            created_at: content.created_at,
                            device_uuid: content.device_uuid,
                            parent_content_id: content.parent_id,
                            process_done: false,
                            project_id: content.project_id,
                            uid: content.created_user.uid,
                            updated_at: content.updated_at,
                            upload_url: content.thumb_url || content.document_preview_url || content.cover_url
                        };
                        return [4 /*yield*/, Firebase.firestore().collection(FirestoreCollectionNames.UPLOAD_CONTENTS)];
                    case 1:
                        collection = _a.sent();
                        collection.doc(content.id).set(uploadContents);
                        return [2 /*return*/];
                }
            });
        });
    };
    ContentApiClient.prototype.createFolder = function (parentId, data) {
        var _a = this, basePath = _a.basePath, client = _a.client;
        var params = {
            content: {
                parent_id: parentId || '0',
                relative_path: data.name,
                info_content_type: 'text/directory'
            }
        };
        return client.post(basePath, params);
    };
    ContentApiClient.prototype.updateContentRequest = function (id, data) {
        var _a = this, basePath = _a.basePath, client = _a.client;
        var url = basePath + "/" + id;
        var params = {
            content: data
        };
        return client.put(url, params);
    };
    ContentApiClient.prototype.updateImageContent = function (parentId, id, file, config) {
        if (config === void 0) { config = {}; }
        return __awaiter(this, void 0, Promise, function () {
            var _a, basePath, client, formData;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this, basePath = _a.basePath, client = _a.client;
                        return [4 /*yield*/, formDataFromFile(file, parentId || '0', id || null)];
                    case 1:
                        formData = _b.sent();
                        return [2 /*return*/, client.post(basePath, formData, config)];
                }
            });
        });
    };
    ContentApiClient.prototype.deleteContent = function (id) {
        var _a = this, basePath = _a.basePath, client = _a.client;
        var url = basePath + "/" + id;
        return client.delete(url);
    };
    ContentApiClient.prototype.fetchDownloadURL = function (id, notice) {
        if (notice === void 0) { notice = true; }
        var basePath = this.basePath;
        var url = basePath + "/" + id + "/download";
        return this.requestActionWithNamedProperty({
            method: 'get',
            url: url,
            params: {},
            resourceName: 'content',
            actionName: 'DOWNLOAD',
            hasData: true,
            notice: notice
        });
    };
    ContentApiClient.prototype.moveContent = function (id, parentId) {
        var _a = this, basePath = _a.basePath, client = _a.client;
        var url = basePath + "/" + (id || 0) + "/move";
        var params = {
            destination_parent_id: parentId
        };
        return client.post(url, params);
    };
    ContentApiClient.prototype.copyContent = function (id, data) {
        var _a = this, basePath = _a.basePath, client = _a.client;
        var url = basePath + "/" + id + "/copy";
        var params = {
            destination_parent_id: data.parentId
        };
        return client.post(url, params);
    };
    ContentApiClient.prototype.updateSharedContent = function (id, sharedRangeType, _a) {
        var users = _a.users, removeUsers = _a.removeUsers;
        var _b = this, basePath = _b.basePath, client = _b.client;
        var url = basePath + "/" + id + "/update_shared_range";
        var usersAttributes = users;
        var params = {
            content: {
                shared_range_type: sharedRangeType
            }
        };
        if (params.content.shared_range_type === 'limited_users') {
            params.content.shared_users = usersAttributes.concat(removeUsers.map(function (u) { return ({
                user_id: u.user_id,
                _destroy: 1
            }); }));
        }
        return client.patch(url, params);
    };
    ContentApiClient.prototype.updatePdfPageCount = function (_a) {
        var content_id = _a.content_id, info_pdf_page_count = _a.info_pdf_page_count;
        var _b = this, basePath = _b.basePath, client = _b.client;
        return client.patch(basePath + "/" + content_id + "/update_pdf_page_count", {
            content: { info_pdf_page_count: info_pdf_page_count }
        });
    };
    ContentApiClient.prototype.downloadZip = function (contentIds) {
        var _a = this, basePath = _a.basePath, client = _a.client;
        var url = basePath + "/download_zip";
        var config = {
            params: {
                content: {
                    ids: contentIds
                }
            }
        };
        return client.get(url, config);
    };
    ContentApiClient.prototype.convertUUID = function (_a) {
        var uuid = _a.uuid, path = _a.path;
        var url = "/contents/convert_uuid/" + uuid;
        return this.client.get(url, {
            params: { path: path || '' },
            paramsSerializer: function (params) {
                return qs.stringify(params, {
                    arrayFormat: 'brackets',
                    encode: false
                });
            }
        });
    };
    ContentApiClient.prototype.exists = function (_a) {
        var id = _a.id, type = _a.type, companyId = _a.companyId;
        var url = "/companies/" + companyId + "/contents/" + id + (type.includes('Directory') ? '' : '/preview');
        return this.client.get(url);
    };
    return ContentApiClient;
}(BaseClient));
export default new ContentApiClient();
