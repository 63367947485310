var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Mixins, Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { DepartmentCode, DepartmentURL } from '@/types';
import CommonButtonDropdownMenu from '@/components/common/atoms/button-dropdown-menu.vue';
import NotificationIcon from '@/components/notification/atom/icon-notification.vue';
import UserIcon from '@/components/common/atoms/user-icon.vue';
import BaseMixin from '@/mixins/base-mixin';
var InitialSettingModule = namespace('initial-setting');
var TheHeader = /** @class */ (function (_super) {
    __extends(TheHeader, _super);
    function TheHeader() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    TheHeader.prototype.clickCareeconHandler = function () {
        this.$gtag('event', this.$gaEvent.actionChangeServiceCareecon);
        location.href = DepartmentURL.HTTPS_HEADER + DepartmentURL.CAREECON_BASE_LINK;
    };
    TheHeader.prototype.clickSitesHandler = function () {
        this.$gtag('event', this.$gaEvent.actionChangeServiceCareeconSites);
        location.href = this.departmentOrigin(DepartmentCode.CAREECON_SITES, DepartmentURL.SITES_BASE_LINK);
    };
    TheHeader.prototype.clickSaiyoHandler = function () {
        location.href = DepartmentURL.HTTPS_HEADER + DepartmentURL.SAIYO_BASE_LINK + 'sign_in';
    };
    __decorate([
        InitialSettingModule.Getter('user')
    ], TheHeader.prototype, "user", void 0);
    TheHeader = __decorate([
        Component({
            components: {
                CommonButtonDropdownMenu: CommonButtonDropdownMenu,
                NotificationIcon: NotificationIcon,
                UserIcon: UserIcon
            }
        })
    ], TheHeader);
    return TheHeader;
}(Mixins(BaseMixin)));
export default TheHeader;
