var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Routes } from '@/types/routes';
import UserAvatar from '@/components/user-avatar.vue';
var InitialSettingModule = namespace('initial-setting');
var MemberModule = namespace('member');
var HELP_URL = 'https://support.work.careecon.jp/hc/ja';
var CommonUserIcon = /** @class */ (function (_super) {
    __extends(CommonUserIcon, _super);
    function CommonUserIcon() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(CommonUserIcon.prototype, "isOwner", {
        get: function () {
            var _a = this, user = _a.user, members = _a.members;
            var owner = members.find(function (member) { return member.is_owner; });
            return user.id === (owner === null || owner === void 0 ? void 0 : owner.id);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CommonUserIcon.prototype, "casUrl", {
        get: function () {
            return process.env.casBaseUrl + "/companies/start?cid=" + this.company.cid;
        },
        enumerable: false,
        configurable: true
    });
    // methods
    CommonUserIcon.prototype.mounted = function () {
        this.fetchMembers();
    };
    CommonUserIcon.prototype.clickProfileHandler = function () {
        this.$router.push({ name: Routes.SettingsUserProfilePage.name });
        this.clickMenuEmitter();
    };
    // emit
    CommonUserIcon.prototype.clickMenuEmitter = function () { };
    CommonUserIcon.prototype.openCasUrlHandler = function () {
        var casUrl = this.casUrl;
        window.open(casUrl, '_blank');
    };
    CommonUserIcon.prototype.clickHelpHandler = function () {
        window.open(HELP_URL, '_blank');
    };
    CommonUserIcon.prototype.clickLogoutHandler = function () {
        this.$router.push({ name: Routes.SessionSignOutPage.name });
    };
    __decorate([
        Prop({ type: Object, required: true })
    ], CommonUserIcon.prototype, "user", void 0);
    __decorate([
        InitialSettingModule.Getter('company')
    ], CommonUserIcon.prototype, "company", void 0);
    __decorate([
        MemberModule.Action('fetchMembers')
    ], CommonUserIcon.prototype, "fetchMembers", void 0);
    __decorate([
        MemberModule.Getter('members')
    ], CommonUserIcon.prototype, "members", void 0);
    __decorate([
        Emit('clickMenu')
    ], CommonUserIcon.prototype, "clickMenuEmitter", null);
    CommonUserIcon = __decorate([
        Component({
            components: {
                UserAvatar: UserAvatar
            }
        })
    ], CommonUserIcon);
    return CommonUserIcon;
}(Vue));
export default CommonUserIcon;
