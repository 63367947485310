var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import BaseClient from '@/plugins/apis/base-api-client';
// 掲示板API - client管理
var BoardApiClient = /** @class */ (function (_super) {
    __extends(BoardApiClient, _super);
    function BoardApiClient() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    BoardApiClient.prototype.basePath = function (projectId) {
        var companyId = this.companyId;
        return "/companies/" + companyId + "/projects/" + projectId;
    };
    BoardApiClient.prototype.fetchBoardPosts = function (searchParams) {
        var _a = this, companyId = _a.companyId, client = _a.client;
        var url = "/companies/" + companyId + "/board_posts";
        var params = {
            params: searchParams
        };
        return client.get(url, params);
    };
    BoardApiClient.prototype.fetchBoardPost = function (projectId, boardPostId) {
        var client = this.client;
        var url = this.basePath(projectId) + "/board_posts/" + boardPostId;
        return client.get(url);
    };
    BoardApiClient.prototype.createBoardPost = function (projectId, formData, contentIds) {
        var requestAction = this.requestAction;
        var url = this.basePath(projectId) + "/board_posts";
        var params = {
            board_post: formData,
            content_ids: contentIds
        };
        return requestAction('post', url, params, 'board_post', 'POST');
    };
    BoardApiClient.prototype.updateBoardPost = function (projectId, boardPostId, formData, contentIds) {
        var requestAction = this.requestAction;
        var url = this.basePath(projectId) + "/board_posts/" + boardPostId;
        var params = {
            board_post: formData,
            content_ids: contentIds
        };
        return requestAction('put', url, params, 'board_post', 'UPDATE');
    };
    BoardApiClient.prototype.deleteBoardPost = function (projectId, boardPostId) {
        var requestAction = this.requestAction;
        var url = this.basePath(projectId) + "/board_posts/" + boardPostId;
        return requestAction('delete', url, {}, 'board_post', 'DELETE', false);
    };
    BoardApiClient.prototype.confirmBoardPost = function (projectId, boardPostId) {
        var client = this.client;
        var url = this.basePath(projectId) + "/board_posts/" + boardPostId + "/confirm";
        return client.patch(url);
    };
    BoardApiClient.prototype.fetchBoardPostMentionableUsers = function (projectId, boardPostId) {
        var client = this.client;
        return client.get(this.basePath(projectId) + "/board_posts/" + boardPostId + "/mentionable_users");
    };
    BoardApiClient.prototype.muteBoardPostComment = function (projectId, boardPostId) {
        var client = this.client;
        var url = this.basePath(projectId) + "/board_posts/" + boardPostId + "/mute";
        return client.patch(url);
    };
    BoardApiClient.prototype.fetchBoardPostUsers = function (projectId, boardPostId) {
        var client = this.client;
        var url = this.basePath(projectId) + "/board_posts/" + boardPostId + "/board_post_users";
        return client.get(url);
    };
    BoardApiClient.prototype.noticeUnreadUsers = function (projectId, boardPostId) {
        var requestAction = this.requestAction;
        var url = this.basePath(projectId) + "/board_posts/" + boardPostId + "/notice_unread_users";
        return requestAction('patch', url, {}, 'unread_users_notification', 'OTHER', false);
    };
    BoardApiClient.prototype.fetchBoardCategories = function () {
        var _a = this, companyId = _a.companyId, client = _a.client;
        var url = "/companies/" + companyId + "/board_categories";
        return client.get(url);
    };
    BoardApiClient.prototype.fetchBoardPostComments = function (projectId, boardPostId, targetId, offset, limit) {
        var client = this.client;
        var url = this.basePath(projectId) + "/board_posts/" + boardPostId + "/board_post_comments";
        var params = { limit: limit, offset: offset, target_id: targetId };
        return client.get(url, { params: params });
    };
    BoardApiClient.prototype.createBoardPostComment = function (projectId, boardPostId, formData, contentIds) {
        var requestAction = this.requestAction;
        var url = this.basePath(projectId) + "/board_posts/" + boardPostId + "/board_post_comments";
        var params = {
            board_post_comment: formData,
            content_ids: contentIds
        };
        return requestAction('post', url, params, 'board_post_comment', 'POST');
    };
    BoardApiClient.prototype.updateBoardPostComment = function (projectId, boardPostId, boardPostCommentId, formData, contentIds) {
        var requestAction = this.requestAction;
        var url = this.basePath(projectId) + "/board_posts/" + boardPostId + "/board_post_comments/" + boardPostCommentId;
        var params = {
            board_post_comment: formData,
            content_ids: contentIds
        };
        return requestAction('put', url, params, 'board_post_comment', 'UPDATE');
    };
    BoardApiClient.prototype.deleteBoardPostComment = function (projectId, boardPostId, boardPostCommentId) {
        var requestAction = this.requestAction;
        var url = this.basePath(projectId) + "/board_posts/" + boardPostId + "/board_post_comments/" + boardPostCommentId;
        return requestAction('delete', url, {}, 'board_post_comment', 'DELETE', false);
    };
    BoardApiClient.prototype.confirmBoardPostComment = function (projectId, boardPostId, boardPostCommentId) {
        var client = this.client;
        var url = this.basePath(projectId) + "/board_posts/" + boardPostId + "/board_post_comments/" + boardPostCommentId + "/confirm";
        return client.patch(url);
    };
    BoardApiClient.prototype.fetchBoardPostCommentConfirmedUsers = function (projectId, boardPostId, boardPostCommentId) {
        var client = this.client;
        var url = this.basePath(projectId) + "/board_posts/" + boardPostId + "/board_post_comments/" + boardPostCommentId + "/confirmed_users";
        return client.get(url);
    };
    BoardApiClient.prototype.searchContents = function (params) {
        var client = this.client;
        var companyId = this.companyId;
        var url = "/companies/" + companyId + "/contents";
        var config = {
            params: __assign({}, params)
        };
        return client.get(url, config);
    };
    return BoardApiClient;
}(BaseClient));
export default new BoardApiClient();
