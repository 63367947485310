export var NoticeTargetType;
(function (NoticeTargetType) {
    NoticeTargetType["ProjectAllUsers"] = "project_all_users";
    NoticeTargetType["SelectedUsers"] = "selected_users";
})(NoticeTargetType || (NoticeTargetType = {}));
export var CommentPaginateType;
(function (CommentPaginateType) {
    CommentPaginateType["PREV"] = "PREV";
    CommentPaginateType["NEXT"] = "NEXT";
})(CommentPaginateType || (CommentPaginateType = {}));
export var BoardMutationTypes;
(function (BoardMutationTypes) {
    BoardMutationTypes["INIT_HOME_BOARD_POSTS"] = "INIT_HOME_BOARD_POSTS";
    BoardMutationTypes["FETCH_HOME_BOARD_POSTS"] = "FETCH_HOME_BOARD_POSTS";
    BoardMutationTypes["FETCH_BOARD_POSTS"] = "FETCH_BOARD_POSTS";
    BoardMutationTypes["UPDATE_BOARD_POSTS_TOTAL_COUNT"] = "UPDATE_BOARD_POSTS_TOTAL_COUNT";
    BoardMutationTypes["INIT_BOARD_POSTS"] = "INIT_BOARD_POSTS";
    BoardMutationTypes["FETCH_BOARD_POST"] = "FETCH_BOARD_POST";
    BoardMutationTypes["DELETE_BOARD_POST"] = "DELETE_BOARD_POST";
    BoardMutationTypes["CONFIRM_BOARD_POST"] = "CONFIRM_BOARD_POST";
    BoardMutationTypes["FETCH_BOARD_POST_USERS"] = "FETCH_BOARD_POST_USERS";
    BoardMutationTypes["FETCH_BOARD_CATEGORIES"] = "FETCH_BOARD_CATEGORIES";
    BoardMutationTypes["FETCH_BOARD_POST_PREV_COMMENTS"] = "FETCH_BOARD_POST_PREV_COMMENTS";
    BoardMutationTypes["FETCH_BOARD_POST_NEXT_COMMENTS"] = "FETCH_BOARD_POST_NEXT_COMMENTS";
    BoardMutationTypes["INIT_BOARD_POST_COMMENTS"] = "INIT_BOARD_POST_COMMENTS";
    BoardMutationTypes["UPDATE_BOARD_POST_COMMENT"] = "UPDATE_BOARD_POST_COMMENT";
    BoardMutationTypes["DELETE_BOARD_POST_COMMENT"] = "DELETE_BOARD_POST_COMMENT";
    BoardMutationTypes["CONFIRM_BOARD_POST_COMMENT"] = "CONFIRM_BOARD_POST_COMMENT";
    BoardMutationTypes["FETCH_BOARD_POST_COMMENTS_CONFIRMED_USERS"] = "FETCH_BOARD_POST_COMMENTS_CONFIRMED_USERS";
    BoardMutationTypes["FETCH_BOARD_POST_MENTIONABLE_USERS"] = "FETCH_BOARD_POST_MENTIONABLE_USERS";
    BoardMutationTypes["FETCH_BOARD_POST_CONTENTS"] = "FETCH_BOARD_POST_CONTENTS";
    BoardMutationTypes["UPDATE_BOARD_POST_CONTENTS_LENGTH"] = "UPDATE_BOARD_POST_CONTENTS_LENGTH";
    BoardMutationTypes["INIT_BOARD_POST_CONTENTS"] = "INIT_BOARD_POST_CONTENTS";
    BoardMutationTypes["ATTACH_POST_INIT_CATEGORY_ID"] = "ATTACH_POST_INIT_CATEGORY_ID";
    BoardMutationTypes["IS_AFTER_CREATED"] = "IS_AFTER_CREATED";
    BoardMutationTypes["RETRY_FETCH_BOARD_POST_CONTENTS"] = "RETRY_FETCH_BOARD_POST_CONTENTS";
    BoardMutationTypes["RETRY_FETCH_HOME_BOARD_POST_CONTENTS"] = "RETRY_FETCH_HOME_BOARD_POST_CONTENTS";
    BoardMutationTypes["RETRY_FETCH_BOARD_POST_COMMENT_CONTENTS"] = "RETRY_FETCH_BOARD_POST_COMMENT_CONTENTS";
    BoardMutationTypes["RETRY_FETCH_BOARD_POSTS_CONTENTS"] = "RETRY_FETCH_BOARD_POSTS_CONTENTS";
    BoardMutationTypes["RETRY_FETCH_BOARD_LIST_CONTENTS"] = "RETRY_FETCH_BOARD_LIST_CONTENTS";
})(BoardMutationTypes || (BoardMutationTypes = {}));
