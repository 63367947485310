var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _a;
import Firebase from 'firebase/app';
import { PublicContentMutationTypes, ResponseType } from '@/types/public-content';
import publicContentApiClient from '@/plugins/apis/public/public-content-api-client';
import publicContentCommentApiClient from '@/plugins/apis/public/public-content-comment-api-client';
import { FirestoreCollectionNames } from '@/types';
export var state = function () { return ({
    sort: {
        prop: 'name',
        order: 'asc'
    },
    publicContents: [],
    publicContent: null,
    currentContent: null,
    loginLink: process.env.casBaseUrl + "/oauth/sign_in",
    rootContent: {
        name: '',
        created_user_name: '',
        document_preview_url: '',
        info_content_type: '',
        info_extension: '',
        url: '',
        type: '',
        thumb_url: '',
        cover_url: '',
        created_at: '',
        updated_at: ''
    },
    responseType: ResponseType.list,
    comments: []
}); };
export var getters = {
    publicContents: function (state) {
        return state.publicContents;
    },
    publicContent: function (state) {
        return state.publicContent;
    },
    currentContent: function (state) {
        return state.currentContent;
    },
    rootContent: function (state) {
        return state.rootContent;
    },
    sort: function (state) {
        return state.sort;
    },
    responseType: function (state) {
        return state.responseType;
    },
    loginLink: function (state) {
        return state.loginLink;
    },
    comments: function (state) {
        return state.comments;
    }
};
export var mutations = (_a = {},
    _a[PublicContentMutationTypes.INIT_STATE] = function (state, payload) {
        Object.assign(state, payload);
    },
    _a[PublicContentMutationTypes.RESET_CONTENTS] = function (state) {
        state.publicContents = [];
    },
    _a[PublicContentMutationTypes.FETCH_PUBLIC_CONTENTS] = function (state, payload) {
        var _a;
        var existsFiles = state.publicContents.map(function (content) { return content.url; });
        (_a = state.publicContents).push.apply(_a, payload.contents.filter(function (content) { return !existsFiles.includes(content.url); }));
        state.rootContent = payload.rootContent;
        state.currentContent = payload.currentContent;
    },
    _a[PublicContentMutationTypes.CHANGE_SORT] = function (state, payload) {
        state.sort = payload;
    },
    _a[PublicContentMutationTypes.FETCH_CONTENT] = function (state, payload) {
        state.publicContent = payload;
    },
    _a[PublicContentMutationTypes.CHANGE_RESPONSE_TYPE] = function (state, payload) {
        state.responseType = payload;
    },
    _a[PublicContentMutationTypes.SET_LOGIN_LINK] = function (state, payload) {
        state.loginLink = payload;
    },
    _a[PublicContentMutationTypes.FETCH_COMMENTS] = function (state, payload) {
        state.comments = payload;
    },
    _a[PublicContentMutationTypes.ADD_COMMENT] = function (state, payload) {
        state.comments.push(payload);
    },
    _a[PublicContentMutationTypes.DELETE_COMMENT] = function (state, payload) {
        state.comments = state.comments.filter(function (comment) { return comment.id !== payload; });
    },
    _a[PublicContentMutationTypes.UPDATE_COMMENT] = function (state, payload) {
        var index = state.comments.findIndex(function (comment) { return comment.id === payload.id; });
        if (index > -1) {
            state.comments.splice(index, 1, payload);
        }
    },
    _a);
export var actions = {
    initState: function (_a) {
        var commit = _a.commit;
        commit(PublicContentMutationTypes.INIT_STATE, state());
    },
    resetContents: function (_a) {
        var commit = _a.commit;
        commit(PublicContentMutationTypes.RESET_CONTENTS);
    },
    fetchPublicContents: function (_a, payload) {
        var dispatch = _a.dispatch, commit = _a.commit, getters = _a.getters;
        return __awaiter(this, void 0, void 0, function () {
            var res, isList, isDetail;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, publicContentApiClient.fetchContents(__assign(__assign({}, payload), { offset: getters.publicContents.length, sort: getters.sort }))];
                    case 1:
                        res = _b.sent();
                        isList = function (res) { return !!res.data.contents; };
                        isDetail = function (res) { return !!res.data.content; };
                        if (isList(res)) {
                            commit(PublicContentMutationTypes.CHANGE_RESPONSE_TYPE, ResponseType.list);
                            commit(PublicContentMutationTypes.FETCH_PUBLIC_CONTENTS, {
                                contents: res.data.contents,
                                rootContent: res.data.meta.root_content,
                                currentContent: res.data.meta.current_content
                            });
                        }
                        else if (isDetail(res)) {
                            Firebase.firestore()
                                .collection(FirestoreCollectionNames.STATUS_CONTENT)
                                .doc(String(res.data.content.id))
                                .onSnapshot(function () {
                                dispatch('fetchComments', payload);
                            });
                            commit(PublicContentMutationTypes.CHANGE_RESPONSE_TYPE, ResponseType.detail);
                            commit(PublicContentMutationTypes.FETCH_CONTENT, res.data.content);
                        }
                        commit(PublicContentMutationTypes.SET_LOGIN_LINK, res.data.meta.login_link);
                        return [2 /*return*/];
                }
            });
        });
    },
    changeSort: function (_a, payload) {
        var commit = _a.commit, dispatch = _a.dispatch;
        dispatch('resetContents');
        commit(PublicContentMutationTypes.CHANGE_SORT, payload);
    },
    fetchComments: function (_a, payload) {
        var commit = _a.commit;
        return __awaiter(this, void 0, void 0, function () {
            var res;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, publicContentCommentApiClient.fetchComments(payload)];
                    case 1:
                        res = _b.sent();
                        commit(PublicContentMutationTypes.FETCH_COMMENTS, res.data.content_comments);
                        return [2 /*return*/];
                }
            });
        });
    },
    createComents: function (_a, payload) {
        var commit = _a.commit, dispatch = _a.dispatch;
        return __awaiter(this, void 0, void 0, function () {
            var res;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, publicContentCommentApiClient.createComments(payload)];
                    case 1:
                        res = _b.sent();
                        commit(PublicContentMutationTypes.ADD_COMMENT, res.data.content_comment);
                        // @ts-ignore
                        dispatch('content-ui/setNewPin', null, { root: true });
                        // @ts-ignore
                        dispatch('content-ui/changePinMode', false, { root: true });
                        return [2 /*return*/];
                }
            });
        });
    },
    deleteComment: function (_a, payload) {
        var commit = _a.commit, dispatch = _a.dispatch;
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, publicContentCommentApiClient.deleteComment(payload)];
                    case 1:
                        _b.sent();
                        commit(PublicContentMutationTypes.DELETE_COMMENT, payload.commentId);
                        dispatch('fetchComments', payload);
                        return [2 /*return*/];
                }
            });
        });
    },
    // store/content-ui.tsから呼び出しています
    updatePin: function (_a, payload) {
        var commit = _a.commit, dispatch = _a.dispatch;
        return __awaiter(this, void 0, void 0, function () {
            var current, res;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        current = this.$router.history.current;
                        return [4 /*yield*/, publicContentCommentApiClient.updatePin({
                                uuid: current.params.uuid,
                                path: current.query.path,
                                commentId: payload.comment.id,
                                pin: payload.pin
                            })];
                    case 1:
                        res = _b.sent();
                        commit(PublicContentMutationTypes.UPDATE_COMMENT, res.data.content_comment);
                        // @ts-ignore
                        dispatch('content-ui/changePinMode', false, { root: true });
                        // @ts-ignore
                        dispatch('content-ui/setTargetComment', null, { root: true });
                        dispatch('fetchComments', { uuid: current.params.uuid, path: current.query.path });
                        return [2 /*return*/];
                }
            });
        });
    },
    updateComment: function (_a, payload) {
        var commit = _a.commit;
        return __awaiter(this, void 0, void 0, function () {
            var res;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, publicContentCommentApiClient.updateComment(payload)];
                    case 1:
                        res = _b.sent();
                        commit(PublicContentMutationTypes.UPDATE_COMMENT, res.data.content_comment);
                        return [2 /*return*/];
                }
            });
        });
    }
};
