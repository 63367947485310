/*
 * company.user_type
 * admin member guest
 * CAREECON WORK 共有
 * https://docs.google.com/spreadsheets/d/1i04RC4NqqgqRu1hVE4f2UtWft6oFm4C3Il8Oq0nkLj8/edit#gid=0
 */
import { UserTypes } from '@/types/company';
import company from '@/policies/company';
import customer from '@/policies/customer';
import project from '@/policies/project';
import content from '@/policies/content';
import constructionKind from '@/policies/construction-kind';
import member from '@/policies/member';
import projectMember from '@/policies/project-member';
export var isGuest = function (userType) {
    return userType === UserTypes.Guest;
};
export var isMember = function (userType) {
    return userType === UserTypes.Member;
};
export var isAdmin = function (userType) {
    return userType === UserTypes.Admin;
};
var policies = {
    company: company,
    customer: customer,
    project: project,
    content: content,
    constructionKind: constructionKind,
    member: member,
    projectMember: projectMember
};
export default policies;
