var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue } from 'vue-property-decorator';
import { LocalStorageKeys } from '@/types';
import contentApiClient from '@/plugins/apis/content-api-client';
var LayoutSharedContent = /** @class */ (function (_super) {
    __extends(LayoutSharedContent, _super);
    function LayoutSharedContent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    LayoutSharedContent = __decorate([
        Component({
            head: function () {
                var $constants = this.$constants;
                return {
                    meta: [
                        {
                            name: 'robots',
                            content: 'noindex,nofollow'
                        }
                    ],
                    titleTemplate: "%s - " + $constants.appName
                };
            },
            middleware: function (_a) {
                var params = _a.params, query = _a.query, error = _a.error, redirect = _a.redirect, store = _a.store;
                store.dispatch('content-ui/setIsPublic', true);
                if (!params.uuid) {
                    error({
                        statusCode: 404
                    });
                    return;
                }
                var jwt = localStorage.getItem(LocalStorageKeys.JWT);
                if (!jwt)
                    return;
                var args = {
                    uuid: params.uuid,
                    path: ''
                };
                var path = query.path;
                if (path && typeof path === 'string') {
                    args.path = path;
                }
                return contentApiClient
                    .convertUUID(args)
                    .then(function (res) {
                    var content = res.data.content;
                    return contentApiClient
                        .exists({
                        id: content.id,
                        companyId: content.company_id,
                        type: content.type
                    })
                        .then(function () {
                        redirect("/companies/" + content.company_id + "/files/" + content.id + (content.type.includes('Directory') ? '' : '/preview'));
                    })
                        .catch(console.log);
                })
                    .catch(console.log);
            }
        })
    ], LayoutSharedContent);
    return LayoutSharedContent;
}(Vue));
export default LayoutSharedContent;
