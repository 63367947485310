var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import BaseApiClient from '@/plugins/apis/base-api-client';
var ConstructionKindApiClient = /** @class */ (function (_super) {
    __extends(ConstructionKindApiClient, _super);
    function ConstructionKindApiClient() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(ConstructionKindApiClient.prototype, "basePath", {
        get: function () {
            var companyId = this.companyId;
            return "/companies/" + companyId + "/construction_kinds";
        },
        enumerable: false,
        configurable: true
    });
    ConstructionKindApiClient.prototype.fetchConstructionKinds = function (params) {
        var _a = this, basePath = _a.basePath, client = _a.client;
        return client.get(basePath, { params: params });
    };
    ConstructionKindApiClient.prototype.fetchAllConstructionKinds = function () {
        var _a = this, basePath = _a.basePath, client = _a.client;
        var url = basePath + "/everything";
        return client.get(url);
    };
    ConstructionKindApiClient.prototype.createConstructionKind = function (data) {
        var _a = this, basePath = _a.basePath, client = _a.client;
        return client.post(basePath, { construction_kind: data });
    };
    ConstructionKindApiClient.prototype.updateConstructionKind = function (id, data) {
        var _a = this, basePath = _a.basePath, client = _a.client;
        var url = basePath + "/" + id;
        return client.put(url, { construction_kind: data });
    };
    ConstructionKindApiClient.prototype.deleteConstructionKind = function (id) {
        var _a = this, basePath = _a.basePath, client = _a.client;
        var url = basePath + "/" + id;
        return client.delete(url);
    };
    return ConstructionKindApiClient;
}(BaseApiClient));
export default new ConstructionKindApiClient();
