export var ReportEditorMutationTypes;
(function (ReportEditorMutationTypes) {
    ReportEditorMutationTypes["INIT_STATE"] = "INIT_STATE";
    ReportEditorMutationTypes["FETCH_REPORT"] = "FETCH_REPORT";
    ReportEditorMutationTypes["TOGGLE_DRAGGING"] = "TOGGLE_DRAGGING";
    ReportEditorMutationTypes["SET_DRAGGED_ITEM"] = "SET_DRAGGED_ITEM";
    ReportEditorMutationTypes["SET_ACTIVE_MEDIA_FIELD"] = "SET_ACTIVE_MEDIA_FIELD";
    ReportEditorMutationTypes["CHANGE_LIST_TYPE"] = "CHANGE_LIST_TYPE";
    ReportEditorMutationTypes["CHANGE_SAVE_STATUS"] = "CHANGE_SAVE_STATUS";
    ReportEditorMutationTypes["RESET_SEARCH_CONTENTS"] = "RESET_SEARCH_CONTENTS";
    ReportEditorMutationTypes["SEARCH_IMAGE_CONTENS"] = "SEARCH_IMAGE_CONTENS";
    ReportEditorMutationTypes["SEARCH_MOVIE_CONTENS"] = "SEARCH_MOVIE_CONTENS";
    ReportEditorMutationTypes["SET_CONTENT"] = "SET_CONTENT";
    ReportEditorMutationTypes["UNDO_HISTORY"] = "UNDO_HISTORY";
    ReportEditorMutationTypes["REDO_HISTORY"] = "REDO_HISTORY";
    ReportEditorMutationTypes["ADD_UNDO_HISTORY"] = "ADD_UNDO_HISTORY";
    ReportEditorMutationTypes["CHANGE_HISTORY"] = "CHANGE_HISTORY";
})(ReportEditorMutationTypes || (ReportEditorMutationTypes = {}));
