var _a;
import { ContentUiMutationTypes } from '@/types/content-ui';
export var state = function () { return ({
    pinMode: false,
    newPin: null,
    targetComment: null,
    dragging: false,
    isPublic: false
}); };
export var getters = {
    pinMode: function (state) {
        return state.pinMode;
    },
    newPin: function (state) {
        return state.newPin;
    },
    targetComment: function (state) {
        return state.targetComment;
    },
    dragging: function (state) {
        return state.dragging;
    },
    isPublic: function (state) {
        return state.isPublic;
    }
};
export var mutations = (_a = {},
    _a[ContentUiMutationTypes.INIT_STATE] = function (state, payload) {
        Object.assign(state, payload);
    },
    _a[ContentUiMutationTypes.CHANGE_PIN_MODE] = function (state, payload) {
        state.pinMode = payload;
    },
    _a[ContentUiMutationTypes.SET_NEW_PIN] = function (state, payload) {
        state.newPin = payload;
    },
    _a[ContentUiMutationTypes.MOVE_PIN] = function (state, payload) {
        if (!state.newPin)
            return;
        state.newPin.coordinate_ratio_y = payload.top;
        state.newPin.coordinate_ratio_x = payload.left;
        if (payload.page) {
            state.newPin.page = payload.page;
        }
    },
    _a[ContentUiMutationTypes.SET_TARGET_COMMENT] = function (state, payload) {
        state.targetComment = payload;
    },
    _a[ContentUiMutationTypes.CHANGE_DRAGGING] = function (state, payload) {
        state.dragging = payload;
    },
    _a[ContentUiMutationTypes.SET_IS_PUBLIC] = function (state, payload) {
        state.isPublic = payload;
    },
    _a);
export var actions = {
    initState: function (_a) {
        var commit = _a.commit;
        commit(ContentUiMutationTypes.INIT_STATE, state());
    },
    changePinMode: function (_a, payload) {
        var commit = _a.commit, getters = _a.getters;
        if (payload) {
            window.addEventListener('keydown', function (event) {
                if (!getters.pinMode)
                    return;
                if (event.key === 'Escape') {
                    commit(ContentUiMutationTypes.CHANGE_PIN_MODE, false);
                    commit(ContentUiMutationTypes.SET_NEW_PIN, null);
                    commit(ContentUiMutationTypes.SET_TARGET_COMMENT, null);
                }
            });
        }
        commit(ContentUiMutationTypes.CHANGE_PIN_MODE, payload);
        commit(ContentUiMutationTypes.SET_NEW_PIN, null);
    },
    setNewPin: function (_a, payload) {
        var commit = _a.commit;
        commit(ContentUiMutationTypes.SET_NEW_PIN, payload);
    },
    movePin: function (_a, payload) {
        var commit = _a.commit;
        commit(ContentUiMutationTypes.MOVE_PIN, payload);
    },
    setTargetComment: function (_a, payload) {
        var commit = _a.commit;
        commit(ContentUiMutationTypes.SET_TARGET_COMMENT, payload);
    },
    changeDragging: function (_a, payload) {
        var commit = _a.commit;
        commit(ContentUiMutationTypes.CHANGE_DRAGGING, payload);
    },
    updatePin: function (_a, payload) {
        var dispatch = _a.dispatch, state = _a.state;
        if (state.isPublic) {
            // @ts-ignore
            return dispatch('public-content/updatePin', payload, { root: true });
        }
        else {
            // @ts-ignore
            return dispatch('content/updatePin', payload, { root: true });
        }
    },
    setIsPublic: function (_a, payload) {
        var commit = _a.commit;
        commit(ContentUiMutationTypes.SET_IS_PUBLIC, payload);
    }
};
