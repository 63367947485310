var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Mixins, Component, Prop } from 'vue-property-decorator';
import NotificationIconUnread from '@/components/notification/atom/icon-unread.vue';
import CommonDateMixin from '@/mixins/common/date-mixin.ts';
import BaseMixin from '@/mixins/base-mixin.ts';
import NotificationBaseMixin from '@/mixins/notification/base-mixin.ts';
var ProviderNotificationListContent = /** @class */ (function (_super) {
    __extends(ProviderNotificationListContent, _super);
    function ProviderNotificationListContent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(ProviderNotificationListContent.prototype, "unreadArticle", {
        // computed
        get: function () {
            var _a = this, notification = _a.notification, announcements = _a.announcements;
            var badge = announcements.find(function (announce) { return announce.article_id === notification.id; });
            return badge && badge.unread;
        },
        enumerable: false,
        configurable: true
    });
    // methods
    ProviderNotificationListContent.prototype.notificationClickHandler = function () {
        var _a = this, notification = _a.notification, user = _a.user;
        this.readAnnouncement({
            id: notification.id,
            uid: user.uid
        });
        this.$gtag('event', this.$gaEvent.clickNotificationItemProvider);
        window.open(notification.html_url);
    };
    __decorate([
        Prop({ type: Object, required: true })
    ], ProviderNotificationListContent.prototype, "notification", void 0);
    ProviderNotificationListContent = __decorate([
        Component({
            components: {
                NotificationIconUnread: NotificationIconUnread
            }
        })
    ], ProviderNotificationListContent);
    return ProviderNotificationListContent;
}(Mixins(CommonDateMixin, BaseMixin, NotificationBaseMixin)));
export default ProviderNotificationListContent;
