/* eslint camelcase: 0 */
export var PlanCodes;
(function (PlanCodes) {
    PlanCodes["Trial"] = "trial";
    PlanCodes["Personal"] = "personal";
    PlanCodes["PersonalAnnual"] = "personal_annual";
    PlanCodes["Business"] = "business";
    PlanCodes["BusinessAnnual"] = "business_annual";
})(PlanCodes || (PlanCodes = {}));
export var PlanStatuses;
(function (PlanStatuses) {
    PlanStatuses["Available"] = "available";
    PlanStatuses["TrialIsExpired"] = "trial_is_expired";
    PlanStatuses["UnpaidPlan"] = "unpaid_plan";
    PlanStatuses["LockedPlan"] = "locked_plan"; // アカウントロック（運営側からの停止)
})(PlanStatuses || (PlanStatuses = {}));
export var UserTypes;
(function (UserTypes) {
    UserTypes["Admin"] = "admin";
    UserTypes["Member"] = "member";
    UserTypes["Guest"] = "guest";
})(UserTypes || (UserTypes = {}));
