var VALID_EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
/**
 * uniqueな大工程名をはじく
 */
var uniqueness_large_process = {
    params: ['largeProcesses'],
    validate: function (value, _a) {
        var largeProcesses = _a.largeProcesses;
        return !largeProcesses.includes(value);
    },
    message: function (field) {
        return field + "\u306F\u65E2\u306B\u767B\u9332\u3055\u308C\u3066\u3044\u307E\u3059";
    }
};
/**
 * 複数データ作成フォームで重複した値をはじく
 */
var uniqueness = {
    params: ['inputValues'],
    validate: function (value, _a) {
        var inputValues = _a.inputValues;
        var set = new Set(inputValues);
        var dupValues = inputValues.filter(function (x, i, self) {
            return self.indexOf(x) === i && i !== self.lastIndexOf(x);
        });
        return set.size == inputValues.length || !dupValues.includes(value);
    },
    message: function (field) {
        return field + "\u306F\u65E2\u306B\u5165\u529B\u3055\u308C\u3066\u3044\u307E\u3059";
    }
};
/**
 * 複数メールアドレスが全部正しい形式かをチェック
 */
var multi_emails = {
    params: ['inputValues'],
    validate: function (value, _a) {
        var inputValues = _a.inputValues;
        return inputValues.every(function (email) {
            return VALID_EMAIL_REGEX.test(email);
        });
    },
    message: function () {
        return "\u4E0D\u6B63\u306A\u5F62\u5F0F\u306E\u30E1\u30FC\u30EB\u30A2\u30C9\u30EC\u30B9\u304C\u5165\u529B\u3055\u308C\u3066\u3044\u307E\u3059";
    }
};
export { uniqueness_large_process, uniqueness, multi_emails };
