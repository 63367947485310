var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import BaseApiClient from '@/plugins/apis/base-api-client';
var InvitedUserApiClient = /** @class */ (function (_super) {
    __extends(InvitedUserApiClient, _super);
    function InvitedUserApiClient() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(InvitedUserApiClient.prototype, "basePath", {
        get: function () {
            var companyId = this.companyId;
            return "/companies/" + companyId + "/invited_users";
        },
        enumerable: false,
        configurable: true
    });
    InvitedUserApiClient.prototype.fetchInvitedUsers = function (projectId) {
        var _a = this, basePath = _a.basePath, client = _a.client;
        var params = { limit: 500 };
        if (projectId) {
            params.project_id = projectId;
        }
        return client.get(basePath, { params: params });
    };
    InvitedUserApiClient.prototype.cancelInvitation = function (data) {
        var basePath = this.basePath;
        var url = basePath + "/mass_destroy";
        return this.requestActionWithNamedProperty({
            method: 'delete',
            url: url,
            params: data,
            resourceName: 'delete_invite',
            actionName: 'OTHER',
            hasData: false
        });
    };
    InvitedUserApiClient.prototype.cancelProjectInvitation = function (data) {
        var basePath = this.basePath;
        var url = basePath + "/mass_destroy";
        return this.requestActionWithNamedProperty({
            method: 'delete',
            url: url,
            params: data,
            resourceName: 'delete_invite',
            actionName: 'OTHER',
            hasData: false
        });
    };
    InvitedUserApiClient.prototype.update = function (data) {
        var basePath = this.basePath;
        var url = basePath + "/" + data.id;
        return this.requestActionWithNamedProperty({
            method: 'put',
            url: url,
            params: data,
            resourceName: 'update_invite',
            actionName: 'OTHER',
            hasData: false
        });
    };
    return InvitedUserApiClient;
}(BaseApiClient));
export default new InvitedUserApiClient();
