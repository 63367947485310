import { Component } from 'vue-property-decorator';
Component.registerHooks([
    // nuxt
    'fetch',
    'asyncData',
    'activated',
    'deactivated',
    // vue router
    'beforeRouteEnter',
    'beforeRouteLeave',
    'beforeRouteUpdate'
]);
