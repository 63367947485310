/**
 * オブジェクトなどからネストした値を取り出します
 * e.g.) keys => 'project.title'
 */
var get = function (data, keys) {
    var keyArray = keys.split('.');
    var accessProperty = function (data) {
        var key = keyArray.shift();
        if (!key) {
            return data;
        }
        var val = data[key];
        if (val === null || val === undefined) {
            return null;
        }
        if (val === '') {
            return '';
        }
        return accessProperty(val);
    };
    return accessProperty(data);
};
export default get;
