var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Routes } from '@/types/routes';
export default (function (context) {
    var app = context.app, store = context.store, params = context.params;
    var routeArray = Object.values(Routes);
    app.router &&
        app.router.afterEach(function (to) {
            setUserProperties(to);
        });
    /**
     * About sending User-ID.
     * https://developers.google.com/analytics/devguides/collection/ga4/user-id?platform=websites
     *
     * About user properties on Google Analytics 4.
     * https://developers.google.com/analytics/devguides/collection/ga4/user-properties?hl=ja&technology=websites
     * */
    var setUserProperties = function (to) { return __awaiter(void 0, void 0, void 0, function () {
        var userProperties, currentRoute;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, buildUserProperties()];
                case 1:
                    userProperties = _a.sent();
                    currentRoute = getCurrentRoute(to);
                    if (!currentRoute)
                        console.error("RoutingError: Undefined routing name - " + to.name + " / [Ref] src/types/routes.ts");
                    app.$gtag('set', 'user_properties', {
                        companyId: userProperties.companyId,
                        environment: userProperties.environment
                    });
                    app.$gtag('config', process.env.googleAnalytics4Id, {
                        user_id: userProperties.uid,
                        send_page_view: false
                    });
                    app.$gtag('event', 'page_view', {
                        page_title: currentRoute ? currentRoute.screen_name : undefined
                    });
                    return [2 /*return*/];
            }
        });
    }); };
    /**
     * Get a defined route from the name of the currently visiting Route.
     * */
    var getCurrentRoute = function (to) {
        return routeArray.find(function (route) { return route.name === to.name; });
    };
    /**
     * Get companyId from Store or URL.
     * */
    var getCompanyId = function () {
        return store.getters['initial-setting/company'].id || params.company_id;
    };
    /**
     * Get uid from Store, or re-fetch jwt.
     * */
    var getUid = function (companyId) { return __awaiter(void 0, void 0, void 0, function () {
        var uid, userId, _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    uid = store.getters['initial-setting/user'].uid;
                    if (!!uid) return [3 /*break*/, 5];
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 4, , 5]);
                    userId = store.getters['initial-setting/user'].id;
                    if (!!userId) return [3 /*break*/, 3];
                    return [4 /*yield*/, store.dispatch('initial-setting/fetchMe', companyId)];
                case 2:
                    _b.sent();
                    _b.label = 3;
                case 3:
                    uid = store.getters['initial-setting/user'].uid;
                    return [3 /*break*/, 5];
                case 4:
                    _a = _b.sent();
                    console.error('SessionError: Could not get user uid. Need to session refresh.');
                    return [3 /*break*/, 5];
                case 5: return [2 /*return*/, uid];
            }
        });
    }); };
    /**
     * buildUserProperties
     *
     * Since it is necessary to check the session expiration date every time the page changes,
     * build the latest user properties for each screen.
     * */
    var buildUserProperties = function () { return __awaiter(void 0, void 0, void 0, function () {
        var userProperties, companyId, uid;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    userProperties = {
                        environment: process.env.appEnv
                    };
                    // Session data does not exist.
                    if (!localStorage.getItem('jwt'))
                        return [2 /*return*/, userProperties];
                    companyId = getCompanyId();
                    // Session data does exist, but companyId does not exist.
                    if (!companyId)
                        return [2 /*return*/, userProperties];
                    return [4 /*yield*/, getUid(companyId)];
                case 1:
                    uid = _a.sent();
                    userProperties.companyId = companyId;
                    userProperties.uid = uid;
                    return [2 /*return*/, userProperties];
            }
        });
    }); };
});
