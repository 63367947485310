var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import BaseApiClient from '@/plugins/apis/base-api-client';
var ReportApiClient = /** @class */ (function (_super) {
    __extends(ReportApiClient, _super);
    function ReportApiClient() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(ReportApiClient.prototype, "basePath", {
        get: function () {
            var companyId = this.companyId;
            return "/companies/" + companyId + "/reports";
        },
        enumerable: false,
        configurable: true
    });
    ReportApiClient.prototype.fetchReports = function (params) {
        var _a = this, basePath = _a.basePath, client = _a.client;
        return client.get(basePath, { params: params });
    };
    ReportApiClient.prototype.searchReports = function (params) {
        var _a = this, basePath = _a.basePath, client = _a.client;
        return client.get(basePath, { params: params });
    };
    ReportApiClient.prototype.fetchReport = function (reportId) {
        var _a = this, basePath = _a.basePath, client = _a.client;
        var url = basePath + "/" + reportId;
        return client.get(url);
    };
    ReportApiClient.prototype.createReport = function (data) {
        var _a = this, basePath = _a.basePath, client = _a.client;
        return client.post(basePath, { report: data });
    };
    ReportApiClient.prototype.deleteReport = function (id) {
        var _a = this, basePath = _a.basePath, client = _a.client;
        var url = basePath + "/" + id;
        return client.delete(url);
    };
    ReportApiClient.prototype.updateReport = function (reportId, data) {
        var _a = this, basePath = _a.basePath, client = _a.client;
        var url = basePath + "/" + reportId;
        return client.put(url, { report: data });
    };
    ReportApiClient.prototype.createReportPage = function (reportId, data) {
        var _a = this, basePath = _a.basePath, client = _a.client;
        var url = basePath + "/" + reportId;
        var params = {
            report_pages_attributes: [data]
        };
        return client.put(url, params);
    };
    // page_num更新の為全てのページデータを投げる
    ReportApiClient.prototype.copyReportPage = function (reportId, data) {
        var _a = this, basePath = _a.basePath, client = _a.client;
        var url = basePath + "/" + reportId;
        var params = {
            report_pages_attributes: data.map(function (page) {
                return __assign(__assign({}, page), { reprot_parts: undefined, report_parts_attributes: page.report_parts });
            })
        };
        return client.put(url, params);
    };
    ReportApiClient.prototype.deleteReportPage = function (reportId, pages) {
        var _a = this, basePath = _a.basePath, client = _a.client;
        var url = basePath + "/" + reportId;
        var params = {
            report_pages_attributes: pages
        };
        return client.put(url, params);
    };
    ReportApiClient.prototype.changeOrderPages = function (reportId, pages) {
        var _a = this, basePath = _a.basePath, client = _a.client;
        var url = basePath + "/" + reportId;
        var params = {
            report_pages_attributes: pages
        };
        return client.put(url, params);
    };
    ReportApiClient.prototype.updateReportPart = function (reportId, part) {
        var _a = this, basePath = _a.basePath, client = _a.client;
        var url = basePath + "/" + reportId;
        var params = {
            report_pages_attributes: [
                {
                    id: part.report_page_id,
                    report_parts_attributes: [part]
                }
            ]
        };
        return client.put(url, params);
    };
    ReportApiClient.prototype.updateMediaDegree = function (reportId, part) {
        var _a = this, basePath = _a.basePath, client = _a.client;
        var url = basePath + "/" + reportId;
        var params = {
            report_pages_attributes: [
                {
                    id: part.report_page_id,
                    report_parts_attributes: [part]
                }
            ]
        };
        return client.patch(url, params);
    };
    ReportApiClient.prototype.saveReport = function (reportId, data) {
        var _a = this, basePath = _a.basePath, client = _a.client;
        var url = basePath + "/" + reportId;
        var params = {
            id: reportId,
            report_pages_attributes: data.report_pages.map(function (page) {
                return __assign(__assign({}, page), { _destroy: page.deleted, report_parts: undefined, report_parts_attributes: page.report_parts.map(function (part) {
                        return __assign(__assign({}, part), { content_id: part.content ? part.content.id : '', _destroy: part.deleted });
                    }) });
            })
        };
        return client.put(url, params);
    };
    ReportApiClient.prototype.downloadPDF = function (reportId) {
        var _a = this, basePath = _a.basePath, client = _a.client;
        var url = basePath + "/" + reportId + "/download";
        var params = {
            url: location.host + "/" + basePath + "/" + reportId + "/pdf"
        };
        return client.get(url, { params: params });
    };
    return ReportApiClient;
}(BaseApiClient));
export default new ReportApiClient();
