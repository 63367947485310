var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Mixins, Component } from 'vue-property-decorator';
import { RootModelsList } from '@/types/content';
import CanBase from '@/mixins/can/base';
var CanSharingContent = /** @class */ (function (_super) {
    __extends(CanSharingContent, _super);
    function CanSharingContent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(CanSharingContent.prototype, "$SharingContentsPolicy", {
        get: function () {
            return this.$Policies.sharing_contents;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CanSharingContent.prototype, "canCreateSharingContent", {
        get: function () {
            return this.$SharingContentsPolicy.create;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CanSharingContent.prototype, "canIndexSharingContent", {
        get: function () {
            return this.$SharingContentsPolicy.index;
        },
        enumerable: false,
        configurable: true
    });
    // HACK: 取引先・案件のルートは共有不可/web先行対応のためpolicyを見ていません
    CanSharingContent.prototype.notSharingContentDirectory = function (content) {
        if (!content.root_model)
            return false;
        return [RootModelsList.Customer, RootModelsList.Project].some(function (model) {
            return model === content.root_model;
        });
    };
    CanSharingContent = __decorate([
        Component
    ], CanSharingContent);
    return CanSharingContent;
}(Mixins(CanBase)));
export default CanSharingContent;
