var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Vue, Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { LocalStorageKeys, DepartmentURL } from '@/types';
var InitialSettingModule = namespace('initial-setting');
var BaseMixin = /** @class */ (function (_super) {
    __extends(BaseMixin, _super);
    function BaseMixin() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    BaseMixin.prototype.departmentOrigin = function (departmentCode, baseURL) {
        var departments = localStorage.getItem(LocalStorageKeys.Departments);
        var parsedDepartments = departments !== null ? JSON.parse(departments) : [];
        var departMent = parsedDepartments.find(function (department) {
            return department.code === departmentCode;
        });
        return departMent && departMent.origin
            ? DepartmentURL.HTTPS_HEADER + departMent.origin
            : DepartmentURL.HTTPS_HEADER + baseURL;
    };
    __decorate([
        InitialSettingModule.Getter('company')
    ], BaseMixin.prototype, "company", void 0);
    __decorate([
        InitialSettingModule.Action('findMyCompany')
    ], BaseMixin.prototype, "findMyCompany", void 0);
    __decorate([
        InitialSettingModule.Getter('user')
    ], BaseMixin.prototype, "user", void 0);
    __decorate([
        InitialSettingModule.Action('fetchMe')
    ], BaseMixin.prototype, "fetchMe", void 0);
    BaseMixin = __decorate([
        Component
    ], BaseMixin);
    return BaseMixin;
}(Vue));
export default BaseMixin;
