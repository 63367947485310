var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
var _a;
import Vue from 'vue';
import showErrorMessage from '@/plugins/show-error-message';
import BoardApiClient from '@/plugins/apis/board-api-client';
import ContentApiClient from '@/plugins/apis/content-api-client';
import { BoardMutationTypes, CommentPaginateType } from '@/types/board';
export var state = function () { return ({
    home_board_posts: [],
    board_posts: [],
    board_posts_total_count: 0,
    board_post: {
        id: '',
        project_id: '',
        board_category_id: '',
        project_title: '',
        created_user_avatar: '',
        created_user_name: '',
        created_user_id: '',
        title: '',
        body: '',
        contents: [],
        confirmed_users_count: 0,
        current_user_confirmed: false,
        current_user_mute: false,
        activity: [],
        read_users_count: 0,
        read_users_avatars: [],
        notify_everyone: false,
        upload_content_id: '',
        upload_content_name: '',
        read_required: false,
        acted_at: '',
        edited_at: '',
        created_at: '',
        policies: {
            show: false,
            update: false,
            destroy: false,
            confirm: false,
            board_post_users: false
        }
    },
    board_post_users: [],
    board_categories: [],
    board_post_comments: [],
    board_post_comments_prev_meta: {
        first_index: 0,
        last_index: 0,
        paginate: {
            current_limit: 0,
            current_offset: 0,
            total_count: 0
        },
        policies: {
            show: false,
            update: false,
            destroy: false,
            confirm: false,
            board_post_users: false
        },
        target_index: 0
    },
    board_post_comments_next_meta: {
        first_index: 0,
        last_index: 0,
        paginate: {
            current_limit: 0,
            current_offset: 0,
            total_count: 0
        },
        policies: {
            show: false,
            update: false,
            destroy: false,
            confirm: false,
            board_post_users: false
        },
        target_index: 0
    },
    board_post_comments_confirmed_users: [],
    board_post_mentionable_users: [],
    board_post_contents: [],
    board_post_contents_total_count: 0,
    post_init_category_id: '',
    is_after_created: false
}); };
export var getters = {
    homeBoardPosts: function (state) {
        return function (categoryId) {
            if (categoryId === void 0) { categoryId = ''; }
            if (!categoryId) {
                return state.home_board_posts;
            }
            return state.home_board_posts.filter(function (value) {
                return value.board_category_id === categoryId;
            });
        };
    },
    boardPosts: function (state) {
        return state.board_posts;
    },
    boardPostsTotalCount: function (state) {
        return state.board_posts_total_count;
    },
    boardPost: function (state) {
        return state.board_post;
    },
    boardPostUsers: function (state) {
        return state.board_post_users;
    },
    boardCategories: function (state) {
        return state.board_categories;
    },
    boardPostComments: function (state) {
        return state.board_post_comments;
    },
    boardPostCommentsTotalCount: function (state) {
        return state.board_post_comments_prev_meta.paginate.total_count;
    },
    hasPrevCommentPage: function (state) {
        var _a = state.board_post_comments_prev_meta.paginate, current_offset = _a.current_offset, current_limit = _a.current_limit, total_count = _a.total_count;
        return current_offset + current_limit < total_count;
    },
    hasNextCommentPage: function (state) {
        var offset = state.board_post_comments_next_meta.paginate.current_offset;
        var LATEST_OFFSET = 0;
        return offset !== LATEST_OFFSET;
    },
    boardPostCommentConfirmedUsers: function (state) {
        return function (commentId) {
            if (commentId === void 0) { commentId = ''; }
            var confirmedUsersObject = state.board_post_comments_confirmed_users.find(function (value) {
                return value.board_post_comment_id === commentId;
            });
            if (!confirmedUsersObject) {
                return [];
            }
            return confirmedUsersObject.users;
        };
    },
    boardPostMentionableUsers: function (state) {
        return state.board_post_mentionable_users;
    },
    boardPostContents: function (state) {
        return state.board_post_contents;
    },
    boardPostContentsTotalCount: function (state) {
        return state.board_post_contents_total_count;
    },
    postInitCategoryId: function (state) {
        return state.post_init_category_id;
    },
    isAfterCreated: function (state) {
        return state.is_after_created;
    }
};
export var mutations = (_a = {},
    _a[BoardMutationTypes.INIT_HOME_BOARD_POSTS] = function (state) {
        state.home_board_posts = [];
    },
    _a[BoardMutationTypes.FETCH_HOME_BOARD_POSTS] = function (state, payload) {
        state.home_board_posts = payload;
    },
    _a[BoardMutationTypes.FETCH_BOARD_POSTS] = function (state, payload) {
        var existingBoardPostIds = state.board_posts.map(function (boardPosts) { return boardPosts.id; });
        var newPosts = payload.filter(function (boardPosts) {
            return !existingBoardPostIds.includes(boardPosts.id);
        });
        state.board_posts = state.board_posts.concat(newPosts);
    },
    _a[BoardMutationTypes.INIT_BOARD_POSTS] = function (state, payload) {
        state.board_posts = payload;
    },
    _a[BoardMutationTypes.UPDATE_BOARD_POSTS_TOTAL_COUNT] = function (state, count) {
        state.board_posts_total_count = count;
    },
    _a[BoardMutationTypes.FETCH_BOARD_POST] = function (state, payload) {
        state.board_post = payload;
    },
    _a[BoardMutationTypes.DELETE_BOARD_POST] = function () { },
    _a[BoardMutationTypes.FETCH_BOARD_POST_USERS] = function (state, payload) {
        state.board_post_users = payload;
    },
    _a[BoardMutationTypes.FETCH_BOARD_CATEGORIES] = function (state, payload) {
        state.board_categories = payload;
    },
    _a[BoardMutationTypes.INIT_BOARD_POST_COMMENTS] = function (state, payload) {
        state.board_post_comments = payload.board_post_comments.reverse();
        state.board_post_comments_next_meta = payload.meta;
        state.board_post_comments_prev_meta = payload.meta;
    },
    _a[BoardMutationTypes.FETCH_BOARD_POST_PREV_COMMENTS] = function (state, payload) {
        var reversedComments = payload.board_post_comments.reverse();
        state.board_post_comments = __spreadArrays(reversedComments, state.board_post_comments);
        state.board_post_comments_prev_meta = payload.meta;
    },
    _a[BoardMutationTypes.FETCH_BOARD_POST_NEXT_COMMENTS] = function (state, payload) {
        var reversedComments = payload.board_post_comments.reverse();
        state.board_post_comments = __spreadArrays(state.board_post_comments, reversedComments);
        state.board_post_comments_next_meta = payload.meta;
    },
    _a[BoardMutationTypes.UPDATE_BOARD_POST_COMMENT] = function (state, payload) {
        var targetComment = state.board_post_comments.find(function (comment) {
            return comment.id === payload.id;
        });
        for (var _i = 0, _a = Object.keys(targetComment); _i < _a.length; _i++) {
            var k = _a[_i];
            targetComment[k] = payload[k];
        }
    },
    _a[BoardMutationTypes.DELETE_BOARD_POST_COMMENT] = function (state, payload) {
        var index = state.board_post_comments.findIndex(function (comment) { return comment.id === payload; });
        if (index !== -1) {
            Vue.delete(state.board_post_comments, index);
            state.board_post_comments_prev_meta.paginate.total_count--;
        }
    },
    _a[BoardMutationTypes.CONFIRM_BOARD_POST] = function (state, payload) {
        state.board_post.confirmed_users_count = payload.confirmed_users_count;
        state.board_post.current_user_confirmed = payload.current_user_confirmed;
    },
    _a[BoardMutationTypes.CONFIRM_BOARD_POST_COMMENT] = function (state, payload) {
        var confirmedUsersObject = state.board_post_comments_confirmed_users.find(function (value) {
            return value.board_post_comment_id === payload.board_post_comment_id;
        });
        if (!confirmedUsersObject) {
            return;
        }
        confirmedUsersObject.users = payload.users;
    },
    _a[BoardMutationTypes.FETCH_BOARD_POST_COMMENTS_CONFIRMED_USERS] = function (state, payload) {
        state.board_post_comments_confirmed_users.push(payload);
    },
    _a[BoardMutationTypes.FETCH_BOARD_POST_MENTIONABLE_USERS] = function (state, payload) {
        state.board_post_mentionable_users = payload;
    },
    _a[BoardMutationTypes.FETCH_BOARD_POST_CONTENTS] = function (state, payload) {
        var existingContentIds = state.board_post_contents.map(function (content) { return content.id; });
        var newContents = payload.filter(function (content) {
            return !existingContentIds.includes(content.id);
        });
        state.board_post_contents = state.board_post_contents.concat(newContents);
    },
    _a[BoardMutationTypes.INIT_BOARD_POST_CONTENTS] = function (state, payload) {
        state.board_post_contents = payload;
    },
    _a[BoardMutationTypes.UPDATE_BOARD_POST_CONTENTS_LENGTH] = function (state, payload) {
        state.board_post_contents_total_count = payload;
    },
    _a[BoardMutationTypes.ATTACH_POST_INIT_CATEGORY_ID] = function (state, payload) {
        state.post_init_category_id = payload;
    },
    _a[BoardMutationTypes.IS_AFTER_CREATED] = function (state, payload) {
        state.is_after_created = payload;
    },
    _a[BoardMutationTypes.RETRY_FETCH_BOARD_POST_CONTENTS] = function (state, payload) {
        payload.forEach(function (retryContent) {
            var content = state.board_post.contents.find(function (stateContent) { return retryContent.id === stateContent.id; });
            // possibly 'undefined'
            if (!content)
                return;
            content.s3_process_done = retryContent.s3_process_done;
        });
    },
    _a[BoardMutationTypes.RETRY_FETCH_HOME_BOARD_POST_CONTENTS] = function (state, payload) {
        var content;
        payload.forEach(function (retryContent) {
            state.home_board_posts.forEach(function (boardPost) {
                content = boardPost.contents.find(function (stateContent) { return retryContent.id === stateContent.id; });
                // possibly 'undefined'
                if (!content)
                    return;
                content.s3_process_done = retryContent.s3_process_done;
            });
        });
    },
    _a[BoardMutationTypes.RETRY_FETCH_BOARD_POSTS_CONTENTS] = function (state, payload) {
        payload.forEach(function (retryContent) {
            var content = state.board_post_contents.find(function (stateContent) { return retryContent.id === stateContent.id; });
            // possibly 'undefined'
            if (!content)
                return;
            content.s3_process_done = retryContent.s3_process_done;
        });
    },
    _a[BoardMutationTypes.RETRY_FETCH_BOARD_POST_COMMENT_CONTENTS] = function (state, payload) {
        var content;
        payload.forEach(function (retryContent) {
            state.board_post_comments.forEach(function (comment) {
                content = comment.contents.find(function (stateContent) { return retryContent.id === stateContent.id; });
                // possibly 'undefined'
                if (!content)
                    return;
                content.s3_process_done = retryContent.s3_process_done;
            });
        });
    },
    _a[BoardMutationTypes.RETRY_FETCH_BOARD_LIST_CONTENTS] = function (state, payload) {
        var content;
        payload.forEach(function (retryContent) {
            state.board_posts.forEach(function (boardPost) {
                content = boardPost.contents.find(function (stateContent) { return retryContent.id === stateContent.id; });
                // possibly 'undefined'
                if (!content)
                    return;
                content.s3_process_done = retryContent.s3_process_done;
            });
        });
    },
    _a);
export var actions = {
    fetchHomeBoardPosts: function (_a, payload) {
        var commit = _a.commit;
        return __awaiter(this, void 0, void 0, function () {
            var res;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        commit(BoardMutationTypes.INIT_HOME_BOARD_POSTS);
                        return [4 /*yield*/, BoardApiClient.fetchBoardPosts(payload.searchParams)];
                    case 1:
                        res = _b.sent();
                        commit(BoardMutationTypes.FETCH_HOME_BOARD_POSTS, res.data.board_posts);
                        return [2 /*return*/];
                }
            });
        });
    },
    fetchBoardPosts: function (_a, payload) {
        var commit = _a.commit;
        return __awaiter(this, void 0, void 0, function () {
            var res;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, BoardApiClient.fetchBoardPosts(payload.searchParams)];
                    case 1:
                        res = _b.sent();
                        commit(BoardMutationTypes.FETCH_BOARD_POSTS, res.data.board_posts);
                        commit(BoardMutationTypes.UPDATE_BOARD_POSTS_TOTAL_COUNT, res.data.meta.paginate.total_count);
                        return [2 /*return*/];
                }
            });
        });
    },
    initBoardPosts: function (_a, payload) {
        var commit = _a.commit;
        return __awaiter(this, void 0, void 0, function () {
            var res;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        commit(BoardMutationTypes.INIT_BOARD_POSTS, []);
                        return [4 /*yield*/, BoardApiClient.fetchBoardPosts(payload.searchParams)];
                    case 1:
                        res = _b.sent();
                        commit(BoardMutationTypes.INIT_BOARD_POSTS, res.data.board_posts);
                        commit(BoardMutationTypes.UPDATE_BOARD_POSTS_TOTAL_COUNT, res.data.meta.paginate.total_count);
                        return [2 /*return*/];
                }
            });
        });
    },
    fetchBoardPost: function (_a, payload) {
        var commit = _a.commit, dispatch = _a.dispatch;
        return __awaiter(this, void 0, void 0, function () {
            var res, _b, id, project_id;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0: return [4 /*yield*/, BoardApiClient.fetchBoardPost(payload.projectId, payload.boardPostId)];
                    case 1:
                        res = _c.sent();
                        commit(BoardMutationTypes.FETCH_BOARD_POST, res.data.board_post);
                        _b = res.data.board_post, id = _b.id, project_id = _b.project_id;
                        dispatch('fetchBoardPostMentionableUsers', { boardPostId: id, projectId: project_id });
                        return [2 /*return*/, res.data.board_post];
                }
            });
        });
    },
    createBoardPost: function (_a, payload) {
        var commit = _a.commit, dispatch = _a.dispatch;
        return __awaiter(this, void 0, void 0, function () {
            var res, homePayload;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, BoardApiClient.createBoardPost(payload.projectId, payload.formData, payload.contentIds)];
                    case 1:
                        res = _b.sent();
                        commit(BoardMutationTypes.FETCH_BOARD_POST, res.data.board_post);
                        homePayload = {
                            searchParams: {
                                project_id: payload.projectId,
                                web_home_response: true
                            }
                        };
                        dispatch('fetchHomeBoardPosts', homePayload);
                        return [2 /*return*/, res.data.board_post];
                }
            });
        });
    },
    updateBoardPost: function (_a, payload) {
        var commit = _a.commit, dispatch = _a.dispatch, getters = _a.getters;
        return __awaiter(this, void 0, void 0, function () {
            var res, homePayload, beforeUpdateBoardPostContentIds, fetchContentsPayload;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, BoardApiClient.updateBoardPost(payload.projectId, payload.boardPostId, payload.formData, payload.contentIds)];
                    case 1:
                        res = _b.sent();
                        homePayload = {
                            searchParams: {
                                project_id: payload.projectId,
                                web_home_response: true
                            }
                        };
                        dispatch('fetchHomeBoardPosts', homePayload);
                        beforeUpdateBoardPostContentIds = getters.boardPostContents.map(function (content) {
                            return content.id;
                        });
                        commit(BoardMutationTypes.FETCH_BOARD_POST, res.data.board_post);
                        dispatch('fetchBoardPostUsers', {
                            projectId: payload.projectId,
                            boardPostId: payload.boardPostId
                        });
                        if (beforeUpdateBoardPostContentIds !== payload.contentIds) {
                            fetchContentsPayload = {
                                params: {
                                    board_post_id: payload.boardPostId
                                }
                            };
                            dispatch('initBoardPostContents', fetchContentsPayload);
                        }
                        return [2 /*return*/];
                }
            });
        });
    },
    deleteBoardPost: function (_a, payload) {
        var commit = _a.commit, dispatch = _a.dispatch;
        return __awaiter(this, void 0, void 0, function () {
            var homePayload;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, BoardApiClient.deleteBoardPost(payload.projectId, payload.boardPostId)];
                    case 1:
                        _b.sent();
                        commit(BoardMutationTypes.DELETE_BOARD_POST);
                        homePayload = {
                            searchParams: {
                                project_id: payload.projectId,
                                web_home_response: true
                            }
                        };
                        return [4 /*yield*/, dispatch('fetchHomeBoardPosts', homePayload)];
                    case 2:
                        _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    },
    muteBoardPostComment: function (_a, payload) {
        var commit = _a.commit;
        return __awaiter(this, void 0, void 0, function () {
            var res;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, BoardApiClient.muteBoardPostComment(payload.projectId, payload.boardPostId)];
                    case 1:
                        res = _b.sent();
                        commit(BoardMutationTypes.FETCH_BOARD_POST, res.data.board_post);
                        return [2 /*return*/];
                }
            });
        });
    },
    fetchBoardPostUsers: function (_a, payload) {
        var _b;
        var commit = _a.commit, rootGetters = _a.rootGetters;
        return __awaiter(this, void 0, void 0, function () {
            var board_post_users, me, confirmed_users_count, current_user_confirmed;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0: return [4 /*yield*/, BoardApiClient.fetchBoardPostUsers(payload.projectId, payload.boardPostId)];
                    case 1:
                        board_post_users = (_c.sent()).data.board_post_users;
                        commit(BoardMutationTypes.FETCH_BOARD_POST_USERS, board_post_users);
                        me = rootGetters['initial-setting/user'];
                        confirmed_users_count = board_post_users.filter(function (_a) {
                            var confirmed = _a.confirmed;
                            return confirmed;
                        }).length;
                        current_user_confirmed = !!((_b = board_post_users.find(function (_a) {
                            var id = _a.id;
                            return id === me.id;
                        })) === null || _b === void 0 ? void 0 : _b.confirmed);
                        commit(BoardMutationTypes.CONFIRM_BOARD_POST, {
                            id: payload.boardPostId,
                            confirmed_users_count: confirmed_users_count,
                            current_user_confirmed: current_user_confirmed
                        });
                        return [2 /*return*/];
                }
            });
        });
    },
    noticeUnreadUsers: function (_, payload) {
        BoardApiClient.noticeUnreadUsers(payload.projectId, payload.boardPostId);
    },
    fetchBoardCategories: function (_a) {
        var commit = _a.commit;
        return __awaiter(this, void 0, void 0, function () {
            var res;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, BoardApiClient.fetchBoardCategories()];
                    case 1:
                        res = _b.sent();
                        commit(BoardMutationTypes.FETCH_BOARD_CATEGORIES, res.data.board_categories);
                        return [2 /*return*/];
                }
            });
        });
    },
    fetchBoardPostComments: function (_a, payload) {
        var commit = _a.commit, dispatch = _a.dispatch, state = _a.state;
        var prevMeta = state.board_post_comments_prev_meta;
        var nextMeta = state.board_post_comments_next_meta;
        var offset, limit;
        switch (payload.fetchType) {
            case CommentPaginateType.PREV:
                offset = prevMeta.paginate.current_offset + prevMeta.paginate.current_limit;
                break;
            case CommentPaginateType.NEXT:
                offset = Math.max(nextMeta.paginate.current_offset - nextMeta.paginate.current_limit, 0);
                limit = nextMeta.paginate.current_offset;
                break;
            default:
                if (payload.isAutoLoad) {
                    offset = 0;
                    limit = prevMeta.paginate.current_offset + prevMeta.paginate.current_limit;
                }
        }
        BoardApiClient.fetchBoardPostComments(payload.projectId, payload.boardPostId, payload.targetId, offset, limit)
            .then(function (res) {
            if (payload.isInitialize || payload.isAutoLoad) {
                commit(BoardMutationTypes.INIT_BOARD_POST_COMMENTS, res.data);
            }
            else {
                payload.fetchType === CommentPaginateType.PREV
                    ? commit(BoardMutationTypes.FETCH_BOARD_POST_PREV_COMMENTS, res.data)
                    : commit(BoardMutationTypes.FETCH_BOARD_POST_NEXT_COMMENTS, res.data);
            }
            for (var i = 0; i < res.data.board_post_comments.length; i++) {
                var comment = res.data.board_post_comments[i];
                var params = {
                    projectId: payload.projectId,
                    boardPostId: payload.boardPostId,
                    boardPostCommentId: comment.id
                };
                dispatch('fetchBoardPostCommentConfirmedUsers', params);
            }
        })
            .catch(function (e) {
            var statusCode = e.response && e.response.status;
            var data = e.response && e.response.data;
            if (payload.targetId && data && statusCode === 422) {
                showErrorMessage('コメントが見つかりませんでした', data);
            }
            delete payload.targetId;
            var initPayload = Object.assign({ isInitialize: true }, payload);
            dispatch('fetchBoardPostComments', initPayload);
        });
    },
    createBoardPostComment: function (_a, payload) {
        var dispatch = _a.dispatch;
        return __awaiter(this, void 0, void 0, function () {
            var fetchContentsPayload;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, BoardApiClient.createBoardPostComment(payload.projectId, payload.boardPostId, payload.formData, payload.contentIds)];
                    case 1:
                        _b.sent();
                        return [4 /*yield*/, dispatch('fetchBoardPostUsers', payload)];
                    case 2:
                        _b.sent();
                        return [4 /*yield*/, dispatch('fetchBoardPostComments', payload)];
                    case 3:
                        _b.sent();
                        if (payload.contentIds.length) {
                            fetchContentsPayload = {
                                params: {
                                    board_post_id: payload.boardPostId
                                }
                            };
                            dispatch('initBoardPostContents', fetchContentsPayload);
                        }
                        return [2 /*return*/];
                }
            });
        });
    },
    updateBoardPostComment: function (_a, payload) {
        var getters = _a.getters, commit = _a.commit, dispatch = _a.dispatch;
        return __awaiter(this, void 0, void 0, function () {
            var res, beforeUpdateComment, beforeUpdateCommentContentIds, fetchContentsPayload;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, BoardApiClient.updateBoardPostComment(payload.projectId, payload.boardPostId, payload.boardPostCommentId, payload.formData, payload.contentIds)];
                    case 1:
                        res = _b.sent();
                        beforeUpdateComment = getters.boardPostComments.find(function (comment) { return comment.id === payload.boardPostCommentId; });
                        commit(BoardMutationTypes.UPDATE_BOARD_POST_COMMENT, res.data.board_post_comment);
                        if (!beforeUpdateComment) {
                            return [2 /*return*/];
                        }
                        beforeUpdateCommentContentIds = beforeUpdateComment.contents.map(function (content) { return content.id; });
                        if (beforeUpdateCommentContentIds !== payload.contentIds) {
                            fetchContentsPayload = {
                                params: {
                                    board_post_id: payload.boardPostId
                                }
                            };
                            dispatch('initBoardPostContents', fetchContentsPayload);
                        }
                        return [2 /*return*/];
                }
            });
        });
    },
    deleteBoardPostComment: function (_a, payload) {
        var getters = _a.getters, commit = _a.commit, dispatch = _a.dispatch;
        return __awaiter(this, void 0, void 0, function () {
            var beforeDeleteComment, fetchContentsPayload;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        BoardApiClient.deleteBoardPostComment(payload.projectId, payload.boardPostId, payload.boardPostCommentId);
                        beforeDeleteComment = getters.boardPostComments.find(function (comment) { return comment.id === payload.boardPostCommentId; });
                        commit(BoardMutationTypes.DELETE_BOARD_POST_COMMENT, payload.boardPostCommentId);
                        if (!beforeDeleteComment) {
                            return [2 /*return*/];
                        }
                        if (!beforeDeleteComment.contents.length) return [3 /*break*/, 2];
                        fetchContentsPayload = {
                            params: {
                                board_post_id: payload.boardPostId
                            }
                        };
                        return [4 /*yield*/, dispatch('initBoardPostContents', fetchContentsPayload)];
                    case 1:
                        _b.sent();
                        _b.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    },
    fetchBoardPostMentionableUsers: function (_a, payload) {
        var commit = _a.commit;
        return __awaiter(this, void 0, void 0, function () {
            var res;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, BoardApiClient.fetchBoardPostMentionableUsers(payload.projectId, payload.boardPostId)];
                    case 1:
                        res = _b.sent();
                        commit(BoardMutationTypes.FETCH_BOARD_POST_MENTIONABLE_USERS, res.data.mentionable_users);
                        return [2 /*return*/];
                }
            });
        });
    },
    confirmBoardPost: function (_a, payload) {
        var commit = _a.commit, dispatch = _a.dispatch;
        return __awaiter(this, void 0, void 0, function () {
            var res;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, BoardApiClient.confirmBoardPost(payload.projectId, payload.boardPostId)];
                    case 1:
                        res = _b.sent();
                        commit(BoardMutationTypes.CONFIRM_BOARD_POST, res.data.board_post);
                        dispatch('fetchBoardPostUsers', payload);
                        return [2 /*return*/];
                }
            });
        });
    },
    confirmBoardPostComment: function (_a, payload) {
        var commit = _a.commit;
        return __awaiter(this, void 0, void 0, function () {
            var res, storeObject;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, BoardApiClient.confirmBoardPostComment(payload.projectId, payload.boardPostId, payload.boardPostCommentId)];
                    case 1:
                        _b.sent();
                        return [4 /*yield*/, BoardApiClient.fetchBoardPostCommentConfirmedUsers(payload.projectId, payload.boardPostId, payload.boardPostCommentId)];
                    case 2:
                        res = _b.sent();
                        storeObject = {
                            board_post_comment_id: payload.boardPostCommentId,
                            users: res.data.confirmed_users
                        };
                        commit(BoardMutationTypes.CONFIRM_BOARD_POST_COMMENT, storeObject);
                        return [2 /*return*/];
                }
            });
        });
    },
    fetchBoardPostCommentConfirmedUsers: function (_a, payload) {
        var commit = _a.commit;
        return __awaiter(this, void 0, void 0, function () {
            var res, storeObject;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, BoardApiClient.fetchBoardPostCommentConfirmedUsers(payload.projectId, payload.boardPostId, payload.boardPostCommentId)];
                    case 1:
                        res = _b.sent();
                        storeObject = {
                            board_post_comment_id: payload.boardPostCommentId,
                            users: res.data.confirmed_users
                        };
                        commit(BoardMutationTypes.FETCH_BOARD_POST_COMMENTS_CONFIRMED_USERS, storeObject);
                        return [2 /*return*/];
                }
            });
        });
    },
    initBoardPostContents: function (_a, payload) {
        var commit = _a.commit;
        return __awaiter(this, void 0, void 0, function () {
            var params, defaultLimit, fetchParams, res;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        params = payload.params;
                        defaultLimit = 25;
                        fetchParams = __assign({ limit: defaultLimit, sort: {
                                target: 'name',
                                order: 'asc'
                            } }, params);
                        return [4 /*yield*/, BoardApiClient.searchContents(fetchParams)];
                    case 1:
                        res = _b.sent();
                        if (!(res.data.meta.paginate.total_count > res.data.contents.length)) return [3 /*break*/, 3];
                        fetchParams.limit = res.data.meta.paginate.total_count;
                        return [4 /*yield*/, BoardApiClient.searchContents(fetchParams)];
                    case 2:
                        res = _b.sent();
                        _b.label = 3;
                    case 3:
                        commit(BoardMutationTypes.INIT_BOARD_POST_CONTENTS, res.data.contents);
                        commit(BoardMutationTypes.UPDATE_BOARD_POST_CONTENTS_LENGTH, res.data.meta.paginate.total_count);
                        return [2 /*return*/];
                }
            });
        });
    },
    fetchBoardPostContents: function (_a, payload) {
        var commit = _a.commit, state = _a.state;
        return __awaiter(this, void 0, void 0, function () {
            var params, fetchParams, res;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        params = payload.params;
                        fetchParams = __assign({ offset: state.board_post_contents.length, sort: {
                                target: 'name',
                                order: 'asc'
                            } }, params);
                        return [4 /*yield*/, BoardApiClient.searchContents(fetchParams)];
                    case 1:
                        res = _b.sent();
                        commit(BoardMutationTypes.FETCH_BOARD_POST_CONTENTS, res.data.contents);
                        commit(BoardMutationTypes.UPDATE_BOARD_POST_CONTENTS_LENGTH, res.data.meta.paginate.total_count);
                        return [2 /*return*/];
                }
            });
        });
    },
    retryFetchBoardPostContents: function (_a, payload) {
        var commit = _a.commit, rootGetters = _a.rootGetters;
        return __awaiter(this, void 0, void 0, function () {
            var params, res;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        params = {
                            plan_code: rootGetters['initial-setting/plan'].code,
                            offset: 0,
                            ids: payload
                        };
                        return [4 /*yield*/, ContentApiClient.searchContents(params)];
                    case 1:
                        res = _b.sent();
                        commit(BoardMutationTypes.RETRY_FETCH_BOARD_POST_CONTENTS, res.data.contents);
                        return [2 /*return*/];
                }
            });
        });
    },
    retryFetchHomeBoardPostContents: function (_a, payload) {
        var commit = _a.commit, rootGetters = _a.rootGetters;
        return __awaiter(this, void 0, void 0, function () {
            var params, res;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        params = {
                            plan_code: rootGetters['initial-setting/plan'].code,
                            offset: 0,
                            ids: payload
                        };
                        return [4 /*yield*/, ContentApiClient.searchContents(params)];
                    case 1:
                        res = _b.sent();
                        commit(BoardMutationTypes.RETRY_FETCH_HOME_BOARD_POST_CONTENTS, res.data.contents);
                        return [2 /*return*/];
                }
            });
        });
    },
    retryFetchBoardPostsContents: function (_a, payload) {
        var commit = _a.commit, rootGetters = _a.rootGetters;
        return __awaiter(this, void 0, void 0, function () {
            var params, res;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        params = {
                            plan_code: rootGetters['initial-setting/plan'].code,
                            offset: 0,
                            ids: payload
                        };
                        return [4 /*yield*/, ContentApiClient.searchContents(params)];
                    case 1:
                        res = _b.sent();
                        commit(BoardMutationTypes.RETRY_FETCH_BOARD_POSTS_CONTENTS, res.data.contents);
                        return [2 /*return*/];
                }
            });
        });
    },
    retryFetchBoardPostCommentContents: function (_a, payload) {
        var commit = _a.commit, rootGetters = _a.rootGetters;
        return __awaiter(this, void 0, void 0, function () {
            var params, res;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        params = {
                            plan_code: rootGetters['initial-setting/plan'].code,
                            offset: 0,
                            ids: payload
                        };
                        return [4 /*yield*/, ContentApiClient.searchContents(params)];
                    case 1:
                        res = _b.sent();
                        commit(BoardMutationTypes.RETRY_FETCH_BOARD_POST_COMMENT_CONTENTS, res.data.contents);
                        return [2 /*return*/];
                }
            });
        });
    },
    retryFetchBoardListContents: function (_a, payload) {
        var commit = _a.commit, rootGetters = _a.rootGetters;
        return __awaiter(this, void 0, void 0, function () {
            var params, res;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        params = {
                            plan_code: rootGetters['initial-setting/plan'].code,
                            offset: 0,
                            ids: payload
                        };
                        return [4 /*yield*/, ContentApiClient.searchContents(params)];
                    case 1:
                        res = _b.sent();
                        commit(BoardMutationTypes.RETRY_FETCH_BOARD_LIST_CONTENTS, res.data.contents);
                        return [2 /*return*/];
                }
            });
        });
    },
    attachPostInitCategoryId: function (_a, payload) {
        var commit = _a.commit;
        commit(BoardMutationTypes.ATTACH_POST_INIT_CATEGORY_ID, payload);
    },
    isAfterCreated: function (_a, payload) {
        var commit = _a.commit;
        commit(BoardMutationTypes.IS_AFTER_CREATED, payload);
    }
};
