import { Routes } from '@/types/routes';
export default (function (context) {
    var app = context.app;
    app.router &&
        app.router.beforeEach(function (to, from, next) {
            if (to.name === Routes.SessionCallbackPage.name &&
                from.name === Routes.ProjectListPage.name) {
                next("/" + Routes.SessionSignInPage.name);
            }
            else {
                next();
            }
        });
});
