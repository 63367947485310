var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _a;
import Firebase from 'firebase/app';
import { NotificationMutationTypes } from '@/types/notification';
import notificationApiClient from '@/plugins/apis/notification-api-client';
import checkFirebaseAuthAndDoAction from '@/plugins/check-firebase-auth-and-do-action';
import { FirestoreCollectionNames } from '@/types';
export var state = function () { return ({
    is_show_loading: false,
    is_fetch_error: false,
    notifications: [],
    providerNotifications: [],
    notificationsMeta: {
        current_limit: 0,
        unsubscribe_function: function () { }
    },
    notificationUserBadge: {
        notification_unread: false
    },
    announcements: []
}); };
export var getters = {
    isShowLoading: function (state) {
        return state.is_show_loading;
    },
    isFetchError: function (state) {
        return state.is_fetch_error;
    },
    notifications: function (state) {
        return state.notifications.filter(function (notification) {
            return notification.via.includes('onService');
        });
    },
    providerNotifications: function (state) {
        return state.providerNotifications;
    },
    notificationUserBadge: function (state) {
        return state.notificationUserBadge;
    },
    announcements: function (state) {
        return state.announcements;
    }
};
export var mutations = (_a = {},
    _a[NotificationMutationTypes.IS_SHOW_LOADING] = function (state, payload) {
        state.is_show_loading = payload;
    },
    _a[NotificationMutationTypes.IS_FETCH_ERROR] = function (state, payload) {
        state.is_fetch_error = payload;
    },
    _a[NotificationMutationTypes.INIT_NOTIFICATIONS] = function (state, payload) {
        state.notifications = payload.notifications;
        state.notificationsMeta = payload.meta;
    },
    _a[NotificationMutationTypes.INIT_PROVIDER_NOTIFICATIONS] = function (state, payload) {
        state.providerNotifications = payload;
    },
    _a[NotificationMutationTypes.UPDATE_NOTIFICATION_USER_BADGE] = function (state, payload) {
        state.notificationUserBadge = payload;
    },
    _a[NotificationMutationTypes.FETCH_ANNOUNCEMENTS] = function (state, payload) {
        state.announcements = payload;
    },
    _a);
export var actions = {
    isShowLoading: function (_a, payload) {
        var commit = _a.commit;
        commit(NotificationMutationTypes.IS_SHOW_LOADING, payload);
    },
    isFetchError: function (_a, payload) {
        var commit = _a.commit;
        commit(NotificationMutationTypes.IS_FETCH_ERROR, payload);
    },
    initUserNotifications: function (_a) {
        var state = _a.state, dispatch = _a.dispatch, commit = _a.commit, rootGetters = _a.rootGetters;
        dispatch('isShowLoading', true);
        dispatch('isFetchError', false);
        state.notificationsMeta.unsubscribe_function();
        var limit = state.notificationsMeta.current_limit + 10;
        try {
            var uid_1 = rootGetters['initial-setting/user'].uid;
            checkFirebaseAuthAndDoAction(function () {
                var unsubscribe = Firebase.firestore()
                    .collection(FirestoreCollectionNames.NOTIFICATIONS)
                    .where('uids', 'array-contains', uid_1)
                    .orderBy('created_at', 'desc')
                    .limit(limit)
                    .onSnapshot(function (snapshot) {
                    if (!snapshot.docs.length)
                        return;
                    var notifications = snapshot.docs.map(function (doc) {
                        var data = doc.data();
                        return {
                            id: doc.id,
                            project_id: data.project_id,
                            board_post_id: data.board_post_id,
                            board_post_comment_id: data.board_post_comment_id,
                            content_comment_id: data.content_comment_id,
                            logo: data.logo,
                            body: data.body,
                            url: data.url,
                            important: data.important,
                            type: data.type,
                            via: data.via,
                            created_at: data.created_at.toDate(),
                            read: data.read_uids.includes(uid_1)
                        };
                    });
                    commit(NotificationMutationTypes.INIT_NOTIFICATIONS, {
                        notifications: notifications,
                        meta: {
                            current_limit: limit,
                            unsubscribe_function: unsubscribe
                        }
                    });
                    dispatch('isShowLoading', false);
                });
            }, rootGetters['initial-setting/company'].id);
        }
        catch (_b) {
            dispatch('isShowLoading', false);
            dispatch('isFetchError', true);
        }
    },
    initProviderNotifications: function (_a) {
        var commit = _a.commit;
        return __awaiter(this, void 0, void 0, function () {
            var articles;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, notificationApiClient.fetchProviderNotifications()];
                    case 1:
                        articles = (_b.sent()).data.articles;
                        commit(NotificationMutationTypes.INIT_PROVIDER_NOTIFICATIONS, articles);
                        return [2 /*return*/];
                }
            });
        });
    },
    readNotification: function (_a, id) {
        var dispatch = _a.dispatch;
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, notificationApiClient.readNotification(id)];
                    case 1:
                        _b.sent();
                        dispatch('initProviderNotifications');
                        return [2 /*return*/];
                }
            });
        });
    },
    readAllNotification: function (_a) {
        var dispatch = _a.dispatch;
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, notificationApiClient.readAllNotification()];
                    case 1:
                        _b.sent();
                        dispatch('initProviderNotifications');
                        return [2 /*return*/];
                }
            });
        });
    },
    addListenerNotificationUserBadge: function (_a, uid) {
        var commit = _a.commit, dispatch = _a.dispatch;
        return __awaiter(this, void 0, void 0, function () {
            var collection;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, Firebase.firestore().collection('user_badges')];
                    case 1:
                        collection = _b.sent();
                        collection.doc(uid).onSnapshot(function (document) {
                            commit(NotificationMutationTypes.UPDATE_NOTIFICATION_USER_BADGE, document.data());
                            dispatch('initProviderNotifications');
                        });
                        return [2 /*return*/];
                }
            });
        });
    },
    updateNotificationUserBadge: function (_, payload) {
        return __awaiter(this, void 0, void 0, function () {
            var collection;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, Firebase.firestore().collection('user_badges')];
                    case 1:
                        collection = _b.sent();
                        collection.doc(payload.uid).update((_a = {},
                            _a[payload.notification_name] = false,
                            _a));
                        return [2 /*return*/];
                }
            });
        });
    },
    fetchAnnouncements: function (_a, uid) {
        var commit = _a.commit;
        return __awaiter(this, void 0, void 0, function () {
            var collection;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, Firebase.firestore().collection('user_badges')];
                    case 1:
                        collection = _b.sent();
                        collection
                            .doc(uid)
                            .collection('announcements')
                            .where('unread', '==', true)
                            .onSnapshot(function (ref) {
                            if (ref) {
                                var badges_1 = [];
                                ref.docs.forEach(function (doc) {
                                    badges_1.push(doc.data());
                                });
                                commit(NotificationMutationTypes.FETCH_ANNOUNCEMENTS, badges_1);
                            }
                        });
                        return [2 /*return*/];
                }
            });
        });
    },
    readAnnouncement: function (_, _a) {
        var uid = _a.uid, id = _a.id;
        return __awaiter(this, void 0, void 0, function () {
            var collection;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, Firebase.firestore().collection('user_badges')];
                    case 1:
                        collection = _b.sent();
                        return [2 /*return*/, collection
                                .doc(uid)
                                .collection('announcements')
                                .doc("" + id)
                                .set({
                                unread: false
                            }, { merge: true })
                                .catch(function (e) { return console.log(e); })];
                }
            });
        });
    },
    allReadAnnouncements: function (_, uid) {
        return __awaiter(this, void 0, void 0, function () {
            var firestore, collection, announcements, batch;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, Firebase.firestore()];
                    case 1:
                        firestore = _a.sent();
                        collection = firestore.collection('user_badges');
                        return [4 /*yield*/, collection
                                .doc(uid)
                                .collection('announcements')
                                .get()];
                    case 2:
                        announcements = _a.sent();
                        batch = firestore.batch();
                        announcements.docs.forEach(function (announcement) {
                            batch.update(announcement.ref, {
                                unread: false
                            });
                        });
                        return [2 /*return*/, batch.commit()];
                }
            });
        });
    }
};
