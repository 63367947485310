var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
var _a;
import Firebase from 'firebase/app';
import ShowErrorMessage from '@/plugins/show-error-message';
import scheduleApiClient from '@/plugins/apis/v2/schedule-api-client';
import { ScheduleMutationTypes } from '@/types/schedule';
import { DownloadType, FirestoreCollectionNames } from '@/types';
import aboveProcessRows from '@/plugins/above-process-rows';
import downloadFile from '@/plugins/download-file';
import checkFirebaseAuthAndDoAction from '@/plugins/check-firebase-auth-and-do-action';
export var state = function () { return ({
    schedule: {
        id: '',
        project_id: '',
        published: false,
        published_schedule_id: '',
        start_date: '',
        end_date: '',
        project_title: '',
        updated_date: '',
        policies: {}
    },
    large_processes: [],
    small_processes: [],
    exist_schedule: false,
    exist_published_schedule: false,
    published_schedule: {
        id: '',
        project_id: '',
        start_date: '',
        end_date: '',
        project_title: '',
        updated_date: '',
        policies: {}
    },
    print_settings: null,
    schedules: [],
    published_schedules: [],
    published_large_processes: [],
    published_small_processes: [],
    schedule_plans: [],
    published_schedule_plans: [],
    // gantt chart
    gantt_width: 0,
    gantt_chart_position: {
        position_x: 0,
        position_y: 0
    },
    large_process_filter_statuses: ['before', 'under', 'after'],
    sidemenu_height: 0,
    is_schedule: true,
    is_opened_status_dialog: false,
    is_opened_schedule_plan: true,
    is_opened_any_list_item: true
}); };
export var getters = {
    schedule: function (state) {
        return state.schedule;
    },
    schedules: function (state) {
        return state.schedules;
    },
    largeProcesses: function (state) {
        return state.large_processes;
    },
    smallProcesses: function (state) {
        return state.small_processes;
    },
    publishedSchedules: function (state) {
        return state.published_schedules;
    },
    publishedSchedule: function (state) {
        return state.published_schedule;
    },
    publishedLargeProcesses: function (state) {
        return state.published_large_processes;
    },
    publishedSmallProcesses: function (state) {
        return state.published_small_processes;
    },
    filteredLargeProcesses: function (state) {
        var _a;
        var filterIds = ((_a = state.print_settings) === null || _a === void 0 ? void 0 : _a.large_process_ids) || [];
        return state.published_large_processes.filter(function (_a) {
            var lpId = _a.id;
            return filterIds.includes(lpId);
        });
    },
    printSettings: function (state) {
        return state.print_settings;
    },
    schedulePlans: function (state) {
        return state.schedule_plans;
    },
    publishedSchedulePlans: function (state) {
        return state.published_schedule_plans;
    },
    existSchedule: function (state) {
        return state.exist_schedule;
    },
    existPublishedSchedule: function (state) {
        return state.exist_published_schedule;
    },
    scheduleAchievementRate: function () {
        // TODO: v2で使わないと思うので後で削除
        return {
            rate: 0,
            afterCount: 0,
            processCount: 0
        };
    },
    largeProcessesAchievementRate: function () {
        // TODO: v2で使わないと思うので後で削除
        return [];
    },
    largeProcessesName: function (state) {
        if (!state.large_processes.length) {
            return [];
        }
        return state.large_processes.map(function (largeProcess) {
            return largeProcess.name;
        });
    },
    ganttChartWidth: function (state) {
        return state.gantt_width;
    },
    ganttChartPosition: function (state) {
        return state.gantt_chart_position;
    },
    sidemenuHeight: function (state) {
        return state.sidemenu_height;
    },
    isSchedule: function (state) {
        return state.is_schedule;
    },
    isOpenedStatusDialog: function (state) {
        return state.is_opened_status_dialog;
    },
    scheduleIncludeChanged: function (state) {
        return state.schedule;
    },
    isScheduleChanged: function (_a) {
        var large_processes = _a.large_processes, small_processes = _a.small_processes, schedule_plans = _a.schedule_plans, published_large_processes = _a.published_large_processes, published_small_processes = _a.published_small_processes, published_schedule_plans = _a.published_schedule_plans;
        return (__spreadArrays(large_processes, small_processes, schedule_plans).some(function (_a) {
            var published = _a.published;
            return !published;
        }) ||
            large_processes.length !== published_large_processes.length ||
            small_processes.length !== published_small_processes.length ||
            schedule_plans.length !== published_schedule_plans.length);
    },
    isOpenedSchedulePlan: function (state) {
        return state.is_opened_schedule_plan;
    },
    isOpenAnyItem: function (state) {
        var isOpenedSchedulePlanListItem = state.is_opened_schedule_plan;
        var isOpenedLargeProcessListItem;
        if (state.is_schedule) {
            isOpenedLargeProcessListItem = state.large_processes.some(function (largeProcess) { return largeProcess.is_opened; });
        }
        else {
            isOpenedLargeProcessListItem = state.published_large_processes.some(function (largeProcess) { return largeProcess.is_opened; });
        }
        return isOpenedSchedulePlanListItem || isOpenedLargeProcessListItem;
    }
};
export var mutations = (_a = {},
    _a[ScheduleMutationTypes.FETCH_SCHEDULE] = function (state, payload) {
        state.schedule = payload;
    },
    _a[ScheduleMutationTypes.FETCH_SCHEDULES] = function (state, payload) {
        state.schedules = payload;
    },
    _a[ScheduleMutationTypes.FETCH_PUBLISHED_SCHEDULE] = function (state, payload) {
        state.published_schedule = payload;
    },
    _a[ScheduleMutationTypes.FETCH_PRINT_SETTINGS] = function (state, payload) {
        state.print_settings = payload;
    },
    _a[ScheduleMutationTypes.FETCH_PUBLISHED_SCHEDULES] = function (state, payload) {
        state.published_schedules = payload;
    },
    _a[ScheduleMutationTypes.SORT_LARGE_PROCESSES] = function (state, _a) {
        var newIndex = _a.newIndex, oldIndex = _a.oldIndex;
        var target = state.large_processes[oldIndex];
        target.published = false;
        delete state.large_processes[oldIndex];
        state.large_processes = state.large_processes.filter(Boolean);
        state.large_processes.splice(newIndex, 0, target);
        state.large_processes.forEach(function (process, index) {
            process.sort_num = index + 1;
        });
    },
    _a[ScheduleMutationTypes.FETCH_LARGE_PROCESSES] = function (state, payload) {
        state.large_processes = payload;
    },
    _a[ScheduleMutationTypes.FETCH_SMALL_PROCESSES] = function (state, payload) {
        state.small_processes = payload;
    },
    _a[ScheduleMutationTypes.FETCH_PUBLISHED_LARGE_PROCESSES] = function (state, payload) {
        state.published_large_processes = payload;
    },
    _a[ScheduleMutationTypes.FETCH_PUBLISHED_SMALL_PROCESSES] = function (state, payload) {
        state.published_small_processes = payload;
    },
    _a[ScheduleMutationTypes.CREATE_SCHEDULE_PLAN] = function (state, payload) {
        state.schedule_plans.push(payload);
    },
    _a[ScheduleMutationTypes.FETCH_SCHEDULE_PLANS] = function (state, payload) {
        state.schedule_plans = payload;
    },
    _a[ScheduleMutationTypes.UPDATE_SCHEDULE_PLAN] = function (state, payload) {
        var ids = payload.map(function (_a) {
            var id = _a.id;
            return id;
        });
        var filteredPlan = state.schedule_plans.filter(function (_a) {
            var id = _a.id;
            return !ids.includes(id);
        });
        state.schedule_plans = filteredPlan.concat(payload);
    },
    _a[ScheduleMutationTypes.DELETE_SCHEDULE_PLAN] = function (state, _a) {
        var deletedPlanId = _a.deletedPlanId, displayPlan = _a.displayPlan;
        var deleteIndex = state.schedule_plans.findIndex(function (_a) {
            var id = _a.id;
            return id === deletedPlanId;
        });
        if (deleteIndex !== -1)
            state.schedule_plans.splice(deleteIndex, 1);
        if (displayPlan) {
            var replaceIndex = state.schedule_plans.findIndex(function (_a) {
                var id = _a.id;
                return id === displayPlan.id;
            });
            if (replaceIndex !== -1)
                state.schedule_plans.splice(replaceIndex, 1, displayPlan);
        }
    },
    _a[ScheduleMutationTypes.FETCH_PUBLISHED_SCHEDULE_PLANS] = function (state, payload) {
        state.published_schedule_plans = payload;
    },
    _a[ScheduleMutationTypes.EXIST_SCHEDULE] = function (state, payload) {
        state.exist_schedule = payload;
    },
    _a[ScheduleMutationTypes.EXIST_PUBLISHED_SCHEDULE] = function (state, payload) {
        state.exist_published_schedule = payload;
    },
    _a[ScheduleMutationTypes.DELETE_SCHEDULE] = function (_state) {
        var _a = state(), schedule = _a.schedule, published_schedule = _a.published_schedule, schedule_plans = _a.schedule_plans, published_schedule_plans = _a.published_schedule_plans, print_settings = _a.print_settings;
        _state.schedule = schedule;
        _state.published_schedule = published_schedule;
        _state.print_settings = print_settings;
        _state.schedule_plans = schedule_plans;
        _state.published_schedule_plans = published_schedule_plans;
        _state.large_processes = [];
        _state.published_large_processes = [];
        _state.small_processes = [];
        _state.published_small_processes = [];
        _state.exist_schedule = false;
    },
    _a[ScheduleMutationTypes.CREATE_PUBLISHED_SCHEDULE] = function (state, payload) {
        state.published_schedule = payload;
        state.published_large_processes = payload.published_large_processes;
        state.published_small_processes = payload.published_small_processes;
        state.published_schedule_plans = payload.published_schedule_plans;
        state.schedule.published = true;
        state.schedule.published_schedule_id = payload.id;
        if (payload.print_settings) {
            state.print_settings = __assign(__assign({}, payload.print_settings), { id: payload.id });
        }
        state.large_processes.forEach(function (largeProcess) {
            largeProcess.published = true;
        });
        state.small_processes.forEach(function (smallProcess) {
            smallProcess.published = true;
        });
        state.schedule_plans.forEach(function (plan) {
            plan.published = true;
        });
    },
    _a[ScheduleMutationTypes.UPDATE_PUBLISHED_SCHEDULE] = function (state, payload) {
        state.published_schedule = payload;
        state.published_large_processes = payload.published_large_processes;
        state.published_small_processes = payload.published_small_processes;
        state.published_schedule_plans = payload.published_schedule_plans;
        state.large_processes.forEach(function (largeProcess) {
            largeProcess.published = true;
        });
        state.small_processes.forEach(function (smallProcess) {
            smallProcess.published = true;
        });
        state.schedule_plans.forEach(function (plan) {
            plan.published = true;
        });
    },
    _a[ScheduleMutationTypes.CREATE_LARGE_PROCESS] = function (state, payload) {
        var _a;
        payload.forEach(function (process) { return (process.is_opened = true); });
        (_a = state.large_processes).push.apply(_a, payload);
    },
    _a[ScheduleMutationTypes.UPDATE_LARGE_PROCESS] = function (state, payload) {
        if (!state.large_processes.length)
            return;
        var targetLargeProcess = state.large_processes.find(function (largeProcess) {
            return largeProcess.id === payload.id;
        });
        if (!targetLargeProcess)
            return;
        targetLargeProcess.name = payload.name;
        targetLargeProcess.color = payload.color;
        targetLargeProcess.published = payload.published;
    },
    _a[ScheduleMutationTypes.DELETE_LARGE_PROCESS] = function (state, payload) {
        if (!state.large_processes.length)
            return;
        state.large_processes = state.large_processes.filter(function (largeProcess) {
            return largeProcess.id !== payload;
        });
        state.small_processes = state.small_processes.filter(function (smallProcess) {
            return smallProcess.large_process_id !== payload;
        });
    },
    _a[ScheduleMutationTypes.CREATE_SMALL_PROCESS] = function (state, payload) {
        if (!state.large_processes.length)
            return;
        payload.forEach(function (process) {
            state.small_processes.push(process);
        });
    },
    _a[ScheduleMutationTypes.UPDATE_SMALL_PROCESS] = function (state, payload) {
        if (!state.large_processes.length)
            return;
        var targetSmallProcess = state.small_processes.find(function (smallProcess) {
            return smallProcess.id === payload.id;
        });
        if (!targetSmallProcess)
            return;
        targetSmallProcess.name = payload.name;
        targetSmallProcess.start_date = payload.start_date;
        targetSmallProcess.end_date = payload.end_date;
        targetSmallProcess.row_position = payload.row_position;
        targetSmallProcess.published = payload.published;
    },
    _a[ScheduleMutationTypes.DELETE_SMALL_PROCESS] = function (state, payload) {
        if (!state.large_processes.length)
            return;
        state.small_processes = state.small_processes.filter(function (smallProcess) {
            return smallProcess.id !== payload.id;
        });
        var filteredProcesses = state.small_processes.filter(function (sp) { return sp.large_process_id === payload.large_process_id; });
        if (!filteredProcesses.length)
            return;
        var rowPositions = aboveProcessRows(filteredProcesses);
        state.small_processes.forEach(function (sp) {
            var data = rowPositions.find(function (data) { return data.small_process_id === sp.id; });
            if (!data)
                return;
            sp.row_position = data.row_position;
        });
    },
    _a[ScheduleMutationTypes.CHANGE_GANTT_WIDTH] = function (state, payload) {
        state.gantt_width = payload;
    },
    _a[ScheduleMutationTypes.CHANGE_GANTT_CHART_POSITION] = function (state, payload) {
        state.gantt_chart_position = payload;
    },
    _a[ScheduleMutationTypes.UPDATE_LARGE_PROCESS_FILETER_STATUSES] = function (status, payload) {
        status.large_process_filter_statuses = payload;
    },
    _a[ScheduleMutationTypes.CHANGE_SIDEMENU_HEIGHT] = function (state, payload) {
        state.sidemenu_height = payload;
    },
    _a[ScheduleMutationTypes.CHANGE_IS_SCHEDULE_STATUS] = function (state, payload) {
        state.is_schedule = payload;
    },
    _a[ScheduleMutationTypes.CHANGE_IS_OPENED_STATUS_DIALOG] = function (state, payload) {
        state.is_opened_status_dialog = payload;
    },
    _a[ScheduleMutationTypes.UPDATE_SCHEDULE_PLANS_OPENED] = function (state) {
        state.is_opened_schedule_plan = !state.is_opened_schedule_plan;
    },
    _a[ScheduleMutationTypes.UPDATE_LARGE_PROCESS_OPENED] = function (state, payload) {
        state.large_processes.forEach(function (largeProcess) {
            if (payload.includes(largeProcess.id)) {
                largeProcess.is_opened = !largeProcess.is_opened;
            }
        });
    },
    _a[ScheduleMutationTypes.UPDATE_PUBLISHED_LARGE_PROCESS_OPENED] = function (state, payload) {
        state.published_large_processes.forEach(function (largeProcess) {
            if (payload.includes(largeProcess.id)) {
                largeProcess.is_opened = !largeProcess.is_opened;
            }
        });
    },
    _a[ScheduleMutationTypes.UPDATE_IS_OPENED_ANY_LIST_ITEM] = function (state, payload) {
        if (state.is_schedule) {
            var updatedLargeProcess_1 = [];
            state.large_processes.forEach(function (largeProcess) {
                largeProcess.is_opened = !payload;
                updatedLargeProcess_1.push(largeProcess);
            });
            state.large_processes = updatedLargeProcess_1;
        }
        else {
            var updatedPublishedLargeProcess_1 = [];
            state.published_large_processes.forEach(function (largeProcess) {
                largeProcess.is_opened = !payload;
                updatedPublishedLargeProcess_1.push(largeProcess);
            });
            state.published_large_processes = updatedPublishedLargeProcess_1;
        }
        state.is_opened_schedule_plan = !payload;
        state.is_opened_any_list_item = !payload;
    },
    _a);
export var actions = {
    fetchSchedule: function (_a, payload) {
        var commit = _a.commit;
        return __awaiter(this, void 0, void 0, function () {
            var res, e_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, scheduleApiClient.fetchSchedule(payload.project_id)];
                    case 1:
                        res = _b.sent();
                        commit(ScheduleMutationTypes.FETCH_SCHEDULE, res.data.schedule);
                        commit(ScheduleMutationTypes.EXIST_SCHEDULE, true);
                        return [3 /*break*/, 3];
                    case 2:
                        e_1 = _b.sent();
                        if (e_1.response.status === 404) {
                            // 別案件のScheduleが残っているケースなどを対策
                            commit(ScheduleMutationTypes.FETCH_SCHEDULE, state().schedule);
                            commit(ScheduleMutationTypes.EXIST_SCHEDULE, false);
                            return [2 /*return*/];
                        }
                        throw e_1;
                    case 3: return [2 /*return*/];
                }
            });
        });
    },
    sortLargeProcesses: function (_a, payload) {
        var commit = _a.commit, state = _a.state;
        var newIndex = payload.newIndex, oldIndex = payload.oldIndex, project_id = payload.project_id, schedule_id = payload.schedule_id;
        var targetProcess = state.large_processes[oldIndex];
        var prevProcessId = '0';
        if (newIndex > 0) {
            if (oldIndex < newIndex) {
                prevProcessId = state.large_processes[newIndex].id;
            }
            else {
                prevProcessId = state.large_processes[newIndex - 1].id;
            }
        }
        scheduleApiClient
            .updateLargeProcess(project_id, schedule_id, targetProcess.id, targetProcess.name, targetProcess.color, prevProcessId)
            .catch(function () {
            commit(ScheduleMutationTypes.SORT_LARGE_PROCESSES, {
                newIndex: oldIndex,
                oldIndex: newIndex
            });
        });
        commit(ScheduleMutationTypes.SORT_LARGE_PROCESSES, payload);
    },
    fetchLargeProcesses: function (_a, payload) {
        var commit = _a.commit;
        return __awaiter(this, void 0, void 0, function () {
            var res, openedLargeProcess;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, scheduleApiClient.fetchLargeProcesses(payload.project_id, payload.schedule_id)];
                    case 1:
                        res = _b.sent();
                        openedLargeProcess = res.data.large_processes.map(function (largeProcess) {
                            largeProcess.is_opened = true;
                            return largeProcess;
                        });
                        commit(ScheduleMutationTypes.FETCH_LARGE_PROCESSES, openedLargeProcess);
                        return [2 /*return*/];
                }
            });
        });
    },
    fetchSmallProcesses: function (_a, payload) {
        var commit = _a.commit;
        return __awaiter(this, void 0, void 0, function () {
            var res;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, scheduleApiClient.fetchSmallProcesses(payload.project_id, payload.schedule_id)];
                    case 1:
                        res = _b.sent();
                        commit(ScheduleMutationTypes.FETCH_SMALL_PROCESSES, res.data.small_processes);
                        return [2 /*return*/];
                }
            });
        });
    },
    fetchPublishedSchedule: function (_a, payload) {
        var commit = _a.commit;
        return __awaiter(this, void 0, void 0, function () {
            var published_schedule, e_2;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, scheduleApiClient.fetchPublishedSchedule(payload.project_id)];
                    case 1:
                        published_schedule = (_b.sent()).data.published_schedule;
                        commit(ScheduleMutationTypes.FETCH_PUBLISHED_SCHEDULE, published_schedule);
                        commit(ScheduleMutationTypes.EXIST_PUBLISHED_SCHEDULE, true);
                        commit(ScheduleMutationTypes.FETCH_PRINT_SETTINGS, published_schedule.print_settings);
                        return [3 /*break*/, 3];
                    case 2:
                        e_2 = _b.sent();
                        if (e_2.response.status === 404) {
                            commit(ScheduleMutationTypes.EXIST_PUBLISHED_SCHEDULE, false);
                            // 別案件のPublishedScheduleが残っているケースなどを対策
                            commit(ScheduleMutationTypes.FETCH_PUBLISHED_SCHEDULE, state().published_schedule);
                            commit(ScheduleMutationTypes.FETCH_PRINT_SETTINGS, state().print_settings);
                            return [2 /*return*/];
                        }
                        throw e_2;
                    case 3: return [2 /*return*/];
                }
            });
        });
    },
    fetchPublishedSchedules: function (_a) {
        var commit = _a.commit;
        return __awaiter(this, void 0, void 0, function () {
            var res;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, scheduleApiClient.fetchPublishedSchedules()];
                    case 1:
                        res = _b.sent();
                        commit(ScheduleMutationTypes.FETCH_PUBLISHED_SCHEDULES, res.data.published_schedules);
                        return [2 /*return*/];
                }
            });
        });
    },
    fetchPublishedLargeProcesses: function (_a, payload) {
        var commit = _a.commit;
        return __awaiter(this, void 0, void 0, function () {
            var res, openedProcesses;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, scheduleApiClient.fetchPublishedLargeProcesses(payload.project_id, payload.published_schedule_id)];
                    case 1:
                        res = _b.sent();
                        openedProcesses = res.data.published_large_processes.map(function (largeProcess) {
                            largeProcess.is_opened = true;
                            return largeProcess;
                        });
                        commit(ScheduleMutationTypes.FETCH_PUBLISHED_LARGE_PROCESSES, openedProcesses);
                        return [2 /*return*/];
                }
            });
        });
    },
    fetchPublishedSmallProcesses: function (_a, payload) {
        var commit = _a.commit;
        return __awaiter(this, void 0, void 0, function () {
            var res;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, scheduleApiClient.fetchPublishedSmallProcesses(payload.project_id, payload.published_schedule_id)];
                    case 1:
                        res = _b.sent();
                        commit(ScheduleMutationTypes.FETCH_PUBLISHED_SMALL_PROCESSES, res.data.published_small_processes);
                        return [2 /*return*/];
                }
            });
        });
    },
    fetchSchedulePlans: function (_a, payload) {
        var commit = _a.commit;
        return __awaiter(this, void 0, void 0, function () {
            var res;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, scheduleApiClient.fetchSchedulePlans(payload.project_id, payload.schedule_id)];
                    case 1:
                        res = _b.sent();
                        commit(ScheduleMutationTypes.FETCH_SCHEDULE_PLANS, res.data.schedule_plans);
                        return [2 /*return*/];
                }
            });
        });
    },
    fetchPublishedSchedulePlans: function (_a, payload) {
        var commit = _a.commit;
        return __awaiter(this, void 0, void 0, function () {
            var res;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        commit(ScheduleMutationTypes.FETCH_PUBLISHED_SCHEDULE_PLANS, state().published_schedule_plans);
                        return [4 /*yield*/, scheduleApiClient.fetchPublishedSchedulePlans(payload.project_id, payload.published_schedule_id)];
                    case 1:
                        res = _b.sent();
                        commit(ScheduleMutationTypes.FETCH_PUBLISHED_SCHEDULE_PLANS, res.data.published_schedule_plans);
                        return [2 /*return*/];
                }
            });
        });
    },
    createSchedule: function (_a, payload) {
        var commit = _a.commit;
        return __awaiter(this, void 0, void 0, function () {
            var res;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, scheduleApiClient.createSchedule(payload.project_id, payload.data)];
                    case 1:
                        res = _b.sent();
                        commit(ScheduleMutationTypes.FETCH_SCHEDULE, res.data.schedule);
                        commit(ScheduleMutationTypes.FETCH_LARGE_PROCESSES, res.data.schedule.large_processes.map(function (lp) { return (__assign(__assign({}, lp), { is_opened: true })); }));
                        commit(ScheduleMutationTypes.FETCH_SMALL_PROCESSES, res.data.schedule.small_processes.map(function (sp) { return (__assign(__assign({}, sp), { is_opened: true })); }));
                        commit(ScheduleMutationTypes.FETCH_SCHEDULE_PLANS, res.data.schedule.schedule_plans);
                        commit(ScheduleMutationTypes.EXIST_SCHEDULE, true);
                        return [2 /*return*/];
                }
            });
        });
    },
    deleteSchedule: function (_a, payload) {
        var commit = _a.commit;
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, scheduleApiClient.deleteSchedule(payload.project_id)];
                    case 1:
                        _b.sent();
                        commit(ScheduleMutationTypes.DELETE_SCHEDULE);
                        return [2 /*return*/];
                }
            });
        });
    },
    createPublishedSchedule: function (_a, payload) {
        var commit = _a.commit;
        return __awaiter(this, void 0, void 0, function () {
            var res;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, scheduleApiClient.createPublishedSchedule(payload.project_id)];
                    case 1:
                        res = _b.sent();
                        commit(ScheduleMutationTypes.CREATE_PUBLISHED_SCHEDULE, res.data.published_schedule);
                        return [2 /*return*/];
                }
            });
        });
    },
    updatePublishedSchedule: function (_a, payload) {
        var commit = _a.commit;
        return __awaiter(this, void 0, void 0, function () {
            var res;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, scheduleApiClient.updatePublishedSchedule(payload.project_id)];
                    case 1:
                        res = _b.sent();
                        commit(ScheduleMutationTypes.UPDATE_PUBLISHED_SCHEDULE, res.data.published_schedule);
                        return [2 /*return*/];
                }
            });
        });
    },
    createLargeProcess: function (_a, payload) {
        var _b, _c, _d;
        var commit = _a.commit;
        return __awaiter(this, void 0, void 0, function () {
            var res, e_3, nameErrors, message;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        _e.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, scheduleApiClient.createLargeProcess(payload.project_id, payload.schedule_id, payload.data)];
                    case 1:
                        res = _e.sent();
                        commit(ScheduleMutationTypes.CREATE_LARGE_PROCESS, res.data.large_processes);
                        return [2 /*return*/, true];
                    case 2:
                        e_3 = _e.sent();
                        nameErrors = (_d = (_c = (_b = e_3.response.data.large_process) === null || _b === void 0 ? void 0 : _b.errors) === null || _c === void 0 ? void 0 : _c.messages) === null || _d === void 0 ? void 0 : _d.name;
                        message = nameErrors ? nameErrors[0] : '入力内容を確認して下さい';
                        ShowErrorMessage('大工程の作成に失敗しました', {}, message);
                        return [2 /*return*/, false];
                    case 3: return [2 /*return*/];
                }
            });
        });
    },
    updateLargeProcess: function (_a, payload) {
        var _b, _c, _d;
        var commit = _a.commit, getters = _a.getters;
        return __awaiter(this, void 0, void 0, function () {
            var targetLargeProcess, oldName, assignedTargetLargeProcess, res, e_4, nameErrors, message;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        targetLargeProcess = getters.largeProcesses.find(function (_a) {
                            var id = _a.id;
                            return id === payload.id;
                        });
                        oldName = '';
                        if (targetLargeProcess)
                            oldName = targetLargeProcess.name;
                        assignedTargetLargeProcess = Object.assign({}, targetLargeProcess);
                        assignedTargetLargeProcess.name = payload.name;
                        assignedTargetLargeProcess.color = payload.color;
                        commit(ScheduleMutationTypes.UPDATE_LARGE_PROCESS, assignedTargetLargeProcess);
                        _e.label = 1;
                    case 1:
                        _e.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, scheduleApiClient.updateLargeProcess(payload.project_id, payload.schedule_id, payload.id, payload.name, payload.color)];
                    case 2:
                        res = _e.sent();
                        commit(ScheduleMutationTypes.UPDATE_LARGE_PROCESS, res.data.large_process);
                        return [3 /*break*/, 4];
                    case 3:
                        e_4 = _e.sent();
                        if (targetLargeProcess) {
                            assignedTargetLargeProcess.name = oldName;
                            commit(ScheduleMutationTypes.UPDATE_LARGE_PROCESS, assignedTargetLargeProcess);
                        }
                        nameErrors = (_d = (_c = (_b = e_4.response.data.large_process) === null || _b === void 0 ? void 0 : _b.errors) === null || _c === void 0 ? void 0 : _c.messages) === null || _d === void 0 ? void 0 : _d.name;
                        message = nameErrors ? nameErrors[0] : '入力内容を確認して下さい';
                        ShowErrorMessage('大工程の更新に失敗しました', {}, message);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    },
    deleteLargeProcess: function (_a, payload) {
        var commit = _a.commit;
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, scheduleApiClient.deleteLargeProcess(payload.project_id, payload.schedule_id, payload.id)];
                    case 1:
                        _b.sent();
                        commit(ScheduleMutationTypes.DELETE_LARGE_PROCESS, payload.id);
                        return [2 /*return*/];
                }
            });
        });
    },
    createSmallProcess: function (_a, payload) {
        var commit = _a.commit;
        return __awaiter(this, void 0, void 0, function () {
            var res;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, scheduleApiClient.createSmallProcess(payload.project_id, payload.schedule_id, payload.large_process_id, payload.data)];
                    case 1:
                        res = _b.sent();
                        commit(ScheduleMutationTypes.CREATE_SMALL_PROCESS, res.data.small_processes);
                        return [2 /*return*/];
                }
            });
        });
    },
    updateSmallProcess: function (_a, payload) {
        var commit = _a.commit;
        return __awaiter(this, void 0, void 0, function () {
            var res;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, scheduleApiClient.updateSmallProcess(payload.project_id, payload.schedule_id, payload.large_process_id, payload.id, payload.data, payload.moving_below_process_ids, payload.moving_above_process_ids)];
                    case 1:
                        res = _b.sent();
                        commit(ScheduleMutationTypes.UPDATE_SMALL_PROCESS, res.data.small_process);
                        return [2 /*return*/];
                }
            });
        });
    },
    deleteSmallProcess: function (_a, payload) {
        var commit = _a.commit;
        return __awaiter(this, void 0, void 0, function () {
            var object;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, scheduleApiClient.deleteSmallProcess(payload.project_id, payload.schedule_id, payload.large_process_id, payload.id)];
                    case 1:
                        _b.sent();
                        object = {
                            large_process_id: payload.large_process_id,
                            id: payload.id
                        };
                        commit(ScheduleMutationTypes.DELETE_SMALL_PROCESS, object);
                        return [2 /*return*/];
                }
            });
        });
    },
    createSchedulePlan: function (_a, payload) {
        var commit = _a.commit;
        return __awaiter(this, void 0, void 0, function () {
            var res;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, scheduleApiClient.createSchedulePlan(payload.project_id, payload.schedule_id, payload.data)];
                    case 1:
                        res = _b.sent();
                        commit(ScheduleMutationTypes.CREATE_SCHEDULE_PLAN, res.data.schedule_plan);
                        return [2 /*return*/];
                }
            });
        });
    },
    updateSchedulePlan: function (_a, payload) {
        var commit = _a.commit;
        return __awaiter(this, void 0, void 0, function () {
            var res;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, scheduleApiClient.updateSchedulePlan(payload.project_id, payload.schedule_id, payload.id, payload.data)];
                    case 1:
                        res = _b.sent();
                        commit(ScheduleMutationTypes.UPDATE_SCHEDULE_PLAN, res.data.schedule_plans);
                        return [2 /*return*/];
                }
            });
        });
    },
    deleteSchedulePlan: function (_a, payload) {
        var commit = _a.commit;
        return __awaiter(this, void 0, void 0, function () {
            var res, planDiff;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, scheduleApiClient.deleteSchedulePlan(payload.project_id, payload.schedule_id, payload.id)];
                    case 1:
                        res = _b.sent();
                        planDiff = {
                            deletedPlanId: payload.id,
                            displayPlan: res.data.schedule_plan
                        };
                        commit(ScheduleMutationTypes.DELETE_SCHEDULE_PLAN, planDiff);
                        return [2 /*return*/];
                }
            });
        });
    },
    changeGanttChartWidth: function (_a, payload) {
        var commit = _a.commit;
        commit(ScheduleMutationTypes.CHANGE_GANTT_WIDTH, payload);
    },
    changeGanttChartPosition: function (_a, payload) {
        var commit = _a.commit;
        commit(ScheduleMutationTypes.CHANGE_GANTT_CHART_POSITION, payload);
    },
    updateLargeProcessFilterStatuses: function (_a, payload) {
        var commit = _a.commit;
        commit(ScheduleMutationTypes.UPDATE_LARGE_PROCESS_FILETER_STATUSES, payload);
    },
    changeSidemenuHeight: function (_a, payload) {
        var commit = _a.commit;
        commit(ScheduleMutationTypes.CHANGE_SIDEMENU_HEIGHT, payload);
    },
    changeIsScheduleStatus: function (_a, payload) {
        var commit = _a.commit;
        commit(ScheduleMutationTypes.CHANGE_IS_SCHEDULE_STATUS, payload);
    },
    changeIsOpenedStatusDialog: function (_a, payload) {
        var commit = _a.commit;
        commit(ScheduleMutationTypes.CHANGE_IS_OPENED_STATUS_DIALOG, payload);
    },
    downloadPDF: function (_a, payload) {
        var _this = this;
        var dispatch = _a.dispatch, rootGetters = _a.rootGetters;
        dispatch(
        // @ts-ignore
        'enqueueDownload', {
            name: payload.project_title,
            promise: (function () { return __awaiter(_this, void 0, void 0, function () {
                var document_id;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, scheduleApiClient.downloadPDF(payload.project_id, {
                                start_date: payload.start_date,
                                end_date: payload.end_date
                            })];
                        case 1:
                            document_id = (_a.sent()).data.document_id;
                            return [2 /*return*/, new Promise(function (resolve) {
                                    checkFirebaseAuthAndDoAction(function () {
                                        var unsubscribe = Firebase.firestore()
                                            .collection(FirestoreCollectionNames.STATUS_SCHEDULE_PDF_GENERATE)
                                            .doc(document_id)
                                            .onSnapshot(function (doc) {
                                            var data = doc.data();
                                            if (!data)
                                                return;
                                            unsubscribe();
                                            resolve(downloadFile(data.url, 'application/pdf', payload.project_title + ".pdf"));
                                        });
                                    }, rootGetters['initial-setting/company'].id);
                                })];
                    }
                });
            }); })(),
            id: payload.id,
            type: DownloadType.schedule
        }, 
        // @ts-ignore
        { root: true });
    },
    updateSchedulePlansOpened: function (_a) {
        var commit = _a.commit;
        commit(ScheduleMutationTypes.UPDATE_SCHEDULE_PLANS_OPENED);
    },
    updateLargeProcessOpened: function (_a, payload) {
        var commit = _a.commit;
        commit(ScheduleMutationTypes.UPDATE_LARGE_PROCESS_OPENED, payload);
    },
    updatePublishedLargeProcessOpened: function (_a, payload) {
        var commit = _a.commit;
        commit(ScheduleMutationTypes.UPDATE_PUBLISHED_LARGE_PROCESS_OPENED, payload);
    },
    updateIsOpenAnyItem: function (_a) {
        var commit = _a.commit, getters = _a.getters;
        commit(ScheduleMutationTypes.UPDATE_IS_OPENED_ANY_LIST_ITEM, getters.isOpenAnyItem);
    },
    changeRowPositions: function (_a, payload) {
        var commit = _a.commit, getters = _a.getters;
        var smallProcesses = getters.smallProcesses;
        commit(ScheduleMutationTypes.FETCH_SMALL_PROCESSES, smallProcesses.map(function (sp) {
            var data = payload.find(function (data) { return data.small_process_id === sp.id; });
            if (!data)
                return sp;
            return __assign(__assign({}, sp), { row_position: data.row_position });
        }));
    },
    updateSchedulePrintSettings: function (_a, payload) {
        var commit = _a.commit, state = _a.state;
        return __awaiter(this, void 0, void 0, function () {
            var _b, project_id, id, published_schedule;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _b = state.published_schedule, project_id = _b.project_id, id = _b.id;
                        return [4 /*yield*/, scheduleApiClient.updatePrintSettings(project_id, __assign({}, payload))];
                    case 1:
                        published_schedule = (_c.sent()).data.published_schedule;
                        if (published_schedule.print_settings) {
                            commit(ScheduleMutationTypes.FETCH_PRINT_SETTINGS, __assign(__assign({}, published_schedule.print_settings), { id: id }));
                        }
                        return [2 /*return*/];
                }
            });
        });
    }
};
