var timer;
export default (function (callback, milliseconds) {
    if (milliseconds === void 0) { milliseconds = 800; }
    if (timer) {
        window.clearTimeout(timer);
    }
    timer = window.setTimeout(function () {
        callback();
    }, milliseconds);
});
