import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'

const _ffd5c8e6 = () => interopDefault(import('../src/pages/companies/index.vue' /* webpackChunkName: "pages/companies/index" */))
const _4c83862a = () => interopDefault(import('../src/pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _98f19554 = () => interopDefault(import('../src/pages/sign_in.vue' /* webpackChunkName: "pages/sign_in" */))
const _9b754612 = () => interopDefault(import('../src/pages/initial-settings/company-profile.vue' /* webpackChunkName: "pages/initial-settings/company-profile" */))
const _19333727 = () => interopDefault(import('../src/pages/initial-settings/invite-members.vue' /* webpackChunkName: "pages/initial-settings/invite-members" */))
const _cbada9d4 = () => interopDefault(import('../src/pages/initial-settings/preferences.vue' /* webpackChunkName: "pages/initial-settings/preferences" */))
const _7f40800e = () => interopDefault(import('../src/pages/initial-settings/user-profile.vue' /* webpackChunkName: "pages/initial-settings/user-profile" */))
const _50e3c1b4 = () => interopDefault(import('../src/pages/sessions/callback.vue' /* webpackChunkName: "pages/sessions/callback" */))
const _3a4b1e3a = () => interopDefault(import('../src/pages/sessions/invitation.vue' /* webpackChunkName: "pages/sessions/invitation" */))
const _42b6976e = () => interopDefault(import('../src/pages/contents/_uuid/index.vue' /* webpackChunkName: "pages/contents/_uuid/index" */))
const _724e1a6b = () => interopDefault(import('../src/pages/companies/_company_id/construction-kinds.vue' /* webpackChunkName: "pages/companies/_company_id/construction-kinds" */))
const _93e9e108 = () => interopDefault(import('../src/pages/companies/_company_id/construction-kinds/new.vue' /* webpackChunkName: "pages/companies/_company_id/construction-kinds/new" */))
const _e5610f06 = () => interopDefault(import('../src/pages/companies/_company_id/customers.vue' /* webpackChunkName: "pages/companies/_company_id/customers" */))
const _d3a9d6e4 = () => interopDefault(import('../src/pages/companies/_company_id/customers/new.vue' /* webpackChunkName: "pages/companies/_company_id/customers/new" */))
const _eba52faa = () => interopDefault(import('../src/pages/companies/_company_id/customers/_id/index.vue' /* webpackChunkName: "pages/companies/_company_id/customers/_id/index" */))
const _d208ec7e = () => interopDefault(import('../src/pages/companies/_company_id/customers/_id/edit.vue' /* webpackChunkName: "pages/companies/_company_id/customers/_id/edit" */))
const _4f4e13b7 = () => interopDefault(import('../src/pages/companies/_company_id/expiration.vue' /* webpackChunkName: "pages/companies/_company_id/expiration" */))
const _21238741 = () => interopDefault(import('../src/pages/companies/_company_id/members.vue' /* webpackChunkName: "pages/companies/_company_id/members" */))
const _4f9f005e = () => interopDefault(import('../src/pages/companies/_company_id/profile.vue' /* webpackChunkName: "pages/companies/_company_id/profile" */))
const _78a1bd05 = () => interopDefault(import('../src/pages/companies/_company_id/projects/index.vue' /* webpackChunkName: "pages/companies/_company_id/projects/index" */))
const _a8d56bac = () => interopDefault(import('../src/pages/companies/_company_id/reports/index.vue' /* webpackChunkName: "pages/companies/_company_id/reports/index" */))
const _735f4e10 = () => interopDefault(import('../src/pages/companies/_company_id/setting.vue' /* webpackChunkName: "pages/companies/_company_id/setting" */))
const _888e7f94 = () => interopDefault(import('../src/pages/companies/_company_id/sharing-contents/index.vue' /* webpackChunkName: "pages/companies/_company_id/sharing-contents/index" */))
const _4420ecfc = () => interopDefault(import('../src/pages/companies/_company_id/unavailability.vue' /* webpackChunkName: "pages/companies/_company_id/unavailability" */))
const _aa3fa2a2 = () => interopDefault(import('../src/pages/companies/_company_id/user-profile.vue' /* webpackChunkName: "pages/companies/_company_id/user-profile" */))
const _4ccb1193 = () => interopDefault(import('../src/pages/companies/_company_id/projects/new.vue' /* webpackChunkName: "pages/companies/_company_id/projects/new" */))
const _63f86a66 = () => interopDefault(import('../src/pages/companies/_company_id/files/_id/index.vue' /* webpackChunkName: "pages/companies/_company_id/files/_id/index" */))
const _39153cad = () => interopDefault(import('../src/pages/companies/_company_id/projects/_id.vue' /* webpackChunkName: "pages/companies/_company_id/projects/_id" */))
const _1e9acd70 = () => interopDefault(import('../src/pages/companies/_company_id/projects/_id/index.vue' /* webpackChunkName: "pages/companies/_company_id/projects/_id/index" */))
const _0807fc4a = () => interopDefault(import('../src/pages/companies/_company_id/projects/_id/board_posts/index.vue' /* webpackChunkName: "pages/companies/_company_id/projects/_id/board_posts/index" */))
const _4d11f023 = () => interopDefault(import('../src/pages/companies/_company_id/projects/_id/detail.vue' /* webpackChunkName: "pages/companies/_company_id/projects/_id/detail" */))
const _3ae615ec = () => interopDefault(import('../src/pages/companies/_company_id/projects/_id/member.vue' /* webpackChunkName: "pages/companies/_company_id/projects/_id/member" */))
const _7751e346 = () => interopDefault(import('../src/pages/companies/_company_id/projects/_id/reports.vue' /* webpackChunkName: "pages/companies/_company_id/projects/_id/reports" */))
const _4921c4ac = () => interopDefault(import('../src/pages/companies/_company_id/projects/_id/schedule/index.vue' /* webpackChunkName: "pages/companies/_company_id/projects/_id/schedule/index" */))
const _767c93e9 = () => interopDefault(import('../src/pages/companies/_company_id/projects/_id/board_posts/new.vue' /* webpackChunkName: "pages/companies/_company_id/projects/_id/board_posts/new" */))
const _2574bddf = () => interopDefault(import('../src/pages/companies/_company_id/projects/_id/board_posts/posts/index.vue' /* webpackChunkName: "pages/companies/_company_id/projects/_id/board_posts/posts/index" */))
const _139c4fd1 = () => interopDefault(import('../src/pages/companies/_company_id/projects/_id/schedule/fullscreen.vue' /* webpackChunkName: "pages/companies/_company_id/projects/_id/schedule/fullscreen" */))
const _29ad8c22 = () => interopDefault(import('../src/pages/companies/_company_id/projects/_id/schedule/preview.vue' /* webpackChunkName: "pages/companies/_company_id/projects/_id/schedule/preview" */))
const _b225636c = () => interopDefault(import('../src/pages/companies/_company_id/projects/_id/schedule/print/index.vue' /* webpackChunkName: "pages/companies/_company_id/projects/_id/schedule/print/index" */))
const _14f16304 = () => interopDefault(import('../src/pages/companies/_company_id/projects/_id/schedule/view/index.vue' /* webpackChunkName: "pages/companies/_company_id/projects/_id/schedule/view/index" */))
const _e6c3cd82 = () => interopDefault(import('../src/pages/companies/_company_id/projects/_id/schedule/view/fullscreen.vue' /* webpackChunkName: "pages/companies/_company_id/projects/_id/schedule/view/fullscreen" */))
const _0a6d8802 = () => interopDefault(import('../src/pages/companies/_company_id/projects/_id/board_posts/_board_id/index.vue' /* webpackChunkName: "pages/companies/_company_id/projects/_id/board_posts/_board_id/index" */))
const _5a8d06a8 = () => interopDefault(import('../src/pages/companies/_company_id/projects/_id/files/_file_id/index.vue' /* webpackChunkName: "pages/companies/_company_id/projects/_id/files/_file_id/index" */))
const _57282926 = () => interopDefault(import('../src/pages/companies/_company_id/projects/_id/board_posts/_board_id/edit.vue' /* webpackChunkName: "pages/companies/_company_id/projects/_id/board_posts/_board_id/edit" */))
const _2ce7e2b2 = () => interopDefault(import('../src/pages/companies/_company_id/projects/_id/board_posts/_board_id/files/index.vue' /* webpackChunkName: "pages/companies/_company_id/projects/_id/board_posts/_board_id/files/index" */))
const _784a6120 = () => interopDefault(import('../src/pages/companies/_company_id/projects/_id/files/_file_id/edit.vue' /* webpackChunkName: "pages/companies/_company_id/projects/_id/files/_file_id/edit" */))
const _a79177bc = () => interopDefault(import('../src/pages/companies/_company_id/projects/_id/files/_file_id/preview.vue' /* webpackChunkName: "pages/companies/_company_id/projects/_id/files/_file_id/preview" */))
const _65094015 = () => interopDefault(import('../src/pages/companies/_company_id/reports/_id/index.vue' /* webpackChunkName: "pages/companies/_company_id/reports/_id/index" */))
const _5f5d4adf = () => interopDefault(import('../src/pages/companies/_company_id/files/_id/edit.vue' /* webpackChunkName: "pages/companies/_company_id/files/_id/edit" */))
const _03b2e3fa = () => interopDefault(import('../src/pages/companies/_company_id/files/_id/preview.vue' /* webpackChunkName: "pages/companies/_company_id/files/_id/preview" */))
const _32b00c97 = () => interopDefault(import('../src/pages/companies/_company_id/reports/_id/edit.vue' /* webpackChunkName: "pages/companies/_company_id/reports/_id/edit" */))
const _4f8026f5 = () => interopDefault(import('../src/pages/companies/_company_id/reports/_id/pdf.vue' /* webpackChunkName: "pages/companies/_company_id/reports/_id/pdf" */))
const _33d53c26 = () => interopDefault(import('../src/pages/companies/_company_id/project/_id/schedule/pdf.vue' /* webpackChunkName: "pages/companies/_company_id/project/_id/schedule/pdf" */))
const _a30208fe = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))

Vue.use(Router)

if (process.client) {
  if ('scrollRestoration' in window.history) {
    window.history.scrollRestoration = 'manual'

    // reset scrollRestoration to auto when leaving page, allowing page reload
    // and back-navigation from other pages to use the browser to restore the
    // scrolling position.
    window.addEventListener('beforeunload', () => {
      window.history.scrollRestoration = 'auto'
    })

    // Setting scrollRestoration to manual again when returning to this page.
    window.addEventListener('load', () => {
      window.history.scrollRestoration = 'manual'
    })
  }
}
const scrollBehavior = function (to, from, savedPosition) {
  // if the returned position is falsy or an empty object,
  // will retain current scroll position.
  let position = false

  // if no children detected and scrollToTop is not explicitly disabled
  if (
    to.matched.length < 2 &&
    to.matched.every(r => r.components.default.options.scrollToTop !== false)
  ) {
    // scroll to the top of the page
    position = { x: 0, y: 0 }
  } else if (to.matched.some(r => r.components.default.options.scrollToTop)) {
    // if one of the children has scrollToTop option set to true
    position = { x: 0, y: 0 }
  }

  // savedPosition is only available for popstate navigations (back button)
  if (savedPosition) {
    position = savedPosition
  }

  return new Promise((resolve) => {
    // wait for the out transition to complete (if necessary)
    window.$nuxt.$once('triggerScroll', () => {
      // coords will be used if no selector is provided,
      // or if the selector didn't match any element.
      if (to.hash) {
        let hash = to.hash
        // CSS.escape() is not supported with IE and Edge.
        if (typeof window.CSS !== 'undefined' && typeof window.CSS.escape !== 'undefined') {
          hash = '#' + window.CSS.escape(hash.substr(1))
        }
        try {
          if (document.querySelector(hash)) {
            // scroll to anchor by returning the selector
            position = { selector: hash }
          }
        } catch (e) {
          console.warn('Failed to save scroll position. Please add CSS.escape() polyfill (https://github.com/mathiasbynens/CSS.escape).')
        }
      }
      resolve(position)
    })
  })
}

export function createRouter() {
  return new Router({
    mode: 'history',
    base: decodeURI('/'),
    linkActiveClass: 'nuxt-link-active',
    linkExactActiveClass: 'nuxt-link-exact-active',
    scrollBehavior,

    routes: [{
      path: "/companies",
      component: _ffd5c8e6,
      name: "companies"
    }, {
      path: "/logout",
      component: _4c83862a,
      name: "logout"
    }, {
      path: "/sign_in",
      component: _98f19554,
      name: "sign_in"
    }, {
      path: "/initial-settings/company-profile",
      component: _9b754612,
      name: "initial-settings-company-profile"
    }, {
      path: "/initial-settings/invite-members",
      component: _19333727,
      name: "initial-settings-invite-members"
    }, {
      path: "/initial-settings/preferences",
      component: _cbada9d4,
      name: "initial-settings-preferences"
    }, {
      path: "/initial-settings/user-profile",
      component: _7f40800e,
      name: "initial-settings-user-profile"
    }, {
      path: "/sessions/callback",
      component: _50e3c1b4,
      name: "sessions-callback"
    }, {
      path: "/sessions/invitation",
      component: _3a4b1e3a,
      name: "sessions-invitation"
    }, {
      path: "/contents/:uuid?",
      component: _42b6976e,
      name: "contents-uuid"
    }, {
      path: "/companies/:company_id/construction-kinds",
      component: _724e1a6b,
      name: "companies-company_id-construction-kinds",
      children: [{
        path: "new",
        component: _93e9e108,
        name: "companies-company_id-construction-kinds-new"
      }]
    }, {
      path: "/companies/:company_id/customers",
      component: _e5610f06,
      name: "companies-company_id-customers",
      children: [{
        path: "new",
        component: _d3a9d6e4,
        name: "companies-company_id-customers-new"
      }, {
        path: ":id",
        component: _eba52faa,
        name: "companies-company_id-customers-id"
      }, {
        path: ":id/edit",
        component: _d208ec7e,
        name: "companies-company_id-customers-id-edit"
      }]
    }, {
      path: "/companies/:company_id/expiration",
      component: _4f4e13b7,
      name: "companies-company_id-expiration"
    }, {
      path: "/companies/:company_id/members",
      component: _21238741,
      name: "companies-company_id-members"
    }, {
      path: "/companies/:company_id/profile",
      component: _4f9f005e,
      name: "companies-company_id-profile"
    }, {
      path: "/companies/:company_id/projects",
      component: _78a1bd05,
      name: "companies-company_id-projects"
    }, {
      path: "/companies/:company_id/reports",
      component: _a8d56bac,
      name: "companies-company_id-reports"
    }, {
      path: "/companies/:company_id/setting",
      component: _735f4e10,
      name: "companies-company_id-setting"
    }, {
      path: "/companies/:company_id/sharing-contents",
      component: _888e7f94,
      name: "companies-company_id-sharing-contents"
    }, {
      path: "/companies/:company_id/unavailability",
      component: _4420ecfc,
      name: "companies-company_id-unavailability"
    }, {
      path: "/companies/:company_id/user-profile",
      component: _aa3fa2a2,
      name: "companies-company_id-user-profile"
    }, {
      path: "/companies/:company_id/projects/new",
      component: _4ccb1193,
      name: "companies-company_id-projects-new"
    }, {
      path: "/companies/:company_id/files/:id?",
      component: _63f86a66,
      name: "companies-company_id-files-id"
    }, {
      path: "/companies/:company_id/projects/:id",
      component: _39153cad,
      children: [{
        path: "",
        component: _1e9acd70,
        name: "companies-company_id-projects-id"
      }, {
        path: "board_posts",
        component: _0807fc4a,
        name: "companies-company_id-projects-id-board_posts"
      }, {
        path: "detail",
        component: _4d11f023,
        name: "companies-company_id-projects-id-detail"
      }, {
        path: "member",
        component: _3ae615ec,
        name: "companies-company_id-projects-id-member"
      }, {
        path: "reports",
        component: _7751e346,
        name: "companies-company_id-projects-id-reports"
      }, {
        path: "schedule",
        component: _4921c4ac,
        name: "companies-company_id-projects-id-schedule"
      }, {
        path: "board_posts/new",
        component: _767c93e9,
        name: "companies-company_id-projects-id-board_posts-new"
      }, {
        path: "board_posts/posts",
        component: _2574bddf,
        name: "companies-company_id-projects-id-board_posts-posts"
      }, {
        path: "schedule/fullscreen",
        component: _139c4fd1,
        name: "companies-company_id-projects-id-schedule-fullscreen"
      }, {
        path: "schedule/preview",
        component: _29ad8c22,
        name: "companies-company_id-projects-id-schedule-preview"
      }, {
        path: "schedule/print",
        component: _b225636c,
        name: "companies-company_id-projects-id-schedule-print"
      }, {
        path: "schedule/view",
        component: _14f16304,
        name: "companies-company_id-projects-id-schedule-view"
      }, {
        path: "schedule/view/fullscreen",
        component: _e6c3cd82,
        name: "companies-company_id-projects-id-schedule-view-fullscreen"
      }, {
        path: "board_posts/:board_id",
        component: _0a6d8802,
        name: "companies-company_id-projects-id-board_posts-board_id"
      }, {
        path: "files/:file_id",
        component: _5a8d06a8,
        name: "companies-company_id-projects-id-files-file_id"
      }, {
        path: "board_posts/:board_id/edit",
        component: _57282926,
        name: "companies-company_id-projects-id-board_posts-board_id-edit"
      }, {
        path: "board_posts/:board_id/files",
        component: _2ce7e2b2,
        name: "companies-company_id-projects-id-board_posts-board_id-files"
      }, {
        path: "files/:file_id/edit",
        component: _784a6120,
        name: "companies-company_id-projects-id-files-file_id-edit"
      }, {
        path: "files/:file_id/preview",
        component: _a79177bc,
        name: "companies-company_id-projects-id-files-file_id-preview"
      }]
    }, {
      path: "/companies/:company_id/reports/:id",
      component: _65094015,
      name: "companies-company_id-reports-id"
    }, {
      path: "/companies/:company_id/files/:id?/edit",
      component: _5f5d4adf,
      name: "companies-company_id-files-id-edit"
    }, {
      path: "/companies/:company_id/files/:id?/preview",
      component: _03b2e3fa,
      name: "companies-company_id-files-id-preview"
    }, {
      path: "/companies/:company_id/reports/:id/edit",
      component: _32b00c97,
      name: "companies-company_id-reports-id-edit"
    }, {
      path: "/companies/:company_id/reports/:id/pdf",
      component: _4f8026f5,
      name: "companies-company_id-reports-id-pdf"
    }, {
      path: "/companies/:company_id/project/:id?/schedule/pdf",
      component: _33d53c26,
      name: "companies-company_id-project-id-schedule-pdf"
    }, {
      path: "/",
      component: _a30208fe,
      name: "index"
    }],

    fallback: false
  })
}
