var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _a;
import { SearchCustomerMutationTypes } from '@/types/search/customer';
import customerApiClient from '@/plugins/apis/customer-api-client';
export var state = function () { return ({
    defaultSort: {
        prop: 'name',
        order: 'desc'
    },
    customers: [],
    paginate: {
        current_limit: 0,
        current_offset: 0,
        total_count: 0
    },
    searchForm: {
        name: ''
    }
}); };
export var getters = {
    customers: function (state) {
        return state.customers;
    },
    defaultSort: function (state) {
        return state.defaultSort;
    },
    paginate: function (state) {
        return state.paginate;
    },
    searchForm: function (state) {
        return state.searchForm;
    }
};
export var mutations = (_a = {},
    _a[SearchCustomerMutationTypes.INIT_STATE] = function (state, payload) {
        Object.assign(state, payload);
    },
    _a[SearchCustomerMutationTypes.SET_SORT_TYPE] = function (state, payload) {
        state.defaultSort = payload;
    },
    _a[SearchCustomerMutationTypes.SET_SEARCH_FORM] = function (state, payload) {
        state.searchForm = payload;
    },
    _a[SearchCustomerMutationTypes.RESET_LIST] = function (state) {
        state.customers = [];
    },
    _a[SearchCustomerMutationTypes.SEARCH_CUSTOMERS] = function (state, _a) {
        var customers = _a.customers, paginate = _a.paginate;
        state.customers = customers;
        state.paginate = paginate;
    },
    _a);
export var actions = {
    initState: function (_a) {
        var commit = _a.commit;
        commit(SearchCustomerMutationTypes.INIT_STATE, state());
    },
    changeSort: function (_a, payload) {
        var dispatch = _a.dispatch, commit = _a.commit;
        commit(SearchCustomerMutationTypes.SET_SORT_TYPE, payload);
        return dispatch('resetList');
    },
    setSearchForm: function (_a, payload) {
        var commit = _a.commit;
        commit(SearchCustomerMutationTypes.SET_SEARCH_FORM, payload);
    },
    resetList: function (_a) {
        var commit = _a.commit;
        commit(SearchCustomerMutationTypes.RESET_LIST);
    },
    searchCustomers: function (_a, payload) {
        var commit = _a.commit;
        return __awaiter(this, void 0, void 0, function () {
            var res, _b, customers, paginate;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0: return [4 /*yield*/, customerApiClient.searchCustomers(payload)];
                    case 1:
                        res = _c.sent();
                        _b = res.data, customers = _b.customers, paginate = _b.meta.paginate;
                        commit(SearchCustomerMutationTypes.SEARCH_CUSTOMERS, {
                            customers: customers,
                            paginate: paginate
                        });
                        return [2 /*return*/];
                }
            });
        });
    }
};
