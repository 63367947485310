export var MemberMutationTypes;
(function (MemberMutationTypes) {
    MemberMutationTypes["INIT_STATE"] = "INIT_STATE";
    MemberMutationTypes["FETCH_MEMBERS"] = "FETCH_MEMBERS";
    MemberMutationTypes["FETCH_INVITED_USERS"] = "FETCH_INVITE_USERS";
    MemberMutationTypes["UPDATE_MEMBER"] = "UPDATE_MEMBER";
    MemberMutationTypes["REMOVE_MEMBER"] = "REMOVE_MEMBER";
    MemberMutationTypes["CANCEL_INVITATION"] = "CANCEL_INVITATION";
    MemberMutationTypes["UPDATE_INVITED_USER"] = "UPDATE_INVITED_USER";
})(MemberMutationTypes || (MemberMutationTypes = {}));
