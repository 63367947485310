import VueLib from 'vue'
import * as Sentry from '@sentry/browser'
import { Dedupe, ExtraErrorData, ReportingObserver, RewriteFrames, Vue } from '@sentry/integrations'

export default function (ctx, inject) {
  const opts = Object.assign({}, {"dsn":"https:\u002F\u002F8927cf8934d54daea0c83485c314f242@o135225.ingest.sentry.io\u002F1798093","environment":"staging","ignoreErrors":["Network Error","Failed to fetch","Load failed","No error message","NotSupportedError","Unexpected token","NotAllowedError","Timeout",/^safari-web-extension:\/\/.+$/,/^chrome-extension:\/\/.+$/,/^Expected "file_id" to match.+$/,/^Cannot read properties of null (reading 'ownerDocument')+$/,/^ReportingObserver \[deprecation]: Synchronous.+$/,/^ReportingObserver \[deprecation]: Unload event listeners are deprecated.+$/],"beforeSend":function (event, hint) {
                if (!hint || !hint.originalException)
                    return event;
                var isTextIncludedInException = function (msg) {
                    var _a;
                    var originalException = hint.originalException;
                    if (!originalException)
                        return false;
                    if (typeof originalException === 'string') {
                        return originalException === msg || (originalException === null || originalException === void 0 ? void 0 : originalException.includes(msg));
                    }
                    if (typeof originalException === 'object' &&
                        typeof originalException.message === 'string') {
                        return (_a = originalException === null || originalException === void 0 ? void 0 : originalException.message) === null || _a === void 0 ? void 0 : _a.includes(msg);
                    }
                    return false;
                };
                // NOTE: https://github.com/getsentry/sentry-javascript/issues/2514
                if (isTextIncludedInException('Timeout'))
                    return null;
                // api リクエストによるエラーなら送信しない
                if (isTextIncludedInException('status code 403'))
                    return null;
                if (isTextIncludedInException('status code 404'))
                    return null;
                if (isTextIncludedInException('status code 50'))
                    return null;
                // DOM Mutation Events の Deprecate 警告を送信しない
                if (isTextIncludedInException('DOMSubtreeModified'))
                    return null;
                var unloadEventError = 'ReportingObserver [deprecation]: Unload event listeners are deprecated';
                if (isTextIncludedInException(unloadEventError))
                    return null;
                if (isTextIncludedInException('ReportingObserver [deprecation]: Synchronous'))
                    return null;
                return event;
            }}, {
    integrations: [
      new Dedupe({}),
      new ExtraErrorData({}),
      new ReportingObserver({}),
      new RewriteFrames({}),
      new Vue({Vue: VueLib, ...{"attachProps":true}})
    ]
  })

  // Initialize sentry
  Sentry.init(opts)

  // Inject Sentry to the context as $sentry
  inject('sentry', Sentry)
}
