var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import uuid4 from 'uuid/v4';
import getFileInfo from '@/plugins/get-file-info';
import compressor from '@/plugins/compressor';
export default (function (file, parentId, originalContentId, params) { return __awaiter(void 0, void 0, void 0, function () {
    var formData, compressedFile, _a, fileType, exif, width, height, pdfPagesNum, processGroupId;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                formData = new FormData();
                return [4 /*yield*/, compressor(file)];
            case 1:
                compressedFile = _b.sent();
                return [4 /*yield*/, getFileInfo(file)];
            case 2:
                _a = _b.sent(), fileType = _a.fileType, exif = _a.exif, width = _a.width, height = _a.height, pdfPagesNum = _a.pdfPagesNum;
                if (pdfPagesNum) {
                    formData.append('content[info_pdf_page_count]', String(pdfPagesNum));
                }
                if (parentId) {
                    formData.append('content[parent_id]', parentId);
                }
                if (originalContentId) {
                    formData.append('content[original_content_id]', originalContentId);
                }
                // ファイル・タイプが取得出来なければ application/octet-stream を固定でセット
                if (fileType && fileType.mime) {
                    formData.append('content[info_content_type]', fileType.mime);
                }
                else {
                    formData.append('content[info_content_type]', 'application/octet-stream');
                }
                formData.append('content[info_extension]', fileType.ext);
                formData.append('content[info_file_size]', String(compressedFile.size));
                // Note: S3ダイレクトアップロードに変更 => ファイルを送らない
                formData.append('content[relative_path]', file.webkitRelativePath || file.name);
                if (exif) {
                    formData.append('content[info_exif]', JSON.stringify(exif));
                }
                if (width && height) {
                    formData.append('content[info_width]', String(width));
                    formData.append('content[info_height]', String(height));
                }
                processGroupId = params && params.content ? params.content.process_group_id : uuid4();
                formData.append('content[process_group_id]', processGroupId);
                return [2 /*return*/, formData];
        }
    });
}); });
