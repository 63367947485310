var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Component, Mixins, Prop } from 'vue-property-decorator';
import BaseMixins from '@/mixins/base-mixin';
import { LocalStorageKeys } from '@/types';
import { Routes } from '@/types/routes';
var ErrorCode;
(function (ErrorCode) {
    ErrorCode["LOGIN_FAILED"] = "login_failed";
    ErrorCode["FULL_OF_ACCOUNT"] = "full_of_account";
})(ErrorCode || (ErrorCode = {}));
var LayoutError = /** @class */ (function (_super) {
    __extends(LayoutError, _super);
    function LayoutError() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    // life_cycle_hooks
    LayoutError.prototype.mounted = function () {
        var _a, _b, _c, _d;
        return __awaiter(this, void 0, void 0, function () {
            var _e, error, statusCode, is403, is410, e_1;
            return __generator(this, function (_f) {
                switch (_f.label) {
                    case 0:
                        _e = this, error = _e.error, statusCode = _e.statusCode;
                        localStorage.removeItem(LocalStorageKeys.LastURL);
                        is403 = statusCode === 403;
                        is410 = statusCode === 410;
                        if (((_b = (_a = error === null || error === void 0 ? void 0 : error.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.is_expired) || is403 || is410) {
                            localStorage.removeItem(LocalStorageKeys.JWT);
                            localStorage.removeItem(LocalStorageKeys.Departments);
                            return [2 /*return*/];
                        }
                        if (this.company.id)
                            return [2 /*return*/];
                        if (!((_d = (_c = this.$route) === null || _c === void 0 ? void 0 : _c.name) === null || _d === void 0 ? void 0 : _d.startsWith('companies'))) return [3 /*break*/, 4];
                        _f.label = 1;
                    case 1:
                        _f.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.findMyCompany()];
                    case 2:
                        _f.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _f.sent();
                        // エラーの無限ループにならないようここではログを出すのみにしています
                        console.log(e_1);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    Object.defineProperty(LayoutError.prototype, "statusCode", {
        // computed
        get: function () {
            var _a;
            return ((_a = this.error) === null || _a === void 0 ? void 0 : _a.statusCode) || 500;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(LayoutError.prototype, "errorCode", {
        get: function () {
            var _a, _b, _c;
            return ((_c = (_b = (_a = this.error) === null || _a === void 0 ? void 0 : _a.response) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.error_code) || '';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(LayoutError.prototype, "messageTitle", {
        get: function () {
            var pageTitle = this.pageTitle;
            return pageTitle;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(LayoutError.prototype, "pageTitle", {
        get: function () {
            var _a = this, statusCode = _a.statusCode, errorCode = _a.errorCode;
            switch (statusCode) {
                case 403:
                    if (errorCode === ErrorCode.FULL_OF_ACCOUNT)
                        return '組織に参加できません';
                    if (errorCode === ErrorCode.LOGIN_FAILED)
                        return '組織に参加できません';
                    return '認証エラー(403)';
                case 404:
                    return 'ページが見つかりません。（404）';
                case 410:
                    return 'ページにアクセスできません（410）';
                case 500:
                    return 'サーバー内部エラー（500）';
                default:
                    break;
            }
            return '不明なエラー';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(LayoutError.prototype, "topLink", {
        get: function () {
            var company = this.company;
            if (company.id) {
                return {
                    name: Routes.ProjectListPage.name,
                    params: {
                        company_id: company.id
                    }
                };
            }
            return {
                name: Routes.SessionSignInPage.name
            };
        },
        enumerable: false,
        configurable: true
    });
    LayoutError.layout = 'blank';
    __decorate([
        Prop({ type: Object, default: null })
    ], LayoutError.prototype, "error", void 0);
    LayoutError = __decorate([
        Component({
            head: function () {
                return {
                    title: this.pageTitle,
                    titleTemplate: "%s - " + this.$constants.appName,
                    meta: [
                        {
                            hid: 'viewport',
                            name: 'viewport',
                            content: 'width=device-width,initial-scale=1.0,minimum-scale=1.0,maximum-scale=1.0,user-scalable=no'
                        }
                    ]
                };
            }
        })
    ], LayoutError);
    return LayoutError;
}(Mixins(BaseMixins)));
export default LayoutError;
