var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import BlockUnloadMixin from '@/mixins/block-unload-mixin';
import ProgressTypeIcon from '@/components/progress-type-icon.vue';
var FileDownloader = /** @class */ (function (_super) {
    __extends(FileDownloader, _super);
    function FileDownloader() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.blockUnloadMessage = 'ダウンロード処理が中断されますがよろしいですか？？';
        _this.blockLeavePage = false;
        _this.timeoutId = null;
        return _this;
    }
    Object.defineProperty(FileDownloader.prototype, "totalProgress", {
        get: function () {
            var downloadQueues = this.downloadQueues;
            var done = downloadQueues.reduce(function (count, queue) {
                if (queue.status === 'success') {
                    count++;
                }
                return count;
            }, 0);
            return (done / downloadQueues.length) * 100;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(FileDownloader.prototype, "totalStatus", {
        get: function () {
            var _a = this, totalProgress = _a.totalProgress, downloadQueues = _a.downloadQueues;
            if (downloadQueues.every(function (queue) { return queue.status === 'exception'; })) {
                return 'exception';
            }
            if (downloadQueues.some(function (queue) { return queue.status === 'exception'; })) {
                return 'warning';
            }
            return totalProgress === 100 ? 'success' : null;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(FileDownloader.prototype, "blockUnload", {
        get: function () {
            var totalProgress = this.totalProgress;
            return totalProgress < 100;
        },
        enumerable: false,
        configurable: true
    });
    FileDownloader.prototype.onChangedTotalProgress = function (value) {
        var _this = this;
        var timeoutId = this.timeoutId;
        if (timeoutId) {
            window.clearTimeout(timeoutId);
        }
        if (value === 100) {
            this.timeoutId = window.setTimeout(function () {
                _this.clearQueue();
            }, 800);
        }
    };
    FileDownloader.prototype.downloadingLength = function (progresses) {
        var downloadingLength = 1;
        progresses.forEach(function (progress) {
            if (progress.status === 'success' && downloadingLength < progresses.length) {
                downloadingLength += 1;
            }
        });
        return downloadingLength;
    };
    __decorate([
        Prop({ type: Array, default: [] })
    ], FileDownloader.prototype, "downloadQueues", void 0);
    __decorate([
        Watch('totalProgress')
    ], FileDownloader.prototype, "onChangedTotalProgress", null);
    __decorate([
        Action('clearQueue')
    ], FileDownloader.prototype, "clearQueue", void 0);
    FileDownloader = __decorate([
        Component({
            components: { ProgressTypeIcon: ProgressTypeIcon }
        })
    ], FileDownloader);
    return FileDownloader;
}(Mixins(BlockUnloadMixin)));
export default FileDownloader;
