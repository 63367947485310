var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import BaseApiClient from '@/plugins/apis/base-api-client';
var CompanyApiClient = /** @class */ (function (_super) {
    __extends(CompanyApiClient, _super);
    function CompanyApiClient() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(CompanyApiClient.prototype, "basePath", {
        get: function () {
            return '/companies';
        },
        enumerable: false,
        configurable: true
    });
    CompanyApiClient.prototype.fetchCompanies = function () {
        var _a = this, basePath = _a.basePath, client = _a.client;
        return client.get(basePath);
    };
    CompanyApiClient.prototype.fetchCompany = function (companyId) {
        var _a = this, basePath = _a.basePath, client = _a.client;
        var url = basePath + "/" + companyId;
        return client.get(url);
    };
    CompanyApiClient.prototype.createCompany = function (params) {
        var _a = this, basePath = _a.basePath, client = _a.client;
        return client.post(basePath, params);
    };
    CompanyApiClient.prototype.updateCompany = function (companyId, params) {
        var _a = this, basePath = _a.basePath, client = _a.client;
        var url = basePath + "/" + companyId;
        return client.put(url, params);
    };
    CompanyApiClient.prototype.inviteMembers = function (companyId, params) {
        var basePath = this.basePath;
        var url = basePath + "/" + companyId + "/invite_members";
        return this.requestActionWithNamedProperty({
            method: 'put',
            url: url,
            data: params,
            resourceName: 'create_invite',
            hasData: false,
            actionName: 'OTHER'
        });
    };
    CompanyApiClient.prototype.createInvitationLink = function (_a) {
        var companyId = _a.companyId, userType = _a.userType;
        var basePath = this.basePath;
        var url = basePath + "/" + companyId + "/invited_users/invitation_link";
        return this.requestActionWithNamedProperty({
            method: 'post',
            url: url,
            data: {
                user_type: userType
            },
            resourceName: 'create_invitation_link',
            actionName: 'OTHER'
        });
    };
    CompanyApiClient.prototype.deleteInvitationLink = function (companyId) {
        var basePath = this.basePath;
        var url = basePath + "/" + companyId + "/invited_users/delete_invitation_link";
        return this.requestActionWithNamedProperty({
            method: 'delete',
            url: url,
            resourceName: 'delete_invitation_link',
            actionName: 'OTHER'
        });
    };
    return CompanyApiClient;
}(BaseApiClient));
export default new CompanyApiClient();
