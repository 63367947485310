var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Vue, Component } from 'vue-property-decorator';
var BlockUnloadMixin = /** @class */ (function (_super) {
    __extends(BlockUnloadMixin, _super);
    function BlockUnloadMixin() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        // 表示するメッセージ
        _this.blockUnloadMessage = '編集内容が保存されませんがページを移動しますか？';
        // アラートを出すかのフラグ
        _this.isBlockUnload = true;
        // ページ遷移する際にアラートを出すかのフラグ
        _this.blockLeavePage = true;
        return _this;
    }
    Object.defineProperty(BlockUnloadMixin.prototype, "blockUnload", {
        get: function () {
            var isBlockUnload = this.isBlockUnload;
            return isBlockUnload;
        },
        enumerable: false,
        configurable: true
    });
    // ページを閉じるときの処理
    BlockUnloadMixin.prototype.created = function () {
        if (process.client) {
            // 作成時にイベントリスナーに登録
            window.addEventListener('beforeunload', this.checkWindow);
        }
    };
    BlockUnloadMixin.prototype.beforeDestroy = function () {
        // 破棄される前にイベントリスナーから削除
        window.removeEventListener('beforeunload', this.checkWindow);
    };
    // 実際の処理
    BlockUnloadMixin.prototype.checkWindow = function (event) {
        if (this.blockUnload) {
            // unloadをキャンセルして、メッセージを表示
            event.preventDefault();
            event.returnValue = this.blockUnloadMessage;
        }
    };
    // ページを移動するときの処理
    BlockUnloadMixin.prototype.beforeRouteLeave = function (to, from, next) {
        var _a = this, blockUnload = _a.blockUnload, blockLeavePage = _a.blockLeavePage, msg = _a.blockUnloadMessage;
        if (blockUnload && blockLeavePage) {
            var answer = window.confirm(msg);
            next(answer);
        }
        else {
            next();
        }
    };
    BlockUnloadMixin = __decorate([
        Component
    ], BlockUnloadMixin);
    return BlockUnloadMixin;
}(Vue));
export default BlockUnloadMixin;
