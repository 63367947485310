var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Mixins, Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import CommonDateMixin from '@/mixins/common/date-mixin';
import { ProjectStatus } from '@/types/project';
var ProjectModule = namespace('project');
var ProjectBaseMixin = /** @class */ (function (_super) {
    __extends(ProjectBaseMixin, _super);
    function ProjectBaseMixin() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        // data
        _this.projectStatuses = {
            waiting: '未着手',
            working: '施工中',
            completed: '施工完了',
            archived: '案件完了'
        };
        return _this;
    }
    ProjectBaseMixin.prototype.getProjectStatusKey = function (value) {
        var _this = this;
        var result = Object.keys(this.projectStatuses).filter(function (key) {
            key = key || ProjectStatus.waiting;
            return _this.projectStatuses[key] === value;
        });
        return result;
    };
    ProjectBaseMixin.prototype.isProjectStartDate = function (startDateValue, status) {
        if (!startDateValue) {
            return false;
        }
        var today = new Date();
        var startDate = new Date(startDateValue);
        today = this.changeOriginalDateFormat(today);
        startDate = this.changeOriginalDateFormat(startDate);
        today = new Date(today).getTime();
        startDate = new Date(startDate).getTime();
        return today >= startDate && status === 'waiting';
    };
    ProjectBaseMixin.prototype.isProjectPeriod = function (project, date) {
        var startDate = new Date(project.start_date);
        var endDate = new Date(project.end_date);
        return startDate.getTime() < date.getTime() && date.getTime() < endDate.getTime();
    };
    ProjectBaseMixin.prototype.getOtherWorkSpaces = function (companies) {
        var _this = this;
        return companies.filter(function (company) {
            return company.id !== _this.$route.params.company_id;
        });
    };
    __decorate([
        ProjectModule.Action('fetchProject')
    ], ProjectBaseMixin.prototype, "fetchProject", void 0);
    __decorate([
        ProjectModule.Action('updateProject')
    ], ProjectBaseMixin.prototype, "updateProject", void 0);
    __decorate([
        ProjectModule.Action('updateProjectPeriod')
    ], ProjectBaseMixin.prototype, "updateProjectPeriod", void 0);
    ProjectBaseMixin = __decorate([
        Component
    ], ProjectBaseMixin);
    return ProjectBaseMixin;
}(Mixins(CommonDateMixin)));
export default ProjectBaseMixin;
