var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { PlanCodes, PlanStatuses } from '@/types/company';
import { isAdmin, isGuest, isMember } from '@/policies';
import meApiClient from '@/plugins/apis/me-api-client';
import companyApiClient from '@/plugins/apis/company-api-client';
var state = function () { return ({
    step: 1,
    company: {
        id: '',
        cid: '',
        name: '',
        logo: '',
        account_type: '',
        zip: '',
        prefecture_id: 13,
        prefecture_name: '',
        city: '',
        address: '',
        tel: '',
        fax: '',
        contact_name: '',
        initialized: false,
        user_type: 'guest',
        user_initialized: false,
        auto_create_process_directory: false,
        plan: {
            id: 1,
            code: PlanCodes.Trial,
            name: 'お試しプラン',
            item_kind: 'plan',
            detail: {
                guests: 1,
                members: 1,
                storage: 1,
                display_storage: true
            },
            status: PlanStatuses.Available
        },
        invitation_link: '',
        invitation_link_expires_in: '',
        invitation_link_expired: true,
        allow_image_link: false
    },
    user: {
        id: '',
        uid: '',
        name: '',
        email: '',
        avatar: '',
        user_type: ''
    },
    companies: [],
    members: [
        {
            name: '',
            email: '',
            user_type: ''
        }
    ],
    total_file_size: 0,
    maximum_storage_gigabytes: 0,
    policies: {
        companies: {},
        construction_kinds: {},
        contents: {},
        customers: {},
        invited_users: {},
        me: {},
        project_users: {},
        projects: {},
        reports: {},
        schedules: {},
        users: {},
        sharing_contents: {}
    }
}); };
// TODO: 全体で使用するactionsを切り出す
var actions = {
    initState: function (_a) {
        var commit = _a.commit;
        commit('initState', state());
    },
    updateStep: function (_a, num) {
        var commit = _a.commit;
        commit('updateStep', num);
    },
    saveCompany: function (_a, _b) {
        var commit = _a.commit;
        var company = _b.company;
        return __awaiter(this, void 0, void 0, function () {
            var params, res, res;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        params = { company: company };
                        if (!company.id) return [3 /*break*/, 2];
                        return [4 /*yield*/, companyApiClient.updateCompany(params.company.id, params)];
                    case 1:
                        res = _c.sent();
                        commit('updateCompany', { company: res.data.company });
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, companyApiClient.createCompany(params)];
                    case 3:
                        res = _c.sent();
                        commit('updateCompany', { company: res.data.company });
                        _c.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        });
    },
    // 作業中組織を取得
    fetchCompany: function (_a, _b) {
        var commit = _a.commit;
        var id = _b.id;
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (!id) return [3 /*break*/, 2];
                        return [4 /*yield*/, companyApiClient.fetchCompany(id).then(function (res) {
                                var _a = res.data, company = _a.company, meta = _a.meta;
                                commit('updateCompany', {
                                    company: company,
                                    meta: meta
                                });
                            })];
                    case 1:
                        _c.sent();
                        _c.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    },
    // companies: 作業可能組織（選択肢）を取得
    fetchCompanies: function (_a) {
        var commit = _a.commit;
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, companyApiClient.fetchCompanies().then(function (res) {
                            commit('updateCompanies', res.data.companies);
                        })];
                    case 1:
                        _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    },
    // 所属組織を取得しセット
    findMyCompany: function (_a, companyData) {
        var state = _a.state, dispatch = _a.dispatch;
        return __awaiter(this, void 0, void 0, function () {
            var ONLY_ONE_COMPANY, currentCompany;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        ONLY_ONE_COMPANY = 1;
                        if (!!state.companies.length) return [3 /*break*/, 2];
                        return [4 /*yield*/, dispatch('fetchCompanies')];
                    case 1:
                        _b.sent();
                        _b.label = 2;
                    case 2:
                        currentCompany = state.companies.find(function (company) {
                            if (companyData) {
                                return company.cid === companyData.cid;
                            }
                            return (company.user_type === 'admin' ||
                                company.user_type === 'member' ||
                                state.companies.length === ONLY_ONE_COMPANY);
                        });
                        if (!currentCompany) return [3 /*break*/, 4];
                        return [4 /*yield*/, dispatch('fetchCompany', { id: currentCompany.id })];
                    case 3:
                        _b.sent();
                        _b.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        });
    },
    // company_idを渡すとuser_roleも含んで帰ってくる
    fetchMe: function (_a, company_id) {
        var commit = _a.commit;
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, meApiClient.fetchMe(company_id).then(function (res) {
                            commit('updateUser', res.data.user);
                        })];
                    case 1:
                        _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    },
    saveMe: function (_a, params) {
        var commit = _a.commit;
        return __awaiter(this, void 0, void 0, function () {
            var res;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, meApiClient.updateMe(params)];
                    case 1:
                        res = _b.sent();
                        commit('updateUser', res.data.user);
                        return [2 /*return*/];
                }
            });
        });
    },
    // 組織に招待するメンバーを取得
    inviteMembers: function (_a, _b) {
        var commit = _a.commit, state = _a.state;
        var members = _b.members;
        commit('updateMembers', members);
        var params = { members: members };
        commit('updateMembers', []);
        return companyApiClient.inviteMembers(state.company.id, params);
    },
    createInvitationLink: function (_a, _b) {
        var commit = _a.commit;
        var companyId = _b.companyId, userType = _b.userType;
        return __awaiter(this, void 0, void 0, function () {
            var res;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0: return [4 /*yield*/, companyApiClient.createInvitationLink({
                            companyId: companyId,
                            userType: userType
                        })];
                    case 1:
                        res = _c.sent();
                        commit('createInvitationLink', res.data);
                        return [2 /*return*/, navigator.clipboard.writeText(res.data.invitation_link)];
                }
            });
        });
    },
    deleteInvitationLink: function (_a, compayId) {
        var commit = _a.commit;
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, companyApiClient.deleteInvitationLink(compayId)];
                    case 1:
                        _b.sent();
                        commit('deleteInvitationLink');
                        return [2 /*return*/];
                }
            });
        });
    },
    updateProjectColumnDisplay: function (_a, projectColumnDisplay) {
        var _this = this;
        var commit = _a.commit;
        commit('updateProjectColumnDisplay', { projectColumnDisplay: projectColumnDisplay });
        window.requestAnimationFrame(function () { return __awaiter(_this, void 0, void 0, function () {
            var e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, meApiClient.updateSettings({
                                settings: {
                                    project_column_display: projectColumnDisplay
                                }
                            })];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        e_1 = _a.sent();
                        console.log(e_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }); });
    }
};
var mutations = {
    initState: function (state, payload) {
        Object.assign(state, payload);
    },
    updateStep: function (state, num) {
        state.step = num;
    },
    updateCompany: function (state, _a) {
        var company = _a.company, meta = _a.meta;
        state.company = company;
        if (meta) {
            state.total_file_size = meta.total_file_size;
            state.maximum_storage_gigabytes = meta.maximum_storage_gigabytes;
            state.policies = meta.policies;
        }
    },
    updateCompanies: function (state, companies) {
        state.companies = companies;
    },
    updateMembers: function (state, members) {
        state.members = [];
        members.forEach(function (m) {
            state.members.push({
                email: m.email,
                name: m.name,
                user_type: ''
            });
        });
    },
    updateUser: function (state, user) {
        state.user = user;
    },
    createInvitationLink: function (state, payload) {
        state.company.invitation_link = payload.invitation_link;
        state.company.invitation_link_expires_in = payload.invitation_link_expires_in;
        state.company.invitation_link_expired = false;
    },
    deleteInvitationLink: function (state) {
        state.company.invitation_link = '';
        state.company.invitation_link_expires_in = '';
        state.company.invitation_link_expired = true;
    },
    updateProjectColumnDisplay: function (state, _a) {
        var projectColumnDisplay = _a.projectColumnDisplay;
        if (!state.user.user_settings)
            return;
        if (projectColumnDisplay) {
            Object.assign(state.user, __assign(__assign({}, state.user), { user_settings: __assign(__assign({}, state.user.user_settings), { project_column_display: __assign(__assign({}, state.user.user_settings.project_column_display), projectColumnDisplay) }) }));
        }
    }
};
var getters = {
    company: function (state) {
        return state.company;
    },
    plan: function (state) {
        return state.company.plan;
    },
    companies: function (state) {
        return state.companies;
    },
    user: function (state) {
        return state.user;
    },
    members: function (state) {
        return state.members;
    },
    totalFileSize: function (state) {
        return state.total_file_size / 1024 / 1024 / 1024;
    },
    maximumStorageGigabytes: function (state) {
        return state.maximum_storage_gigabytes;
    },
    policies: function (state) {
        return state.policies;
    },
    limitGuestInvites: function (_, getters) {
        var plan = getters.plan;
        return plan.detail.guests;
    },
    // 管理者・メンバーの合計
    limitMemberInvites: function (_, getters) {
        var plan = getters.plan;
        return plan.detail.members;
    },
    isUnlimitedGuestInvites: function (_, getters) {
        var plan = getters.plan;
        return plan.detail.guests < 0;
    },
    isUnlimitedMemberInvites: function (_, getters) {
        var plan = getters.plan;
        return plan.detail.members < 0;
    },
    isBusinessPlan: function (_, getters) {
        var plan = getters.plan;
        return plan.code === PlanCodes.Business || plan.code === PlanCodes.BusinessAnnual;
    },
    isTrialPlan: function (_, getters) {
        return getters.plan.code === PlanCodes.Trial;
    },
    isPersonalPlan: function (_, getters) {
        var plan = getters.plan;
        return plan.code === PlanCodes.Personal || plan.code === PlanCodes.PersonalAnnual;
    },
    isAvailable: function (_, getters) {
        return getters.plan.status === PlanStatuses.Available;
    },
    isTrialIsExpired: function (_, getters) {
        return getters.plan.status === PlanStatuses.TrialIsExpired;
    },
    isUnpaidPlan: function (_, getters) {
        return getters.plan.status === PlanStatuses.UnpaidPlan;
    },
    isLockedPlan: function (_, getters) {
        return getters.plan.status === PlanStatuses.LockedPlan;
    },
    isUndefinedStatus: function (_, getters) {
        if (getters.isAvailable ||
            getters.isTrialIsExpired ||
            getters.isUnpaidPlan ||
            getters.isLockedPlan) {
            return false;
        }
        else {
            return true;
        }
    },
    isAdmin: function (state) {
        var userType = state.company.user_type;
        return isAdmin(userType);
    },
    isMember: function (state) {
        var userType = state.company.user_type;
        return isMember(userType);
    },
    isGuest: function (state) {
        return isGuest(state.company.user_type);
    },
    projectDisplayColumns: function (state) {
        var userSettings = state.user.user_settings;
        if (!userSettings)
            return [];
        var columns = userSettings.project_column_display;
        return [
            {
                label: 'ステータス',
                active: columns.status,
                key: 'status'
            },
            {
                label: '工期',
                active: columns.construction_period,
                key: 'construction_period'
            },
            {
                label: '取引先名',
                active: columns.customer,
                key: 'customer'
            },
            {
                label: '現場住所',
                active: columns.address,
                key: 'address'
            },
            {
                label: 'メンバー',
                active: columns.member,
                key: 'member'
            },
            {
                label: '工事種別',
                active: columns.construction_kind,
                key: 'construction_kind'
            }
        ];
    },
    displayStorage: function (_, getters) {
        return getters.plan.detail.display_storage;
    }
};
var initialSetting = {
    namespaced: true,
    state: state,
    actions: actions,
    getters: getters,
    mutations: mutations
};
export default initialSetting;
