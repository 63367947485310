export var ResponseType;
(function (ResponseType) {
    ResponseType["list"] = "list";
    ResponseType["detail"] = "detail";
})(ResponseType || (ResponseType = {}));
export var PublicContentMutationTypes;
(function (PublicContentMutationTypes) {
    PublicContentMutationTypes["INIT_STATE"] = "INIT_STATE";
    PublicContentMutationTypes["FETCH_PUBLIC_CONTENTS"] = "FETCH_PUBLIC_CONTENTS";
    PublicContentMutationTypes["RESET_CONTENTS"] = "RESET_CONTENTS";
    PublicContentMutationTypes["CHANGE_SORT"] = "CHANGE_SORT";
    PublicContentMutationTypes["FETCH_CONTENT"] = "FETCH_CONTENT";
    PublicContentMutationTypes["CHANGE_RESPONSE_TYPE"] = "CHANGE_RESPONSE_TYPE";
    PublicContentMutationTypes["SET_LOGIN_LINK"] = "SET_LOGIN_LINK";
    PublicContentMutationTypes["FETCH_COMMENTS"] = "FETCH_COMMENTS";
    PublicContentMutationTypes["ADD_COMMENT"] = "ADD_COMMENT";
    PublicContentMutationTypes["DELETE_COMMENT"] = "DELETE_COMMENT";
    PublicContentMutationTypes["UPDATE_COMMENT"] = "UPDATE_COMMENT";
})(PublicContentMutationTypes || (PublicContentMutationTypes = {}));
