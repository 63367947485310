/**
 * [Usage]
 * Add the `data-ga-event` attribute to html tag,
 * it will automatically detect the click event and send the event to Google Analytics, as shown below.
 *
 * data-ga-event="click-foo"
 *
 *
 * [Appendix]
 * If the event propagation doesn't work and you can't get the click event, call gtag directly with @click.
 * Do as follows.
 *
 * $gtag('event','click-bar')
 * */
var eventAttributeName = 'data-ga-event';
var handler = function (context) {
    var app = context.app;
    document.addEventListener('click', function (e) {
        var element = e.target;
        var clickEvent = element.getAttribute(eventAttributeName);
        if (clickEvent) {
            app.$gtag('event', clickEvent, {
                click_classes: element.classList.value
            });
        }
    }, true);
};
export default handler;
