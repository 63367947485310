import apiClientPublic from '@/plugins/api-client-public';
import { ResourceType } from '@/types/base';
import showErrorMessage from '@/plugins/show-error-message';
import { alertMessage, otherAlertMessage } from '@/plugins/alert-messages';
import MessageType from '@/plugins/message-type';
import Notification from '@/plugins/notification';
var BaseApiClient = /** @class */ (function () {
    function BaseApiClient() {
        this.client = apiClientPublic;
    }
    BaseApiClient.prototype.requestAction = function (_a) {
        var _b = _a.method, method = _b === void 0 ? 'post' : _b, url = _a.url, params = _a.params, data = _a.data, resourceName = _a.resourceName, actionName = _a.actionName, _c = _a.hasData, hasData = _c === void 0 ? false : _c, _d = _a.notice, notice = _d === void 0 ? true : _d, paramsSerializer = _a.paramsSerializer;
        if (!window.navigator.onLine) {
            var error = '現在オフラインです';
            showErrorMessage(error, {
                message: 'オンラインにしてから再度試して下さい。'
            });
            throw new Error(error);
        }
        var resource = ResourceType[resourceName];
        return apiClientPublic
            .request({
            method: method,
            url: url,
            params: params,
            data: data,
            paramsSerializer: paramsSerializer
        })
            .then(function (res) {
            if (!notice)
                return res;
            var message = '';
            if (hasData) {
                var data_1 = res.data[resourceName];
                message = data_1.title || data_1.name;
            }
            var alertTitle = actionName === 'OTHER'
                ? otherAlertMessage[MessageType.success(resourceName)]
                : alertMessage[MessageType.success(actionName)](resource);
            Notification.success({
                title: alertTitle,
                message: message
            });
            return res;
        })
            .catch(function (e) {
            if (e.response === undefined) {
                throw new Error(e.message);
            }
            if (notice) {
                if (actionName === 'OTHER') {
                    showErrorMessage(otherAlertMessage[MessageType.error(resourceName)], e.response.data);
                }
                else {
                    var getMessageTitleFn = alertMessage[MessageType.error(actionName)];
                    if (typeof getMessageTitleFn === 'function') {
                        showErrorMessage(getMessageTitleFn(resource), e.response.data);
                    }
                    else {
                        showErrorMessage('エラーが発生しました', e.response.data);
                    }
                }
            }
            throw e;
        });
    };
    return BaseApiClient;
}());
export default BaseApiClient;
