const middleware = {}

middleware['board-post-detail'] = require('../src/middleware/board-post-detail.ts');
middleware['board-post-detail'] = middleware['board-post-detail'].default || middleware['board-post-detail']

middleware['content-file-list-page'] = require('../src/middleware/content-file-list-page.ts');
middleware['content-file-list-page'] = middleware['content-file-list-page'].default || middleware['content-file-list-page']

middleware['fetch-company'] = require('../src/middleware/fetch-company.ts');
middleware['fetch-company'] = middleware['fetch-company'].default || middleware['fetch-company']

middleware['fetch-holiday'] = require('../src/middleware/fetch-holiday.ts');
middleware['fetch-holiday'] = middleware['fetch-holiday'].default || middleware['fetch-holiday']

middleware['save-last-url'] = require('../src/middleware/save-last-url.ts');
middleware['save-last-url'] = middleware['save-last-url'].default || middleware['save-last-url']

export default middleware
