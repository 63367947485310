import showErrorMessage from '@/plugins/show-error-message';
// /projects/xxx/board/{board_id}/
// 配下のページ全てに適応
export default (function (_a) {
    var params = _a.params, store = _a.store, query = _a.query;
    var id = params.id;
    var boardId = params.board_id;
    var boardPost = store.getters['board/boardPost'];
    var boardCategories = store.getters['board/boardCategories'];
    var isAfterCreated = store.getters['board/isAfterCreated'];
    var content = store.getters['content/content'];
    var uploadContentId = '';
    var promises = [];
    var payload = {
        projectId: id,
        boardPostId: boardId
    };
    if (!boardCategories.length) {
        promises.push(store.dispatch('board/fetchBoardCategories'));
    }
    if (isAfterCreated || boardId !== boardPost.id) {
        store.dispatch('board/isAfterCreated', false);
        var fetchPromise = store
            .dispatch('board/fetchBoardPost', payload)
            .then(function (result) {
            uploadContentId = result.upload_content_id;
            var commentPayload = {
                projectId: id,
                boardPostId: boardId,
                targetId: query.target_id,
                isInitialize: true
            };
            var fetchContentsPayload = {
                params: {
                    board_post_id: boardId
                }
            };
            return Promise.all([
                store.dispatch('board/fetchBoardPostUsers', payload),
                store.dispatch('board/fetchBoardPostComments', commentPayload),
                store.dispatch('board/initBoardPostContents', fetchContentsPayload)
            ]);
        });
        promises.push(fetchPromise);
    }
    else {
        uploadContentId = boardPost.upload_content_id;
    }
    if (uploadContentId && uploadContentId !== content.id) {
        promises.push(store.dispatch('content/fetchContent', uploadContentId));
    }
    Promise.all(promises).catch(function () {
        showErrorMessage('エラーが発生しました', {
            message: 'しばらくしてから画面を読み込み直してください'
        });
    });
});
