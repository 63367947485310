var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
var _a;
import Vue from 'vue';
import axios from 'axios';
import uuid4 from 'uuid/v4';
import Firebase from 'firebase/app';
import { ContentMutationTypes, ProcessStatus, SharedRangeType, SideType } from '@/types/content';
import { Routes } from '@/types/routes';
import contentApiClient from '@/plugins/apis/content-api-client';
import sharingContentApi from '@/plugins/apis/sharing-content-api';
import sleep from '@/plugins/sleep';
import sequentialPromises from '@/plugins/sequential-promises';
import showErrorMessage from '@/plugins/show-error-message';
import sortResources from '@/plugins/sort-resources';
import downloadFile from '@/plugins/download-file';
import Notification from '@/plugins/notification';
import contentCommentApi from '@/plugins/apis/content-comment-api';
import isDirectory from '@/plugins/is-directory';
import checkFirebaseAuthAndDoAction from '@/plugins/check-firebase-auth-and-do-action';
import { FirestoreCollectionNames } from '@/types';
var CancelToken = axios.CancelToken;
// ファイルアップロード時の最大最大並列数
var Concurrency = 1;
// アップロード完了後にwaitを付けます
var waitUplaod = function () {
    return sleep(1000 + Math.random() * 800);
};
var sortBy = function (contents, sort) {
    var prop = sort.prop, order = sort.order;
    return sortResources(contents, prop, order);
};
// actions setSortedContentsでのみ使用します・throttleの為の値
var interval = 4000;
var maxTime = 8000;
var lastTime = new Date().getTime() - interval;
var timer = 0;
var getCurrentParentId = function (router) {
    var _a = router.history.current, name = _a.name, _b = _a.params, fileId = _b.file_id, id = _b.id;
    if (name === Routes.CompanyFilesListPage.name) {
        return id || '0';
    }
    // 案件・ファイル一覧
    if (name === Routes.ProjectFilesListPage.name) {
        return fileId || '0';
    }
    return '';
};
export var state = function () { return ({
    defaultSort: {
        prop: 'name',
        order: 'asc'
    },
    contents: [],
    content: {
        id: '',
        url: '',
        original_content_id: '',
        original_content_url: '',
        s3_process_done: false,
        process_status: ProcessStatus.recordCreated,
        hls_url: '',
        cover_url: '',
        thumb_url: '',
        document_preview_url: '',
        parent_id: '',
        name: '',
        info_content_type: '',
        root_model: null,
        created_user_id: '',
        info_extension: '',
        shared_range_type: SharedRangeType.limitedUsers,
        shared_users: [],
        prev_id: '',
        next_id: '',
        sharing_content: null,
        ancestors_sharing: false,
        sharing: false,
        descendants_sharing: false,
        info_pdf_page_count: 0,
        pdf_image_urls: [],
        policies: {
            preview: false,
            download: false,
            move: false,
            copy: false,
            update: false,
            update_shared_range: false,
            destroy: false
        }
    },
    frequentlyContents: [],
    latelyContents: [],
    listType: 'table',
    progressMap: {},
    paginate: {
        current_limit: 0,
        // サーバーが設定値を持っているのでこちらからは指定しません
        current_offset: 0,
        total_count: 0
    },
    ancestors: [],
    // アップロードを実行する関数を詰めます
    // store内のみで参照している為getterは定義していません
    queues: [],
    filesListInitialized: false,
    sideType: SideType.info,
    contentComments: [],
    mentionableUsers: [],
    currentDirectory: {
        id: '',
        url: '',
        original_content_id: '',
        original_content_url: '',
        s3_process_done: false,
        process_status: ProcessStatus.recordCreated,
        hls_url: '',
        cover_url: '',
        thumb_url: '',
        document_preview_url: '',
        parent_id: '',
        name: '',
        info_content_type: '',
        root_model: null,
        created_user_id: '',
        info_extension: '',
        shared_range_type: SharedRangeType.limitedUsers,
        shared_users: [],
        prev_id: '',
        next_id: '',
        sharing_content: null,
        ancestors_sharing: false,
        sharing: false,
        descendants_sharing: false,
        info_pdf_page_count: 0,
        pdf_image_urls: [],
        policies: {
            preview: false,
            download: false,
            move: false,
            copy: false,
            update: false,
            update_shared_range: false,
            destroy: false
        }
    }
}); };
export var getters = {
    contents: function (state) {
        return state.contents;
    },
    content: function (state) {
        return state.content;
    },
    frequentlyContents: function (state) {
        return state.frequentlyContents;
    },
    latelyContents: function (state) {
        return state.latelyContents;
    },
    // 選択中のファイルに付随する案件情報を返します
    project: function (state) {
        var content = state.content;
        if (!content.project) {
            return null;
        }
        return content.project;
    },
    // 選択中のファイルに付随する取引先情報を返します
    customer: function (state) {
        var content = state.content;
        if (!content.customer) {
            return null;
        }
        return content.customer;
    },
    defaultSort: function (state) {
        return state.defaultSort;
    },
    paginate: function (state) {
        return state.paginate;
    },
    ancestors: function (state) {
        return state.ancestors;
    },
    listType: function (state) {
        return state.listType;
    },
    isTableList: function (state) {
        return state.listType === 'table';
    },
    isCardList: function (state) {
        return state.listType === 'card';
    },
    progressMap: function (state) {
        return state.progressMap;
    },
    existsFile: function (state) {
        var progressMap = state.progressMap;
        return Object.keys(progressMap).length > 0;
    },
    filesListInitialized: function (state) {
        return state.filesListInitialized;
    },
    sideType: function (state) {
        return state.sideType;
    },
    contentComments: function (state) {
        return state.contentComments;
    },
    mentionableUsers: function (state) {
        return state.mentionableUsers;
    },
    currentDirectory: function (state) {
        return state.currentDirectory;
    }
};
export var mutations = (_a = {},
    _a[ContentMutationTypes.INIT_STATE] = function (state, payload) {
        Object.assign(state, payload);
    },
    _a[ContentMutationTypes.SET_SORT_TYPE] = function (state, payload) {
        state.defaultSort = payload;
    },
    _a[ContentMutationTypes.CHANGE_LIST_TYPE] = function (state, payload) {
        state.listType = payload;
    },
    _a[ContentMutationTypes.REPLACE_CONTENT] = function (state, payload) {
        var index = state.contents.findIndex(function (c) { return c.id === payload.id; });
        if (Number.isInteger(index) && index >= 0) {
            state.contents.splice(index, 1, payload);
        }
        if (state.content && state.content.id === payload.id) {
            state.content = payload;
        }
        if (state.currentDirectory && state.currentDirectory.id === payload.id) {
            state.currentDirectory = payload;
        }
    },
    _a[ContentMutationTypes.RESET_CONTENTS] = function (state) {
        state.contents = [];
    },
    _a[ContentMutationTypes.INIT_CONTENTS] = function (state, _a) {
        var contents = _a.contents, paginate = _a.paginate, ancestors = _a.ancestors;
        state.contents = contents;
        state.paginate = paginate;
        state.ancestors = ancestors;
        state.filesListInitialized = true;
    },
    _a[ContentMutationTypes.FETCH_CONTENTS_META] = function (state, _a) {
        var ancestors = _a.ancestors;
        state.ancestors = ancestors;
    },
    _a[ContentMutationTypes.FETCH_CONTENTS] = function (state, _a) {
        var contents = _a.contents, paginate = _a.paginate;
        var ids = state.contents.map(function (c) { return c.id; });
        var filterdList = contents.filter(function (c) {
            return !ids.includes(c.id);
        });
        state.contents = state.contents.concat(filterdList);
        state.paginate = paginate;
    },
    _a[ContentMutationTypes.FETCH_CONTENTS_BY_IDS] = function (state, _a) {
        var contents = _a.contents, contentIds = _a.contentIds;
        var sortedContents = [];
        contentIds.filter(function (id) {
            contents.filter(function (content) {
                if (content.id === id) {
                    sortedContents.push(content);
                }
            });
        });
        state.contents = sortedContents;
    },
    _a[ContentMutationTypes.FETCH_MENTIONABLE_USERS] = function (state, payload) {
        state.mentionableUsers = payload;
    },
    _a[ContentMutationTypes.FETCH_CONTENT] = function (state, payload) {
        state.content = payload;
    },
    _a[ContentMutationTypes.FETCH_CURRENT_DIRECTORY] = function (state, payload) {
        state.currentDirectory = payload;
    },
    _a[ContentMutationTypes.FETCH_FREQUENTLY_CONTENTS] = function (state, payload) {
        state.frequentlyContents = payload;
    },
    _a[ContentMutationTypes.FETCH_LATELY_CONTENTS] = function (state, payload) {
        state.latelyContents = payload;
    },
    _a[ContentMutationTypes.DELETE_CONTENT] = function (state, payload) {
        var index = state.contents.findIndex(function (c) { return c.id === payload; });
        if (index !== -1) {
            Vue.delete(state.contents, index);
        }
    },
    _a[ContentMutationTypes.MOVE_CONTENTS] = function (state, payload) {
        var contents = __spreadArrays(state.contents);
        payload.forEach(function (contentId) {
            var index = contents.findIndex(function (c) { return c.id === contentId; });
            if (index !== -1) {
                contents.splice(index, 1);
            }
        });
        state.contents = contents;
    },
    _a[ContentMutationTypes.RETRY_FETCH_CONTENTS] = function (state, payload) {
        payload.forEach(function (retryContent) {
            var content = state.contents.find(function (stateContent) { return retryContent.id === stateContent.id; });
            // possibly 'undefined'
            if (!content)
                return;
            content.s3_process_done = retryContent.s3_process_done;
        });
    },
    _a[ContentMutationTypes.ADD_FILE_LIST] = function (state, _a) {
        var key = _a.key, progresses = _a.progresses;
        Vue.set(state.progressMap, key, progresses);
    },
    _a[ContentMutationTypes.ADD_QUEUE] = function (state, payload) {
        state.queues.push(payload);
    },
    _a[ContentMutationTypes.DELETE_QUEUE] = function (state) {
        state.queues.shift();
    },
    _a[ContentMutationTypes.UPDATE_PROGRESS] = function (state, _a) {
        var key = _a.key, index = _a.index, progress = _a.progress, _b = _a.status, status = _b === void 0 ? 'success' : _b, errors = _a.errors;
        if (!state.progressMap[key]) {
            return;
        }
        Vue.set(state.progressMap[key][index], 'progress', progress);
        if (errors) {
            Vue.set(state.progressMap[key][index], 'errors', errors);
        }
        if (!state.progressMap[key][index].status && progress >= 100) {
            Vue.set(state.progressMap[key][index], 'status', status);
        }
    },
    _a[ContentMutationTypes.CLEAR_QUEUES] = function (state) {
        state.queues = [];
        state.progressMap = {};
    },
    _a[ContentMutationTypes.CANCEL_FILES] = function (state, payload) {
        var key = payload.key, progresses = payload.progresses;
        Vue.set(state.progressMap, key, progresses);
    },
    _a[ContentMutationTypes.REMOVE_FILE_LIST] = function (state, payload) {
        Vue.delete(state.progressMap, payload);
    },
    _a[ContentMutationTypes.SET_SORTED_CONTENTS] = function (state, payload) {
        state.contents = payload;
    },
    _a[ContentMutationTypes.UNINITIALIZED] = function (state) {
        state.filesListInitialized = false;
    },
    _a[ContentMutationTypes.CHANGE_SIDE_TYPE] = function (state, payload) {
        state.sideType = payload;
    },
    _a[ContentMutationTypes.ADD_COMMENT] = function (state, payload) {
        state.contentComments.push(payload);
    },
    _a[ContentMutationTypes.DELETE_COMMENT] = function (state, payload) {
        state.contentComments = state.contentComments.filter(function (comment) { return comment.id !== payload; });
    },
    _a[ContentMutationTypes.FETCH_COMMENTS] = function (state, payload) {
        state.contentComments = payload;
    },
    _a[ContentMutationTypes.UPDATE_COMMENT] = function (state, payload) {
        var index = state.contentComments.findIndex(function (comment) { return comment.id === payload.id; });
        if (index > -1) {
            state.contentComments.splice(index, 1, payload);
        }
    },
    _a[ContentMutationTypes.UPDATE_PDF_PAGE_COUNT] = function (state, payload) {
        state.content.info_pdf_page_count = payload;
    },
    _a);
export var actions = {
    initState: function (_a) {
        var commit = _a.commit;
        commit(ContentMutationTypes.INIT_STATE, state());
    },
    changeSort: function (_a, payload) {
        var commit = _a.commit;
        commit(ContentMutationTypes.SET_SORT_TYPE, payload);
    },
    changeListType: function (_a, payload) {
        var commit = _a.commit;
        commit(ContentMutationTypes.CHANGE_LIST_TYPE, payload);
    },
    replaceContent: function (_a, payload) {
        var commit = _a.commit;
        commit(ContentMutationTypes.REPLACE_CONTENT, payload);
    },
    removeContent: function (_a, payload) {
        var commit = _a.commit;
        commit(ContentMutationTypes.DELETE_CONTENT, payload.id);
    },
    resetContents: function (_a) {
        var commit = _a.commit;
        commit(ContentMutationTypes.UNINITIALIZED);
        commit(ContentMutationTypes.RESET_CONTENTS);
    },
    initContents: function (_a, payload) {
        var commit = _a.commit, getters = _a.getters, rootGetters = _a.rootGetters;
        return __awaiter(this, void 0, void 0, function () {
            var defaultSort, params;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        commit(ContentMutationTypes.UNINITIALIZED);
                        defaultSort = getters.defaultSort;
                        params = {
                            plan_code: rootGetters['initial-setting/plan'].code,
                            offset: 0,
                            limit: 20,
                            sort: {
                                target: defaultSort.prop,
                                order: defaultSort.order
                            }
                        };
                        return [4 /*yield*/, contentApiClient
                                .fetchContents(payload.id, params, payload.project_id)
                                .then(function (res) {
                                var _a = res.data, contents = _a.contents, _b = _a.meta, paginate = _b.paginate, content = _b.content, ancestors = _b.content_ancestors;
                                commit(ContentMutationTypes.FETCH_CONTENT, content || undefined);
                                commit(ContentMutationTypes.FETCH_CURRENT_DIRECTORY, content || undefined);
                                commit(ContentMutationTypes.INIT_CONTENTS, {
                                    contents: contents,
                                    paginate: paginate,
                                    ancestors: ancestors
                                });
                            })
                                .catch(function (e) {
                                console.error(e);
                                var data = (e.response && e.response.data) || {};
                                showErrorMessage('ファイルの取得に失敗しました', data);
                            })];
                    case 1:
                        _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    },
    fetchContents: function (_a, payload) {
        var commit = _a.commit, getters = _a.getters, rootGetters = _a.rootGetters;
        return __awaiter(this, void 0, void 0, function () {
            var defaultSort, currentContents, params, res, _b, contents, paginate, currentParentId;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        defaultSort = getters.defaultSort, currentContents = getters.contents;
                        params = {
                            plan_code: rootGetters['initial-setting/plan'].code,
                            offset: currentContents.length,
                            limit: payload.limit,
                            sort: {
                                target: defaultSort.prop,
                                order: defaultSort.order
                            }
                        };
                        return [4 /*yield*/, contentApiClient.fetchContents(payload.id, params, payload.project_id)];
                    case 1:
                        res = _c.sent();
                        _b = res.data, contents = _b.contents, paginate = _b.meta.paginate;
                        if (!contents || contents.length === 0) {
                            return [2 /*return*/];
                        }
                        currentParentId = getCurrentParentId(this.$router);
                        if (currentParentId !== contents[0].parent_id) {
                            return [2 /*return*/];
                        }
                        commit(ContentMutationTypes.FETCH_CONTENTS, { contents: contents, paginate: paginate });
                        return [2 /*return*/];
                }
            });
        });
    },
    fetchContentsByIds: function (_a, contentIds) {
        var commit = _a.commit, getters = _a.getters, rootGetters = _a.rootGetters;
        return __awaiter(this, void 0, void 0, function () {
            var defaultSort, params, res, contents;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        defaultSort = getters.defaultSort;
                        params = {
                            plan_code: rootGetters['initial-setting/plan'].code,
                            offset: 0,
                            sort: {
                                target: defaultSort.prop,
                                order: defaultSort.order
                            },
                            ids: contentIds
                        };
                        return [4 /*yield*/, contentApiClient.searchContents(params)];
                    case 1:
                        res = _b.sent();
                        contents = res.data.contents;
                        commit(ContentMutationTypes.FETCH_CONTENTS_BY_IDS, { contents: contents, contentIds: contentIds });
                        return [2 /*return*/];
                }
            });
        });
    },
    fetchContent: function (_a, payload) {
        var dispatch = _a.dispatch, commit = _a.commit, getters = _a.getters, rootGetters = _a.rootGetters;
        return __awaiter(this, void 0, void 0, function () {
            var sortParams, res;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        sortParams = {
                            order: getters.defaultSort.order,
                            target: getters.defaultSort.prop
                        };
                        return [4 /*yield*/, contentApiClient.fetchContent(payload, sortParams)];
                    case 1:
                        res = _b.sent();
                        if (!isDirectory(res.data.content.info_content_type)) {
                            checkFirebaseAuthAndDoAction(function () {
                                Firebase.firestore()
                                    .collection(FirestoreCollectionNames.STATUS_CONTENT)
                                    .doc(res.data.content.id)
                                    .onSnapshot(function () {
                                    dispatch('fetchComments', payload);
                                });
                            }, rootGetters['initial-setting/company'].id);
                        }
                        commit(ContentMutationTypes.FETCH_CONTENT, res.data.content);
                        return [2 /*return*/];
                }
            });
        });
    },
    // 掲示板 - ファイルプレビューの詳細情報サイドバーに値をセットするためにstoreに保存
    // TODO: file-side-bar.vueのcontentをstoreではなくpropでセットするようリファクタリング
    setContent: function (_a, content) {
        var commit = _a.commit;
        commit(ContentMutationTypes.FETCH_CONTENT, content);
    },
    fetchLatelyContents: function (_a, payload) {
        var commit = _a.commit;
        return __awaiter(this, void 0, void 0, function () {
            var res;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        commit(ContentMutationTypes.FETCH_LATELY_CONTENTS, []);
                        return [4 /*yield*/, contentApiClient.fetchLatelyContents(payload)];
                    case 1:
                        res = _b.sent();
                        commit(ContentMutationTypes.FETCH_LATELY_CONTENTS, res.data.contents);
                        return [2 /*return*/];
                }
            });
        });
    },
    fetchFrequentlyContents: function (_a, payload) {
        var commit = _a.commit;
        return __awaiter(this, void 0, void 0, function () {
            var res;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        commit(ContentMutationTypes.FETCH_FREQUENTLY_CONTENTS, []);
                        return [4 /*yield*/, contentApiClient.fetchFrequentlyContents(payload)];
                    case 1:
                        res = _b.sent();
                        commit(ContentMutationTypes.FETCH_FREQUENTLY_CONTENTS, res.data.contents);
                        return [2 /*return*/];
                }
            });
        });
    },
    createFolder: function (_a, payload) {
        var dispatch = _a.dispatch;
        return __awaiter(this, void 0, void 0, function () {
            var res;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, contentApiClient.createFolder(payload.id, payload.params)];
                    case 1:
                        res = _b.sent();
                        dispatch('setSortedContents', res.data.content);
                        return [2 /*return*/, res.data.content];
                }
            });
        });
    },
    updateContent: function (_a, payload) {
        var dispatch = _a.dispatch;
        return __awaiter(this, void 0, void 0, function () {
            var id, project_id, params, isCurrentDir, res, _b, content, meta;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        id = payload.id, project_id = payload.project_id, params = payload.params, isCurrentDir = payload.isCurrentDir;
                        return [4 /*yield*/, contentApiClient.updateContentRequest(id, params)];
                    case 1:
                        res = _c.sent();
                        _b = res.data, content = _b.content, meta = _b.meta;
                        dispatch('fetchContent', content.id);
                        if (project_id) {
                            dispatch('fetchContentsMeta', {
                                id: content.id,
                                project_id: project_id,
                                isCurrentDir: isCurrentDir
                            });
                        }
                        dispatch('setSortedContents', content);
                        return [2 /*return*/, meta];
                }
            });
        });
    },
    fetchContentsMeta: function (_a, payload) {
        var commit = _a.commit, rootGetters = _a.rootGetters;
        return __awaiter(this, void 0, void 0, function () {
            var id, project_id, isCurrentDir, params, res, ancestors;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        id = payload.id, project_id = payload.project_id, isCurrentDir = payload.isCurrentDir;
                        params = {
                            plan_code: rootGetters['initial-setting/plan'].code,
                            offset: 0,
                            limit: 0
                        };
                        return [4 /*yield*/, contentApiClient.fetchContents(id, params, project_id)];
                    case 1:
                        res = _b.sent();
                        ancestors = res.data.meta.content_ancestors;
                        if (!isCurrentDir) {
                            ancestors.pop();
                        }
                        if (!res.data.meta.content)
                            return [2 /*return*/];
                        if (isCurrentDir) {
                            commit(ContentMutationTypes.FETCH_CURRENT_DIRECTORY, res.data.meta.content);
                        }
                        commit(ContentMutationTypes.FETCH_CONTENTS_META, { ancestors: ancestors });
                        return [2 /*return*/];
                }
            });
        });
    },
    deleteContent: function (_a, payload) {
        var commit = _a.commit;
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, contentApiClient.deleteContent(payload)];
                    case 1:
                        _b.sent();
                        commit(ContentMutationTypes.DELETE_CONTENT, payload);
                        return [2 /*return*/];
                }
            });
        });
    },
    fetchDownloadURL: function (_, payload) {
        return __awaiter(this, void 0, void 0, function () {
            var content, column, res;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        content = payload.content, column = payload.column;
                        return [4 /*yield*/, contentApiClient.fetchDownloadURL(content.id)];
                    case 1:
                        res = _a.sent();
                        return [4 /*yield*/, downloadFile(res.data.content[column], content.info_content_type, res.data.content.name)];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    },
    moveContents: function (_a, payload) {
        var commit = _a.commit;
        return __awaiter(this, void 0, void 0, function () {
            var movedIds, promises;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        movedIds = [];
                        promises = payload.contentIds.map(function (contentId) {
                            return function () { return __awaiter(_this, void 0, void 0, function () {
                                var e_1;
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0:
                                            _a.trys.push([0, 2, , 3]);
                                            return [4 /*yield*/, contentApiClient.moveContent(contentId, payload.parent_id)];
                                        case 1:
                                            _a.sent();
                                            movedIds.push(contentId);
                                            return [3 /*break*/, 3];
                                        case 2:
                                            e_1 = _a.sent();
                                            console.log(e_1);
                                            showErrorMessage('ファイルの移動に失敗しました', e_1.response.data);
                                            return [3 /*break*/, 3];
                                        case 3: return [2 /*return*/];
                                    }
                                });
                            }); };
                        });
                        return [4 /*yield*/, sequentialPromises(promises, 1)];
                    case 1:
                        _b.sent();
                        commit(ContentMutationTypes.MOVE_CONTENTS, movedIds);
                        return [2 /*return*/, movedIds];
                }
            });
        });
    },
    // ユーザーのアップロード操作単位で処理を行う為
    // 処理終了時点で次の処理を実行します
    nextUpload: function (_a) {
        var state = _a.state;
        var queues = state.queues;
        var queue = queues[0];
        if (!queue) {
            return;
        }
        return queue();
    },
    // アップロード対象のファイル一覧を追加します
    addFileList: function (_a, payload) {
        var _this = this;
        var dispatch = _a.dispatch, commit = _a.commit, state = _a.state;
        var key = uuid4();
        var canceller = CancelToken.source();
        var progresses = payload.files.map(function (file) {
            return {
                file: file,
                canceller: canceller,
                name: file.name,
                progress: 0,
                canceled: false,
                status: null,
                errors: []
            };
        });
        commit(ContentMutationTypes.ADD_FILE_LIST, {
            progresses: progresses,
            key: key
        });
        // アップロード対象のファイルを管理する為即時実行していません
        var task = function () { return __awaiter(_this, void 0, void 0, function () {
            var processGroupId;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        processGroupId = uuid4();
                        return [4 /*yield*/, sequentialPromises(progresses.map(function (_a, index) {
                                var file = _a.file, canceller = _a.canceller;
                                return function () { return __awaiter(_this, void 0, void 0, function () {
                                    var res;
                                    return __generator(this, function (_a) {
                                        switch (_a.label) {
                                            case 0: return [4 /*yield*/, dispatch('uploadContent', {
                                                    file: file,
                                                    canceller: canceller,
                                                    index: index,
                                                    parentId: payload.parent_id,
                                                    key: key,
                                                    processGroupId: processGroupId
                                                })];
                                            case 1:
                                                res = _a.sent();
                                                if (res) {
                                                    dispatch('addContentToList', res.data.content);
                                                    if (res.data.meta && res.data.meta.directories) {
                                                        res.data.meta.directories.forEach(function (dir) {
                                                            dispatch('addContentToList', dir);
                                                        });
                                                    }
                                                }
                                                return [2 /*return*/];
                                        }
                                    });
                                }); };
                            }), Concurrency)];
                    case 1:
                        _a.sent();
                        commit(ContentMutationTypes.DELETE_QUEUE);
                        return [4 /*yield*/, dispatch('nextUpload')];
                    case 2:
                        _a.sent();
                        setTimeout(function () {
                            commit(ContentMutationTypes.REMOVE_FILE_LIST, key);
                        }, 3000);
                        return [2 /*return*/];
                }
            });
        }); };
        commit(ContentMutationTypes.ADD_QUEUE, task);
        // 最初のアップロード操作の時を対象にdispatchします
        if (state.queues.length === 1) {
            return dispatch('nextUpload');
        }
    },
    // アップロードが完了した時などにファイル一覧を削除します
    removeFileList: function (_a, payload) {
        var commit = _a.commit;
        commit(ContentMutationTypes.REMOVE_FILE_LIST, payload);
    },
    // 最大90までprogress表示を更新し続けます
    // レスポンスが返ってきた時100をセットします
    updateProgress: function (_a, _b) {
        var commit = _a.commit;
        var progressEvent = _b.progressEvent, index = _b.index, key = _b.key;
        var progress = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
        commit(ContentMutationTypes.UPDATE_PROGRESS, {
            progress: progress >= 90 ? 90 : progress,
            index: index,
            key: key
        });
    },
    uploadContent: function (_a, payload) {
        var dispatch = _a.dispatch, commit = _a.commit, getters = _a.getters, rootGetters = _a.rootGetters;
        return __awaiter(this, void 0, void 0, function () {
            var file, canceller, index, parentId, key, processGroupId, progressMap, progresses, canceled, config, params, res, company, content_1, e_2, errors;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        file = payload.file, canceller = payload.canceller, index = payload.index, parentId = payload.parentId, key = payload.key, processGroupId = payload.processGroupId;
                        progressMap = getters.progressMap;
                        progresses = progressMap[key];
                        // キャンセル時などは存在しません
                        if (!progresses) {
                            return [2 /*return*/];
                        }
                        canceled = progresses[index].canceled;
                        if (canceled) {
                            return [2 /*return*/];
                        }
                        config = {
                            cancelToken: canceller.token,
                            onUploadProgress: function (progressEvent) {
                                dispatch('updateProgress', { progressEvent: progressEvent, index: index, key: key });
                            }
                        };
                        params = {
                            content: { process_group_id: processGroupId }
                        };
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 5, , 6]);
                        return [4 /*yield*/, contentApiClient.uploadContent(parentId, file, {
                                cancelToken: canceller.token
                            }, params)];
                    case 2:
                        res = _b.sent();
                        if (!(res.status === 200)) return [3 /*break*/, 4];
                        // TODO: S3アップロード時のエラーハンドリング
                        return [4 /*yield*/, contentApiClient.uploadFileS3Request(res.data.meta, file, config)];
                    case 3:
                        // TODO: S3アップロード時のエラーハンドリング
                        _b.sent();
                        company = rootGetters['initial-setting/company'];
                        content_1 = res.data.content;
                        checkFirebaseAuthAndDoAction(function () {
                            contentApiClient.uploadFileFirebaseRequest(content_1);
                        }, company.id);
                        this.app.$gtag('event', Vue.prototype.$gaEvent.actionFileUpload);
                        _b.label = 4;
                    case 4: return [3 /*break*/, 6];
                    case 5:
                        e_2 = _b.sent();
                        errors = [];
                        if (e_2.response && e_2.response.data.message) {
                            errors = errors.concat(e_2.response.data.message);
                        }
                        commit(ContentMutationTypes.UPDATE_PROGRESS, {
                            index: index,
                            key: key,
                            progress: 100,
                            status: 'exception',
                            errors: errors
                        });
                        return [2 /*return*/];
                    case 6: return [4 /*yield*/, waitUplaod()];
                    case 7:
                        _b.sent();
                        commit(ContentMutationTypes.UPDATE_PROGRESS, { progress: 100, index: index, key: key });
                        return [2 /*return*/, res];
                }
            });
        });
    },
    // アップロードしたファイルが所属するフォルダを開いている時、一覧に追加します
    addContentToList: function (_a, payload) {
        var dispatch = _a.dispatch;
        var currentParentId = getCurrentParentId(this.$router);
        if (!currentParentId) {
            return;
        }
        if (currentParentId === payload.parent_id) {
            dispatch('setSortedContents', payload);
        }
    },
    // アップロード中のファイルを全件キャンセルします
    // ログアウト時や会社切り替え時などにdispatchします
    clearQueues: function (_a) {
        var commit = _a.commit;
        commit(ContentMutationTypes.CLEAR_QUEUES);
    },
    // アップロード操作単位でのキャンセル処理を行います
    cancelQueues: function (_a, key) {
        var commit = _a.commit, getters = _a.getters;
        var progressMap = getters.progressMap;
        var progresses = progressMap[key];
        if (!progresses) {
            return;
        }
        commit(ContentMutationTypes.CANCEL_FILES, {
            key: key,
            progresses: progresses.map(function (prog) {
                prog.canceller.cancel('アップロードをキャンセルしました');
                var status = prog.status || 'warning';
                return __assign(__assign({}, prog), { progress: 100, canceled: true, status: status });
            })
        });
        setTimeout(function () {
            commit(ContentMutationTypes.REMOVE_FILE_LIST, key);
        }, 3000);
    },
    // 作成・更新時はフロントソート処理を行い現在の一覧を保持します
    setSortedContents: function (_a, payload) {
        var _this = this;
        var dispatch = _a.dispatch, commit = _a.commit, getters = _a.getters;
        var currentContents = getters.contents, defaultSort = getters.defaultSort;
        var dupContents = __spreadArrays(currentContents);
        var index = dupContents.findIndex(function (cs) { return cs.id === payload.id; });
        if (dupContents.length && dupContents[0].parent_id !== payload.parent_id) {
            return;
        }
        if (index !== -1) {
            dupContents.splice(index, 1, payload);
        }
        else {
            dupContents.push(payload);
        }
        var sortedContents = sortBy(dupContents, defaultSort);
        sortedContents.sort(function (a, b) {
            if (a.info_content_type === 'text/directory' && b.info_content_type !== 'text/directory') {
                return -1;
            }
            else {
                return 0;
            }
        });
        var lastContents = sortedContents[sortedContents.length - 1];
        var isLastContent = lastContents.id === payload.id;
        if (isLastContent) {
            sortedContents.pop();
        }
        commit(ContentMutationTypes.SET_SORTED_CONTENTS, sortedContents);
        if (isLastContent) {
            var currentParentId = getCurrentParentId(this.$router);
            if (!currentParentId) {
                return;
            }
            // throttle
            if (currentParentId === payload.parent_id) {
                var fetchPayload_1 = {
                    id: payload.parent_id
                };
                if (timer || timer === 0) {
                    window.clearTimeout(timer);
                    if (lastTime + maxTime <= new Date().getTime()) {
                        lastTime = new Date().getTime();
                        dispatch('fetchContents', fetchPayload_1);
                        return;
                    }
                }
                timer = window.setTimeout(function () {
                    var currentParentId = getCurrentParentId(_this.$router);
                    if (currentParentId === payload.parent_id) {
                        lastTime = new Date().getTime();
                        dispatch('fetchContents', fetchPayload_1);
                    }
                }, interval);
            }
        }
    },
    retryFetchContents: function (_a, payload) {
        var commit = _a.commit, getters = _a.getters, rootGetters = _a.rootGetters;
        return __awaiter(this, void 0, void 0, function () {
            var defaultSort, params, res;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        defaultSort = getters.defaultSort;
                        params = {
                            plan_code: rootGetters['initial-setting/plan'].code,
                            offset: 0,
                            sort: {
                                target: defaultSort.prop,
                                order: defaultSort.order
                            },
                            ids: payload
                        };
                        return [4 /*yield*/, contentApiClient.searchContents(params)];
                    case 1:
                        res = _b.sent();
                        commit(ContentMutationTypes.RETRY_FETCH_CONTENTS, res.data.contents);
                        return [2 /*return*/];
                }
            });
        });
    },
    // 移動済みのファイルを処理します
    movedContent: function (_a, payload) {
        var commit = _a.commit;
        commit(ContentMutationTypes.DELETE_CONTENT, payload.id);
    },
    fileUpload: function (_, _a) {
        var parentId = _a.parentId, file = _a.file, params = _a.params;
        return __awaiter(this, void 0, void 0, function () {
            var res;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, contentApiClient.uploadContent(parentId, file, {}, params)];
                    case 1:
                        res = _b.sent();
                        if (!(res.status === 200)) return [3 /*break*/, 4];
                        return [4 /*yield*/, contentApiClient.uploadFileS3Request(res.data.meta, file)];
                    case 2:
                        _b.sent();
                        return [4 /*yield*/, contentApiClient.uploadFileFirebaseRequest(res.data.content)];
                    case 3:
                        _b.sent();
                        _b.label = 4;
                    case 4: return [2 /*return*/, res];
                }
            });
        });
    },
    copyContent: function (_a, _b) {
        var dispatch = _a.dispatch;
        var content = _b.content, parentId = _b.parentId, onClick = _b.onClick;
        return __awaiter(this, void 0, void 0, function () {
            var res, e_3;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 3, , 4]);
                        return [4 /*yield*/, contentApiClient.copyContent(content.id, { parentId: parentId })];
                    case 1:
                        res = _c.sent();
                        Notification.success({
                            title: 'ファイルを複製しました',
                            message: res.data.content.name,
                            onClick: onClick
                        });
                        return [4 /*yield*/, dispatch('addContentToList', res.data.content)];
                    case 2:
                        _c.sent();
                        return [2 /*return*/, res.data.content];
                    case 3:
                        e_3 = _c.sent();
                        showErrorMessage('エラーが発生しました', e_3.response.data);
                        return [2 /*return*/, false];
                    case 4: return [2 /*return*/];
                }
            });
        });
    },
    removeSharingContent: function (_a, payload) {
        var dispatch = _a.dispatch;
        return __awaiter(this, void 0, void 0, function () {
            var res;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, sharingContentApi.removeLink(payload.sharingContent.id)];
                    case 1:
                        res = _b.sent();
                        dispatch('replaceContent', __assign(__assign({}, payload.content), { sharing: false, sharing_content: res.data.sharing_content }));
                        return [2 /*return*/];
                }
            });
        });
    },
    changeSideType: function (_a, payload) {
        var commit = _a.commit;
        commit(ContentMutationTypes.CHANGE_SIDE_TYPE, payload);
    },
    createComment: function (_a, payload) {
        var commit = _a.commit, dispatch = _a.dispatch;
        return __awaiter(this, void 0, void 0, function () {
            var res;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, contentCommentApi.createComment({
                            contentId: payload.contentId,
                            text: payload.text,
                            mentioned_uids: payload.mentionedUids,
                            pin: payload.pin
                        })];
                    case 1:
                        res = _b.sent();
                        commit(ContentMutationTypes.ADD_COMMENT, res.data.content_comment);
                        // @ts-ignore
                        dispatch('content-ui/setNewPin', null, { root: true });
                        // @ts-ignore
                        dispatch('content-ui/changePinMode', false, { root: true });
                        // @ts-ignore
                        dispatch('content-ui/setTargetComment', null, { root: true });
                        return [2 /*return*/];
                }
            });
        });
    },
    deleteComment: function (_a, payload) {
        var commit = _a.commit, dispatch = _a.dispatch;
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, contentCommentApi.deleteComment(payload)];
                    case 1:
                        _b.sent();
                        commit(ContentMutationTypes.DELETE_COMMENT, payload.commentId);
                        dispatch('fetchComments', payload.contentId);
                        return [2 /*return*/];
                }
            });
        });
    },
    fetchComments: function (_a, payload) {
        var commit = _a.commit;
        return __awaiter(this, void 0, void 0, function () {
            var res;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, contentCommentApi.fetchComments(payload)];
                    case 1:
                        res = _b.sent();
                        commit(ContentMutationTypes.FETCH_COMMENTS, []);
                        commit(ContentMutationTypes.FETCH_COMMENTS, res.data.content_comments);
                        return [2 /*return*/];
                }
            });
        });
    },
    fetchMentionableUsers: function (_a, payload) {
        var commit = _a.commit;
        return __awaiter(this, void 0, void 0, function () {
            var res;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, contentCommentApi.fetchMentionableUsers({ contentId: payload })];
                    case 1:
                        res = _b.sent();
                        commit(ContentMutationTypes.FETCH_MENTIONABLE_USERS, res.data.mentionable_users);
                        return [2 /*return*/];
                }
            });
        });
    },
    // store/content-ui.tsから呼び出しています
    updatePin: function (_a, payload) {
        var commit = _a.commit, dispatch = _a.dispatch;
        return __awaiter(this, void 0, void 0, function () {
            var res;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, contentCommentApi.updatePin({
                            contentId: payload.contentId,
                            commentId: payload.comment.id,
                            pin: payload.pin
                        })];
                    case 1:
                        res = _b.sent();
                        commit(ContentMutationTypes.UPDATE_COMMENT, res.data.content_comment);
                        // @ts-ignore
                        dispatch('content-ui/changePinMode', false, { root: true });
                        // @ts-ignore
                        dispatch('content-ui/setTargetComment', null, { root: true });
                        dispatch('fetchComments', payload.contentId);
                        return [2 /*return*/];
                }
            });
        });
    },
    updateComment: function (_a, payload) {
        var commit = _a.commit;
        return __awaiter(this, void 0, void 0, function () {
            var res;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, contentCommentApi.updateComment(payload)];
                    case 1:
                        res = _b.sent();
                        commit(ContentMutationTypes.UPDATE_COMMENT, res.data.content_comment);
                        return [2 /*return*/];
                }
            });
        });
    },
    updatePdfPageCount: function (_a, payload) {
        var commit = _a.commit;
        return __awaiter(this, void 0, void 0, function () {
            var e_4;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, contentApiClient.updatePdfPageCount(payload)];
                    case 1:
                        _b.sent();
                        commit(ContentMutationTypes.UPDATE_PDF_PAGE_COUNT, payload.info_pdf_page_count);
                        return [3 /*break*/, 3];
                    case 2:
                        e_4 = _b.sent();
                        throw e_4;
                    case 3: return [2 /*return*/];
                }
            });
        });
    }
};
