var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Component, Mixins, Prop } from 'vue-property-decorator';
import NotificationIconUnread from '@/components/notification/atom/icon-unread.vue';
import ProviderNotificationBoard from '@/components/notification/organisms/provider-notification-board.vue';
import UserNotificationBoard from '@/components/notification/organisms/user-notification-board.vue';
import NotificationBaseMixin from '@/mixins/notification/base-mixin';
import showErrorMessage from '@/plugins/show-error-message';
import showLoading from '@/plugins/show-loading';
var Tabs;
(function (Tabs) {
    Tabs["UserNotice"] = "user-notification-board";
    Tabs["ProviderNotice"] = "provider-notification-board";
})(Tabs || (Tabs = {}));
var NotificationBoard = /** @class */ (function (_super) {
    __extends(NotificationBoard, _super);
    function NotificationBoard() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.activeTabName = Tabs.UserNotice;
        return _this;
    }
    Object.defineProperty(NotificationBoard.prototype, "isProviderNotificationUnread", {
        get: function () {
            var announcements = this.announcements;
            return announcements.length;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(NotificationBoard.prototype, "isUserNotificationTab", {
        get: function () {
            var activeTabName = this.activeTabName;
            return activeTabName === Tabs.UserNotice;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(NotificationBoard.prototype, "tabs", {
        get: function () {
            return [this.generateTab(Tabs.UserNotice), this.generateTab(Tabs.ProviderNotice)];
        },
        enumerable: false,
        configurable: true
    });
    NotificationBoard.prototype.isReadAllNotifications = function () {
        var _a = this, activeTabName = _a.activeTabName, notifications = _a.notifications, announcements = _a.announcements;
        switch (activeTabName) {
            case Tabs.ProviderNotice:
                return !announcements.length;
            case Tabs.UserNotice:
                return notifications.every(function (notification) {
                    return notification.read;
                });
        }
    };
    NotificationBoard.prototype.generateTab = function (tabName) {
        var tabs = [
            { name: Tabs.UserNotice, label: 'あなたへのお知らせ' },
            { name: Tabs.ProviderNotice, label: '運営からのお知らせ' }
        ];
        return tabs.find(function (_a) {
            var name = _a.name;
            return name === tabName;
        }) || tabs[0];
    };
    NotificationBoard.prototype.changeTabHandler = function (vm) {
        var activeTabName = this.activeTabName;
        if (activeTabName === vm.name)
            return;
        this.activeTabName = vm.name;
    };
    NotificationBoard.prototype.readAllNotificationHandler = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, activeTabName, user, loading, e_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this, activeTabName = _a.activeTabName, user = _a.user;
                        if (activeTabName === Tabs.ProviderNotice) {
                            this.allReadAnnouncements(user.uid);
                            this.$gtag('event', this.$gaEvent.actionNotificationReadAllUser);
                            return [2 /*return*/];
                        }
                        loading = showLoading();
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, 4, 5]);
                        return [4 /*yield*/, this.readAllNotification()];
                    case 2:
                        _b.sent();
                        this.$gtag('event', this.$gaEvent.actionNotificationReadAllProvider);
                        return [3 /*break*/, 5];
                    case 3:
                        e_1 = _b.sent();
                        console.log(e_1);
                        showErrorMessage('既読に失敗しました', e_1.response.data);
                        return [3 /*break*/, 5];
                    case 4:
                        loading.close();
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    __decorate([
        Prop({ type: Object, required: true })
    ], NotificationBoard.prototype, "user", void 0);
    NotificationBoard = __decorate([
        Component({
            components: { ProviderNotificationBoard: ProviderNotificationBoard, UserNotificationBoard: UserNotificationBoard, NotificationIconUnread: NotificationIconUnread }
        })
    ], NotificationBoard);
    return NotificationBoard;
}(Mixins(NotificationBaseMixin)));
export default NotificationBoard;
