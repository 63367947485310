var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
var _a;
import Vue from 'vue';
import Firebase from 'firebase/app';
import { ReportMutationTypes } from '@/types/report';
import { DownloadType, FirestoreCollectionNames } from '@/types';
import reportApiClient from '@/plugins/apis/report-api-client';
import downloadFile from '@/plugins/download-file';
import checkFirebaseAuthAndDoAction from '@/plugins/check-firebase-auth-and-do-action';
export var state = function () { return ({
    reports: [],
    report: {
        id: '',
        pdf_url: '',
        user_id: '',
        project_id: '',
        title: '',
        description: '',
        content_id: '',
        last_updated_user: {
            id: '',
            email: '',
            name: '',
            user_type: ''
        },
        created_at: '',
        updated_at: '',
        report_pages: [],
        policies: {}
    },
    defaultSort: {
        prop: 'title',
        order: 'desc'
    },
    paginate: {
        current_limit: 1,
        current_offset: 0,
        total_count: 0
    },
    // 報告書ファイル情報エリアの表示フラグ
    isActiveInfoBar: false
}); };
export var getters = {
    reports: function (state) {
        return state.reports;
    },
    report: function (state) {
        var reportPages = __spreadArrays(state.report.report_pages);
        var sortedPages = reportPages
            .filter(function (page) {
            return !page.deleted;
        })
            .sort(function (a, b) {
            return a.page_num - b.page_num;
        });
        return __assign(__assign({}, state.report), { report_pages: sortedPages });
    },
    defaultSort: function (state) {
        return state.defaultSort;
    },
    paginate: function (state) {
        return state.paginate;
    },
    isActiveInfoBar: function (state) {
        return state.isActiveInfoBar;
    }
};
export var mutations = (_a = {},
    _a[ReportMutationTypes.INIT_STATE] = function (state, payload) {
        Object.assign(state, payload);
    },
    _a[ReportMutationTypes.SET_SORT_TYPE] = function (state, payload) {
        state.defaultSort = payload;
    },
    _a[ReportMutationTypes.TOGGLE_INFO_BAR] = function (state, payload) {
        state.isActiveInfoBar = payload;
    },
    _a[ReportMutationTypes.INIT_REPORTS] = function (state, _a) {
        var reports = _a.reports, paginate = _a.paginate;
        state.reports = reports;
        state.paginate = paginate;
    },
    _a[ReportMutationTypes.FETCH_REPORTS] = function (state, _a) {
        var reports = _a.reports, paginate = _a.paginate;
        var ids = state.reports.map(function (c) { return c.id; });
        var filteredList = reports.filter(function (c) {
            return !ids.includes(c.id);
        });
        state.reports = state.reports.concat(filteredList);
        state.paginate = paginate;
    },
    _a[ReportMutationTypes.FETCH_REPORT] = function (state, payload) {
        state.report = payload;
    },
    _a[ReportMutationTypes.SET_SORTED_REPORTS] = function (state, payload) {
        state.reports = payload;
    },
    _a[ReportMutationTypes.DELETE_REPORT] = function (state, payload) {
        var index = state.reports.findIndex(function (cs) { return cs.id === payload; });
        if (index !== -1) {
            Vue.delete(state.reports, index);
        }
    },
    _a);
export var actions = {
    initState: function (_a) {
        var commit = _a.commit;
        commit(ReportMutationTypes.INIT_STATE, state());
    },
    changeSort: function (_a, payload) {
        var commit = _a.commit;
        commit(ReportMutationTypes.SET_SORT_TYPE, payload);
    },
    toggleInfoBar: function (_a, payload) {
        var commit = _a.commit, getters = _a.getters;
        if (payload === void 0) { payload = !getters.isActiveInfoBar; }
        commit(ReportMutationTypes.TOGGLE_INFO_BAR, payload);
    },
    resetReport: function (_a) {
        var commit = _a.commit;
        commit(ReportMutationTypes.FETCH_REPORT, state().report);
    },
    initReports: function (_a, payload) {
        var getters = _a.getters, commit = _a.commit;
        return __awaiter(this, void 0, void 0, function () {
            var defaultSort, params, res, _b, reports, paginate;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        defaultSort = getters.defaultSort;
                        params = {
                            project_id: payload,
                            offset: 0,
                            sort: {
                                target: defaultSort.prop,
                                order: defaultSort.order
                            }
                        };
                        return [4 /*yield*/, reportApiClient.fetchReports(params)];
                    case 1:
                        res = _c.sent();
                        _b = res.data, reports = _b.reports, paginate = _b.meta.paginate;
                        commit(ReportMutationTypes.INIT_REPORTS, { reports: reports, paginate: paginate });
                        return [2 /*return*/];
                }
            });
        });
    },
    fetchReports: function (_a, payload) {
        var getters = _a.getters, commit = _a.commit;
        return __awaiter(this, void 0, void 0, function () {
            var currentReports, defaultSort, params, res, _b, reports, paginate;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        currentReports = getters.reports, defaultSort = getters.defaultSort;
                        params = {
                            project_id: payload,
                            offset: currentReports.length,
                            sort: {
                                target: defaultSort.prop,
                                order: defaultSort.order
                            }
                        };
                        return [4 /*yield*/, reportApiClient.fetchReports(params)];
                    case 1:
                        res = _c.sent();
                        _b = res.data, reports = _b.reports, paginate = _b.meta.paginate;
                        commit(ReportMutationTypes.FETCH_REPORTS, { reports: reports, paginate: paginate });
                        return [2 /*return*/];
                }
            });
        });
    },
    fetchReport: function (_a, payload) {
        var commit = _a.commit;
        return __awaiter(this, void 0, void 0, function () {
            var res;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, reportApiClient.fetchReport(payload)];
                    case 1:
                        res = _b.sent();
                        commit(ReportMutationTypes.FETCH_REPORT, res.data.report);
                        return [2 /*return*/];
                }
            });
        });
    },
    // 新規作成 => idを得てedit画面へ、となる為一覧に直接追加はしない
    createReport: function (_, payload) {
        return reportApiClient.createReport(payload);
    },
    updateComment: function (_a, payload) {
        var dispatch = _a.dispatch, getters = _a.getters;
        return __awaiter(this, void 0, void 0, function () {
            var report;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        report = getters.report;
                        return [4 /*yield*/, reportApiClient.updateReport(report.id, payload)];
                    case 1:
                        _b.sent();
                        dispatch('fetchReport', report.id);
                        return [2 /*return*/];
                }
            });
        });
    },
    deleteReport: function (_a, payload) {
        var commit = _a.commit;
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, reportApiClient.deleteReport(payload)];
                    case 1:
                        _b.sent();
                        commit(ReportMutationTypes.DELETE_REPORT, payload);
                        return [2 /*return*/];
                }
            });
        });
    },
    downloadPDF: function (_a, payload) {
        var _this = this;
        var dispatch = _a.dispatch, rootGetters = _a.rootGetters;
        dispatch(
        // @ts-ignore
        'enqueueDownload', {
            name: payload.title,
            promise: (function () { return __awaiter(_this, void 0, void 0, function () {
                var document_id;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, reportApiClient.downloadPDF(payload.id)];
                        case 1:
                            document_id = (_a.sent()).data.document_id;
                            return [2 /*return*/, new Promise(function (resolve) {
                                    checkFirebaseAuthAndDoAction(function () {
                                        var unsubscribe = Firebase.firestore()
                                            .collection(FirestoreCollectionNames.STATUS_REPORT_PDF_GENERATE)
                                            .doc(document_id)
                                            .onSnapshot(function (doc) {
                                            var data = doc.data();
                                            if (!data)
                                                return;
                                            unsubscribe();
                                            resolve(downloadFile(data.url, 'application/pdf', payload.title + ".pdf"));
                                        });
                                    }, rootGetters['initial-setting/company'].id);
                                })];
                    }
                });
            }); })(),
            id: payload.id,
            type: DownloadType.report
        }, 
        // @ts-ignore
        { root: true });
    }
};
