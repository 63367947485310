/**
 * 画面遷移時、queryに値を追加し遷移元を特定出来るようにします
 * e.g.)
 * this.$router.push({
 *  ...to,
 *  query: { [ReferrerKeys.ProjectReportIndex]: null }
 * })
 *
 * this.$route.query.hasOwnProperty(ReportReferrerKeys.ProjectReportIndex) // => true
 */
export var ReportReferrerKeys;
(function (ReportReferrerKeys) {
    ReportReferrerKeys["ProjectReportIndex"] = "pj";
    ReportReferrerKeys["Edit"] = "edit";
})(ReportReferrerKeys || (ReportReferrerKeys = {}));
export var ImageDegrees;
(function (ImageDegrees) {
    ImageDegrees[ImageDegrees["ROTATE_0"] = 0] = "ROTATE_0";
    ImageDegrees[ImageDegrees["ROTATE_90"] = 90] = "ROTATE_90";
    ImageDegrees[ImageDegrees["ROTATE_180"] = 180] = "ROTATE_180";
    ImageDegrees[ImageDegrees["ROTATE_270"] = 270] = "ROTATE_270";
})(ImageDegrees || (ImageDegrees = {}));
export var ReportMutationTypes;
(function (ReportMutationTypes) {
    ReportMutationTypes["INIT_STATE"] = "INIT_STATE";
    ReportMutationTypes["SET_SORT_TYPE"] = "SET_SORT_TYPE";
    ReportMutationTypes["INIT_REPORTS"] = "INIT_REPORTS";
    ReportMutationTypes["FETCH_REPORTS"] = "FETCH_REPORTS";
    ReportMutationTypes["FETCH_REPORT"] = "FETCH_REPORT";
    ReportMutationTypes["DELETE_REPORT"] = "DELETE_REPORT";
    ReportMutationTypes["SET_SORTED_REPORTS"] = "SET_SORTED_REPORTS";
    ReportMutationTypes["TOGGLE_INFO_BAR"] = "TOGGLE_INFO_BAR";
})(ReportMutationTypes || (ReportMutationTypes = {}));
