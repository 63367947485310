var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import BaseApiClient from '@/plugins/apis/base-api-client';
var CustomerApiClient = /** @class */ (function (_super) {
    __extends(CustomerApiClient, _super);
    function CustomerApiClient() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(CustomerApiClient.prototype, "basePath", {
        get: function () {
            var companyId = this.companyId;
            return "/companies/" + companyId + "/customers";
        },
        enumerable: false,
        configurable: true
    });
    CustomerApiClient.prototype.fetchCustomers = function (params) {
        var _a = this, basePath = _a.basePath, client = _a.client;
        return client.get(basePath, { params: params });
    };
    CustomerApiClient.prototype.fetchCustomer = function (id) {
        var _a = this, basePath = _a.basePath, client = _a.client;
        var url = basePath + "/" + id;
        return client.get(url);
    };
    CustomerApiClient.prototype.searchCustomers = function (params) {
        var _a = this, basePath = _a.basePath, client = _a.client;
        return client.get(basePath, { params: params });
    };
    CustomerApiClient.prototype.createCustomer = function (data) {
        var _a = this, basePath = _a.basePath, client = _a.client;
        return client.post(basePath, { customer: data });
    };
    CustomerApiClient.prototype.updateCustomer = function (id, data) {
        var _a = this, basePath = _a.basePath, client = _a.client;
        var url = basePath + "/" + id;
        return client.put(url, { customer: data });
    };
    CustomerApiClient.prototype.deleteCustomer = function (id) {
        var _a = this, basePath = _a.basePath, client = _a.client;
        var url = basePath + "/" + id;
        return client.delete(url);
    };
    return CustomerApiClient;
}(BaseApiClient));
export default new CustomerApiClient();
