var _a;
import { RootMutationTypes } from '@/types';
import showErrorMessage from '@/plugins/show-error-message';
var STORE_NAMES = Object.freeze(require
    .context('../store/', true, /\.ts$/)
    .keys()
    .map(function (filename) {
    var name = filename.match(/^\.\/(.*)\.ts$/);
    if (name) {
        return name[1];
    }
})
    .filter(function (name) { return name; }));
export var state = function () { return ({
    isActiveInfoBar: false,
    downloadQueues: []
}); };
export var getters = {
    isActiveInfoBar: function (state) {
        return state.isActiveInfoBar;
    },
    totalGuestCount: function (state, getters, _, rootGetters) {
        var guestUsers = rootGetters['member/guestUsers'];
        var guestInvitedUsers = rootGetters['member/guestInvitedUsers'];
        return guestUsers.length + guestInvitedUsers.length;
    },
    downloadQueues: function (state) {
        return state.downloadQueues;
    }
};
export var mutations = (_a = {},
    _a[RootMutationTypes.INIT_STATE] = function (state, payload) {
        Object.assign(state, payload);
    },
    _a[RootMutationTypes.TOGGLE_INFO_BAR] = function (state, payload) {
        state.isActiveInfoBar = payload;
    },
    _a[RootMutationTypes.ENQUEUE_DOWNLOAD] = function (state, payload) {
        state.downloadQueues.push(payload);
    },
    _a[RootMutationTypes.COMPLETED_QUEUE] = function (state, payload) {
        state.downloadQueues = state.downloadQueues.map(function (queue) {
            if (queue.promise === payload.promise) {
                queue.status = 'success';
            }
            return queue;
        });
    },
    _a[RootMutationTypes.FAILED_QUEUE] = function (state, payload) {
        state.downloadQueues = state.downloadQueues.map(function (queue) {
            if (queue.promise === payload.promise) {
                queue.status = 'exception';
            }
            return queue;
        });
    },
    _a[RootMutationTypes.CLEAR_QUEUE] = function (state) {
        state.downloadQueues = [];
    },
    _a);
export var actions = {
    initState: function (_a) {
        var commit = _a.commit;
        commit(RootMutationTypes.INIT_STATE, state());
    },
    clearState: function (_a) {
        var dispatch = _a.dispatch;
        STORE_NAMES.forEach(function (name) {
            if (name === 'index') {
                return dispatch('initState');
            }
            // HACK: 各moduleの初期化処理をdispatchするため暫定でignoreします
            // @ts-ignore
            dispatch(name + "/initState");
        });
    },
    toggleInfoBar: function (_a, payload) {
        var commit = _a.commit, getters = _a.getters;
        if (payload === void 0) { payload = !getters.isActiveInfoBar; }
        if (payload) {
            if (window.document.fullscreenElement) {
                window.document.exitFullscreen();
            }
        }
        commit(RootMutationTypes.TOGGLE_INFO_BAR, payload);
    },
    enqueueDownload: function (_a, payload) {
        var commit = _a.commit;
        commit(RootMutationTypes.ENQUEUE_DOWNLOAD, payload);
        payload.promise
            .catch(function (e) {
            var _a;
            commit(RootMutationTypes.FAILED_QUEUE, payload);
            showErrorMessage(payload.name + "\u306E\u30C0\u30A6\u30F3\u30ED\u30FC\u30C9\u306B\u5931\u6557\u3057\u307E\u3057\u305F", (_a = e === null || e === void 0 ? void 0 : e.response) === null || _a === void 0 ? void 0 : _a.data);
        })
            .finally(function () {
            commit(RootMutationTypes.COMPLETED_QUEUE, payload);
        });
    },
    clearQueue: function (_a) {
        var commit = _a.commit;
        commit(RootMutationTypes.CLEAR_QUEUE);
    }
};
