var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import get from '@/plugins/get';
/*
 * 比較対象が同値の場合
 * updated_at -> created_at
 * の順に比較していきます
 *
 * 空文字/nullについては
 * 昇順
 * null -> 空文字列 -> 文字列
 * 降順
 * 文字列 -> 空文字列 -> null
 * とします
 */
var OrderTypes = ['asc', 'desc'];
var TimeKeys = ['created_at', 'updated_at'];
var sortResources = function (resources, key, order) {
    var targetArray = __spreadArrays(resources);
    var _a = partition(targetArray, key), nulls = _a.nulls, blank = _a.blank, present = _a.present;
    var sortCallback = function (a, b) { return compare(a, b, key, order); };
    if (order === 'asc') {
        return nulls
            .sort(sortCallback)
            .concat(blank.sort(sortCallback))
            .concat(present.sort(sortCallback));
    }
    return present
        .sort(sortCallback)
        .concat(blank.sort(sortCallback))
        .concat(nulls.sort(sortCallback));
};
var compare = function (a, b, key, order) {
    var vector = order === 'asc' ? -1 : 1;
    var aValue = TimeKeys.includes(key)
        ? new Date(a[key])
        : get(a, key);
    var bValue = TimeKeys.includes(key)
        ? new Date(b[key])
        : get(b, key);
    // 同値の場合は updated_atのdesc -> created_atのdescの順に比較していく
    if (aValue === bValue) {
        if (key === 'created_at') {
            return 1;
        }
        if (key === 'updated_at') {
            return compare(a, b, 'created_at', 'desc');
        }
        return compare(a, b, 'updated_at', 'desc');
    }
    if (aValue < bValue) {
        return vector;
    }
    return -1 * vector;
};
// 文字列、空文字、nullでarrayを分けます
var partition = function (resources, key) {
    var present = [];
    var blank = [];
    var nulls = [];
    resources.forEach(function (resource) {
        switch (resource[key]) {
            case '':
                blank.push(resource);
                break;
            case null:
                nulls.push(resource);
                break;
            default:
                present.push(resource);
                break;
        }
    });
    return { present: present, blank: blank, nulls: nulls };
};
export default sortResources;
