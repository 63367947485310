import { isAdmin, isMember } from '@/policies/';
var content = {
    index: function () {
        return true;
    },
    show: function () {
        return true;
    },
    // パンくずの末端より判定します
    // ルート、取引先フォルダ直下でゲストのアップロードは不可とします
    // 現状一覧画面でアップロードが前提となっています
    // （必ずパンくずが最新化されている
    // （編集画面のアップロードはファイルを開いている = 同一階層へのアップロード権限がある
    create: function (company, options) {
        var isAdminOrMember = isAdmin(company.user_type) || isMember(company.user_type);
        var ancestors = options.ancestors;
        // ルートではパンくずが無い = ゲスト以外許可
        if (!ancestors || ancestors.length === 0) {
            return isAdminOrMember;
        }
        var ancestor = ancestors.slice(-1)[0];
        // 案件フォルダ内では一覧が取得出来ている = 権限があるとみなす
        if (ancestor.project_id) {
            return true;
        }
        // プロジェクトと結びつかない場合（取引先フォルダの直下）は必ずゲストに関連しない
        return isAdminOrMember;
    },
    // ゲストは自身がアップロードしたファイルのみ編集・削除出来る
    update: function (company, options) {
        if (isAdmin(company.user_type) || isMember(company.user_type)) {
            return true;
        }
        if (!options.content) {
            return false;
        }
        return options.content.created_user_id === options.user.id;
    },
    delete: function (company, options) {
        return this.update(company, options);
    }
};
export default content;
