// 汎用的なアラートメッセージ
export var alertMessage = {
    CREATE_SUCCESS: function (resourceType) {
        return resourceType + "\u3092\u4F5C\u6210\u3057\u307E\u3057\u305F";
    },
    CREATE_ERROR: function (resourceType) {
        return resourceType + "\u306E\u4F5C\u6210\u306B\u5931\u6557\u3057\u307E\u3057\u305F";
    },
    ADD_SUCCESS: function (resourceType) {
        return resourceType + "\u3092\u8FFD\u52A0\u3057\u307E\u3057\u305F";
    },
    ADD_ERROR: function (resourceType) {
        return resourceType + "\u306E\u8FFD\u52A0\u306B\u5931\u6557\u3057\u307E\u3057\u305F";
    },
    POST_SUCCESS: function (resourceType) {
        return resourceType + "\u3092\u6295\u7A3F\u3057\u307E\u3057\u305F";
    },
    POST_ERROR: function (resourceType) {
        return resourceType + "\u306E\u6295\u7A3F\u306B\u5931\u6557\u3057\u307E\u3057\u305F";
    },
    UPDATE_SUCCESS: function (resourceType) {
        return resourceType + "\u3092\u66F4\u65B0\u3057\u307E\u3057\u305F";
    },
    UPDATE_ERROR: function (resourceType) {
        return resourceType + "\u306E\u66F4\u65B0\u306B\u5931\u6557\u3057\u307E\u3057\u305F";
    },
    DELETE_SUCCESS: function (resourceType) {
        return resourceType + "\u3092\u524A\u9664\u3057\u307E\u3057\u305F";
    },
    DELETE_ERROR: function (resourceType) {
        return resourceType + "\u306E\u524A\u9664\u306B\u5931\u6557\u3057\u307E\u3057\u305F";
    },
    CLOSE_SUCCESS: function (resourceType) {
        return resourceType + "\u3092\u30AF\u30ED\u30FC\u30BA\u3057\u307E\u3057\u305F";
    },
    CLOSE_ERROR: function (resourceType) {
        return resourceType + "\u306E\u30AF\u30ED\u30FC\u30BA\u306B\u5931\u6557\u3057\u307E\u3057\u305F";
    },
    PUBLISH_SUCCESS: function (resourceType) {
        return resourceType + "\u3092\u516C\u958B\u3057\u307E\u3057\u305F";
    },
    PUBLISH_ERROR: function (resourceType) {
        return resourceType + "\u306E\u516C\u958B\u306B\u5931\u6557\u3057\u307E\u3057\u305F";
    },
    DOWNLOAD_SUCCESS: function () {
        return 'ダウンロードに成功しました';
    },
    DOWNLOAD_ERROR: function () {
        return 'ダウンロードに失敗しました';
    },
    UPDATE_PERIOD_SUCCESS: function (resourceType) {
        return resourceType + "\u306E\u5DE5\u671F\u3092\u66F4\u65B0\u3057\u307E\u3057\u305F";
    },
    UPDATE_PERIOD_ERROR: function (resourceType) {
        return resourceType + "\u306E\u5DE5\u671F\u3092\u66F4\u65B0\u3067\u304D\u307E\u305B\u3093\u3067\u3057\u305F";
    }
};
// 固有のアラートメッセージ
export var otherAlertMessage = {
    UNREAD_USERS_NOTIFICATION_SUCCESS: "\u672A\u8AAD\u306E\u30E6\u30FC\u30B6\u30FC\u3078\u304A\u77E5\u3089\u305B\u3057\u307E\u3057\u305F",
    UNREAD_USERS_NOTIFICATION_ERROR: "\u672A\u8AAD\u306E\u30E6\u30FC\u30B6\u30FC\u3078\u306E\u304A\u77E5\u3089\u305B\u306B\u5931\u6557\u3057\u307E\u3057\u305F",
    CREATE_INVITATION_LINK_SUCCESS: '招待リンクをコピーしました',
    CREATE_INVITATION_LINK_ERROR: '招待リンクの作成に失敗しました',
    DELETE_INVITATION_LINK_SUCCESS: '招待リンクを削除しました',
    DELETE_INVITATION_LINK_ERROR: '招待リンクの削除に失敗しました',
    CREATE_INVITE_SUCCESS: 'ユーザーを招待しました',
    CREATE_INVITE_ERROR: 'ユーザーの招待に失敗しました',
    DELETE_INVITE_SUCCESS: '招待をキャンセルしました',
    DELETE_INVITE_ERROR: '招待のキャンセルに失敗しました',
    DELETE_MEMBER_SUCCESS: 'メンバーを解除しました',
    DELETE_MEMBER_ERROR: 'メンバーの解除に失敗しました',
    UPDATE_INVITE_SUCCESS: '招待情報を変更しました',
    UPDATE_INVITE_ERROR: '招待情報の更新に失敗しました'
};
