var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import Vue from 'vue';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { required, max } from 'vee-validate/dist/rules';
import * as CustomRules from '~/utils/validation-custom-rules';
var ja = require('vee-validate/dist/locale/ja');
export default (function () {
    Vue.component('ValidationProvider', ValidationProvider);
    Vue.component('ValidationObserver', ValidationObserver);
    extend('required', __assign(__assign({}, required), { message: ja.messages.required }));
    extend('max', __assign(__assign({}, max), { message: ja.messages.max }));
});
for (var rule in CustomRules) {
    var customRules = CustomRules;
    extend(rule, customRules[rule]);
}
