export var StaticPeriods;
(function (StaticPeriods) {
    StaticPeriods["LAST_30_DAYS"] = "last_30_days";
    StaticPeriods["LAST_3_MONTHS"] = "last_3_months";
    StaticPeriods["THIS_YEAR"] = "this_year";
    StaticPeriods["LAST_YEAR"] = "last_year";
})(StaticPeriods || (StaticPeriods = {}));
export var SearchProjectMutationTypes;
(function (SearchProjectMutationTypes) {
    SearchProjectMutationTypes["INIT_STATE"] = "INIT_STATE";
    SearchProjectMutationTypes["SET_SEARCH_FORM"] = "SET_SEARCH_FORM";
    SearchProjectMutationTypes["ADD_SEARCH_FORM_TAG"] = "ADD_SEARCH_FORM_TAG";
    SearchProjectMutationTypes["DELETE_SEARCH_FORM_TAG"] = "DELETE_SEARCH_FORM_TAG";
    SearchProjectMutationTypes["SEARCH_PROJECTS"] = "SEARCH_PROJECTS";
    SearchProjectMutationTypes["SUGGESTED_TAGS"] = "SUGGESTED_TAGS";
    SearchProjectMutationTypes["SEARCH_HISTORIES"] = "SEARCH_HISTORIES";
})(SearchProjectMutationTypes || (SearchProjectMutationTypes = {}));
