import { datadogRum } from '@datadog/browser-rum';
if (process.env.appEnv === 'staging' || process.env.appEnv === 'production') {
    datadogRum.init({
        applicationId: process.env.applicationId || '',
        clientToken: process.env.clientToken || '',
        site: 'datadoghq.com',
        service: 'careecon_work_frontend',
        env: process.env.appEnv || '',
        // Specify a version number to identify the deployed version of your application in Datadog
        // version: '1.0.0',
        sampleRate: 100,
        trackInteractions: true,
        defaultPrivacyLevel: 'mask-user-input'
    });
    datadogRum.startSessionReplayRecording();
}
