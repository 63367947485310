import { isAdmin } from '@/policies/';
var constructionKind = {
    index: function () {
        return true;
    },
    show: function () {
        return true;
    },
    create: function (company) {
        return isAdmin(company.user_type);
    },
    update: function (company) {
        return isAdmin(company.user_type);
    },
    delete: function (company) {
        return isAdmin(company.user_type);
    }
};
export default constructionKind;
