export var Routes = {
    // Session
    SessionSignInPage: {
        name: 'sign_in',
        screen_name: 'セッション/ログイン'
    },
    SessionSignOutPage: {
        name: 'logout',
        screen_name: 'セッション/ログアウト'
    },
    SessionCallbackPage: {
        name: 'sessions-callback',
        screen_name: 'セッション/コールバック'
    },
    SessionInvitationPage: {
        name: 'sessions-invitation',
        screen_name: '[Deprecated] セッション/招待'
    },
    // Initial Setting
    InitialSettingsUserProfilePage: {
        name: 'initial-settings-user-profile',
        screen_name: '初期設定/ユーザープロフィール設定'
    },
    InitialSettingsCompanyProfilePage: {
        name: 'initial-settings-company-profile',
        screen_name: '初期設定/組織プロフィール設定'
    },
    InitialSettingsPreferencesPage: {
        name: 'initial-settings-preferences',
        screen_name: '初期設定/環境設定'
    },
    // Company --
    CompanyFilesRootPage: {
        name: 'companies-company_id-files',
        screen_name: 'ファイル/一覧/トップ'
    },
    CompanyFilesListPage: {
        name: 'companies-company_id-files-id',
        screen_name: 'ファイル/一覧/下層'
    },
    CompanyFilesEditPage: {
        name: 'companies-company_id-files-id-edit',
        screen_name: 'ファイル/編集'
    },
    CompanyFilesPreviewPage: {
        name: 'companies-company_id-files-id-preview',
        screen_name: 'ファイル/詳細'
    },
    // Project --
    ProjectListPage: {
        name: 'companies-company_id-projects',
        screen_name: '案件/一覧'
    },
    ProjectNewPage: {
        name: 'companies-company_id-projects-new',
        screen_name: '案件/新規作成'
    },
    ProjectPage: {
        name: 'companies-company_id-projects-id',
        screen_name: ''
    },
    ProjectDetailPage: {
        name: 'companies-company_id-projects-id-detail',
        screen_name: '案件/詳細'
    },
    ProjectFilesListPage: {
        name: 'companies-company_id-projects-id-files-file_id',
        screen_name: '案件/ファイル/一覧'
    },
    ProjectFilesEditPage: {
        name: 'companies-company_id-projects-id-files-file_id-edit',
        screen_name: '案件/ファイル/編集'
    },
    ProjectFilesPreviewPage: {
        name: 'companies-company_id-projects-id-files-file_id-preview',
        screen_name: '案件/ファイル/詳細'
    },
    ProjectReportsListPage: {
        name: 'companies-company_id-projects-id-reports',
        screen_name: '案件/報告書/一覧'
    },
    ProjectSchedulePage: {
        name: 'companies-company_id-projects-id-schedule',
        screen_name: '案件/工程表'
    },
    ProjectScheduleFullscreenPage: {
        name: 'companies-company_id-projects-id-schedule-fullscreen',
        screen_name: '案件/工程表/全画面'
    },
    ProjectSchedulePreviewPage: {
        name: 'companies-company_id-projects-id-schedule-preview',
        screen_name: '案件/工程表/プレビュー'
    },
    ProjectScheduleViewPage: {
        name: 'companies-company_id-projects-id-schedule-view',
        screen_name: '[Deprecated] 案件/工程表/ビュー'
    },
    ProjectScheduleViewFullscreenPage: {
        name: 'companies-company_id-projects-id-schedule-view-fullscreen',
        screen_name: '案件/工程表/公開済み'
    },
    ProjectSchedulePrintPage: {
        name: 'companies-company_id-projects-id-schedule-print',
        screen_name: '案件/工程表/印刷'
    },
    ProjectMembersListPage: {
        name: 'companies-company_id-projects-id-member',
        screen_name: '案件/案件メンバー/一覧'
    },
    // Board --
    BoardPostHomePage: {
        name: 'companies-company_id-projects-id-board_posts',
        screen_name: '案件/掲示板/一覧'
    },
    BoardPostNewPage: {
        name: 'companies-company_id-projects-id-board_posts-new',
        screen_name: '案件/掲示板/投稿'
    },
    BoardPostsPage: {
        name: 'companies-company_id-projects-id-board_posts-posts',
        screen_name: '案件/掲示板/カテゴリ詳細'
    },
    BoardPostDetailPage: {
        name: 'companies-company_id-projects-id-board_posts-board_id',
        screen_name: '案件/掲示板/詳細'
    },
    BoardPostEditPage: {
        name: 'companies-company_id-projects-id-board_posts-board_id-edit',
        screen_name: '案件/掲示板/編集'
    },
    BoardPostFilesPage: {
        name: 'companies-company_id-projects-id-board_posts-board_id-files',
        screen_name: '案件/掲示板/詳細/ファイル'
    },
    // Report --
    ReportListPage: {
        name: 'companies-company_id-reports',
        screen_name: '報告書/一覧'
    },
    ReportDetailPage: {
        name: 'companies-company_id-reports-id',
        screen_name: '報告書/詳細'
    },
    ReportEditPage: {
        name: 'companies-company_id-reports-id-edit',
        screen_name: '報告書/編集'
    },
    // Customer --
    CustomerListPage: {
        name: 'companies-company_id-customers',
        screen_name: '取引先/一覧'
    },
    CustomerNewPage: {
        name: 'companies-company_id-customers-new',
        screen_name: '取引先/新規作成'
    },
    CustomerDetailPage: {
        name: 'companies-company_id-customers-id',
        screen_name: '取引先/詳細'
    },
    CustomerEditPage: {
        name: 'companies-company_id-customers-id-edit',
        screen_name: '取引先/編集'
    },
    // Member --
    MemberListPage: {
        name: 'companies-company_id-members',
        screen_name: 'メンバー/一覧'
    },
    // Settings --
    SettingsUserProfilePage: {
        name: 'companies-company_id-user-profile',
        screen_name: '設定/ユーザープロフィール設定'
    },
    SettingsCompanyProfilePage: {
        name: 'companies-company_id-profile',
        screen_name: '設定/組織プロフィール設定'
    },
    SettingsCompanySettingPage: {
        name: 'companies-company_id-setting',
        screen_name: '設定/機能設定'
    },
    SettingsConstructionKindListPage: {
        name: 'companies-company_id-construction-kinds',
        screen_name: '設定/工事種別'
    },
    SettingsConstructionKindNewPage: {
        name: 'companies-company_id-construction-kinds-new',
        screen_name: '設定/工事種別/新規作成'
    },
    // Plan
    PlanExpirationPage: {
        name: 'companies-company_id-expiration',
        screen_name: 'プラン/有効期限切れ'
    },
    PlanUnavailabilityPage: {
        name: 'companies-company_id-unavailability',
        screen_name: 'プラン/利用停止'
    },
    SharingContentsPage: {
        name: 'companies-company_id-sharing_contents',
        screen_name: '共有/一覧'
    },
    PublicContentsPage: {
        name: 'contents-uuid',
        screen_name: '共有ファイル/一覧'
    },
    PublicContentPage: {
        name: 'contents-uuid-path+',
        screen_name: '共有ファイル'
    },
    SharingContentListPage: {
        name: 'companies-company_id-sharing-contents',
        screen_name: '共有中/一覧'
    }
};
export var ExternalLink;
(function (ExternalLink) {
    ExternalLink["UserTypeDescription"] = "https://support.work.careecon.jp/hc/ja/articles/360047754594-%E3%83%83%BC%E3%82%B6%E3%83%BC%E6%A8%A9%E9%99%90";
    ExternalLink["help"] = "https://support.work.careecon.jp/hc/ja";
    ExternalLink["privacyPolicy"] = "https://careecon-plus.com/privacy";
    ExternalLink["terms"] = "https://careecon-plus.com/products/sekoukanri/term";
})(ExternalLink || (ExternalLink = {}));
