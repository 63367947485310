var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
var _a;
import { ProcessStatus, SharedRangeType } from '@/types/content';
import { ReportEditorMutationTypes } from '@/types/report-editor';
import reportApiClient from '@/plugins/apis/report-api-client';
import debounce from '@/plugins/debounce';
import contentApiClient from '@/plugins/apis/content-api-client';
// undo/redoを行う時、commitを動的に行う為にmutation typeの対応表を持ちます
export var state = function () { return ({
    report: {
        id: '',
        pdf_url: '',
        user_id: '',
        project_id: '',
        title: '',
        description: '',
        content_id: '',
        last_updated_user: {
            id: '',
            email: '',
            name: '',
            user_type: ''
        },
        created_at: '',
        updated_at: '',
        report_pages: [],
        policies: {}
    },
    content: {
        id: '',
        url: '',
        original_content_id: '',
        original_content_url: '',
        s3_process_done: false,
        process_status: ProcessStatus.recordCreated,
        cover_url: '',
        thumb_url: '',
        hls_url: '',
        document_preview_url: '',
        parent_id: '',
        name: '',
        info_content_type: '',
        info_extension: '',
        root_model: null,
        created_user_id: '',
        shared_range_type: SharedRangeType.limitedUsers,
        shared_users: [],
        prev_id: '',
        next_id: '',
        sharing: false,
        ancestors_sharing: false,
        descendants_sharing: false,
        sharing_content: null,
        info_pdf_page_count: 0,
        pdf_image_urls: [],
        policies: {
            preview: false,
            download: false,
            move: false,
            copy: false,
            update: false,
            update_shared_range: false,
            destroy: false
        }
    },
    imageContents: [],
    movieContents: [],
    // ファイル一覧から項目をドラッグしているか
    dragging: false,
    // ドラッグ中の項目のid(content.id)
    draggedId: '',
    // ファイルファイル入力欄をクリックした時の対象情報
    activeMediaField: {
        field_name: '',
        report_page_id: ''
    },
    // ファイル一覧やページ一覧等の表示タイプ
    currentListType: '',
    undoHistories: [],
    redoHistories: [],
    // 自動保存テキストの状態
    autoSaveStatus: ''
}); };
export var getters = {
    report: function (state) {
        var reportPages = __spreadArrays(state.report.report_pages);
        var sortedPages = reportPages
            .filter(function (page) {
            return !page.deleted;
        })
            .sort(function (a, b) {
            return a.page_num - b.page_num;
        });
        return __assign(__assign({}, state.report), { report_pages: sortedPages });
    },
    content: function (state) {
        return state.content;
    },
    imageContents: function (state) {
        return state.imageContents;
    },
    movieContents: function (state) {
        return state.movieContents;
    },
    groupedContents: function (state) {
        return state.imageContents;
    },
    unProcessedReportContentCount: function (state) {
        return state.report.report_pages
            .map(function (page) { return page.report_parts; })
            .map(function (parts) { return parts.map(function (part) { return part.content; }); })
            .reduce(function (acc, cur) { return acc.concat(cur); }, [])
            .filter(function (content) { return !!content && !content.s3_process_done; }).length;
    },
    dragging: function (state) {
        return state.dragging;
    },
    draggedId: function (state) {
        return state.draggedId;
    },
    activeMediaField: function (state) {
        return state.activeMediaField;
    },
    currentListType: function (state) {
        return state.currentListType;
    },
    autoSaveStatus: function (state) {
        var report = state.report;
        if (!state.autoSaveStatus && report.created_at !== report.updated_at) {
            return 'updated_at';
        }
        return state.autoSaveStatus;
    },
    undoHistories: function (state) {
        return state.undoHistories;
    },
    redoHistories: function (state) {
        return state.redoHistories;
    }
};
export var mutations = (_a = {},
    _a[ReportEditorMutationTypes.INIT_STATE] = function (state, payload) {
        Object.assign(state, payload);
    },
    _a[ReportEditorMutationTypes.FETCH_REPORT] = function (state, payload) {
        state.report = payload;
    },
    _a[ReportEditorMutationTypes.TOGGLE_DRAGGING] = function (state, payload) {
        state.dragging = payload;
    },
    _a[ReportEditorMutationTypes.SET_DRAGGED_ITEM] = function (state, payload) {
        state.draggedId = payload;
    },
    _a[ReportEditorMutationTypes.SET_ACTIVE_MEDIA_FIELD] = function (state, payload) {
        state.activeMediaField = payload;
    },
    _a[ReportEditorMutationTypes.CHANGE_LIST_TYPE] = function (state, payload) {
        state.currentListType = payload;
    },
    _a[ReportEditorMutationTypes.CHANGE_SAVE_STATUS] = function (state, payload) {
        state.autoSaveStatus = payload;
    },
    _a[ReportEditorMutationTypes.RESET_SEARCH_CONTENTS] = function (state) {
        state.imageContents = [];
        state.movieContents = [];
    },
    _a[ReportEditorMutationTypes.SEARCH_IMAGE_CONTENS] = function (state, payload) {
        var ids = state.imageContents.map(function (c) { return c.id; });
        var filterdList = payload.filter(function (c) {
            return !ids.includes(c.id);
        });
        state.imageContents = state.imageContents.concat(filterdList);
    },
    _a[ReportEditorMutationTypes.SEARCH_MOVIE_CONTENS] = function (state, payload) {
        var ids = state.movieContents.map(function (c) { return c.id; });
        var filterdList = payload.filter(function (c) {
            return !ids.includes(c.id);
        });
        state.movieContents = state.movieContents.concat(filterdList);
    },
    _a[ReportEditorMutationTypes.SET_CONTENT] = function (state, payload) {
        state.content = payload;
    },
    _a[ReportEditorMutationTypes.ADD_UNDO_HISTORY] = function (state, payload) {
        state.undoHistories.push(payload);
        state.redoHistories = [];
    },
    _a[ReportEditorMutationTypes.UNDO_HISTORY] = function (state) {
        var history = state.undoHistories.pop();
        if (!history) {
            return;
        }
        var dupHistory = JSON.parse(JSON.stringify(history));
        var redoHistory = JSON.parse(JSON.stringify(state.report));
        state.redoHistories.push(redoHistory);
        // 履歴に存在しないページを削除対象として履歴に追加 -> API保存時に削除します
        dupHistory.report_pages = redoHistory.report_pages.map(function (historyPage) {
            var targetPage = dupHistory.report_pages.find(function (page) { return page.id === historyPage.id; });
            if (!targetPage) {
                return __assign(__assign({}, historyPage), { deleted: true });
            }
            return __assign(__assign({}, targetPage), { report_parts: historyPage.report_parts.map(function (part) {
                    var prePart = targetPage.report_parts.find(function (targetPart) { return targetPart.field_name === part.field_name; });
                    // 履歴に存在しないパーツは空で削除します
                    if (!prePart) {
                        var blankPart = __assign(__assign({}, part), { content_text: '', content_id: '', content: undefined });
                        return blankPart;
                    }
                    // 履歴ではファイルに紐付いていないが現在の状態では紐付いている時に紐付けを削除します
                    if (part.content && !prePart.content) {
                        var blankPart = __assign(__assign({}, part), { content_text: '', content_id: '', content: undefined });
                        return blankPart;
                    }
                    return prePart;
                }) });
        });
        state.report = dupHistory;
    },
    _a[ReportEditorMutationTypes.REDO_HISTORY] = function (state) {
        var history = state.redoHistories.pop();
        if (!history) {
            return;
        }
        var dup = JSON.parse(JSON.stringify(history));
        var undoHistory = JSON.parse(JSON.stringify(state.report));
        state.undoHistories.push(undoHistory);
        state.report = dup;
    },
    _a);
export var actions = {
    initState: function (_a) {
        var commit = _a.commit;
        commit(ReportEditorMutationTypes.INIT_STATE, state());
    },
    fetchReport: function (_a, payload) {
        var commit = _a.commit;
        return __awaiter(this, void 0, Promise, function () {
            var res;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, reportApiClient.fetchReport(payload)];
                    case 1:
                        res = _b.sent();
                        commit(ReportEditorMutationTypes.FETCH_REPORT, res.data.report);
                        return [2 /*return*/, res.data.report];
                }
            });
        });
    },
    toggleDragging: function (_a, payload) {
        var commit = _a.commit;
        commit(ReportEditorMutationTypes.TOGGLE_DRAGGING, payload);
    },
    changeListType: function (_a, payload) {
        var commit = _a.commit;
        commit(ReportEditorMutationTypes.CHANGE_LIST_TYPE, payload);
    },
    changeSaveStatus: function (_a, payload) {
        var commit = _a.commit;
        commit(ReportEditorMutationTypes.CHANGE_SAVE_STATUS, payload);
    },
    // エディター画面でファイルをドラッグした場合にデータを保持します
    setDraggedItem: function (_a, payload) {
        var commit = _a.commit;
        commit(ReportEditorMutationTypes.SET_DRAGGED_ITEM, payload);
    },
    // ドラッグ用に保持したデータをリセットします
    resetDraggedItem: function (_a) {
        var commit = _a.commit;
        commit(ReportEditorMutationTypes.SET_DRAGGED_ITEM, state().draggedId);
    },
    setActiveMediaField: function (_a, payload) {
        var commit = _a.commit;
        commit(ReportEditorMutationTypes.SET_ACTIVE_MEDIA_FIELD, payload);
    },
    resetActiveMediaField: function (_a) {
        var commit = _a.commit;
        commit(ReportEditorMutationTypes.SET_ACTIVE_MEDIA_FIELD, state().activeMediaField);
    },
    resetSearchContents: function (_a) {
        var commit = _a.commit;
        commit(ReportEditorMutationTypes.RESET_SEARCH_CONTENTS);
    },
    searchContents: function (_a, payload) {
        var commit = _a.commit, getters = _a.getters, rootGetters = _a.rootGetters;
        return __awaiter(this, void 0, void 0, function () {
            var imageContents, movieContents, report, isImage, params, res;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        imageContents = getters.imageContents, movieContents = getters.movieContents, report = getters.report;
                        isImage = payload.type === 'image';
                        params = {
                            plan_code: rootGetters['initial-setting/plan'].code,
                            offset: isImage ? imageContents.length : movieContents.length,
                            sort: {
                                target: 'updated_at',
                                order: 'desc'
                            },
                            name: payload.name,
                            type: payload.type,
                            limit: 20
                        };
                        if (report.project && report.project.id) {
                            params.project_id = report.project.id;
                            if (report.id) {
                                params.report_id = report.id;
                            }
                        }
                        else {
                            params.is_root = true;
                            params.unrelated = 'on';
                        }
                        return [4 /*yield*/, contentApiClient.searchContents(params)];
                    case 1:
                        res = _b.sent();
                        if (isImage) {
                            commit(ReportEditorMutationTypes.SEARCH_IMAGE_CONTENS, res.data.contents);
                        }
                        else {
                            commit(ReportEditorMutationTypes.SEARCH_MOVIE_CONTENS, res.data.contents);
                        }
                        return [2 /*return*/];
                }
            });
        });
    },
    setContent: function (_a, payload) {
        var commit = _a.commit;
        commit(ReportEditorMutationTypes.SET_CONTENT, payload);
    },
    closeFileModal: function (_a) {
        var commit = _a.commit;
        commit(ReportEditorMutationTypes.SET_CONTENT, state().content);
    },
    fileUpload: function (_, _a) {
        var parentId = _a.parentId, file = _a.file;
        return __awaiter(this, void 0, void 0, function () {
            var res;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, contentApiClient.uploadContent(parentId, file)];
                    case 1:
                        res = _b.sent();
                        if (!(res.status === 200)) return [3 /*break*/, 4];
                        // TODO: S3アップロード時のエラーハンドリング
                        return [4 /*yield*/, contentApiClient.uploadFileS3Request(res.data.meta, file)];
                    case 2:
                        // TODO: S3アップロード時のエラーハンドリング
                        _b.sent();
                        return [4 /*yield*/, contentApiClient.uploadFileFirebaseRequest(res.data.content)];
                    case 3:
                        _b.sent();
                        _b.label = 4;
                    case 4: return [2 /*return*/, res];
                }
            });
        });
    },
    updateTitle: function (_a, payload) {
        var getters = _a.getters, commit = _a.commit;
        return __awaiter(this, void 0, void 0, function () {
            var report, id, params, res, e_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        report = getters.report;
                        id = payload.id, params = payload.params;
                        commit(ReportEditorMutationTypes.CHANGE_SAVE_STATUS, 'saving');
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, reportApiClient.updateReport(id, params)];
                    case 2:
                        res = _b.sent();
                        commit(ReportEditorMutationTypes.ADD_UNDO_HISTORY, report);
                        commit(ReportEditorMutationTypes.FETCH_REPORT, res.data.report);
                        commit(ReportEditorMutationTypes.CHANGE_SAVE_STATUS, 'saved');
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _b.sent();
                        commit(ReportEditorMutationTypes.CHANGE_SAVE_STATUS, 'updated_at');
                        throw e_1;
                    case 4: return [2 /*return*/];
                }
            });
        });
    },
    // undo/redoなどの操作後に全体を保存する
    saveReport: function (_a) {
        var commit = _a.commit, state = _a.state;
        return __awaiter(this, void 0, void 0, function () {
            var report, res;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        report = state.report;
                        commit(ReportEditorMutationTypes.CHANGE_SAVE_STATUS, 'saving');
                        return [4 /*yield*/, reportApiClient.saveReport(report.id, report)];
                    case 1:
                        res = _b.sent();
                        commit(ReportEditorMutationTypes.FETCH_REPORT, res.data.report);
                        commit(ReportEditorMutationTypes.CHANGE_SAVE_STATUS, 'saved');
                        return [2 /*return*/];
                }
            });
        });
    },
    updateReportPart: function (_a, payload) {
        var commit = _a.commit, getters = _a.getters;
        return __awaiter(this, void 0, void 0, function () {
            var report, res;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        report = getters.report;
                        commit(ReportEditorMutationTypes.CHANGE_SAVE_STATUS, 'saving');
                        return [4 /*yield*/, reportApiClient.updateReportPart(report.id, payload)];
                    case 1:
                        res = _b.sent();
                        commit(ReportEditorMutationTypes.ADD_UNDO_HISTORY, report);
                        commit(ReportEditorMutationTypes.FETCH_REPORT, res.data.report);
                        commit(ReportEditorMutationTypes.CHANGE_SAVE_STATUS, 'saved');
                        return [2 /*return*/];
                }
            });
        });
    },
    updateMediaDegree: function (_a, payload) {
        var commit = _a.commit, getters = _a.getters;
        return __awaiter(this, void 0, void 0, function () {
            var report, res;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        report = getters.report;
                        commit(ReportEditorMutationTypes.CHANGE_SAVE_STATUS, 'saving');
                        return [4 /*yield*/, reportApiClient.updateMediaDegree(report.id, payload)];
                    case 1:
                        res = _b.sent();
                        commit(ReportEditorMutationTypes.ADD_UNDO_HISTORY, report);
                        commit(ReportEditorMutationTypes.FETCH_REPORT, res.data.report);
                        commit(ReportEditorMutationTypes.CHANGE_SAVE_STATUS, 'saved');
                        return [2 /*return*/];
                }
            });
        });
    },
    updateMediaField: function (_a, payload) {
        var commit = _a.commit, getters = _a.getters;
        return __awaiter(this, void 0, void 0, function () {
            var report, res;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        report = getters.report;
                        commit(ReportEditorMutationTypes.CHANGE_SAVE_STATUS, 'saving');
                        payload = __assign(__assign({}, payload), { rotation: 0 });
                        return [4 /*yield*/, reportApiClient.updateReportPart(report.id, payload)];
                    case 1:
                        res = _b.sent();
                        commit(ReportEditorMutationTypes.ADD_UNDO_HISTORY, report);
                        commit(ReportEditorMutationTypes.FETCH_REPORT, res.data.report);
                        commit(ReportEditorMutationTypes.CHANGE_SAVE_STATUS, 'saved');
                        return [2 /*return*/];
                }
            });
        });
    },
    createReportPage: function (_a, payload) {
        var getters = _a.getters, commit = _a.commit, dispatch = _a.dispatch;
        return __awaiter(this, void 0, void 0, function () {
            var report, template, page, params, res;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        report = getters.report;
                        template = payload.template, page = payload.page;
                        if (page) {
                            params = {
                                id: page.id,
                                report_id: report.id,
                                template_id: template.id,
                                page_num: page.page_num,
                                report_parts: [],
                                deleted: false
                            };
                            return [2 /*return*/, dispatch('copyReportPage', params)];
                        }
                        commit(ReportEditorMutationTypes.CHANGE_SAVE_STATUS, 'saving');
                        return [4 /*yield*/, reportApiClient.createReportPage(report.id, {
                                template_id: template.id,
                                page_num: report.report_pages.length + 1
                            })];
                    case 1:
                        res = _b.sent();
                        commit(ReportEditorMutationTypes.ADD_UNDO_HISTORY, report);
                        commit(ReportEditorMutationTypes.FETCH_REPORT, res.data.report);
                        commit(ReportEditorMutationTypes.CHANGE_SAVE_STATUS, 'saved');
                        return [2 /*return*/];
                }
            });
        });
    },
    copyReportPage: function (_a, payload) {
        var commit = _a.commit, getters = _a.getters;
        return __awaiter(this, void 0, void 0, function () {
            var report, reportPages, index, newPage, updatedPages, res;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        report = getters.report;
                        reportPages = __spreadArrays(report.report_pages);
                        index = reportPages.findIndex(function (page) { return page.id === payload.id; });
                        newPage = __assign(__assign({}, payload), { 
                            // 複製元の次のページに入れる
                            page_num: payload.page_num ? payload.page_num + 1 : payload.report_parts.length + 1, id: '', report_parts: payload.report_parts.map(function (part) {
                                var contentId = part.content ? part.content.id : '';
                                return __assign(__assign({}, part), { id: '', content_id: contentId });
                            }) });
                        reportPages.splice(index + 1, 0, newPage);
                        updatedPages = reportPages.map(function (page, index) {
                            return __assign(__assign({}, page), { 
                                // TODO: deletedなページとundo/redoの兼ね合いを確認
                                page_num: index + 1 });
                        });
                        commit(ReportEditorMutationTypes.CHANGE_SAVE_STATUS, 'saving');
                        return [4 /*yield*/, reportApiClient.copyReportPage(report.id, updatedPages)];
                    case 1:
                        res = _b.sent();
                        commit(ReportEditorMutationTypes.ADD_UNDO_HISTORY, report);
                        commit(ReportEditorMutationTypes.FETCH_REPORT, res.data.report);
                        commit(ReportEditorMutationTypes.CHANGE_SAVE_STATUS, 'saved');
                        return [2 /*return*/];
                }
            });
        });
    },
    deleteReportPage: function (_a, payload) {
        var commit = _a.commit, getters = _a.getters;
        return __awaiter(this, void 0, void 0, function () {
            var report, reportPages, pageNum, updatedPages, res;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        report = getters.report;
                        reportPages = __spreadArrays(report.report_pages);
                        pageNum = 0;
                        updatedPages = reportPages.map(function (page) {
                            if (page.id === payload.id) {
                                return {
                                    id: page.id,
                                    _destroy: true
                                };
                            }
                            pageNum += 1;
                            return {
                                id: page.id,
                                page_num: pageNum
                            };
                        });
                        commit(ReportEditorMutationTypes.CHANGE_SAVE_STATUS, 'saving');
                        return [4 /*yield*/, reportApiClient.deleteReportPage(report.id, updatedPages)];
                    case 1:
                        res = _b.sent();
                        commit(ReportEditorMutationTypes.ADD_UNDO_HISTORY, report);
                        commit(ReportEditorMutationTypes.FETCH_REPORT, res.data.report);
                        commit(ReportEditorMutationTypes.CHANGE_SAVE_STATUS, 'saved');
                        return [2 /*return*/];
                }
            });
        });
    },
    changeOrderPages: function (_a, payload) {
        var commit = _a.commit, getters = _a.getters;
        return __awaiter(this, void 0, void 0, function () {
            var report, pages, res;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        report = getters.report;
                        pages = payload.map(function (page, index) {
                            return {
                                id: page.id,
                                // TODO: deletedなページとundo/redoの兼ね合いを確認
                                page_num: index + 1
                            };
                        });
                        commit(ReportEditorMutationTypes.CHANGE_SAVE_STATUS, 'saving');
                        return [4 /*yield*/, reportApiClient.changeOrderPages(report.id, pages)];
                    case 1:
                        res = _b.sent();
                        commit(ReportEditorMutationTypes.ADD_UNDO_HISTORY, report);
                        commit(ReportEditorMutationTypes.FETCH_REPORT, res.data.report);
                        commit(ReportEditorMutationTypes.CHANGE_SAVE_STATUS, 'saving');
                        return [2 /*return*/];
                }
            });
        });
    },
    undoHistory: function (_a) {
        var commit = _a.commit, dispatch = _a.dispatch;
        commit(ReportEditorMutationTypes.UNDO_HISTORY);
        debounce(function () {
            dispatch('saveReport');
        });
    },
    redoHistory: function (_a) {
        var commit = _a.commit, dispatch = _a.dispatch;
        commit(ReportEditorMutationTypes.REDO_HISTORY);
        debounce(function () {
            dispatch('saveReport');
        });
    }
};
