var aboveProcessRows = function (smallProcesses, targetProcess, targetRowPosition) {
    var rowPositions = smallProcesses.map(function (sp) {
        if (targetProcess && targetRowPosition && targetProcess.id === sp.id) {
            return targetRowPosition;
        }
        return sp.row_position;
    });
    var maxRowPosition = Math.max.apply(Math, rowPositions);
    var emptyRowPosition = 0;
    Array.from({ length: maxRowPosition }).forEach(function (_, index) {
        if (!rowPositions.includes(index + 1)) {
            emptyRowPosition = index + 1;
        }
    });
    if (!emptyRowPosition)
        return [];
    var targetProcesses = smallProcesses
        .filter(function (sp) { return sp.row_position > emptyRowPosition; })
        .map(function (_a) {
        var id = _a.id, row_position = _a.row_position;
        return ({
            small_process_id: id,
            row_position: row_position - 1
        });
    });
    if (targetRowPosition && targetProcess && targetRowPosition > emptyRowPosition) {
        targetProcesses.push({
            small_process_id: targetProcess.id,
            row_position: targetRowPosition - 1
        });
    }
    return targetProcesses;
};
export default aboveProcessRows;
