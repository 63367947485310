var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import BaseApiClient from '@/plugins/apis/base-api-client';
import { UserTypes } from '@/types/company';
var ProjectUserApiClient = /** @class */ (function (_super) {
    __extends(ProjectUserApiClient, _super);
    function ProjectUserApiClient() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(ProjectUserApiClient.prototype, "basePath", {
        get: function () {
            var companyId = this.companyId;
            return "/companies/" + companyId + "/projects";
        },
        enumerable: false,
        configurable: true
    });
    ProjectUserApiClient.prototype.fetchProjectUsers = function (projectId) {
        var _a = this, client = _a.client, basePath = _a.basePath;
        var url = basePath + "/" + projectId + "/project_users";
        return client.get(url, { params: { limit: 500 } });
    };
    ProjectUserApiClient.prototype.invitedProjectMembers = function (projectId, emails) {
        var _a = this, client = _a.client, basePath = _a.basePath;
        var url = basePath + "/" + projectId + "/invite_members";
        var params = {
            user: { emails: emails }
        };
        return client.post(url, params);
    };
    ProjectUserApiClient.prototype.deleteProjectMember = function (projectId, id) {
        var basePath = this.basePath;
        var url = basePath + "/" + projectId + "/project_users/" + id;
        return this.requestAction('delete', url, {}, 'delete_member', 'OTHER', false);
    };
    ProjectUserApiClient.prototype.createInvitationLink = function (_a) {
        var projectId = _a.projectId, _b = _a.user_type, user_type = _b === void 0 ? UserTypes.Guest : _b;
        var basePath = this.basePath;
        var url = basePath + "/" + projectId + "/invited_users/invitation_link";
        return this.requestAction('post', url, { user_type: user_type }, 'create_invitation_link', 'OTHER', false);
    };
    ProjectUserApiClient.prototype.deleteIvitationLink = function (_a) {
        var projectId = _a.projectId;
        var basePath = this.basePath;
        var url = basePath + "/" + projectId + "/invited_users/delete_invitation_link";
        return this.requestAction('delete', url, {}, 'delete_invitation_link', 'OTHER', false);
    };
    ProjectUserApiClient.prototype.inviteMembersWithSms = function (payload) {
        var _a = this, basePath = _a.basePath, requestAction = _a.requestAction;
        var id = payload.id, invites = payload.invites;
        var url = basePath + "/" + id + "/invite_members_with_sms";
        var data = {
            members: invites
        };
        return requestAction('post', url, data, 'create_invite', 'OTHER', false);
    };
    return ProjectUserApiClient;
}(BaseApiClient));
export default new ProjectUserApiClient();
