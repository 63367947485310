var _a;
import { PolicyMutationTypes } from '@/types/policies';
export var state = function () { return ({
    // NOTE
    // initial-settingsのpoliciesは案件にアサインされているか否かを判定されていない。
    // こちらのpoliciesは、projectを取得時にセットする
    policies: {
        board_posts: {
            index: false,
            create: false
        },
        board_post_comments: {
            index: false,
            create: false
        },
        board_categories: {
            index: false
        },
        companies: {},
        construction_kinds: {
            index: false,
            everything: false,
            create: false
        },
        contents: {
            index: false,
            show: false,
            create: false
        },
        customers: {
            index: false,
            create: false
        },
        invited_users: {
            index: false,
            mass_destroy: false
        },
        project_statuses: {
            index: false,
            everything: false,
            create: false,
            mass_update: false
        },
        project_users: {
            index: false
        },
        projects: {
            index: false,
            create: false
        },
        reports: {
            index: false,
            create: false
        },
        users: {
            index: false
        },
        schedules: {
            index: false,
            create: false
        },
        large_processes: {
            index: false,
            create: false
        },
        small_processes: {
            index: false,
            create: false
        },
        published_schedules: {
            index: false,
            work_list: false,
            create: false
        },
        pdf_covers: {},
        me: {}
    }
}); };
export var getters = {
    policies: function (state) {
        return state.policies;
    }
};
export var mutations = (_a = {},
    _a[PolicyMutationTypes.FETCH_POLICIES] = function (state, payload) {
        state.policies = payload;
    },
    _a);
export var actions = {
    fetchPolicies: function (_a, payload) {
        var commit = _a.commit;
        commit(PolicyMutationTypes.FETCH_POLICIES, payload);
    }
};
